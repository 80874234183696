import React, { useState } from 'react';
import { Box, styled, Popover, Typography, MenuItem, useTheme, useMediaQuery, Grid, Paper, Divider, Popper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, TextField } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const Dropdown = ({setAssetType}) => {

    const [isShown, setIsShown] =useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleNo = () => {
        setIsShown(false);
    };
    
    const handleMouseEnter = (e) => {
        setIsShown(true);
        setAnchorEl(e.currentTarget);
    };
    
    const handleClose = () => {
        setIsShown(false);
    }

    const handleMouseLeave = () => {
        setIsShown(false);
    }


    const assetTypes = [
        {
            value: 'applicationServer',
            label: 'Application Server',
            description: 'Application servers are used across many different sectors to host various diverse software applications necessary to supporting the ICS. Example functions can include data analytics and reporting, alarm management, and the management/coordination of different control servers. The application server typically runs on a modern server operating system (e.g., MS Windows Server).',
            example: 'Windows Server 2022, Red Hat Enterprise Linux 9, or other Linux server'
        },
        {
            value: 'controlServer',
            label:'Control Server',
            description: 'Control servers are typically a software platform that runs on a modern server operating system (e.g., MS Windows Server). The server typically uses one or more automation protocols (e.g., Modbus, DNP3) to communicate with the various low-level control devices such as Remote Terminal Units (RTUs) and Programmable Logic Controllers (PLCs). The control server also usually provides an interface/network service to connect with an HMI.',
            example: 'SCADA Server, MTU, Distributed/Energy Management System'
        },
        {
            value: 'dataGateway',
            label: 'Data Gateway',
            description: 'Data Gateway is a device that supports the communication and exchange of data between different systems, networks, or protocols within the ICS. Different types of data gateways are used to perform various functions, including: Protocol Translators, Media Converters, and Data Aggregators. Data gateways are often critical to the forwarding/transmission of critical control or monitoring data within the ICS. Further, these devices often have remote various network services that are used to communicate across different zones or networks.',
            example: 'Data Acquisition Server, Serial to Ethernet Gateway'
        },
        {
            value: 'dataHistorian',
            label: 'Data Historian',
            description: 'Data historians, or historian, are systems used to collect and store data, including telemetry, events, alerts, and alarms about the operational process and supporting devices. The historian typically utilizes a database to store this data, and commonly provide tools and interfaces to support the analysis of the data. Data historians are often used to support various engineering or business analysis functions and therefore commonly needs access from the corporate network.',
            example: 'GE Proficy, Ignition Historian'
        },
        {
            value: 'fieldIO',
            label: 'Field I/O',
            description: 'Field I/O are devices that communicate with a controller or data aggregator to either send input data or receive output data. Input data may include readings about a given environment/device state from sensors, while output data may include data sent back to actuators for them to either undertake actions or change parameter values. These devices are frequently embedded devices running on lightweight embedded operating systems or RTOSes.',
            example: 'Smart Sensors, Variable Frequency Drives'
        },
        {
            value: 'humanMachineInterface', 
            label: 'Human Machine Interface',
            description: 'Human-Machine Interfaces (HMIs) are systems used by an operator to monitor the real-time status of an operational process and to perform necessary control functions, including the adjustment of device parameters. An HMI can take various forms, including a dedicated screen or control panel integrated with a specific device/controller, or a customizable software GUI application.',
            example: 'Operator Workstations, Consoles, or Mounted Touchscreens'
        },
        {
            value: 'intelligentElectronicDevice', 
            label: 'Intelligent Electronic Device',
            description: 'An Intelligent Electronic Device (IED) is a type of specialized field device that is designed to perform specific operational functions, frequently for protection, monitoring, or control within the electric sector. IEDs are typically used to both acquire telemetry and execute tailored control algorithms/actions based on customizable parameters/settings. An IED is usually implemented as a dedicated embedded device and supports various network automation protocols to communicate with RTUs and Control Servers.',
            example: 'Protection Relay, Field Device Controller'
        },
        {
            value: 'jumpHost',
            label: 'Jump Host',
            description: 'Jump hosts are devices used to support remote management sessions into ICS networks or devices. The system is used to access the ICS environment securely from external networks, such as the corporate network. The user must first remote into the jump host before they can access ICS devices. The jump host may be a customized Windows server using common remote access protocols (e.g., RDP) or a dedicated access management device. The jump host typically performs various security functions to ensure the authenticity of remote sessions, including authentication, enforcing access controls/permissions, and auditing all access attempts.',
            example: 'Intermediate System, Bastion Host, or Gateway'
        },
        {
            value: 'programmableLogicController',
            label: 'Programmable Logic Controller',
            description: 'A Programmable Logic Controller (PLC) is an embedded programmable control device. PLCs typically utilize a modular architecture with separate modules used to support its processing capabilities, communication mediums, and I/O interfaces. PLCs allow for the deployment of customized programs/logic to control or monitor an operational process.',
            example:'Process Automation Controller, Field Device Controller'
        }, 
        {
            value: 'remoteTerminalUnit', 
            label: 'Remote Terminal Unit',
            description: 'A Remote Terminal Unit (RTU) is a device that typically resides between field devices (e.g., PLCs, IEDs) and control/SCADA servers and supports various communication interfacing and data aggregation functions. RTUs are typically responsible for forwarding commands from the control server and the collection of telemetry, events, and alerts from the field devices.',
            example: 'Motorola ACE'
        },
        {
            value: 'router',
            label: 'Router',
            description: 'A computer that is a gateway between two networks at OSI layer 3 and that relays and directs data packets through that inter-network. The most common form of router operates on IP packets.',
            example: 'Cisco, Ubiquiti, TP-Link, NetGear'
        },
        {
            value: 'safetyController',
            label: 'Safety Controller',
            description: 'Safety controllers are typically a type of field device used to perform the safety critical function. Safety controllers often support the deployment of custom programs/logic, similar to a PLC, but can also be tailored for sector specific functions/applications.',
            example: 'Safety Instrumented System (SIS) Contoller, Emergency Shutdown Systems (EMS) Controller, Burner Management Systesm (BMS) Controller'
        },
        {
            value: 'virtualPrivateNetworkServer', 
            label: 'VPN Server',
            description: 'A VPN server is a device that is used to establish a secure network tunnel between itself and other remote VPN devices, including field VPNs. VPN servers can be used to establish a secure connection with a single remote device, or to securely bridge all traffic between two separate networks together by encapsulating all data between those networks.',
            example: 'Virtual Private Network (VPN) Terminator, Field VPN, Cisco AnyConnect, Ivanti'
        },
        {
            value: 'workstation', 
            label: 'Workstation',
            description: 'Workstations are devices used by human operators or engineers to perform various configuration, programming, maintenance, diagnostic, or operational tasks. Workstations typically utilize standard desktop or laptop hardware and operating systems (e.g., MS Windows), but run dedicated control system applications or diagnostic/management software to support interfacing with the control servers or field devices.',
            example: 'Transient Cyber Asset (TCA), Engineering Workstation (EWS), Windows 11 Laptop or Desktop, Linux Laptop or Desktop'
        }
    ]

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 500,
          fontSize: theme.typography.pxToRem(16),
          border: '1px solid #dadde9',
        },
      }));

  return (
    <Box>
        <TextField
            required
            id="outlined-select-currency"
            select
            label="Select Asset Type"
            defaultValue=""
            helperText="Please select an asset type."
            fullWidth
            onChange={(e) => setAssetType(e.target.value)}
            >
            {assetTypes.map((option) => (
                <MenuItem key={option.value} value={option.label} >
                <HtmlTooltip placement="right-start" title={
                    <React.Fragment>
                    {option.description} <br /><br />Examples include: {option.example}
                    </React.Fragment>} 
                    >
                    {option.label}
                </HtmlTooltip>
                
                </MenuItem>
                ))}
        </TextField>
    </Box>
    
  )
}

export default Dropdown