import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import WidgetWrapper from '../../../components/WidgetWrapper';
import { SettingsVoiceRounded } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


const Overview = () => {

    const [assessmentData, setAssessmentData] = useState();
    const axiosPrivate = useAxiosPrivate();
    const { assessmentID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const theme = useTheme();        
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

    const [scopingQuestions, setScopingQuestions] = useState(false);
    const [hasInventory, setHasInventory] = useState("");
    const [hasFERCDesignation, setHasFERCDesignation] = useState("");
    const [hasOtherFERCProjects, setHasOtherFERCProjects] = useState("");
    const [hasCybersecurityPlan, setHasCybersecurityPlan] = useState("");
    const [nERCCIPApplies, setNERCCIPApplies] = useState("");
    const [nERCCIPFindingsAddressed, setNERCCIPFindingsAddressed] = useState("");
    const [hasBusinessCyberAssets , setHasBusinessCyberAssets] = useState("");
    const [missionEssentialAssetsIdentified , setMissionEssentialAssetsIdentified] = useState("");
    const [userTraining, setUserTraining] = useState("");
    const [cybersecurityPlanName, setCybersecurityPlanName] = useState('');
    
    // const getCVEs = async () => {
    //     try {
    //         const responsecve = await fetch("https://cve.circl.lu/api/browse/microsoft", {
    //             headres: {
    //                 Accept: 'application/json',
    //                 "Content-Type": "application/json"
    //             },
    //             method: "GET"
    //         });
    //         console.log(responsecve.data);
    //     } catch (err) {
    //         // alert("Login Expired.");
    //         console.error(err);
    //         // navigate('/login', { state: { from: location }, replace: true });
    //     }
    // }

    // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
    const getAssessmentData = async () => {
        try {
            const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
                withCredentials: true
            });
            const data = await response.data;
            // console.log('OVerview DATA IS:');
            // console.log(data?.scopingQuestions?.toString());
            // console.log("Current Assessment Data is:");
            // console.log(data);
            setAssessmentData(data);
            setScopingQuestions(data?.scopingQuestions);
            setHasInventory(data?.hasInventory);
            setHasFERCDesignation(data?.hasFERCDesignation)
            setHasOtherFERCProjects(data?.hasFERCDesignation);
            setHasCybersecurityPlan(data?.hasCybersecurityPlan);
            setCybersecurityPlanName(data?.cybersecurityPlanName);
            setNERCCIPApplies(data?.nERCCIPApplies);
            setNERCCIPFindingsAddressed(data?.nERCCIPFindingsAddressed);
            setHasBusinessCyberAssets(data?.hasBusinessCyberAssets);
            setMissionEssentialAssetsIdentified(data?.missionEssentialAssetsIdentified);
            setUserTraining(data?.userTraining);
        } catch (err) {
            alert("Login Expired. Overview");
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
        }
    };
  
    useEffect(() => {
        getAssessmentData();
        // getCVEs();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!assessmentData) {
        return null;
      }

    // Direct the user to the asset inventory guidance or let them add cyber assets. 
    // const handleScopingQuestions = () => {
    //     if (hasFERCDesignation!==null && hasInventory!==null && hasOtherFERCProjects!==null && hasCybersecurityPlan!==null && hasCybersecurityPlan!==null && cybersecurityPlanName!==null && nERCCIPApplies!==null && nERCCIPFindingsAddressed!==null && hasBusinessCyberAssets!==null && missionEssentialAssetsIdentified!==null && ) {
    //         setScopingQuestions(true);
    //     }
    //     setScopingQuestions(true);
    // }

    const buttonStyle = {
        'backgroundColor': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    }

    const handleInventoryGuidance = () => {
        navigate('/Training/Inventory');
    }

    const handleCybersecurityPlan = () => {
        navigate('/Training/CybersecurityPlan');
    }

    const handleUserTraining = () => {
        navigate('/Training/Training');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let scopingQuestions = true;
        const cybersecurityOverview = JSON.stringify({ hasInventory, hasFERCDesignation, hasOtherFERCProjects, hasCybersecurityPlan, cybersecurityPlanName, nERCCIPApplies, nERCCIPFindingsAddressed, hasBusinessCyberAssets, missionEssentialAssetsIdentified, userTraining });
        try {
            const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
        //    JSON.stringify({ scopingQuestions, hasInventory, hasFERCDesignation, hasOtherFERCProjects, hasCybersecurityPlan, cybersecurityPlanName, nERCCIPApplies, nERCCIPFindingsAddressed, hasBusinessCyberAssets, missionEssentialAssetsIdentified, userTraining }),
            JSON.stringify({ scopingQuestions, cybersecurityOverview }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        // setSecurityGroup(securityGroupLocal);
        getAssessmentData();
        } catch (err) {
           // alert("Login Expired.");
            console.error(err);
            navigate('/login', { state: { from: location }, replace: true });
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }


  return (
    <Box>
        {scopingQuestions && (
            <WidgetWrapper sx={{ mt: '1rem', display: 'flex' }}>
                <Grid container spacing={1} sx={{ display: 'flex' }}>
                    <Grid item xs={9}>
                        <Paper sx={{ mb: '0.5rem', p: '1rem' }}>
                            <Typography sx={{ mt:'0.5rem', mr:'2rem'}}>
                                <b>2. Cyber/SCADA Assessment</b> - THe Cyber/SCADA Assessment is required for all dams. This assessment includes identifying the criticality of cyber assets and the cybersecurity plan for the site. 
                            </Typography>
                        </Paper>
                        {hasInventory==="false" &&
                        <Box sx={{ border: 'solid 1px', padding: '0.5rem' }}>
                            <Typography>
                                FERC requires that you maintain a cyber asset inventory. You do not need to enter your data here, but you will need to present your inventory to FERC auditors during the inspection. 
                            </Typography>
                            <Typography sx={{ m:'0.5rem' }}>
                                <Button sx={{ m:'0.5rem' }} variant='contained' onClick={handleInventoryGuidance}>Help Me With Asset Inventory</Button>
                            </Typography>
                        </Box>
                        }
                        {hasCybersecurityPlan==="false" &&
                        <Box sx={{ border: 'solid 1px', padding: '0.5rem', marginTop: '0.5rem' }}>
                            <Typography>
                                FERC requires that you develop a Cybersecurity Plan. You will need to present your cybersecurity plan to FERC auditors during the inspection. 
                            </Typography>
                            <Typography sx={{ m:'0.5rem' }}>
                                <Button sx={{ m:'0.5rem' }} variant='contained' onClick={handleCybersecurityPlan}>Help Me Make a Cybersecurity Plan</Button>
                            </Typography>
                        </Box>
                        }
                        {userTraining==="false" &&
                        <Box sx={{ border: 'solid 1px', padding: '0.5rem', marginTop: '0.5rem' }}>
                            <Typography>
                                FERC requires that you provide security awareness training to all users prior to allowing access to controls systems and annually. You will need to provide proof of training for all users during the inspection. 
                            </Typography>
                            <Typography sx={{ m:'0.5rem' }}>
                                <Button sx={{ m:'0.5rem' }} variant='contained' onClick={handleUserTraining}>Help Me With User Training</Button>
                            </Typography>
                        </Box>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{border: 'solid 1px', paddingTop: '1rem', paddingLeft: '1rem'}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSiz: "2rem" }}><b>Cyber/SCADA Assessment Progress</b></Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{ paddingTop: '0.5rem' }}><b>Overview</b></Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {scopingQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Scoping Questions</Typography>
                                </Grid>    
                        
                                <Grid item xs={12}>
                                    <Typography><b>Cyber/SCADA Inventory</b></Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.cyberAssetInventoryComplete===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Inventory Complete</Typography>
                                </Grid>    

                                <Grid item xs={12}>
                                    <Typography><b>Cyber/SCADA Asset Designation</b></Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.cyberAssetDesignationComplete===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Asset Designation Complete</Typography>
                                </Grid>  
                                
                                <Grid item xs={12}>
                                    <Typography><b>FERC Cyber/SCADA Checklist</b></Typography>
                                </Grid>
                                {/* <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.cybersecurityChecklistForm3===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Cyber/SCADA Checklist</Typography>
                                </Grid>   */}
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.governanceQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Governance</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.accessControlQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Access Control</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.assetConfigurationManagementQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Asset & Configuration Management</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.intrusionDetectionResponseQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Intrusion Detection & Response</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.securityArchitectureQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Security Architecture</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.riskManagementQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Risk Management</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.systemRestorationRecoveryQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>System Restoration & Recovery</Typography>
                                </Grid>
                                <Grid item xs={1} sx={{paddingLeft: '0.5rem'}}>
                                    {assessmentData?.cyberTrainingQuestions===true ? (<CheckCircleOutlineIcon />) : (<CheckBoxOutlineBlankIcon />)}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Training</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </WidgetWrapper>
        )}
        <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
                <Box
                width="100%"
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background.alt}
                >
                    <section>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                            {errMsg}
                        </p>
                        <h1>Scoping Questions</h1>
                        <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            alignItems="downward"
                            gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Grid container spacing={1} columns={12}>

                                {/* Question 9a */}
                            <Grid item xs={10} >
                            <p>You need a hardware and software asset inventory to continue.  Do you have a complete hardware and software asset inventory?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasInventory"
                                type="radio" 
                                id="hasInventoryYes"
                                value="true"
                                checked={hasInventory==="true"}
                                onChange={(e) => setHasInventory(e.target.value)}
                                
                            />
                            <label htmlFor="hasInventoryYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasInventory"
                                type="radio" 
                                id="hasInventoryNo" 
                                value="false"
                                checked={hasInventory==="false"}
                                onChange={(e) => setHasInventory(e.target.value)}
                                
                            />
                            <label htmlFor="hasInventoryNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>

                                {/* Question 9b */}
                            <Grid item xs={10}>
                            <p>Have your cyber assets already been designated as critical, operational, or non-critical?</p> 
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasFERCDesignation"
                                type="radio" 
                                id="hasFERCDesignationYes"
                                value="true"
                                checked={hasFERCDesignation==="true"}
                                onChange={(e) => setHasFERCDesignation(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasFERCDesignationYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasFERCDesignation"
                                type="radio" 
                                id="hasFERCDesignationNo"
                                value="false"
                                checked={hasFERCDesignation==="false"}
                                onChange={(e) => setHasFERCDesignation(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasFERCDesignationNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            
                             {/* Question 5 */}
                            <Grid item xs={10}>
                            <p>Are other FERC regulated projects controlled by this facility?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasOtherFERCProjects"
                                type="radio" 
                                id="hasOtherFERCProjectsYes"
                                value="true"
                                checked={hasOtherFERCProjects==="true"}
                                onChange={(e) => setHasOtherFERCProjects(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasOtherFERCProjectsYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasOtherFERCProjects"
                                type="radio" 
                                id="hasOtherFERCProjectsNo"
                                value="false"
                                checked={hasOtherFERCProjects==="false"}
                                onChange={(e) => setHasOtherFERCProjects(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasOtherFERCProjectsNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                
                            {/* Question 7a */}
                            <Grid item xs={10}>
                            <p style={{ marginBottom:0, marginTop:'1rem'}}>Does the facility/project have a Cyber/Industrial Control System (e.g. SCADA) Security Plan whether a standalone plan or part of another document?</p> 
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}> 
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasCybersecurityPlan"
                                type="radio" 
                                id="hasCybersecurityPlanYes"
                                value="true"
                                checked={hasCybersecurityPlan==="true"}
                                onChange={(e) => setHasCybersecurityPlan(e.target.value)}
                            />
                            <label htmlFor="hasCybersecurityPlanYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasCybersecurityPlan"
                                type="radio" 
                                id="hasCybersecurityPlanNo"
                                value="false"
                                checked={hasCybersecurityPlan==="false"}
                                onChange={(e) => setHasCybersecurityPlan(e.target.value)}
                            />  
                            <label htmlFor="hasCybersecurityPlanNo">No</label>
                            </Grid>
                            <Grid item xs={8}>
                            <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="cybersecurityPlanName">Enter the plan's name:</label>
                            <input style={{ width:'30%', marginLeft:'3rem'}}
                                type="text" 
                                id="cybersecurityPlanName"
                                value={cybersecurityPlanName || ""}
                                onChange={(e) => setCybersecurityPlanName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                            
                             {/* Question 8a */}
                            <Grid item xs={10}>
                            <p>Does the project have any (hydroelectric) cyber assets which are subject to NERC-CIP Standards?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="nERCCIPApplies"
                                type="radio" 
                                id="nERCCIPAppliesYes"
                                value="true"
                                checked={nERCCIPApplies==="true"}
                                onChange={(e) => setNERCCIPApplies(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="nERCCIPAppliesYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="nERCCIPApplies"
                                type="radio" 
                                id="nERCCIPAppliesNo"
                                value="false"
                                checked={nERCCIPApplies==="false"}
                                onChange={(e) => setNERCCIPApplies(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="nERCCIPAppliesNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                        
                            {/* Question 8b */}
                            <Grid item xs={10}>
                            <p>If a NERC-CIP compliance audit has been performed, have all identified deficiencies been addressed?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="nERCCIPFindingsAddressed"
                                type="radio" 
                                id="nERCCIPFindingsAddressedYes"
                                value="true"
                                checked={nERCCIPFindingsAddressed==="true"}
                                onChange={(e) => setNERCCIPFindingsAddressed(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="nERCCIPFindingsAddressedYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="nERCCIPFindingsAddressed"
                                type="radio" 
                                id="nERCCIPFindingsAddressedNo"
                                value="false"
                                checked={nERCCIPFindingsAddressed==="false"}
                                onChange={(e) => setNERCCIPFindingsAddressed(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="nERCCIPFindingsAddressedNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            
                             {/* Question 10 */}
                            <Grid item xs={10}>
                            <p>Does the facility/project have Business Cyber Assets (non-industrial control systems which include corporate email, human resources, company website, etc.)?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasBusinessCyberAssets"
                                type="radio" 
                                id="hasBusinessCyberAssetsYes"
                                value="true"
                                checked={hasBusinessCyberAssets==="true"}
                                onChange={(e) => setHasBusinessCyberAssets(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasBusinessCyberAssetsYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasBusinessCyberAssets"
                                type="radio" 
                                id="hasBusinessCyberAssetsNo"
                                value="false"
                                checked={hasBusinessCyberAssets==="false"}
                                onChange={(e) => setHasBusinessCyberAssets(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="hasBusinessCyberAssetsNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            
                             {/* Question 20 */}
                            <Grid item xs={10}>
                            <p>Have you identified the systems, assets, information, and processes that are essential to your organizational mission?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="missionEssentialAssetsIdentified"
                                type="radio" 
                                id="missionEssentialAssetsIdentifiedYes"
                                value="true"
                                checked={missionEssentialAssetsIdentified==="true"}
                                onChange={(e) => setMissionEssentialAssetsIdentified(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="missionEssentialAssetsIdentifiedYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="missionEssentialAssetsIdentified"
                                type="radio" 
                                id="missionEssentialAssetsIdentifiedNo"
                                value="false"
                                checked={missionEssentialAssetsIdentified==="false"}
                                onChange={(e) => setMissionEssentialAssetsIdentified(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="missionEssentialAssetsIdentifiedNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>


                             {/* Question XX */}
                            <Grid item xs={10}>
                            <p>Does your organization have basic cybersecurity training for all users?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="userTraining"
                                type="radio" 
                                id="userTrainingYes"
                                value="true"
                                checked={userTraining==="true"}
                                onChange={(e) => setUserTraining(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="userTrainingYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="userTraining"
                                type="radio" 
                                id="userTrainingNo"
                                value="false"
                                checked={userTraining==="false"}
                                onChange={(e) => setUserTraining(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="userTrainingNo">No</label>
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>
                            </Grid>
                        {/* <button disabled={( hasFERCDesignation==="" && hasInventory==="" && hasOtherFERCProjects==="" && hasCybersecurityPlan==="" && hasCybersecurityPlan!==null && nERCCIPApplies==="" && nERCCIPFindingsAddressed==="" && hasBusinessCyberAssets==="" && missionEssentialAssetsIdentified==="" )} style={buttonStyle}>Submit</button> */}
                        <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
                        {/* disabled={( hasFERCDesignation==="" || hasInventory==="" || hasOtherFERCProjects==="" || hasCybersecurityPlan==="" || nERCCIPApplies==="" || nERCCIPFindingsAddressed==="" || hasBusinessCyberAssets==="" || missionEssentialAssetsIdentified==="" || userTraining==="" )} */}
                        </Box>
                        </form>
                    </section>
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}

export default Overview