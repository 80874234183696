import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Collapse, Typography, useTheme, Grid, Divider, 
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, IconButton } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from '../../../components/DataGridCustomToolbar';
import Header from "../../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';



const CriticalAssets = ({activeStep, setActiveStep}) =>  {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();

  const date = new Date();
  const currentYear = date.getFullYear();

  const [assetToDelete, setAssetToDelete] = useState(null);
  const [criticalAssetName, setCriticalAssetName] = useState(null);

  const [open, setOpen] = useState(false);
  const [userAddedCATemp , setUserAddedCATemp] = useState();
  const [openInstructions, setOpenInstructions] = useState(true);

  const {assessmentID} = useParams();
  const [refresh, setRefresh] = useState(true);


   // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
const getCriticalAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setRows(data);
    console.log(data);
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};

  useEffect(() => {
      getCriticalAssets();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!rows) {
      return null;
    }

  
  // Update Database Function 
  const handleAddCyberAsset = () => {
    setRows(null);
  }

  const handleRowEditStop = (id, event) => {
    if (id.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row) => () => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
  };

  // Update Database Function 
  const handleCyberAssetUpdate = async (updatedRow) => {
    let id = updatedRow._id;
    let assetName = updatedRow.assetName;
    let assetType = updatedRow.assetType;
    let description = updatedRow.description;
    let riskManagementDecision = updatedRow.riskManagementDecision;
    const cyberAssetData = JSON.stringify({  description, riskManagementDecision });
    try {
      const response = await axiosPrivate.patch(`/criticalassets/criticalasset/${id}`,
        JSON.stringify({ assessmentID, assetName, assetType, description, riskManagementDecision }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
      }
    };

  const handleSaveClick = (row) => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    handleCyberAssetUpdate(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeleteAsset = async () => {
    try {
        const response = await axiosPrivate.delete(`/criticalassets/${assetToDelete}`, 
            ({ "active": false }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
        // console.log(response);
    } catch (err) {
        console.error(err);
    }
     setAssetToDelete(null);
     setCriticalAssetName(null);
     setRefresh(!refresh);
 }
 
 const handleClickOpen = (params) => {
    setAssetToDelete(params.row._id);
    setCriticalAssetName(params.row.assetName || "this device");
    setOpen(true);
  };

const handleNo = () => {
  setOpen(false);
};

const handleYes = () => {
    handleDeleteAsset();
    setOpen(false);
};

const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
      editable: true
  },
  {
      field: "assetType",
      headerName: "Asset Type",
      flex: 1,
      editable: true
  },
  {
      field: "description",
      headerName: "Description",
      flex: 2,
      editable: true
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 250,
    cellClassName: 'actions',
    getActions: (params) => {
      const isInEditMode = rowModesModel[params.row._id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => handleSaveClick(params.row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.row._id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleClickOpen(params)}
          color="inherit"
        />,
      ];
    },
  },
];


const handleAddNewCriticalAsset = async () => {
  if (userAddedCATemp.length>1) {
    let assetName = userAddedCATemp;
    let riskManagementDecision = "";
    let active = true;
    try {
      const response = await axiosPrivate.post(`/criticalassets/criticalasset/new`,
        JSON.stringify({ assessmentID, assetName, active }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      } 
    );
    } catch (err) {
        console.log(err);
        
    }
  }
  setUserAddedCATemp("");
  setRefresh(!refresh);
};

const DefaultCriticalAssets = [
  {
    assetName: 'Dam Structure',
    assetType: 'dam',
  },
  {
    assetName: 'Penstocks',
    assetType: 'penstocks',
  },
  {
    assetName: 'Spillway Gate Control',
    assetType: 'spillwayGateControl',
  },
  {
    assetName: 'Intake Units',
    assetType: 'intakeUnits',
  },
  {
    assetName: 'Transformers',
    assetType: 'transformers',
  },
  {
    assetName: 'Powerhouse',
    assetType: 'powerhouse',
  },
  {
    assetName: 'Turbines',
    assetType: 'turbines',
  },
  {
    assetName: 'Control Room',
    assetType: 'controlRoom',
  },
  {
    assetName: 'Server Room',
    assetType: 'serverRoom',
  },
  {
    assetName: 'Switchyard',
    assetType: 'switchyard',
  },
  {
    assetName: 'Substation',
    assetType: 'substation',
  },
  {
    assetName: 'Abutments',
    assetType: 'abutments',
  },
  {
    assetName: 'Transmission',
    assetType: 'transmission',
  },
  {
    assetName: 'Fishway Structure Penetrations',
    assetType: 'fishwayStructurePenetrations',
  },
  {
    assetName: 'Recreation Structure Penetration',
    assetType: 'recreationStructurePenetration',
  },
  {
    assetName: 'Visitor Center',
    assetType: 'visitorCenter',
  },
  {
    assetName: 'Maintenance Galleries',
    assetType: 'maintenanceGalleries',

  },

  {
    assetName: 'Domestic Water',
    assetType: 'domesticWater',
      
  },
  {
    assetName: 'HazMat Storage',
    assetType: 'hazMatStorage',
  },
];

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};

const handleNext = () => {
  setActiveStep(activeStep+1)
}


  return (
    <Box sx={{ marginTop:'1em' }}>
      <Header title='Critical Assets' subtitle='Add critical assets.' />
      <Grid container spacing={2}>
        <Grid item xs={12} >
        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
          <Grid item xs={12} sx={{ p:'0.5rem' }}>
          {openInstructions ? (
              <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
              </IconButton>
                ) : (
                <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                  <ExpandCircleDownIcon color='primary' fontSize="large" />
                  <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                </IconButton>
                )}
              <Divider sx={{ border:0.5, mt:'6px' }}/>
          </Grid>
          <Collapse in={openInstructions} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ ml:'2rem' }}>
                <Typography>Add critical assets from the drop-down or define your own. Click on the Click on the <DeleteIcon sx={{ cursor:'text' }}  /> to remove an asset. Click on the <EditIcon sx={{ cursor:'text' }}  /> to make changes to the default critical assets. </Typography>
                <Typography>Enter the name of the restricted area and press the <AddCircleIcon color="primary" /> to add a new critical asset.</Typography>
                <Typography>This information is necessary to complete the secuirty assessment.</Typography>
                <Typography>This information is included in the final Site Security Plan.</Typography>
              </Grid>
            </Grid>
          </Collapse>
          </Paper>
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', mt:'1rem', p:'4px', minHeight:'250px'}} >
              <Grid item xs={12} >
              <Typography display="inline" sx={{ fontSize:'1.5em', marginTop:'0.5em', p:'0.5em'  }}>Critical Assets:</Typography>
                <input style={{ height: '40px', width: '20%', marginLeft: '2rem' }}
                  type="text"
                  id="userAddedCATemp"
                  onChange={(e) => setUserAddedCATemp(e.target.value)}
                />
                <select style={{ height: '40px', width: '20%', marginLeft: '2rem'}} onChange={(e) => setUserAddedCATemp(e.target.value)}> 
                  {DefaultCriticalAssets.map((asset) => (
                    <option value={asset.assetName}>{asset.assetName}</option>
                  ))}
               </select> 
                <IconButton color="primary"  aria-label="add a critical asset" onClick={handleAddNewCriticalAsset}>
                  <AddCircleIcon fontSize="large" />
                </IconButton>
                <Button sx={{ ml:'5rem', mr:'5%'}} variant="contained" onClick={handleNext}>Save & Continue</Button>
              </Grid>

              <Grid item xs={12} sx={{ width: '99%' }}>
                <Dialog
                  open={open}
                  onClose={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  >
                  <DialogTitle id="alert-dialog-title">
                  {"Verify Delete Command"}
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete {criticalAssetName}?
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleYes}>Yes</Button>
                  <Button onClick={handleNo} autoFocus>
                      No
                  </Button>
                  </DialogActions>
                </Dialog>
                <Box
                  sx={{
                    width: '100%',
                    fontSize: '2em',
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.secondary.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "solid 2px black",
                        borderTop: "solid 2px black",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiDataGrid-main": {
                      fontSize: "1.5em"
                    }
                  }}
                  >
                  <DataGrid
                    rows={rows || []} 
                    getRowId={(row) => row._id}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: DataGridCustomToolbar,
                    }}
                    slotProps={{
                      toolbar: { setRows, setRowModesModel },
                    }}
                  />
                </Box>
                
              </Grid>
        </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CriticalAssets


{/*
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Typography, IconButton, useTheme, useMediaQuery, Grid, Paper, Divider } from "@mui/material";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';



const CriticalAssets = ({damData, setProcessValue, setTabValue}) => {
  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();

  // update this variable name.
  // const userAddedCriticalAssets = [];
  const [userAddedCAIndex, setUserAddedCAIndex] = useState(0);
  const [allCriticalAssetData , setAllCriticalAssetData] = useState();
  
 
  const [hasDamStructure , setHasDamStructure] = useState();
  const [penstocks, setPenstocks] = useState();
  const [spillwayGateControl, setSpillwayGateControls] = useState();
  const [intakeUnits, setIntakeUnits] = useState();
  const [transformers, setTransformers] = useState();
  const [powerhouse, setPowerhouse] = useState();
  const [turbines, setTurbines] = useState();
  const [controlRoom, setControlRoom] = useState();
  const [serverRoom, setServerRoom] = useState();
  const [switchyard, setSwitchyard] = useState();
  const [substation, setSubstation] = useState();
  const [transmission, setTransmission] = useState();
  const [abutments, setAbutments] = useState();
  const [fishwayStructurePenetrations, setFishwayStructurePenetrations] = useState();
  const [recreationStructurePenetration, setRecreationStructurePenetration] = useState();
  const [visitorCenter, setVisitorCenter] = useState();
  const [maintenanceGalleries, setMaintenanceGalleries] = useState();
  const [domesticWater, setDomesticWater] = useState();
  const [hazMatStorage, setHazMatStorage] = useState();

  const [userAddedCriticalAssets, setUserAddedCriticalAssets] = useState([]);
  const [userAddedCATemp, setUserAddedCATemp] = useState("");
  const [refresh, setRefresh] = useState(true);

  

  // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
const getCriticalAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAllCriticalAssetData(data);
    console.log(data);
    
    for (let i=0; i<=data.length; i++) {
      if (data[i].assetType==='dam') {
        setHasDamStructure(data[i].active);
      }
      if (data[i].assetType==='penstocks') {
        setPenstocks(data[i].active);
      }
      if (data[i].assetType==='spillwayGateControl') {
        setSpillwayGateControls(data[i].active);
      }
      if (data[i].assetType==='intakeUnits') {
        setIntakeUnits(data[i].active);
      }
      if (data[i].assetType==='transformers') {
        setTransformers(data[i].active);
      }
      if (data[i].assetType==='powerhouse') {
        setPowerhouse(data[i].active);
      }
      if (data[i].assetType==='turbines') {
        setTurbines(data[i].active);
      }
      if (data[i].assetType==='controlRoom') {
        setControlRoom(data[i].active);
      }
      if (data[i].assetType==='serverRoom') {
        setServerRoom(data[i].active);
      }
      if (data[i].assetType==='switchyard') {
        setSwitchyard(data[i].active);
      }
      if (data[i].assetType==='substation') {
        setSubstation(data[i].active);
      }
      if (data[i].assetType==='transmission') {
        setTransmission(data[i].active);
      }
      if (data[i].assetType==='abutments') {
        setAbutments(data[i].active);
      }
      if (data[i].assetType==='fishwayStructurePenetrations') {
        setFishwayStructurePenetrations(data[i].active);
      }
      if (data[i].assetType==='recreationStructurePenetration') {
        setRecreationStructurePenetration(data[i].active);
      }
      if (data[i].assetType==='visitorCenter') {
        setVisitorCenter(data[i].active);
      }
      if (data[i].assetType==='maintenanceGalleries') {
        setMaintenanceGalleries(data[i].active);
      }
      if (data[i].assetType==='domesticWater') {
        setDomesticWater(data[i].active);
      }
      if (data[i].assetType==='hazMatStorage') {
        setHazMatStorage(data[i].active);
      }
      if (data[i].assetType==='') {
        setPenstocks(data[i].active);
      }
      if (data[i].assetType==='') {
        setPenstocks(data[i].active);
      }
      if (data[i].assetType==='') {
        setPenstocks(data[i].active);
      }
      if (data[i].assetType==='') {
        setPenstocks(data[i].active);
      }

    }
    if (allCriticalAssetData === null) {
        return;
    }
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};


// **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        const criticalDamData = JSON.parse(data.criticalDamAssetsPageData);
        setAssessmentData(data);
        const damRecord = await axiosPrivate.get(`/dams/${data.damID}`, {
          withCredentials: true
      });
      setCurrentDam(damRecord.data);
      setUserAddedCriticalAssets(data?.criticalAssets);
      setHasDamStructure(criticalDamData.hasDamStructure);
      setPenstocks(criticalDamData.penstocks);
      setSpillwayGateControls(criticalDamData.spillwayGateControl);
      setIntakeUnits(criticalDamData.intakeUnits);
      setTransformers(criticalDamData.transformers);
      setPowerhouse(criticalDamData.powerhouse);
      setTurbines(criticalDamData.turbines);
      setControlRoom(criticalDamData.controlRoom);
      setServerRoom(criticalDamData.serverRoom);
      setSwitchyard(criticalDamData.switchyard);
      setSubstation(criticalDamData.substation);
      setTransmission(criticalDamData.transmission);
      setAbutments(criticalDamData.abutments);
      setFishwayStructurePenetrations(criticalDamData.fishwayStructurePenetrations);
      setRecreationStructurePenetration(criticalDamData.recreationStructurePenetration);
      setVisitorCenter(criticalDamData.visitorCenter);
      setMaintenanceGalleries(criticalDamData.maintenanceGalleries);
      setDomesticWater(criticalDamData.domesticWater);
      setHazMatStorage(criticalDamData.hazMatStorage);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  // Execute these functions on load. Add functions as necessary. 
  useEffect(() => {
    // getDams();
    // getAssessment();
    getCriticalAssets();
  }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps


  const handleSubmit = async ({assetName, assetType, active}) => { 
    for (let i=0; i<=allCriticalAssetData?.length; i++) {
      if (allCriticalAssetData[i].assetName===assetName) {
        let id = allCriticalAssetData[i]._id;
      try {
        const response = await axiosPrivate.patch(`/criticalassets/criticalasset/${id}`,
          JSON.stringify({ assetName, assetType, active }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
          );
          const data = await response.data;
          // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
          // The console log commands below allow you to see the responses from Axios for the response.  
          // navigate(from, { replace: true });
        } catch (err) {
          // alert("Login Expired.");
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
      }}
  
    const DefaultCriticalAssets = [
      {
          assetName: 'Dam Structure',
          assetType: 'dam',
          active: `${hasDamStructure}`
      },
      {
          assetName: 'Penstocks',
          assetType: 'penstocks',
          active: `${penstocks}`
      },
      {
          assetName: 'Spillway Gate Control',
          assetType: 'spillwayGateControl',
          active: `${spillwayGateControl}`
      },
      {
          assetName: 'Intake Units',
          assetType: 'intakeUnits',
          active: `${intakeUnits}`
      },
      {
          assetName: 'Transformers',
          assetType: 'transformers',
          active: `${transformers}`
      },
      {
          assetName: 'Powerhouse',
          assetType: 'powerhouse',
          active: `${powerhouse}`
      },
      {
          assetName: 'Turbines',
          assetType: 'turbines',
          active: `${turbines}`
      },
      {
          assetName: 'Control Room',
          assetType: 'controlRoom',
          active: `${controlRoom}`
      },
      {
          assetName: 'Server Room',
          assetType: 'serverRoom',
          active: `${serverRoom}`
      },
      {
          assetName: 'Switchyard',
          assetType: 'switchyard',
          active: `${switchyard}`
      },
      {
          assetName: 'Substation',
          assetType: 'substation',
          active: `${substation}`
      },
      {
          assetName: 'Abutments',
          assetType: 'abutments',
          active: `${abutments}`
      },
      {
          assetName: 'Transmission',
          assetType: 'transmission',
          active: `${transmission}`
      },
      {
          assetName: 'Fishway Structure Penetrations',
          assetType: 'fishwayStructurePenetrations',
          active: `${fishwayStructurePenetrations}`
      },
      {
          assetName: 'Recreation Structure Penetration',
          assetType: 'recreationStructurePenetration',
          active: `${recreationStructurePenetration}`
      },
      {
          assetName: 'Visitor Center',
          assetType: 'visitorCenter',
          active: `${visitorCenter}`
      },
      {
          assetName: 'Maintenance Galleries',
          assetType: 'maintenanceGalleries',
          active: `${maintenanceGalleries}`
      },

      {
          assetName: 'Domestic Water',
          assetType: 'domesticWater',
          active: `${domesticWater}`
      },
      {
          assetName: 'HazMat Storage',
          assetType: 'hazMatStorage',
          active: `${hazMatStorage}`
      },
  ];


  // **UPDATE Assessment Record 
  const handleUpdate = async (e) => {
    console.log(e);
    // let criticalDamAssets = true;
    // let criticalAssets = userAddedCriticalAssets;
    // for (let i=0; i<=DefaultCriticalAssets?.length; i++) {
    //   let assetName = DefaultCriticalAssets[i].assetName;
    //   let assetType = DefaultCriticalAssets[i].assetType;
    //   let active = DefaultCriticalAssets[i].active;
    //   handleSubmit({ assetName, assetType, active });
    // }

    // if (hasDamStructure) {
    //   const assetName = 'Dam Structure';
    //   const assetType = 'dam';
    //   const active = 
    //   handleSubmit({ assetName, assetType});
    // }
    // if (penstocks) {
    //   const assetName = 'Penstocks';
    //   const assetType = 'penstocks';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (spillwayGateControl) {
    //   const assetName = 'Spillway Gate Control';
    //   const assetType = 'spillwayGateControl';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (intakeUnits) {
    //   const assetName = 'IntakeUnits';
    //   const assetType = 'intakeUnits';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (transformers) {
    //   const assetName = 'Transformers';
    //   const assetType = 'transformers';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (powerhouse) {
    //   const assetName = 'Powerhouse';
    //   const assetType = 'powerhouse';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (turbines) {
    //   const assetName = 'Turbines';
    //   const assetType = 'turbines';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (controlRoom) {
    //   const assetName = 'Control Room';
    //   const assetType = 'controlRoom';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (serverRoom) {
    //   const assetName = 'Server Room';
    //   const assetType = 'serverRoom';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (switchyard) {
    //   const assetName = 'Switchyard';
    //   const assetType = 'switchyard';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (substation) {
    //   const assetName = 'Substation';
    //   const assetType = 'substation';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (abutments) {
    //   const assetName = 'Abutments';
    //   const assetType = 'abutments';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (transmission) {
    //   const assetName = 'Transmission';
    //   const assetType = 'transmission';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (fishwayStructurePenetrations) {
    //   const assetName = 'Fishway Structure Penetrations';
    //   const assetType = 'fishwayStructurePenetrations';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (recreationStructurePenetration) {
    //   const assetName = 'Recreation Structure Penetrations';
    //   const assetType = 'recreationStructurePenetration';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (visitorCenter) {
    //   const assetName = 'Visitor Center';
    //   const assetType = 'visitorCenter';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (maintenanceGalleries) {
    //   const assetName = 'Maintenance Galleries';
    //   const assetType = 'maintenanceGalleries';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (domesticWater) {
    //   const assetName = 'Domestic Water';
    //   const assetType = 'domesticWater';
    //   handleSubmit({ assetName, assetType});
    // }
    // if (hazMatStorage) {
    //   const assetName = 'HazMat Storage';
    //   const assetType = 'hazMatStorage';
    //   handleSubmit({ assetName, assetType});
    // }

    // const criticalDamAssetsPageData = JSON.stringify({
    //   hasDamStructure,
    //   penstocks,
    //   spillwayGateControl,
    //   intakeUnits,
    //   transformers,
    //   powerhouse,
    //   turbines,
    //   controlRoom,
    //   serverRoom,
    //   switchyard,
    //   substation,
    //   abutments,
    //   transmission,
    //   fishwayStructurePenetrations,
    //   recreationStructurePenetration,
    //   visitorCenter,
    //   maintenanceGalleries,
    //   domesticWater,
    //   hazMatStorage,
    //  });
   
  };

  const getId = (arg) => {
    for (let i=0; i<=allCriticalAssetData?.length; i++) {
      if (allCriticalAssetData[i].assetType===arg) {
        return allCriticalAssetData[i]._id;
      }
    }
  };


  const CriticalAssetDefaultList = [
    {
      id: getId("dam", setHasDamStructure),
      variable: hasDamStructure,
      title: "Dam (Structure)",
      onChangeInput: setHasDamStructure
    },
    {
      id: getId("penstocks"),
      variable: penstocks,
      title: "Penstocks",
      onChangeInput: setPenstocks
    },
    {
      id: getId("spillwayGateControl"),
      variable: spillwayGateControl,
      title: "Spillway Gate Control",
      onChangeInput: setSpillwayGateControls
    },
    {
      id: getId("intakeUnits"),
      variable: intakeUnits,
      title: "Intake Units",
      onChangeInput: setIntakeUnits
    },
    {
      id: getId("transformers"),
      variable: transformers,
      title: "Transformers",
      onChangeInput: setTransformers
    },
    {
      id: getId("powerhouse"),
      variable: powerhouse,
      title: "Powerhouse",
      onChangeInput: setPowerhouse
    },
    {
      id: getId("turbines"),
      variable: turbines,
      title: "Turbines",
      onChangeInput: setTurbines
    },
    {
      id: getId("controlRoom"),
      variable: controlRoom,
      title: "Control Room",
      onChangeInput: setControlRoom
    },
    {
      id: getId("serverRoom"),
      variable: serverRoom,
      title: "Server Room",
      onChangeInput: setServerRoom
    },
    {
      id: getId("switchyard"),
      variable: switchyard,
      title: "Switchyard",
      onChangeInput: setSwitchyard
    },
    {
      id: getId("substation"),
      variable: substation,
      title: "Substation",
      onChangeInput: setSubstation
    },
    {
      id: getId("transmission"),
      variable: transmission,
      title: "Transmission",
      onChangeInput: setTransmission
    },
    {
      id: getId("abutments"),
      variable: abutments,
      title: "Abutments",
      onChangeInput: setAbutments
    },
    {
      id: getId("fishwayStructurePenetrations"),
      variable: fishwayStructurePenetrations,
      title: "Fish Structure Penetrations",
      onChangeInput: setFishwayStructurePenetrations
    },
    {
      id: getId("recreationStructurePenetration"),
      variable: recreationStructurePenetration,
      title: "Recreation Structure Penetrations",
      onChangeInput: setRecreationStructurePenetration
    },
    {
      id: getId("visitorCenter"),
      variable: visitorCenter,
      title: "Visitor Center",
      onChangeInput: setVisitorCenter
    },
    {
      id: getId("maintenanceGalleries"),
      variable: maintenanceGalleries,
      title: "Maintenance Galleries",
      onChangeInput: setMaintenanceGalleries
    },
    {
      id: getId("domesticWater"),
      variable: domesticWater,
      title: "Domestic Water Supply",
      onChangeInput: setDomesticWater
    },
    {
      id: getId("hazMatStorage"),
      variable: hazMatStorage,
      title: "HazMat Storage",
      onChangeInput: setHazMatStorage
    }
  ];

 
  const GridItemQuestionXS = 6;
  const GridItemInputXS = 3;
  const GridItemInputSX = 'center';
  const inputWidth = '40px';
  const inputHeight = '40px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;
  const inputStyleWidth = '30px';
  const inputStyleHeight = '30px';
  
  const CheckboxReturn = ({
    _id, 
    variable,
    name,
    title,
    onChangeInput,
    }) => {
  
    return (
      <Grid item xs={4}>
        <input style={{ marginBottom: '1em',  marginLeft: checkboxMarginLeft, width: inputStyleWidth, height: inputStyleHeight}}
        name={name}
        type="checkbox"
        id={_id}
        value={true}
        checked={variable}
        onChange={(e) => handleUpdate(e)}
      />
       <label style={{ fontSize:"1.5em" }} htmlFor={name}>{title}</label>
      </Grid>
  )
  }

  const handleAddNewCriticalAsset = () => {
    if (userAddedCATemp==="") {
      setUserAddedCATemp("");
    } else {
      userAddedCriticalAssets.push(userAddedCATemp);
      setUserAddedCAIndex(userAddedCAIndex+1);
      handleUpdate();
      setUserAddedCATemp("");
    }
  }

  const handleClearRestrictedAreas = async () => {
    let criticalDamAssetsPageData = "";
    let criticalAssets =[];
    setUserAddedCriticalAssets("");
    setHasDamStructure();
    setPenstocks();
    setSpillwayGateControls();
    setIntakeUnits();
    setTransformers();
    setPowerhouse();
    setTurbines();
    setControlRoom();
    setServerRoom();
    setSwitchyard();
    setSubstation();
    setAbutments();
    setTransmission();
    setFishwayStructurePenetrations();
    setRecreationStructurePenetration();
    setVisitorCenter();
    setMaintenanceGalleries();
    setDomesticWater();
    setHazMatStorage();
    try {
      const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
      JSON.stringify({ criticalDamAssetsPageData, criticalAssets }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
  );
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
  setRefresh(!refresh);
  }

  console.log(allCriticalAssetData);

  
  return (
    <Box sx={{ marginTop:'1em', flexGrow: 1 }}>
      <Header title='Critical Assets' subtitle='Add critical assets.' />
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px', minHeight:'250px'}} >
              <Grid item xs={12} sx={{ p:'1rem' }}>
              <Typography sx={{ fontSize:'1.5em' }}><u>Instructions</u>:  Click on the checkbox next to each asset that is a critical asset for {currentDam.damName}. Then click on the <Button sx={{ '&:hover': {
           background:'#00D5FA' }, cursor:'text', fontSize: '10pt' }} disableElevation disableRipple disableFocusRipple variant="contained" >Submit</Button> to add the critical assets to the assessment.</Typography>
              <Typography sx={{ fontSize:'1.5em' }}> Enter the name of the restricted area and press the <AddCircleIcon color="primary" />.  This information is necessary to complete the secuirty assessment. </Typography>
              <Typography sx={{ fontSize:'1.5em' }}>This information is included in the final Site Security Plan.</Typography>
                <Divider sx={{ border:0.5, mt:'6px' }}/>
              </Grid>
              <Grid item xs={12}>
              <Typography display="inline" sx={{ fontSize:'1.5em', marginTop:'0.5em', p:'0.5em'  }}>Currently Defined Critical Assets:</Typography>
                <input style={{ height: '40px', width: '30%', marginLeft: '2rem' }}
                  type="text"
                  value={userAddedCATemp}
                  id="userAddedCATemp"
                  onChange={(e) => setUserAddedCATemp(e.target.value)}
                />
                <IconButton color="primary"  aria-label="add a critical asset" onClick={handleAddNewCriticalAsset} >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
                <Grid item xs={12} sx={{ marginLeft:'0.5em' }}>
                  <Button variant="contained" onClick={handleUpdate}>
                    Submit
                  </Button>
                </Grid>
            </Grid>
              <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <Grid item xs={12} sx={{ p:'0.5em', m:'0.5em', border:'solid 1px'}}>
                <Grid container spacing={1}>
                  {/* {CriticalAssetDefaultList.map(({
                    _id,
                    active,
                    assetName,
                    // onChangeInput
                  }) => (
                  <CheckboxReturn 
                    key={_id}
                    variable={active}
                    name={assetName}
                    title={assetName}
                    // onChangeInput={onChangeInput}
                    />
                    )
                    )}
                  </Grid>
                  <Grid container spacing={1}>
                    {userAddedCriticalAssets && userAddedCriticalAssets.map((asset) => (
                      <Grid item xs={4}>
                      <label style={{ marginBottom: '1em',  marginLeft: checkboxMarginLeft, width: inputStyleWidth, height: inputStyleHeight, fontSize:"1.5em" }} >{asset}</label>
                      </Grid>
                      )
                    )}
                  </Grid>
                  
                </Grid>
                <Grid item xs={12} sx={{ display:'flex', justifyContent:'flex-end' }}>
              <Button variant="contained" onClick={handleClearRestrictedAreas}>
                Clear Restricted Areas List
              </Button>
            </Grid>
              </Grid>
            </Paper>
          </Grid>
         </Grid>
        </Box>  
  )
}

export default CriticalAssets */}