import React, { useState, useContext } from 'react';
import { Position, NodeResizer, useNodeId, useStore } from 'reactflow';
import PanToolIcon from '@mui/icons-material/PanTool';
import { NodeContext } from '../index';

const rx = 0;
const ry = 0;
const width = 200;
const height = 200;




const Group = ({data, selected}) => {

  const [nodes, setNodes] = useContext(NodeContext);
  const nodeID = useNodeId();

  const zoomSelector = (s) => s.transform[2];
  const zoomLevel = useStore(zoomSelector);
  const variableFontSize = Math.floor(20 / useStore(zoomSelector)) + 'pt';
  const variableHeight = Math.floor(40 / useStore(zoomSelector)) + 'px';
  const variableWidth = Math.floor( 300 / useStore(zoomSelector)) + 'px';
  console.log(variableFontSize);

  const handleNodeLabelUpdate = (e) => {
    console.log(e.target.value);
    for (let index in nodes) {
      if (nodes[index].id === nodeID) {
        nodes[index].data.label = e.target.value;
        setNodes(nodes);
      }
    }
  }



  return (
    <>        
      <NodeResizer isVisible={selected} />
      {zoomLevel < 0.14 ? (
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', position: 'relative', 
          height: `${variableHeight}`, maxHeight: '25%', 
          width: `${variableWidth}`, resize: 'none', overflow: "hidden", maxWidth: '100%', top: 0, fontSize: `140pt`, textAlign: 'center' }} onChange={(e) => handleNodeLabelUpdate(e)} >{data.label}</textarea>
      ) : (
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', position: 'relative', 
          height: `${variableHeight}`, maxHeight: '25%', 
          width: `${variableWidth}`, resize: 'none', overflow: "hidden", maxWidth: '100%', top: 0, fontSize: `${variableFontSize}`, textAlign: 'center' }} onChange={(e) => handleNodeLabelUpdate(e)} >{data.label}</textarea>
      )}
      
      {/* <PanTool /> */}
    </>
  )
};

function PanTool() {
  return (
    <>
      <div>
        {/* Use the class specified at node.dragHandle here */}
        <span className="custom-drag-handle" >
          <PanToolIcon sx={{ fontSize: 'small', position: 'absolute', top: 5, right: 5 }} />
        </span>
      </div>
    </>
  );
}


export default Group;