import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, IconButton, FormControlLabel, Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';



const ConsequenceDetermination = ({questionIndex, setQuestionIndex}) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [allCyberAssetData, setAllCyberAssetData] = useState();  
  const theme = useTheme();
  const { assessmentID } = useParams();

  const [openInstructions, setOpenInstructions] = useState(true);


  // Get cyberAssets Function.  This should pull cyberAssets for the current assessment.  Only assets that answered Yes to one of the Remote Access questions. 
  const getCyberAssetsDesignationCD = async () => {
    try {
      const response = await axiosPrivate.get(`/assets/designationcd/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAllCyberAssetData(data);

    if (allCyberAssetData === null) {
        return;
    }

    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
      getCyberAssetsDesignationCD();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!allCyberAssetData) {
      return null;
      }

  // Determine FERC Designation from questionnaire answers.
  // const determineFERCDesignation = () => {
  //   for (let index in allCyberAssetData) {
  //       if (allCyberAssetData[index].fercDesignation==="") {
  //           if (rows[index].remoteAccess==="no" && rows[index].remoteAccessConnected==='no') {
  //               rows[index].fercDesignation = 'Non-Critical';
            // } else if (rows[index].consequenceDetermination==='no') {
            //     rows[index].fercDesignation = 'Non-Critical';
            // } 
            // if (rows[index].consequenceDetermination==='yes') {
            //     if (rows[index].blackStart==='no' && rows[index].powerGen100W==='no' && rows[index].powerGen1500W==='no' && rows[index].populationAtRisk==='no' && rows[index].municipal==='no' ) {
            //         rows[index].fercDesignation = 'Non-Critical';
            //     } else if (rows[index].populationAtRisk==='yes' || rows[index].municipal==='yes' || rows[index].powerGen1500MW==='yes') {
            //         rows[index].fercDesignation = 'Critical';
            //     } else if (rows[index].populationAtRisk==='no' && rows[index].municipal==='no' && rows[index].powerGen1500MW==='no' && rows[index].powerGen100MW==='yes'  ) {
            //     rows[index].fercDesignation = 'Operational';
            //     } else if (rows[index].blackStart==='yes'  ) {
            //         rows[index].fercDesignation = 'Operational';
                // }
  //           }
  //       }
  //   }
  //   handleCyberAssetUpdate();
  //   getCyberAssets();
  // }
  
  
    // Remote Access Determination Questions
  const assetDeterminationQuestions = [
    {
      id: 'consequenceDetermination',
      questionText: 'Could a compromise of the cyber asset dam result in any of the following to this dam or a connected dam:',
      questionTextAug1: '1. dam failure, consider cascading effects from operational loss could cause the dam to fail, similar to the 2009 Sayano-Shushenskaya incident;',
      questionTextAug2: '2. loss of power generation, access to power generation data, or power generation control?',
      questionTextAug3: '3. release of water from the reservoir, assume all gates are open with no response for 48 hours?',
      questionNumber: '3. ',
      questionTitle: 'Cyber Asset Designation: Consequence Determination',
    }
  ]


// Update Database Function 
const handleCyberAssetUpdate = async () => {
  for (let updatedRow in allCyberAssetData) {
    let id = allCyberAssetData[updatedRow]._id;
    let consequenceDetermination = allCyberAssetData[updatedRow].consequenceDetermination;
    let fercDesignation = allCyberAssetData[updatedRow].fercDesignation;
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "consequenceDetermination": consequenceDetermination, "fercDesignation": fercDesignation },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  };

const UpdateRemoteAccessQuestions = (e, params) => {
  for (let index in allCyberAssetData) {
    if (allCyberAssetData[index]._id===params.row._id) {
        allCyberAssetData[index].consequenceDetermination = e;
        if (allCyberAssetData[index].consequenceDetermination==='no') {
          allCyberAssetData[index].fercDesignation = 'Non-Critical';
        }
      }
  
  }
}

const handleNextQuestion = async () => {
  const waiteForIt = await handleCyberAssetUpdate();
  setQuestionIndex(questionIndex+1);
  setAllCyberAssetData(null);
  getCyberAssetsDesignationCD();
}

const handlePreviousQuestion = () => {
  setQuestionIndex(questionIndex-1);
}


// Columns for MUI Data Grid
const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
  },
  {
      field: "assetType",
      headerName: "Asset Type/Function",
      flex: 1,
},
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
  },
  {
      field: "description",
      headerName: "Description",
      flex: 1,
  },
  {
      field: "macAddress",
      headerName: "MAC Address",
      flex: 0.5,
  },
  {
      field: "yearPurchased",
      headerName: "Year Purchased (approximate)",
      flex: 0.5,
  },
  {
      field: "physicalLocation",
      headerName: "Physical Location",
      flex: 1,
  },
  {
    field: "Answer",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            }
          }}>

        <RadioGroup row>
          <FormControlLabel
          label="Yes"
          control={
            <Radio
              value="yes"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
          <FormControlLabel
          label="No"
          control={
            <Radio
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
        </RadioGroup>
        </Box>
      )
    }
  }
];

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};


  return (
    <Box sx={{ margin: '1rem' }}>
    <Grid container spacing={2}> 

  {/** Question box to determine Remote Operations and Consequences. */}
    {questionIndex===2 ? ( 
      <Grid item xs={12}>
        <Paper sx={{ marginTop:'1rem', backgroundImage: "none", borderRadius: "0.55rem", mr:'0.55rem', p:'1rem', minHeight:'250px'}} >
          <Typography>
              {assetDeterminationQuestions[0].questionTitle}
          </Typography>
        
          <Box sx={{ fontSize: 24, p: '2rem', mt: '1rem' }}>
            {assetDeterminationQuestions[0].questionText}
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
            {assetDeterminationQuestions[0].questionTextAug1 || ""}
            </Typography>
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
              {assetDeterminationQuestions[0].questionTextAug2 || ""}
            </Typography>
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
              {assetDeterminationQuestions[0].questionTextAug3 || ""}
            </Typography>
          </Box>
             
            {questionIndex>0 ? (
              <Grid sx={{ display:'flex', justifyContent:'space-between' }}>
                <Button variant="contained" onClick={handlePreviousQuestion}>Previous Question</Button>
                <Button variant="contained" onClick={handleNextQuestion}>Next Question</Button>
              </Grid>
              ):(
                <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
              )}
          {/* <Grid>
            <Typography>
              <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
            </Typography> 
          </Grid> */}
        </Paper>
      </Grid> 
    ) : (
      <Typography>
        move to consequence Threshold quesitons.
      </Typography>
    )} 
          {/** DATA GRID OF CYBER ASSETS */}
          <Grid container spacing={2}>
          <Box sx={{ mt:'1rem', flexGrow: 1, padding: "1.5rem 1.5rem 0.75rem 2rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
      <Grid item xs={12} >
        <Box
          sx={{
            height: 500,
            width: '100%',
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.secondary.alt,
                color: theme.palette.secondary[100],
                borderBottom: "solid 2px black",
                borderTop: "solid 2px black"
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary[100],
                borderTop: "none"
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
            },
        }}
        >
          <DataGrid
            rows={allCyberAssetData || []} 
            getRowId={(row) => row._id}
            columns={columns}
            editMode="row"
            loading={!allCyberAssetData}
          />
        </Box>
      </Grid>
    </Box>
          </Grid>
    </Grid>
  </Box>
  );
};

export default ConsequenceDetermination