import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const RiskManagementQuestions = ({questionFamily, setQuestionFamily}) => {


    // *************** Questionnaire specific Variables***************//
  
    const [hasRiskManagementStrategy, setHasRiskManagementStrategy] = useState("");
    const [doesPeriodicRiskAssessments, setDoesPeriodicRiskAssessments] = useState("");
    const [doesAssessWirelessUse, setDoesAssessWirelessUse] = useState("");
    const [hasOrgWideRiskParticipation, setHasOrgWideRiskParticipation] = useState("");
    const [includesSupplyChainRiskManagement, setIncludesSupplyChainRiskManagement] = useState("");
    const [addressesInsiderThreat, setAddressesInsiderThreat] = useState("");
    const [doesThreatAssessment, setDoesThreatAssessment] = useState("");
    const [doesIndependentThreatAssessment, setDoesIndependentThreatAssessment] = useState("");
    const [hasRiskManagementStrategyComments, setHasRiskManagementStrategyComments] = useState("");
    const [doesPeriodicRiskAssessmentsComments, setDoesPeriodicRiskAssessmentsComments] = useState("");
    const [doesAssessWirelessUseComments, setDoesAssessWirelessUseComments] = useState("");
    const [hasOrgWideRiskParticipationComments, setHasOrgWideRiskParticipationComments] = useState("");
    const [includesSupplyChainRiskManagementComments, setIncludesSupplyChainRiskManagementComments] = useState("");
    const [addressesInsiderThreatComments, setAddressesInsiderThreatComments] = useState("");
    const [doesThreatAssessmentComments, setDoesThreatAssessmentComments] = useState("");
    const [doesIndependentThreatAssessmentComments, setDoesIndependentThreatAssessmentComments] = useState("");
    const [hasSituationAwareness, setHasSituationAwareness] = useState("");
    const [hasInformationSharing, setHasInformationSharing] = useState("");
    const [hasSituationAwarenessComments, setHasSituationAwarenessComments] = useState("");
    const [hasInformationSharingComments, setHasInformationSharingComments] = useState("");

   // *************** Questionnaire specific Variables***************//

  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();     

  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setHasRiskManagementStrategy(data?.hasRiskManagementStrategy?.toString());
    setDoesPeriodicRiskAssessments(data?.doesPeriodicRiskAssessments?.toString());
    setDoesAssessWirelessUse(data?.doesAssessWirelessUse?.toString());
    setHasOrgWideRiskParticipation(data?.hasOrgWideRiskParticipation?.toString());
    setIncludesSupplyChainRiskManagement(data?.includesSupplyChainRiskManagement?.toString());
    setAddressesInsiderThreat(data?.addressesInsiderThreat?.toString());
    setDoesThreatAssessment(data?.doesThreatAssessment?.toString());
    setDoesIndependentThreatAssessment(data?.doesIndependentThreatAssessment?.toString());
    setHasSituationAwareness(data?.hasSituationAwareness?.toString());
    setHasInformationSharing(data?.hasInformationSharing?.toString());
    setHasRiskManagementStrategyComments(data?.hasRiskManagementStrategyComments?.toString());
    setDoesPeriodicRiskAssessmentsComments(data?.doesPeriodicRiskAssessmentsComments?.toString());
    setDoesAssessWirelessUseComments(data?.doesAssessWirelessUseComments?.toString());
    setHasOrgWideRiskParticipationComments(data?.hasOrgWideRiskParticipationComments?.toString());
    setIncludesSupplyChainRiskManagementComments(data?.includesSupplyChainRiskManagementComments?.toString());
    setAddressesInsiderThreatComments(data?.addressesInsiderThreatComments?.toString());
    setDoesThreatAssessmentComments(data?.doesThreatAssessmentComments?.toString());
    setDoesIndependentThreatAssessmentComments(data?.doesIndependentThreatAssessmentComments?.toString());
    setHasSituationAwarenessComments(data?.hasSituationAwarenessComments?.toString());
    setHasInformationSharingComments(data?.hasInformationSharingComments?.toString());

    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }

    if (assessmentData === null) {
        return;
    }
};

useEffect(() => {
    getAssessmentData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }

 // *************** Update to use Questionnaire specific Variables***************//
  const handleSubmit = async (e) => {
    e.preventDefault();
    let riskManagementQuestions = true;
    const riskManagementData = JSON.stringify({ hasRiskManagementStrategy,
      doesPeriodicRiskAssessments,
      doesAssessWirelessUse,
      hasOrgWideRiskParticipation,
      includesSupplyChainRiskManagement,
      addressesInsiderThreat,
      doesThreatAssessment,
      doesIndependentThreatAssessment,
      hasRiskManagementStrategyComments,
      doesPeriodicRiskAssessmentsComments,
      doesAssessWirelessUseComments,
      hasOrgWideRiskParticipationComments,
      includesSupplyChainRiskManagementComments,
      addressesInsiderThreatComments,
      doesThreatAssessmentComments,
      doesIndependentThreatAssessmentComments,
      hasSituationAwareness,
      hasInformationSharing,
      hasSituationAwarenessComments,
      hasInformationSharingComments,
    });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ riskManagementQuestions, riskManagementData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(2) activates the Asset Management questions. 
    setQuestionFamily(6);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

  const GridItemQuestionXS = 7;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '100%';



  //************Add category specific Questions.****************//
  const RiskManagementQuestionsList = [
    {
        // 1	Do you have an enterprise-wide all-hazards risk management strategy?
        //	hasRiskManagementStrategy	setHasRiskManagementStrategy	hasRiskManagementStrategyComments	setHasRiskManagementStrategyComments

      uid: 'RM-1',
      variable: hasRiskManagementStrategy,
      questionNumber: '1',
      questionText: 'Do you have an enterprise-wide all-hazards risk management strategy?',
      name: "hasRiskManagementStrategy",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasRiskManagementStrategy,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasRiskManagementStrategyComments",
      valueComment: hasRiskManagementStrategyComments,
      onChangeComments: setHasRiskManagementStrategyComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
    // 2	Do you periodically conduct risk assessments, including outsourced vulnerability assessments, and are the results reported to you?
    //	doesPeriodicRiskAssessments	setDoesPeriodicRiskAssessments	doesPeriodicRiskAssessmentsComments	setDoesPeriodicRiskAssessmentsComments
    
    uid: 'RM-2',
    variable: doesPeriodicRiskAssessments,
    questionNumber: '2',
    questionText: 'Do you conduct risk assessments, including outsourced vulnerability assessments, and are the results reported to you annually?',
    name: "doesPeriodicRiskAssessments",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesPeriodicRiskAssessments,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesPeriodicRiskAssessmentsComments",
    valueComment: doesPeriodicRiskAssessmentsComments,
    onChangeComments: setDoesPeriodicRiskAssessmentsComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    }, 
    {
    
    // 3	Did you conduct a risk assessment before implementing wireless networking?
    // 	doesAssessWirelessUse	setDoesAssessWirelessUse	doesAssessWirelessUseComments	setDoesAssessWirelessUseComments
    
    uid: 'RM-3',
    variable: doesAssessWirelessUse,
    questionNumber: '3',
    questionText: 'Did you conduct a risk assessment before implementing wireless networking?',
    name: "doesAssessWirelessUse",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesAssessWirelessUse,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesAssessWirelessUseComments",
    valueComment: doesAssessWirelessUseComments,
    onChangeComments: setDoesAssessWirelessUseComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    },
    {
        
    // 4	Are your operations, cyber, and physical security teams engaged in your risk management strategy?
    //	hasOrgWideRiskParticipation	setHasOrgWideRiskParticipation	hasOrgWideRiskParticipationComments	setHasOrgWideRiskParticipationComments
        
      uid: 'RM-4',
      variable: hasOrgWideRiskParticipation,
      questionNumber: '4',
      questionText: 'Are your operations, cyber, and physical security teams engaged in your risk management strategy?',
      name: "hasOrgWideRiskParticipation",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasOrgWideRiskParticipation,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasOrgWideRiskParticipationComments",
      valueComment: hasOrgWideRiskParticipationComments,
      onChangeComments: setHasOrgWideRiskParticipationComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
        
        // 5	Does your risk management strategy address cybersecurity supply chain risks?
        //	includesSupplyChainRiskManagement	setIncludesSupplyChainRiskManagement	includesSupplyChainRiskManagementComments	setIncludesSupplyChainRiskManagementComments
        
      uid: 'RM-5',
      variable: includesSupplyChainRiskManagement,
      questionNumber: '5',
      questionText: 'Does your risk management strategy address cybersecurity supply chain risks?',
      name: "includesSupplyChainRiskManagement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setIncludesSupplyChainRiskManagement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "includesSupplyChainRiskManagementComments",
      valueComment: includesSupplyChainRiskManagementComments,
      onChangeComments: setIncludesSupplyChainRiskManagementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
       // 6	Does your risk management strategy address insider threat risks?
       //	addressesInsiderThreat	setAddressesInsiderThreat	addressesInsiderThreatComments	setAddressesInsiderThreatComments
      uid: 'RM-6',
      variable: addressesInsiderThreat,
      questionNumber: '6',
      questionText: 'Does your risk management strategy address insider threat risks?',
      name: "addressesInsiderThreat",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setAddressesInsiderThreat,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "addressesInsiderThreatComments",
      valueComment: addressesInsiderThreatComments,
      onChangeComments: setAddressesInsiderThreatComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
        // 7	Do you assess the threats to your organization and the resources available for an appropriate defense?
        //	doesThreatAssessment	setDoesThreatAssessment	doesThreatAssessmentComments	setDoesThreatAssessmentComments
        
      uid: 'RM-7',
      variable: doesThreatAssessment,
      questionNumber: '7',
      questionText: 'Do you assess the threats to your organization and the resources available for an appropriate defense?',
      name: "doesThreatAssessment",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesThreatAssessment,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesThreatAssessmentComments",
      valueComment: doesThreatAssessmentComments,
      onChangeComments: setDoesThreatAssessmentComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
        // 8	Do you perform this assessment independently?
        // 	doesIndependentThreatAssessment	setDoesIndependentThreatAssessment	doesIndependentThreatAssessmentComments	setDoesIndependentThreatAssessmentComments

      uid: 'RM-8',
      variable: doesIndependentThreatAssessment,
      questionNumber: '8',
      questionText: 'Do you perform this assessment independently?',
      name: "doesIndependentThreatAssessment",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesIndependentThreatAssessment,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesIndependentThreatAssessmentComments",
      valueComment: doesIndependentThreatAssessmentComments,
      onChangeComments: setDoesIndependentThreatAssessmentComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
        // 1	Do you maintain and integrate situational awareness of operations, cyber and physical threats?
        //	hasSituationAwareness	setHasSituationAwareness	hasSituationAwarenessComments	setHasSituationAwarenessComments
        uid: 'RM-9',
        variable: hasSituationAwareness,
        questionNumber: '9',
        questionText: 'Do you maintain and integrate situational awareness of operations, cyber and physical threats?',
        name: "hasSituationAwareness",
        type: 'radio',
        answerType: 'YesNo', 
        //***Only used for question type 'checkbox'. */
        checkboxInputs: [
        {
            // answer options for checkbox
        },
        {
            // answer options for checkbox
        }
        ], 
        onChangeInput: setHasSituationAwareness,
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'true',
        idComment: "hasSituationAwarenessComments",
        valueComment: hasSituationAwarenessComments,
        onChangeComments: setHasSituationAwarenessComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: GridItemQuestionXS,
        GridItemInputXS: GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
    },
    {
    // 2	Do you maintain informational sharing relationships with external entities (both government and commercial) to collect and provide cybersecurity and physical security information?
    //	hasInformationSharing	setHasInformationSharing	hasInformationSharingComments	setHasInformationSharingComments
        uid: 'RM-10',
        variable: hasInformationSharing,
        questionNumber: '10',
        questionText: 'Do you maintain informational sharing relationships with external entities (both government and commercial) to collect and provide cybersecurity and physical security information?',
        name: "hasInformationSharing",
        type: 'radio',
        answerType: 'YesNo', 
        //***Only used for question type 'checkbox'. */
        checkboxInputs: [
        {
            // answer options for checkbox
        },
        {
            // answer options for checkbox
        }
        ], 
        onChangeInput: setHasInformationSharing,
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'true',
        idComment: "hasInformationSharingComments",
        valueComment: hasInformationSharingComments,
        onChangeComments: setHasInformationSharingComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: GridItemQuestionXS,
        GridItemInputXS: GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
    },
    ]

  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>Risk Management</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {RiskManagementQuestionsList.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasRiskManagementStrategy ==="" || doesPeriodicRiskAssessments ==="" || doesAssessWirelessUse ==="" || hasOrgWideRiskParticipation ==="" || includesSupplyChainRiskManagement ==="" || addressesInsiderThreat ==="" || doesThreatAssessment ==="" || hasSituationAwareness ==="" || hasInformationSharing ==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box> 
  )
}

export default RiskManagementQuestions