import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Paper, Tab, Typography, useTheme } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Overview from './Overview';
import CyberSCADAAssets from './InventoryAssetDesignation/CyberSCADAAssets';
import FERCHydropowerCyberSCADAChecklist from './FERCCyberSCADAChecklist/FERCHydropowerCyberSCADAChecklist';
import CyberSCADAAssetDesignation from './InventoryAssetDesignation/CyberSCADAAssetDesignation';
import AttackTrees2 from './GraphCreator';
import RiskAssessmentReport from './RiskAssessmentReults';
import SystemSecurityPlan from './SystemSecurityPlan';



const CyberSCADAAssessment = ({damData, assessmentID, setProcessValue}) => {
    const [tabValue, setTabValue] = useState('1');
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    
    // const steps = ['Overview', 'Cyber/SCADA Inventory', 'Cyber/SCADA Asset Designation', 'FERC Cyber/SCADA Checklist', 'Attack Trees', 'Risk Assessment Report', 'Cyber/SCADA Security Plan' ];
    const steps = ['Overview', 'Cyber/SCADA Inventory', 'Cyber/SCADA Asset Designation', 'FERC Cyber/SCADA Checklist', 'Attack Trees', 'Cyber/SCADA Security Plan' ];
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }


   return (
    <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
                </StepButton>
            </Step>
            ))}
        </Stepper>
       
        <div>
            {allStepsCompleted() ? (
            <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
                </Box>
            </React.Fragment>
            ) : (
            <React.Fragment>
                {/* <Typography sx={{ mt: 0, mb: 0, py: 1 }}>
                Step {activeStep + 1} */}
                {/* </Typography> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 1 }}>
                <Button
                    variant='contained'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                </Button>
                {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                    </Typography>
                    ) : (
                      <></>
                    // <Button onClick={handleComplete}>
                    //     {completedSteps() === totalSteps() - 1
                    //     ? 'Finish'
                    //     : 'Complete Step'}
                    // </Button>
                    ))}
                </Box>
            </React.Fragment>
            )}
            <Divider />
        </div>
        <Box>
            {activeStep===0 && (
                <Overview />
            )}
            {activeStep===1 && (
                <CyberSCADAAssets setActiveStep={setActiveStep} />
            )}
            {activeStep===2 && (
                <CyberSCADAAssetDesignation setActiveStep={setActiveStep}/>
            )}
            {activeStep===3 && (
                <FERCHydropowerCyberSCADAChecklist setActiveStep={setActiveStep}/>
            )}
            {activeStep===4 && (
              <AttackTrees2 />
            )}
            {/* {activeStep===5 && (
              <RiskAssessmentReport />
            )} */}
             {activeStep===5 && (
              <SystemSecurityPlan />
            )}
        </Box>
    </Box>
    )
}

export default CyberSCADAAssessment