import React, { useState, useEffect } from 'react';
import { Alert, Box, Card, CardContent, CardActions, Collapse, Button, Typography, Divider, useTheme, useMediaQuery,
     Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


const EnterpriseAssessmentCards = ({
    _id,
    assessmentID,
    assessmentType,
    assessmentName,
    refresh,
    setRefresh

}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [assessmentToDelete, setAssessmentToDelete] = useState();
    const [damNameToDelete, setDamNameToDelete] = useState();
    const [open, setOpen] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    
    const handleDeleteAssessment = async () => {
        console.log(assessmentToDelete);
        if (assessmentType === "ZTMM") {
            try {
                const response = await axiosPrivate.delete(`/zta/delete/${assessmentToDelete}`, {
                    withCredentials: true
                }); 
                if (response.data.success) {
                    alert(response.data.msg);
                } 
               } catch (err) {
                console.error(err);
               }
        }
        if (assessmentType === "C2M2") {
            try {
                const response = await axiosPrivate.delete(`/c2m2/delete/${assessmentToDelete}`, {
                    withCredentials: true
                }); 
                if (response.data.success) {
                    alert(response.data.msg);
                } 
               } catch (err) {
                console.error(err);
               }
        }
        if (assessmentType === "CSF") {
            try {
                const response = await axiosPrivate.delete(`/csf/delete/${assessmentToDelete}`, {
                    withCredentials: true
                }); 
                if (response.data.success) {
                    alert(response.data.msg);
                } 
               } catch (err) {
                console.error(err);
               }
        }
        if (assessmentType === "RMF") {
            try {
                const response = await axiosPrivate.delete(`/rmf/delete/${assessmentToDelete}`, {
                    withCredentials: true
                }); 
                if (response.data.success) {
                    alert(response.data.msg);
                } 
               } catch (err) {
                console.error(err);
               }
        }
    //    try {
    //     const response = await axiosPrivate.delete(`/zta/delete/${assessmentToDelete}`, {
    //         withCredentials: true
    //     }); 
    //     if (response.data.success) {
    //         alert(response.data.msg);
    //     } 
    //    } catch (err) {
    //     console.error(err);
    //    }
    // try {
    //     const response = await axiosPrivate.patch(`/assessment/${assessmentToDelete}`, 
    //         ({ "active": false }),
    //       {
    //           headers: { 'Content-Type': 'application/json' },
    //           withCredentials: true
    //       }
    //   );
    //     if (response.data.success) {
    //         alert(response.data.msg);
    //     } 
    // } catch (err) {
    //     console.error(err);
    // }
    setRefresh(!refresh);
    setAssessmentToDelete(null);
    setDamNameToDelete(null);
    }

    const handleEditRequest = (assessmentID, assessmentType) => {
        console.log(assessmentType);
        if (assessmentType === "ZTMM") {
            navigate(`/ztmmassessment/${assessmentID}`);
        }
        if (assessmentType === "C2M2") {
            navigate(`/c2m2assessment/${assessmentID}`);
        }
        if (assessmentType === "CSF") {
            navigate(`/csfassessment/${assessmentID}`);
        }
        if (assessmentType === "RMF") {
            navigate(`/rmfassessment/${assessmentID}`);
        }
        
    }
    
    const handleClickOpen = (assessmentID, assessmentName) => {
        setAssessmentToDelete(assessmentID);
        setDamNameToDelete(assessmentName);
        setOpen(true);
      };
    
    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = () => {
        handleDeleteAssessment();
        setOpen(false);
    };


    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Verify Delete Command"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the {damNameToDelete} assessment?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleYes}>Yes</Button>
                <Button onClick={handleNo} autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
            <Card sx={{ backgroundImage: "none", backgroundColor: theme.palette.background, borderRadius: "0.55rem", border: '4px', width: 1/2 }} >
                <CardContent >
                    <Typography color={theme.palette.secondary[700]} gutterBottom>
                        {assessmentName}
                    </Typography>
                    <Divider sx={{ marginBottom: '1em' }} />
                    <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleClickOpen(assessmentID, assessmentName)}}>Delete</Button>
                    {/* <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleDeleteAssessment(assessmentID, assessmentName)}}>Delete</Button> */}
                    <Button variant='contained' onClick={() => {handleEditRequest(assessmentID, assessmentType)}}>Edit</Button>
                    {/* <Rating value={rating} readOnly /> */}
                    {/* <Typography variant="body2">{description}</Typography> */}
                </CardContent>
                {/* <CardActions>
                    <Button 
                        variant="primary"
                        size="small"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        See More
                    </Button>
                </CardActions> */}
                <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
                    {/* <CardContent>
                        <Typography>Assessment Started: {startDate}</Typography>
                        <Typography>Other: {assessmentID}</Typography>
                    </CardContent> */}
                </Collapse>
            </Card>
        </Box>
    )
 }

const UserEnterpriseAssessmentWidget = ({ userAssessmentData, setRefresh, refresh }) => {

    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    

    const handleFormatDate = (date) => {
        const d = new Date(date);
        const dateFormatted = d.toLocaleDateString("en-US");
        return dateFormatted;
    }

    
    return (
        <Box m="1.5rem 2.5rem">
            <Box mt="20px" display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))" 
                justifyContent="space-between" 
                rowGap="20px" 
                columnGap="1.33%" 
                sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
            >
                {userAssessmentData.map(
                    ({  
                    _id,
                    assessmentName,
                    assessmentType,
            // add status to assessment model. 
                }) => (
                    <EnterpriseAssessmentCards
                        key={_id}
                        assessmentID={_id}
                        assessmentType={assessmentType}
                        assessmentName={assessmentName}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                    )
                )}
            </Box>
        </Box>
    )
};

export default UserEnterpriseAssessmentWidget;