import React, {useState} from 'react';
import { Box, Divider, Grid, Tooltip, Typography } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {v4} from 'uuid';


const QuestionnaireForm = ({
    variable,
    GridItemQuestionXS,
    questionNumber,
    questionText,
    GridItemInputXS,
    GridItemInputSX,
    inputStyleWidth,
    inputStyleHeight,
    checkboxMarginLeft,
    GridItemCheckboxContainerInputXS,
    name, 
    type,
    answerType,
    checkboxInputs,
    radiogroupInputs,
    onChangeInput,
    comments,
    GridItemCommentsXS,
    inputCommentWidth,
    idComment,
    valueComment,
    onChangeComments,
    commentsLabel,
    helpText,
    valueHelpText
}) => {

    const [checked, setChecked] = useState(false);

    const NoMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 'none',
        },
      });

      const getId = () => {
        let id = v4();
        return (
          `uid_${id}`
        )
      }

    
  return (
    <Box>
        
        {type==="radio" && (
            <Box>
            {/* RADIO BUTTON QUESTION */}
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContents: 'center' }} container spacing={2}>
        <Grid item xs={GridItemQuestionXS} >
        <Box>{questionNumber}. {questionText}</Box>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', margin: 'auto', alignSelf:{GridItemInputSX}, justifyItems: 'center' }}>
        <input style={{ marginLeft: '30%', width: inputStyleWidth, height: inputStyleHeight}}
            name={name}
            type="radio"
            id={name + "Yes"}
            value="true"
            checked={variable==="true"}
            onChange={(e) => onChangeInput(e.target.value)}
        />
        <label htmlFor={name + "Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:{GridItemInputSX}, width: inputStyleWidth, height: inputStyleHeight}}
            name={name}
            type="radio" 
            id={name + "No"}
            value="false"
            checked={variable==="false"}
            onChange={(e) => onChangeInput(e.target.value)}
        />
        <label htmlFor={name + "No"}>No</label>
        </Grid>
        {comments==='true' && (
            <Grid item xs={GridItemCommentsXS} sx={{ justifyContent: 'center'}}>
            {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
            <label htmlFor="hasLogicalAccessControlComments">Comments:</label>
            <input style={{ height: '60px', width: inputCommentWidth, marginLeft: '2rem' }}
                type="text"
                id={idComment}
                value={valueComment || ""}
                onChange={(e) => onChangeComments(e.target.value)}
            />
            </Grid>
        )}
        <Grid item xs={12}><Divider sx={{ margin: '1rem' }} /></Grid>
        </Grid>
        </Box>
        )}
        {/* Checkbox Question */}
        {type==="checkbox" && (
            <Grid sx={{ display: 'flex', alignItems: 'center' }} container spacing={2}>
                <Grid item xs={GridItemQuestionXS} >
                    <Box>{questionNumber}. {questionText}</Box>
                </Grid>
                <Grid item xs={GridItemCheckboxContainerInputXS}>
                {checkboxInputs?.map((index) =>
                    <Grid container spacing={1} columns={12} key={getId()}>
                    <Grid item xs={11}>
                        <>
                            <input style={{ marginLeft: checkboxMarginLeft, width: inputStyleWidth, height: inputStyleHeight}}
                                name={index.name}
                                
                                type="checkbox"
                                id={index.name}
                                value={true}
                                checked={index.variable}
                                onChange={(e) => index.onChangeInput(!index.variable)}
                            />
                            <label htmlFor={index.name}>{index.title}</label>
                        </>
                        {/* {index.onChangeInput(checked)} */}
                    
                    </Grid>
                    </Grid>
                )}
                </Grid>
                {comments==='true' && (
                    <Grid item xs={GridItemCommentsXS} sx={{ justifyContent: 'center'}}>
                    {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
                    <label htmlFor="QuestionComments">Comments:</label>
                    <input style={{ height: '60px', width: inputCommentWidth, marginLeft: '2rem' }}
                        type="text"
                        id={idComment}
                        value={valueComment || ""}
                        onChange={(e) => onChangeComments(e.target.value)}
                    />
                    </Grid>
                )}
                <Grid item xs={12}><Divider sx={{ margin: '1rem' }} /></Grid>
            </Grid>
        )}
         {/* Radio Group Question */}
         {type==="radiogroup" && (
            <Grid sx={{ display: 'flex', alignItems: 'center' }} container spacing={2}>
                <Grid item xs={GridItemQuestionXS} >
                    <Box inline ><h4>{questionNumber}. {questionText}
                        <span className={helpText ? "valid" : "hide"}>
                            <NoMaxWidthTooltip color='primary' describeChild={true} title={valueHelpText?.map((example) => 
                                <Typography key={getId()} sx={{ mt:'5px', fontSize: '2em', background: 'grey' }}>{example}</Typography>)} placement="top-start"><HelpIcon />
                            </NoMaxWidthTooltip>
                        </span>
                        </h4>
                    </Box>
                </Grid>
                <Grid item xs={GridItemCheckboxContainerInputXS}>
                {radiogroupInputs?.map((index) =>
                    <Grid container spacing={2} columns={12} sx={{ p:'5px' }} key={getId()}>
                    <Grid item xs={1}>
                    <input style={{ marginLeft: checkboxMarginLeft, width: inputStyleWidth, height: inputStyleHeight}}
                            name={index.name}
                            type="radio"
                            id={index.value}
                            value={index.value}
                            checked={index.variable===index.value}
                            onChange={(e) => index.onChangeInput(e.target.value)}
                        />
                        </Grid>
                        <Grid item xs={10}>
                            <label htmlFor={index.name}>{index.title}</label>
                        </Grid>
                    </Grid>
                )}
                </Grid>
                {comments==='true' && (
                    <Grid item xs={GridItemCommentsXS} sx={{ justifyContent: 'center'}}>
                    {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
                    
                    {commentsLabel ? (<label htmlFor="QuestionComments">commentsLabel</label>) : (<label htmlFor="QuestionComments">Comments:</label>)}
                        
                    <input style={{ height: '60px', width: inputCommentWidth, marginLeft: '2rem' }}
                        type="text"
                        id={idComment}
                        value={valueComment || ""}
                        onChange={(e) => onChangeComments(e.target.value)}
                    />
                    </Grid>
                )}
                <Grid item xs={12}><Divider sx={{ margin: '1rem' }} /></Grid>
            </Grid>
        )}
    </Box>
  )
}

export default QuestionnaireForm