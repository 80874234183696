import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import Navbar from "../navbar";
import FriendListWidget from "../../widgets/FriendListWidget";
import MyPostWidget from "../../widgets/MyPostWidget";
import PostsWidget from "../../widgets/PostsWidget";
import UserWidget from "../../widgets/UserWidget"; 
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


const ProfilePage = () => {
    const [user, setUser] = useState(null);
    const { userId } = useParams();
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const axiosPrivate = useAxiosPrivate();
  
    // const getUser = async () => {
    //   const response = await axiosPrivate.get(`/usersma/${userId}`, 
    //       {
    //         withCredentials: true
    //     });
    //     const data = await response.data;
    //     setUser(data);
    // };
  
    // useEffect(() => {
    //   getUser();
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    if (!user) return null;
  
    return (
      <Box>
        <Box
          width="100%"
          padding="2rem 6%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Typography> 
            <h1>PROFILE PAGE</h1>
            1. Add Information about Roles,
            2. email address
            3. Change email widget
            4. Other voluntary information.
            </Typography>
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            <UserWidget userId={userId} picturePath={user.picturePath} />
            <Box m="2rem 0" />
            <FriendListWidget userId={userId} />
          </Box>
          <Box
            flexBasis={isNonMobileScreens ? "42%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            <MyPostWidget picturePath={user.picturePath} />
            <Box m="2rem 0" />
            <PostsWidget userId={userId} isProfile />
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default ProfilePage;