import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Login from './Login';
import axios from '../api/axios';
import FlexBetween from './FlexBetween';

// START from mern_app project
// import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Box, Button, TextField, useMediaQuery, Typography, useTheme } from "@mui/material";
// END from mern_app project



// REGEX Validation for user and password inputs
// Username must start with a lower or uppercase letter, only letters and the '-' or '_' can be used in the username and the length must be between at least 4 characters but less than 23 characters.
const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
// Passwords must have a lowercase letter, an uppercase letter, a number, and one of the following special characters '[!@#$%]' AND it must be between 8 and 24 characters long.
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';


const LoginHelp = () => {

    const theme = useTheme();                                           // From mern_app project 
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

    const userRef = useRef();
    const errRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [organization, setOrganization] = useState('');
    const [validOrganization, setValidOrganization] = useState(false);
    const [organizationFocus, setOrganizationFocus] = useState(false);

    const [message, setMessage] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    // useEffect(() => {
    //     userRef.current.focus();
    // }, [])

    useEffect(() => {
        const result = USER_REGEX.test(user);
        setValidName(result);
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const v1 = USER_REGEX.test(user);
        try {
            const response = await axios.post(`/helprequest/new`,
                JSON.stringify({ user, email, organization, message }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            navigate('/login');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                errRef.current.focus();
        }
    } 
    };

    const buttonStyle = {
        'background-color': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    };

    return (
        <Box>
            <Box width="100%" backgroundColor={theme.palette.background.alt} p="1rem 6%" textAlign="center">
                <Typography 
                    fontWeight="bold"
                    fontSize="32px"
                    color="primary"
                >
                    WEBPAGE NAME
                </Typography>

            </Box>
            <Box
                width='40%'
                p="1rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background.alt}
            >
                <Typography fontWeight="500" variant="h5" sx={{mb: "1.5rem"}}>
                    Welcome to WEBPAGE NAME!
                </Typography>
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Request Login Help</h1>
                    <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? "span 4" : "span 8"},
                        }}
                    >
                        <label htmlFor="username" sx={{gridColumn: "span 4"}}>
                            Username:
                            <span className={validName ? "valid" : "hide"}>
                                <CheckCircleOutlineIcon />
                            </span>
                            <span className={validName || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span>
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                            />
                            <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                4 to 24 characters. <br />
                                Must begin with a letter. <br />
                                Letters, numbers, underscores, and hyphens are allowed.
                            </p>

                            <label htmlFor="email" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Email:
                            </label>
                            <input 
                                type="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />

                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Organization:
                            </label>
                            <input 
                                type="organization"
                                id="organization"
                                onChange={(e) => setOrganization(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />


                        
                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            How can we help?:
                            </label>
                            <input 
                                type="message"
                                id="message"
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />


                        <button style={buttonStyle} disabled={!validName ? true : false}>Get Help</button>
                        </Box>
                    </form>
                    <p>
                        Already registered?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/login">Sign In</a>
                        </span>
                    </p>
                </section>
            </Box>
        </Box>
    )
}

export default LoginHelp
