import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Tab, Typography, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import SecurityOperationsProcedures from './SecurityOperationsProcedures';
import KeyControl from './KeyControl';
import CommunicationsPlan from './CommunicationsPlan';
import ThreatLevel from './ThreatLevel';
import InternalEmergencyResponse from './InternalEmergencyResponse';
import RapidRecoveryProcedures from './RapidRecoveryProcedures';



const SecurityPlan = ({securityGroup, damData, assessmentID, setProcessValue}) => {
    const [tabValue, setTabValue] = useState('1');
    const theme = useTheme();  
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
  
    // const steps = [ 'Overview', 'Security Operations Procedures', 'Key Control', 'Communications Plan', 'Threat Level Planning', 'Internal Emergency Response', 'Rapid Recovery Procedures', 'Template' ];
    const steps = [ 'Key Control', 'Communications Plan', 'Threat Level Planning', 'Internal Emergency Response', 'Rapid Recovery Procedures' ];
  

    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

   return (
        <Box>
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                    </StepButton>
                </Step>
                ))}
            </Stepper>
           
            <div>
                {allStepsCompleted() ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
                ) : (
                <React.Fragment>
                    {/* <Typography sx={{ mt: 0, mb: 0, py: 1 }}>
                    Step {activeStep + 1} */}
                    {/* </Typography> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 1 }}>
                    <Button
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                        Next
                    </Button>
                    {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                        </Typography>
                        ) : (
                        <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                            ? 'Finish'
                            : 'Complete Step'}
                        </Button>
                        ))}
                    </Box>
                </React.Fragment>
                )}
                <Divider />
            </div>
            <Box>
                {/* {activeStep===0 && (
                    <Box> 
                      {securityGroup==='securityGroup3' &&
                        <Typography>
                          Security Group 3 description and requirements.
                        </Typography>
                      }

                      {securityGroup==='securityGroup2' &&
                        <Typography>
                          Security Group 2 description and requirements. 

                        </Typography>
                      }

                      {securityGroup==='securityGroup1' &&
                        <Typography>
                          Security Group 1 description and requirements. 

                        </Typography>
                      }

                        
                    </Box>
                )} */}
                {/* {activeStep===1 && (
                    <SecurityOperationsProcedures />
                )} */}
                {activeStep===0 && (
                    <KeyControl />
                )}
                {activeStep===1 && (
                    <CommunicationsPlan />
                )}
                {activeStep===2 && (
                    <ThreatLevel />
                )}
                {activeStep===3 && (
                    <InternalEmergencyResponse />
                )}
                {activeStep===4 && (
                    <RapidRecoveryProcedures />
                )}
            </Box>
            </Box>
        </Box>
    )
}
        
export default SecurityPlan

{/* <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 5, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} >
                            <Tab sx={{ border: 2 }} label='1-Critical Assets' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
            //                 <Tab sx={{ border: 2 }} label='2-Restricted Areas' value='2' />
            //                 <Tab sx={{ border: 2 }} label='3-FERC Security Checklist v5' value='3' />
            //                 <Tab sx={{ border: 2 }} label='4-Accessibility & Visibility' value='4' />
            //                 <Tab sx={{ border: 2 }} label='5-Security Protection' value='5' />
            //                 <Tab sx={{ border: 2 }} label='6-Attack Scenarios' value='6' />
            //         </TabList>
            //     </Box>
            //     <TabPanel value='1'>
            //         <CriticalAssets damData={damData} setProcessValue={setProcessValue} setTabValue={setTabValue} assessmentID={assessmentID} />
            //     </TabPanel>
            //     <Box sx={{ backgroundColor: theme.palette.background }}>
            //     <TabPanel value='2'>
            //         <RestrictedAreas setTabValue={setTabValue} assessmentID={assessmentID} />
            //     </TabPanel>
            //     <TabPanel value='3'>
            //         <FERCSecuirtyChecklistv5 />
            //     </TabPanel>
            //     <TabPanel value='4'>
            //         <AccessibilityVisibility />
            //     </TabPanel>
            //     <TabPanel value='5'>
            //         <SecurityProtection />
            //     </TabPanel>
            //     <TabPanel value='6'>
            //         <AttackScenarios />
            //     </TabPanel>
            //     </Box>
            // </TabContext> */}