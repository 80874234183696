// May not use this at all.  

import { Box } from "@mui/material";
import useAxiosPrivate, { axiosPrivate } from "../hooks/useAxiosPrivate";
import { ImageAspectRatioOutlined } from "@mui/icons-material";

const UserImage = ({ image, size="60px" }) => {
    const axiosPrivate = useAxiosPrivate();
    return (
        <Box width={size} hieght={size}>
            <img
                style={{ objectFit: "cover", borderRadius: "50%" }}
                width={size}
                height={size}
                alt="user"
                src={axiosPrivate.get(`/subdir/assets`, 
                {
                  withCredentials: true
              })}
                // src={`http://localhost:3500/assets/${image}`}  // changed to 3500 for new server. need to add authentication to this.  
            />
        </Box>
    )
};

export default UserImage;


//C:\Users\jeyca\OneDrive\Documents\0_webappdev\fercwebapp\server\public\assets\p1.jpg