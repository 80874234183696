
import React, { useState, useEffect } from 'react';
import { Box, Button, FormControlLabel , Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import WidgetWrapper from '../../../../components/WidgetWrapper';


const RemoteAccessDetermination = ({questionIndex, setQuestionIndex, setActiveStep}) => {

  const axiosPrivate = useAxiosPrivate();
  const [allCyberAssetData, setAllCyberAssetData] = useState();
  const theme = useTheme();
  const { assessmentID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [usePlaceholderAsset, setUsePlaceholderAsset] = useState(false);

// Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
const getCyberAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAllCyberAssetData(data);

    if (allCyberAssetData === null) {
        return;
    }
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    navigate('/login', { state: { from: location }, replace: true });
  }
};

// Not using this one because I need the second RA question answered for the Form 3 question #4
// const getCyberAssetsDesignationRA1 = async () => {
//   const response = await axiosPrivate.get(`/assets/designationra1/${assessmentID}`, {
//       withCredentials: true
//   });
//   const data = await response.data;
//   if (data.length===0) {
//     setQuestionIndex(questionIndex+1);
//   }
//   setAllCyberAssetData(data);
//   console.log('RA1 response is: ');
//   console.log(data);

//   if (allCyberAssetData === null) {
//     return;
//   }
//   };

  useEffect(() => {
      getCyberAssets();
      // getCyberAssetsDesignationRA1();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!allCyberAssetData) {
      return null;
    }

  
    
  // Remote Access Determination Questions
const assetDeterminationQuestions = [
  {
    id: 'remoteAccess',
    questionText: 'Does the facility/project use automated or remote (off-site) control of any of the following:',
    questionTextAug1: '1. data acquisition, such as critical instrumentation or operation data?',
    questionTextAug2: '2. power generation data or power generation controls?',
    questionTextAug3: '3. water management data or direct control of water retention features?',
    questionNumber: '1. ',
    questionTitle: 'Cyber Asset Designation: Remote Access'
  },
  {
    id: 'remoteAccessConnected',
    questionText: 'Is there an interconnection of computer Systems from/to this facility/project to other dam(s)?',
    questionNumber: '2. ',
    questionTitle: 'Cyber Asset Designation: Connections to Other Dams',
  }
]


// Update Database Function 
const handleCyberAssetUpdate = async () => {
  for (let updatedRow in allCyberAssetData) {
    let id = allCyberAssetData[updatedRow]._id;
    let remoteAccess = allCyberAssetData[updatedRow].remoteAccess;
    let remoteAccessConnected = allCyberAssetData[updatedRow].remoteAccessConnected;
    let fercDesignation = allCyberAssetData[updatedRow].fercDesignation;
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "remoteAccess": remoteAccess, "remoteAccessConnected": remoteAccessConnected, "fercDesignation": fercDesignation },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        setAllCyberAssetData(null);
        setAllCyberAssetData(data);
        if (typeof(data.length)==="undefined") {
          setQuestionIndex(questionIndex+1);
        }
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.REmoteAccessDEtermination");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      };

    // try {
    //   for (let updatedRow in allCyberAssetData) {
    //     let id = allCyberAssetData[updatedRow]._id;
    //     let remoteAccess = allCyberAssetData[updatedRow].remoteAccess;
    //     let remoteAccessConnected = allCyberAssetData[updatedRow].remoteAccessConnected;
    //     if (remoteAccess==='yes') {
    //       const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
    //       { "remoteAccess": remoteAccess },
    //     {
    //         headers: { 'Content-Type': 'application/json' },
    //         withCredentials: true
    //     }
    //     );
    //     }
    //     if (remoteAccessConnected==='yes') {
    //       const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
    //       { "remoteAccessConnected": remoteAccessConnected },
    //     {
    //         headers: { 'Content-Type': 'application/json' },
    //         withCredentials: true
    //     }
    //     );
    //     }
    //   }
    // } catch (err) {
    //   if (!err?.response) {
    //     console.log('No Server Response');
    //   } else {
    //     console.log('Action Failed')
    // }
    // }
    }
  };

const UpdateRemoteAccessQuestions = (e, params) => {
  if (questionIndex===0) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].remoteAccess = e;
        }
    }
  } else if (questionIndex===1) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].remoteAccessConnected = e;
          if (allCyberAssetData[index].remoteAccess==='no' && allCyberAssetData[index].remoteAccessConnected==='no') {
            allCyberAssetData[index].fercDesignation = 'Non-Critical';
        }
      }
    }
  }
}

const handleNextQuestion = async () => {
  const waiteForIt = await handleCyberAssetUpdate();
  setAllCyberAssetData(null);
  setQuestionIndex(questionIndex+1);
  getCyberAssets();  
  if (usePlaceholderAsset) {
    getCyberAssets();
  } 
  // Not Used becuase I need the answer for RA question 2 in the Form 3 question #4.
  // else {
  //   const waitForIt = await getCyberAssetsDesignationRA1();
  // }
}

const handlePreviousQuestion = () => {
  setQuestionIndex(questionIndex-1);
}

  // Update Database Function 
  const handleAddCyberAsset = async (placeholderAsset) => {
    let assessmentID = placeholderAsset.assessmentID;
    let assetName = placeholderAsset.assetName;
    let assetType = placeholderAsset.assetType;
    let description = placeholderAsset.description;
    try {
        const response = await axiosPrivate.post(`/assets/cyberasset/new`,
            JSON.stringify({ assessmentID, assetName, assetType, description }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
  };

const handleAddPlaceholder = async () =>{
  const placeholderAsset = { "assessmentID": assessmentID, "assetName": "Dam", "assetType": "FERC Project", "description": "This is a placeholder asset. The inventory will be provided outside of this document."}
  const waitForIt = await handleAddCyberAsset(placeholderAsset);
  // getCyberAssets();
  setUsePlaceholderAsset(true);
}

const handleAddInventory = () => {
  setActiveStep(1);
}

// Columns for MUI Data Grid
const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
  },
  {
      field: "assetType",
      headerName: "Asset Type/Function",
      flex: 1,
},
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
  },
  {
      field: "description",
      headerName: "Description",
      flex: 1,
  },
  {
      field: "macAddress",
      headerName: "MAC Address",
      flex: 0.5,
  },
  {
      field: "yearPurchased",
      headerName: "Year Purchased (approximate)",
      flex: 0.5,
  },
  {
      field: "physicalLocation",
      headerName: "Physical Location",
      flex: 1,
  },
  {
    field: "Answer",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            }
          }}>

        <RadioGroup row>
          <FormControlLabel
          label="Yes"
          control={
            <Radio
              value="yes"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
          <FormControlLabel
          label="No"
          control={
            <Radio
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
        </RadioGroup>
        </Box>
      )
    }
  }
];


  return (
    <Box sx={{ margin: '1rem' }}>
      <Grid container spacing={2}> 
      {/** Question box to determine Remote Operations and Consequences. */}
        {questionIndex<=1 ? ( 
          <Grid item xs={12}>
            <Paper sx={{ marginTop:'1rem', backgroundImage: "none", borderRadius: "0.55rem", mr:'0.55rem', p:'1rem', minHeight:'250px'}} >
              <Typography>
                  {assetDeterminationQuestions[questionIndex].questionTitle}
              </Typography>
            
              <Box sx={{ fontSize: 24, p: '2rem', mt: '1rem' }}>
                {assetDeterminationQuestions[questionIndex].questionText}
                <Typography sx={{ fontSize: 22, ml: '3rem' }}>
                {assetDeterminationQuestions[questionIndex].questionTextAug1 || ""}
                </Typography>
                <Typography sx={{ fontSize: 22, ml: '3rem' }}>
                  {assetDeterminationQuestions[questionIndex].questionTextAug2 || ""}
                </Typography>
                <Typography sx={{ fontSize: 22, ml: '3rem' }}>
                  {assetDeterminationQuestions[questionIndex].questionTextAug3 || ""}
                </Typography>
              </Box>
              
                {questionIndex>0 ? (
                  <Grid sx={{ display:'flex', justifyContent:'space-between' }}>
                    <Button variant="contained" onClick={handlePreviousQuestion}>Previous Question</Button>
                    <Button variant="contained" onClick={handleNextQuestion}>Next Question</Button>
                  </Grid>
                ):(
                  <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
                )}
               
            </Paper>
          </Grid> 
        ) : (
          <Typography>
            move to consequence Threshold quesitons.
          </Typography>
        )} 
          {/** DATA GRID OF CYBER ASSETS */}
          <Grid container spacing={2}>
          <Box sx={{ mt:'1rem', flexGrow: 1, padding: "1.5rem 1.5rem 0.75rem 2rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
            <Grid item xs={12} >
              <Box
                sx={{
                  height: 500,
                  width: '100%',
                  "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: theme.palette.secondary.alt,
                      color: theme.palette.secondary[100],
                      borderBottom: "solid 2px black",
                      borderTop: "solid 2px black"
                  },
                  "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: theme.palette.primary.light,
                  },
                  "& .MuiDataGrid-footerContainer": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary[100],
                      borderTop: "none"
                  },
                  "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: `${theme.palette.secondary[200]} !important`,
                  },
              }}
              >
                <DataGrid
                  rows={allCyberAssetData || []} 
                  getRowId={(row) => row._id}
                  columns={columns}
                  editMode="row"
                  loading={!allCyberAssetData}
                />
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RemoteAccessDetermination