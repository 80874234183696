import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const IntrusionDetectionResponseQuestions = ({questionFamily, setQuestionFamily}) => {

  // *************** Questionnaire specific Variables***************//
  const [hasIntrusionDetectionResponsePolicy, setHasIntrusionDetectionResponsePolicy] = useState("");
  const [doesNotifyLawEnforcement, setDoesNotifyLawEnforcement] = useState("");
  const [doesExerciseResponsePlans, setDoesExerciseResponsePlans] = useState("");
  const [includesExerciseWithLawEnforcement, setIncludesExerciseWithLawEnforcement] = useState("");
  const [doesPostEventAnalysis, setDoesPostEventAnalysis] = useState("");
  const [includesAnalysisWithLawEnforcement, setIncludesAnalysisWithLawEnforcement] = useState("");
  const [incorporatesIDRLessonedLearned, setIncorporatesIDRLessonedLearned] = useState("");
  const [hasDedicatedSecMonTeam, setHasDedicatedSecMonTeam] = useState("");
  const [doesSecurityLogReviewFrequency, setDoesSecurityLogReviewFrequency] = useState("");
  const [hasIDRControlsImplemented, setHasIDRControlsImplemented] = useState("");
  const [doesWirelessAccessPointMonitoring, setDoesWirelessAccessPointMonitoring] = useState("");
  const [hasIntrusionDetectionResponsePolicyComments, setHasIntrusionDetectionResponsePolicyComments] = useState("");
  const [doesNotifyLawEnforcementComments, setDoesNotifyLawEnforcementComments] = useState("");
  const [doesExerciseResponsePlansComments, setDoesExerciseResponsePlansComments] = useState("");
  const [includesExerciseWithLawEnforcementComments, setIncludesExerciseWithLawEnforcementComments] = useState("");
  const [doesPostEventAnalysisComments, setDoesPostEventAnalysisComments] = useState("");
  const [includesAnalysisWithLawEnforcementComments, setIncludesAnalysisWithLawEnforcementComments] = useState("");
  const [incorporatesIDRLessonedLearnedComments, setIncorporatesIDRLessonedLearnedComments] = useState("");
  const [hasIDRControlsImplementedComments, setHasIDRControlsImplementedComments] = useState("");
  const [hasDedicatedSecMonTeamComments, setHasDedicatedSecMonTeamComments] = useState("");
  const [doesSecurityLogReviewFrequencyComments, setDoesSecurityLogReviewFrequencyComments] = useState("");
  const [doesWirelessAccessPointMonitoringComments, setDoesWirelessAccessPointMonitoringComments] = useState("");
  const [doesLogging, setDoesLogging] = useState(false);
  const [doesMonitoring, setDoesMonitoring] = useState(false);
  const [doesDetection, setDoesDetection] = useState(false);
  const [doesAnomalyIsolation, setDoesAnomalyIsolation] = useState(false);
  
  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();     

  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setHasIntrusionDetectionResponsePolicy(data?.hasIntrusionDetectionResponsePolicy?.toString());
    setDoesNotifyLawEnforcement(data?.doesNotifyLawEnforcement?.toString());
    setDoesExerciseResponsePlans(data?.doesExerciseResponsePlans?.toString());
    setIncludesExerciseWithLawEnforcement(data?.includesExerciseWithLawEnforcement?.toString());
    setDoesPostEventAnalysis(data?.doesPostEventAnalysis?.toString());
    setIncludesAnalysisWithLawEnforcement(data?.includesAnalysisWithLawEnforcement?.toString());
    setIncorporatesIDRLessonedLearned(data?.incorporatesIDRLessonedLearned?.toString());
    setHasIDRControlsImplemented(data?.hasIDRControlsImplemented?.toString());
    setHasDedicatedSecMonTeam(data?.hasDedicatedSecMonTeam?.toString());
    setDoesSecurityLogReviewFrequency(data?.doesSecurityLogReviewFrequency?.toString());
    setDoesWirelessAccessPointMonitoring(data?.doesWirelessAccessPointMonitoring?.toString());
    setHasIntrusionDetectionResponsePolicyComments(data?.hasIntrusionDetectionResponsePolicyComments?.toString());
    setDoesNotifyLawEnforcementComments(data?.doesNotifyLawEnforcementComments?.toString());
    setDoesExerciseResponsePlansComments(data?.doesExerciseResponsePlansComments?.toString());
    setIncludesExerciseWithLawEnforcementComments(data?.includesExerciseWithLawEnforcementComments?.toString());
    setDoesPostEventAnalysisComments(data?.doesPostEventAnalysisComments?.toString());
    setIncludesAnalysisWithLawEnforcementComments(data?.includesAnalysisWithLawEnforcementComments?.toString());
    setIncorporatesIDRLessonedLearnedComments(data?.incorporatesIDRLessonedLearnedComments?.toString());
    setHasIDRControlsImplementedComments(data?.hasIDRControlsImplementedComments?.toString());
    setHasDedicatedSecMonTeamComments(data?.hasDedicatedSecMonTeamComments?.toString());
    setDoesSecurityLogReviewFrequencyComments(data?.doesSecurityLogReviewFrequencyComments?.toString());
    setDoesWirelessAccessPointMonitoringComments(data?.doesWirelessAccessPointMonitoringComments?.toString());
    setDoesLogging(data?.doesLogging);
    setDoesMonitoring(data?.doesMonitoring);
    setDoesDetection(data?.doesDetection);
    setDoesAnomalyIsolation(data?.doesAnomalyIsolation);

    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }
    

    if (assessmentData === null) {
        return;
    }
};

useEffect(() => {
    getAssessmentData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let intrusionDetectionResponseQuestions = true;
    const intrusionDetectionResponseData = JSON.stringify({ hasIntrusionDetectionResponsePolicy, doesNotifyLawEnforcement, doesExerciseResponsePlans, includesExerciseWithLawEnforcement, doesPostEventAnalysis, includesAnalysisWithLawEnforcement, incorporatesIDRLessonedLearned, doesLogging, doesMonitoring, doesDetection, doesAnomalyIsolation, hasDedicatedSecMonTeam, doesSecurityLogReviewFrequency, doesWirelessAccessPointMonitoring, hasIntrusionDetectionResponsePolicyComments, doesNotifyLawEnforcementComments, doesExerciseResponsePlansComments, includesExerciseWithLawEnforcementComments, doesPostEventAnalysisComments, includesAnalysisWithLawEnforcementComments, incorporatesIDRLessonedLearnedComments, hasIDRControlsImplementedComments, hasDedicatedSecMonTeamComments, doesSecurityLogReviewFrequencyComments, doesWirelessAccessPointMonitoringComments });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ intrusionDetectionResponseQuestions, intrusionDetectionResponseData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(2) activates the Asset Management questions. 
    setQuestionFamily(4);
    } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }
}

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

console.log("HAS LOGGING POLICY");
console.log(doesLogging);
console.log(doesMonitoring);
console.log(doesDetection);
console.log(doesAnomalyIsolation);


  const GridItemQuestionXS = 6;
  const GridItemInputXS = 3;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;


  const IntrusionQuestions = [
    {
      uid: 'IDR-1',
      //1	Do you maintain plans, procedures, and technologies to detect, analyze, and respond to cybersecurity events (and their physical protection)?	
      // hasIntrusionDetectionResponsePolicy	setIntrusionDetectionResponsePolicy	hasIntrusionDetectionResponsePolicyComments	setIntrusionDetectionResponsePolicyComments

      variable: hasIntrusionDetectionResponsePolicy,
      questionNumber: '1',
      questionText: 'Do you maintain plans, procedures, and technologies to detect, analyze, and respond to cybersecurity events (and their physical protection)?',
      name: "hasIntrusionDetectionResponsePolicy",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasIntrusionDetectionResponsePolicy,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasIntrusionDetectionResponsePolicyComments",
      valueComment: hasIntrusionDetectionResponsePolicyComments,
      onChangeComments: setHasIntrusionDetectionResponsePolicyComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 2	Do these include notifying with law enforcement and government security agencies?	
        // doesNotifyLawEnforcement	setDoesNotifyLawEnforcement	doesNotifyLawEnforcementComments	setDoesNotifyLawEnforcementComments

      uid: 'IDR-2',
      variable: doesNotifyLawEnforcement,
      questionNumber: '2',
      questionText: 'Do these include notifying with law enforcement and government security agencies?',
      name: "doesNotifyLawEnforcement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesNotifyLawEnforcement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesNotifyLawEnforcementComments",
      valueComment: doesNotifyLawEnforcementComments,
      onChangeComments: setDoesNotifyLawEnforcementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 3	Do you routinely exercise your cyber response plans and procedures?	
        // doesExerciseResponsePlans	setDoesExerciseResponsePlans	doesExerciseResponsePlansComments	setDoesExerciseResponsePlansComments

      uid: 'IDR-3',
      variable: doesExerciseResponsePlans,
      questionNumber: '3',
      questionText: 'Do you routinely exercise your cyber response plans and procedures?',
      name: "doesExerciseResponsePlans",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesExerciseResponsePlans,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesExerciseResponsePlansComments",
      valueComment: doesExerciseResponsePlansComments,
      onChangeComments: setDoesExerciseResponsePlansComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 4	Does this include working with law enforcement and government security agencies?
        //	includesExerciseWithLawEnforcement	setIncludesExerciseWithLawEnforcement	includesExerciseWithLawEnforcementComments	setIncludesExerciseWithLawEnforcementComments

      uid: 'IDR-4',
      variable: includesExerciseWithLawEnforcement,
      questionNumber: '4',
      questionText: 'Does this include working with law enforcement and government security agencies?',
      name: "includesExerciseWithLawEnforcement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setIncludesExerciseWithLawEnforcement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "includesExerciseWithLawEnforcementComments",
      valueComment: includesExerciseWithLawEnforcementComments,
      onChangeComments: setIncludesExerciseWithLawEnforcementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 5	Do you perform post-event analysis?
        //	doesPostEventAnalysis	setDoesPostEventAnalysis	doesPostEventAnalysisComments	setDoesPostEventAnalysisComments

      uid: 'IDR-5',
      variable: doesPostEventAnalysis,
      questionNumber: '5',
      questionText: 'Do you perform post-event analysis?',
      name: "doesPostEventAnalysis",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesPostEventAnalysis,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesPostEventAnalysisComments",
      valueComment: doesPostEventAnalysisComments,
      onChangeComments: setDoesPostEventAnalysisComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 6	Does this include working with law enforcement and government security agencies?
        //	includesAnalysisWithLawEnforcement	setIncludesAnalysisWithLawEnforcement	includesAnalysisWithLawEnforcementComments	setIncludesAnalysisWithLawEnforcementComments

      uid: 'IDR-6',
      variable: includesAnalysisWithLawEnforcement,
      questionNumber: '6',
      questionText: 'Does this include working with law enforcement and government security agencies?',
      name: "includesAnalysisWithLawEnforcement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setIncludesAnalysisWithLawEnforcement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "includesAnalysisWithLawEnforcementComments",
      valueComment: includesAnalysisWithLawEnforcementComments,
      onChangeComments: setIncludesAnalysisWithLawEnforcementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        //  7	Do you incorporate lessons learned into your policies, plans, and procedures?
        //	incorporatesIDRLessonedLearned	setIncorporatesIDRLessonedLearned	incorporatesIDRLessonedLearnedComments	setIncorporatesIDRLessonedLearnedComments

      uid: 'IDR-7',
      variable: incorporatesIDRLessonedLearned,
      questionNumber: '7',
      questionText: 'Do you incorporate lessons learned into your policies, plans, and procedures?',
      name: "incorporatesIDRLessonedLearned",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setIncorporatesIDRLessonedLearned,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "incorporatesIDRLessonedLearnedComments",
      valueComment: incorporatesIDRLessonedLearnedComments,
      onChangeComments: setIncorporatesIDRLessonedLearnedComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
        // 8	Are cyber security controls implemented within the ICS network that allow for logging, monitoring, detection, and isolation of an anomalous cyber event?
        //	hasIDRControlsImplemented	setHasIDRControlsImplemented	hasIDRControlsImplementedComments	setHasIDRControlsImplementedComments

    {
    uid: 'IDR-8',
    variable: hasIDRControlsImplemented,
    questionNumber: '8',
    questionText: 'Are cyber security controls implemented within the ICS network that allow for: ',
    name: "hasIDRControlsImplemented",
    type: 'checkbox',
    answerType: 'YesNo',
    //***Only used for question type 'checkbox'. */  
    checkboxInputs: [
        {                             
        variable: doesLogging,
        name: "doesLogging",
        title: "Logging",
        onChangeInput: setDoesLogging,
        },
        {
        variable: doesMonitoring,
        name: "doesMonitoring",
        title: "Monitoring",
        onChangeInput: setDoesMonitoring,
        },
        {
        variable: doesDetection,
        name: "doesDetection",
        title: "Detection",
        onChangeInput: setDoesDetection,
        },
        {
        variable: doesAnomalyIsolation,
        name: "doesAnomalyIsolation",
        title: "Isolation of anomalies",
        onChangeInput: setDoesAnomalyIsolation,
        }
    ], 
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "hasIDRControlsImplementedComments",
    valueComment: hasIDRControlsImplementedComments,
    onChangeComments: setHasIDRControlsImplementedComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    checkboxMarginLeft: checkboxMarginLeft,
    },
    {
        // 9	Is there a dedicated security monitoring team?	
        // hasDedicatedSecMonTeam	setHasDedicatedSecMonTeam	hasDedicatedSecMonTeamComments	setHasDedicatedSecMonTeamComments

      uid: 'IDR-9',
      variable: hasDedicatedSecMonTeam,
      questionNumber: '9',
      questionText: 'Is there a dedicated security monitoring team?',
      name: "hasDedicatedSecMonTeam",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasDedicatedSecMonTeam,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasDedicatedSecMonTeamComments",
      valueComment: hasDedicatedSecMonTeamComments,
      onChangeComments: setHasDedicatedSecMonTeamComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 10	How often are security logs reviewed?	
        // doesSecurityLogReview	setDoesSecurityLogReview	doesSecurityLogReviewComments	setDoesSecurityLogReviewComments

      uid: 'IDR-10',
      variable: doesSecurityLogReviewFrequency,
      questionNumber: '10',
      questionText: 'How often are security logs reviewed?',
      name: "doesSecurityLogReview",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesSecurityLogReviewFrequency,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesSecurityLogReviewFrequencyComments",
      valueComment: doesSecurityLogReviewFrequencyComments,
      onChangeComments: setDoesSecurityLogReviewFrequencyComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 11	Do you monitor for rogue wireless access points? 
        //	doesWirelessAccessPointMonitoring	setDoesWirelessAccessPointMonitoring	doesWirelessAccessPointMonitoringComments	setDoesWirelessAccessPointMonitoringComments

      uid: 'IDR-11',
      variable: doesWirelessAccessPointMonitoring,
      questionNumber: '11',
      questionText: 'Do you monitor for rogue wireless access points?',
      name: "doesWirelessAccessPointMonitoring",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesWirelessAccessPointMonitoring,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesWirelessAccessPointMonitoringComments",
      valueComment: doesWirelessAccessPointMonitoringComments,
      onChangeComments: setDoesWirelessAccessPointMonitoringComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 



    // {
    //     //***Example of radio button question. */
    //   uid: 'IDR-12',
    //   variable: knowsAssetCriticality,
    //   questionNumber: '1',
    //   questionText: 'Do you review and reassess cyber asset criticality (non-critical, operational, critical) at least every 12 months?',
    //   name: "knowsAssetCriticality",
    //   type: 'radio',
    //   answerType: 'YesNo', 
    //   //***Only used for question type 'checkbox'. */
    //   checkboxInputs: [
    //     {
    //       // answer options for checkbox
    //     },
    //     {
    //       // answer options for checkbox
    //     }
    //   ], 
    //   onChangeInput: setKnowsAssetCriticality,
    //   //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    //   comments: 'true',
    //   idComment: "knowsAssetCriticalityComments",
    //   valueComment: knowsAssetCriticalityComments,
    //   onChangeComments: setKnowsAssetCriticalityComments,
    //   //***Modify these settings if you want to change them from the defaults for this specific question. */
    //   GridItemQuestionXS: GridItemQuestionXS,
    //   GridItemInputXS: GridItemInputXS,
    //   GridItemCommentsXS: GridItemCommentsXS,
    //   GridItemInputSX: GridItemInputSX,
    //   inputStyleWidth: inputWidth,
    //   inputStyleHeight: inputHeight,
    //   inputCommentWidth: inputCommentWidth,
    // }, 




    // //***Example of checkbox question. */
    // {
    //     uid: 'ACM-2',
    //     variable: hasTest1,
    //     questionNumber: '4',
    //     questionText: 'Test Checkbox questions?',
    //     name: "hasTest1",
    //     type: 'checkbox',
    //     answerType: 'YesNo',
    //     //***Only used for question type 'checkbox'. */ 
    //     checkboxInputs: [
    //       {
    //         variable: hasTest1,
    //         name: "hasTest1",
    //         title: "Has Test 1",
    //         onChangeInput: setHasTest1,
    //       },
    //       {
    //         variable: hasTest2,
    //         name: "hasTest2",
    //         title: "Has Test 2",
    //         onChangeInput: setHasTest2,
    //       },
    //       {
    //         variable: hasTest2,
    //         name: "hasTest2",
    //         title: "Has Test 2",
    //         onChangeInput: setHasTest2,
    //       }
    //     ], 
    //     //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    //     comments: 'true',
    //     idComment: "hasTestComments",
    //     valueComment: hasTestComments,
    //     onChangeComments: setHasTestComments,
    //     //***Modify these settings if you want to change them from the defaults for this specific question. */
    //     GridItemQuestionXS: GridItemQuestionXS,
    //     GridItemInputXS: GridItemInputXS,
    //     GridItemCommentsXS: GridItemCommentsXS,
    //     GridItemInputSX: GridItemInputSX,
    //     inputStyleWidth: inputWidth,
    //     inputStyleHeight: inputHeight,
    //     inputCommentWidth: inputCommentWidth,
    //   } 
  ]


  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>Intrusion Detection and Response</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {IntrusionQuestions.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          checkboxMarginLeft,
          GridItemCheckboxContainerInputXS,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            checkboxMarginLeft={checkboxMarginLeft}
            GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasIntrusionDetectionResponsePolicy==="" || doesNotifyLawEnforcement==="" || doesExerciseResponsePlans==="" || includesExerciseWithLawEnforcement==="" || doesPostEventAnalysis==="" || includesAnalysisWithLawEnforcement==="" || incorporatesIDRLessonedLearned==="" || hasDedicatedSecMonTeam==="" || doesSecurityLogReviewFrequency==="" || doesWirelessAccessPointMonitoring==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
    
   </Box> 
  )
}

export default IntrusionDetectionResponseQuestions