import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Button, FormControlLabel , Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, 
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, ButtonBase,
    Table, TableRow} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CircleIcon from '@mui/icons-material/Circle';
import { Slide } from 'react-slideshow-image';
import WidgetWrapper from '../../components/WidgetWrapper';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


import QuestionnaireForm from '../../components/QuestionnaireForm';



const Index = () => {

    const [open, setOpen] = useState(true);
    const [questionFamily, setQuestionFamily] = useState(0);

    // const handleChange = (event, newValue) => {
    //     setTabValue(newValue);
    // }

    const handleNo = () => {
        setOpen(false);
    }

    const handlePrevious = () => {
        if (questionFamily >= 0) {
            setQuestionFamily(questionFamily-1);
        }
    }

    const handleNext = () => {
        if (questionFamily <= 8) {
            setQuestionFamily(questionFamily+1);
        }
    }

  return (



    <Box>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display:'flex', justifyContent:'space-between' }}>
                <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handlePrevious}>Previous</Button>
                <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleNext}>Next</Button>
                <Divider />
            </Grid>
        </Grid>
    <Dialog
        fullScreen
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogActions>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display:'flex', justifyContent:'space-between' }}>
                <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handlePrevious}>Previous</Button>
                <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleNext}>Next</Button>
                <Divider />
            </Grid>
        </Grid>
        </DialogActions>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {questionFamily===0 ? (
         <Typography>Slide 1</Typography>
        ) : (
        questionFamily===1 ? (
            <Typography>Slide 2</Typography>  
        ) : (
          questionFamily===2 ? (
            <Typography>Slide 3</Typography>  
          ) : (
            questionFamily===3 ? (
                <Typography>Slide 4</Typography>  
            ) : (
              questionFamily===4 ? (
                <Typography>Slide 5</Typography>  
              ) : ( 
                questionFamily===5 ? (
                    <Typography>Slide 6</Typography>  
                ) : ( 
                  questionFamily===6 ? (
                    <Typography>Slide 7</Typography>  
                  ) : ( 
                    questionFamily===7 ? (
                        <Typography>Slide 8</Typography>  
                    ) : (
                        <Typography>Slide 9</Typography>  
                        )
                      )
                    )
                  )
                )
              )
            )
          )} 
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Box sx={{ display:'flex', justifyContent:'space-between' }} >
                        <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handlePrevious}>Previous</Button>
                        <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleNext}>Next</Button>
                        <Divider />  
                      </Box> 
          <Button onClick={handleNo}>Close</Button>
          {/* <Button onClick={handleNo} autoFocus>
              No
          </Button> */}
        </DialogActions>
      </Dialog>


        <Grid container spacing={2}>
            <Grid item xs={12}>
                        <Typography>
                            A Security Group 1 Dam is defined by the level of its Consequence, Vulnerability, and Likelihood of Attack values as derived from the DAMSVR (v2) analysis. 
                        </Typography>
                        <Typography sx={{ mt: '1rem'}}>
                            Dams belonging to Security Group 1 will be inspected with a higher level of scrutiny by FERC D2SI.
                        </Typography>
                        <Typography sx={{ mt: '1rem'}}>
                            <u>Security Group 1 Requirements: </u>
                        </Typography>
            </Grid>
                    <Grid container spacing={1} direction="row" maxWidth='xlg' sx={{ justifyContent:'space-around'}}>
                        <Grid item xs={6} sx={{ mt: '1rem' }}>
                        <WidgetWrapper>
                            <Paper sx={{ mb: '0.5rem', p: '1rem' }}>
                        <Typography sx={{ mt: '0.5rem', mr: '2rem'}}>
                            <b>1. Vulnerability Assessment</b> - The Vulnerability Assessment for a Group 1 Dam also includes all the details required for a 
                                Security Assessment of a Group 2 Dam. A Vulnerability Assessment must also be completed to justify requests for permanent licensed facility closures for any
                        </Typography>
                        </Paper>
                            <TableContainer >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableRow>
                                                {/* <Checkbox onChange={handleChange} /> */}
                                            </TableRow>
                                            <TableCell >
                                                Document 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Required
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Suggested
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                Vulnerability Assessment 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell></TableCell>
                                            
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Discusses Scope/Methodology/Assumptions
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                            Discusses List of Critical Assets
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Consequences of Individual Asset Destruction
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ textAlign: 'center'}}>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                            Vulnerability of Individual Assets
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Likelihood of Attack of Individual Assets
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Threats Against Individual Assets
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Discusses Attack Scenarios
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Security Protection for Individual Assets
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Site Security Risk and Recommendations
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Security Recommendations, if any
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell sx={{ mr:'0.5rem' }}>
                                                Planned and Recently Completed Security Upgrades
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </WidgetWrapper>
                        
                            <WidgetWrapper sx={{ mt: '1rem' }}>
                                <Paper sx={{ mb: '0.5rem', p: '1rem' }}>
                                    <Typography sx={{ mt:'0.5rem', mr:'2rem'}}>
                                        <b>2. Cyber/SCADA Assessment</b> - THe Cyber/SCADA Assessment is required for all dams. This assessment includes identifying the criticality of cyber assets and the cybersecurity plan for the site. 
                                    </Typography>
                                </Paper>
                                <Typography>
                                    ADD TABLE OF CYBER / SCADA ASSESSMENT INFO. 
                                </Typography>
                            </WidgetWrapper>

                        </Grid>

                        <Grid item xs={6}>
                            <WidgetWrapper sx={{ mt: '1rem' }}>
                            <Paper sx={{ mb: '0.5rem', p: '1rem' }}>
                                <Typography sx={{ mt:'0.5rem', mr: '2rem'}}>
                            <b>3. Security Plan</b> - A Security Plan (SP) is a formal document (or set of documents) and constitutes the 
“Standard Operating Procedures” for the operation of all security concerns (physical, cyber, and 
procedural) at the dam and the project. Security Plans must be <i>site-specific</i> and avaialble to the dam operator at the site. The one exception to the on-site requirement is 
for un-staffed dams operated at a central facility.
                        </Typography>
                                </Paper>
                            <TableContainer >
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableRow>
                                                {/* <Checkbox onChange={handleChange} /> */}
                                            </TableRow> 
                                            <TableCell >
                                                Document 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Required
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                Suggested
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >
                                                Security Plan
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell></TableCell>
                                            
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                List of Restricted Areas
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            List of Critical Dam Assets
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Physical Security Descriptions/Inventory
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            Physical Security Layout/Drawings
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Security Operations Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Site Access Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Key Control Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Civil Disturbance Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Bomb Threat Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Temporary Project Closure Procedures
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Threat Level Planning
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Communication Procedures
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Information Technology (if applicable)
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Cyber/SCADA 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                            Security Equipment Testing
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Internal Emergency Response
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                Rapid Recovery Plan 
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell >
                                                All upgrades to physical and operational security
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center'}}>
                                                X
                                            </TableCell>
                                            <TableCell >
                                                
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </WidgetWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            
                        </Grid>
                    </Grid>
        </Grid>

    </Box>
  )
}

export default Index