import { memo, useContext } from 'react';
import { Handle, Position, NodeResizer, useNodeId, NodeResizeControl, useStore } from 'reactflow';
import PanToolIcon from '@mui/icons-material/PanTool';
import { NodeContext } from './index';


const options = [
  {
    value: '10',
    label: '10',
  },
  {
    value: 'default',
    label: '12 (default)',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '16',
    label: '16',
  },
];

const ResizableObjective = ({ data, selected }) => {

  const nodeID = useNodeId();
  const [nodes, setNodes] = useContext(NodeContext);

  const zoomSelector = (s) => s.transform[2];
  const zoomLevel = useStore(zoomSelector);
  const variableFontSize = Math.floor(20 / useStore(zoomSelector)) + 'pt';

  const handleStyleLeft = {
    l1: 10,
  }
  
  const handleStyleRight = {top: 100};
  
  // const controlStyle = {
  //   background: 'transparent',
  //   border: 'none',
  // };

  const handleNodeLabelUpdate = (e) => {
    for (let index in nodes) {
      if (nodes[index].id === nodeID) {
        nodes[index].data.label = e.target.value;
        setNodes(nodes);
      }
    }
  }

  return (
    <>
      <NodeResizeControl minWidth={200} minHeight={150} maxWidth={700} maxHeight={500}>
        <ResizeIcon />
      </NodeResizeControl>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
      {zoomLevel < 0.14 ? (
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ margin: '1rem', position: 'absolute', height: '80%',
          width: '85%', resize:'none', overflow: "auto", textAlign: 'center', fontSize: `${variableFontSize}` }} onChange={(e) => handleNodeLabelUpdate(e)} >{data.label}</textarea>
      ) : (
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ margin: '1rem', position: 'absolute', height: '80%',
          width: '85%', resize:'none', overflow: "auto", textAlign: 'center', fontSize: `${variableFontSize}` }} onChange={(e) => handleNodeLabelUpdate(e)} >{data.label}</textarea>
      )}
      <PanTool zoomSelector={zoomSelector} />
    </>
    // <>
    // {/* <Box> */}
    //   <NodeResizer isVisible={selected} minWidth={100} minHeight={100} maxHeight={300} maxWidth={400} />
    //   {/* <Input  id='text' multiline={true} maxRows={5} maxLength={100} autoComplete='off' fullWidth={true} inputProps={{ style:  { textAlign:'center', overflow: 'hidden', border: "solid 1px", backgroundColor: 'white' }}} placeholder='Title' sx={{ maxHeight: '75%' }} /> */}
    //   <Handle id="a" type="target" position={Position.Left} />
    //   {/* <input id="text" type="text" name="text" autoComplete='off' maxLength={100} style={{ marginTop: '1%', height: '25%', width: '97%', minWidth: '20px', minHeight: '30px', textAlign: 'center', fontSize: '1em'}} /> */}
    //   <textarea name="text" rows="14" cols="10" wrap="soft" autoComplete='off' maxLength={100} style={{ marginTop: '10%', width: '97%', height: '55%', textAlign: 'center', fontSize: '1em'}}> </textarea>
    //   <Handle id="b" type="source" position={Position.Right} />
    //   <Handle id="c" type="target" position={Position.Top} />
    //   <Handle id="d" type="source" position={Position.Bottom} />
    //   {/* <Handle id="e" style={handleStyleRight} type="source" position={Position.Right} /> */}
    // {/* </Box> */}

    // </>
  );
};
function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke= "#404040"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ position: 'absolute', right: 5, bottom: 5 }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="16 20 20 20 20 16" />
      <line x1="14" y1="14" x2="20" y2="20" />
      <polyline points="8 4 4 4 4 8" />
      <line x1="4" y1="4" x2="10" y2="10" />
    </svg>
  );
}

function PanTool({zoomSelector}) {

  const zoomLevel = 20 / useStore(zoomSelector) + 'px';


  return (
    <>
      <div>
        {/* Use the class specified at node.dragHandle here */}
        <span className="custom-drag-handle" >
          <PanToolIcon sx={{ fontSize: `${zoomLevel}`, position: 'absolute', top: 5, right: 5 }} />
        </span>
      </div>
    </>
  );
}


export default memo(ResizableObjective);