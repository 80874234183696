import React, { useState } from 'react';
import WidgetWrapper from '../../../../components/WidgetWrapper';
import { Box, Popover, InputLabel, Select, Button, Typography, MenuItem, useTheme, useMediaQuery, Grid, Paper, Divider, Popper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Header from '../../../../components/Header';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import FlexBetween from "../../../../components/FlexBetween";
import { read, utils, writeFile } from 'xlsx';
import { CSVLink } from 'react-csv';


const UploadCSV = ({handleAddCyberAssetRefresh}) => {

    const { assessmentID } = useParams();

    const [csvData, setCSVData] = useState(null);
    const [importedData, setImportedData] = useState(null);
    const EXTENSIONS = ['xlsx', 'xls', 'csv'];


    // const [description, setDescription] = useState();
    // const [manufacturer, setManufacturer] = useState();
    // const [macAddress, setMACAddress] = useState();
    // const [purchaseDate, setPurchaseDate] = useState();
    // const [assetName, setAssetName] = useState(null);
    // const [assetType, setAssetType] = useState(null);
    // const [model, setModel] = useState();
    // const [physicalLocation, setPhysicalLocation] = useState();
    // const [localID, setLocalID] = useState();
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [dialogTitle, setDialogTitle] = useState();
    const [dialogMessage, setDialogMessage] = useState();
    const [dialogReference, setDialogReference] = useState();
    const [dialogReferenceLink, setDialogReferenceLink] = useState();

    const [open, setOpen] = useState(false);

    const [cyberAssetDesignationAttestation, setCyberAssetDesignationAttestation] = useState();



     // Update Database Function 
  const handleAddCyberAssetFromCSV = async (rows) => {
    // for (let row in csvData) {
    //     let assessmentID = csvData[row].assessmentID;
    //     let assetName = csvData[row].assetName;
    //     let assetType = csvData[row].assetType;
    //     let manufacturer = csvData[row].manufacturer;
    //     let model = csvData[row].model;
    //     let macAddress = csvData[row].macAddress;
    //     let yearPurchased = csvData[row].yearPurchased;
    //     let description = csvData[row].description;
    //     let physicalLocation = csvData[row].physicalLocation;
    //     let operatingSystem = csvData[row].operatingSystem;
        for (let row in rows) {
            let assessmentID = rows[row].assessmentID;
            let assetName = rows[row].assetName;
            let assetType = rows[row].assetType;
            let manufacturer = rows[row].manufacturer;
            let model = rows[row].model;
            let macAddress = rows[row].macAddress;
            let yearPurchased = rows[row].yearPurchased;
            let description = rows[row].description;
            let physicalLocation = rows[row].physicalLocation;
            let operatingSystem = rows[row].operatingSystem;
            let cyberAssetData = JSON.stringify({ manufacturer, model, macAddress, yearPurchased, physicalLocation, description, operatingSystem });

        try {
            const response = await axiosPrivate.post(`/assets/cyberasset/new`,
                JSON.stringify({ assessmentID, assetName, assetType, cyberAssetData }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const data = await response.data;
            handleAddCyberAssetRefresh();
            // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
            // The console log commands below allow you to see the responses from Axios for the response.  
            // navigate(from, { replace: true });
        } catch (err) {
            // alert("Login Expired.");
            console.error(err);
            navigate('/login', { state: { from: location }, replace: true });
        }
    }
    }


    const handleImportCSV = (e) => {
        const files = e.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = read(e.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    // add unique-ish ID for each record in the csv, because MUI Data Grid needs a unique ID. 
                    rows.forEach((item, i) => {
                        item._id = i + 1;
                        item.assessmentID = assessmentID;
                      });
                      setCSVData(rows);
                     
                    // Check CSV for required fields (assetName and assetType) before writing to database and populating MUI Data Grid 
                    let csvErrorReport = "";
                        let counter = 0;
                    for (let index in rows) {
                        counter = counter+1;
                        if (!rows[index].assetName) {
                            csvErrorReport=csvErrorReport + (`Asset Name is missing on row ${counter}. This is a required field.\n`);
                        } 
                        if (!rows[index].assetType) {
                            csvErrorReport=csvErrorReport + (`Asset Type is missing on row ${counter}. This is a required field.\n`);
                        }
                        // if (rows[index].yearPurchased) {
                        //     console.log(rows[index].yearPurchased);
                        //     let temp = rows[index].yearPurchased;
                        //     let newTemp = temp.replace(/[^a-zA-Z0-9 ]/g, "");
                        //     rows[index].yearPurchased = newTemp;
                        // }
                    }
                    if (csvErrorReport.length>0) {
                        alert(csvErrorReport);
                    } else if (!csvErrorReport) {
                        handleAddCyberAssetFromCSV(rows); 
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

      const CheckCSVInputs = () => {
        let csvErrorReport = "";
        let counter = 0;
        for (let row in csvData) {
            counter = counter+1;
            if (csvData[row].assessmentID !== assessmentID) {
                csvData[row].assessmentID = assessmentID;
            }
            if (!csvData[row].assetName) {
                csvErrorReport=csvErrorReport + (`Asset Name is missing on row ${counter}. This is a required field.\n`);
            }
            if (!csvData[row].assetType) {
                csvErrorReport=csvErrorReport + (`Asset Type is missing on row ${counter}. This is a required field.\n`);
            } 
      }
      if (csvErrorReport.length>0) {
        alert(csvErrorReport);
      } else {
        handleAddCyberAssetFromCSV();
      }
    }

    const headers = [
        {
            label: 'assetName',
            key: 'assetName'
        },
        {
            label: 'assetType',
            key: 'type'
        },
        {
            label: 'localID',
            key: 'localID'
        },
        {
            label: 'manufacturer',
            key: 'manufacturer'
        },
        {
            label: 'model',
            key: 'model'
        },
        {
            label: 'description',
            key: 'description'
        },
        {
            label: 'macAddress',
            key: 'macAdress'
        },
        {
            label: 'yearPurchased',
            key: 'yearPurchased'
        },
        {
            label: 'physicalLocation',
            key: 'physicalLocation'
        },
        {
            label: 'operatingSystem',
            key: 'operatingSystem'
        }, 
    ]


    const csvTemplateData = [
        { damName: '', assetType: '', localID: '', manufacturer: '', model: '', description: '', macAddress: '', yearPurchased: '', physicalLocation: '' },
        { damName: '', assetType: '', localID: '', manufacturer: '', model: '', description: '', macAddress: '', yearPurchased: '', physicalLocation: '' }
    ]


  return (
    <Box>    
        <Button sx={{ mr: '2rem', ml: '2rem', mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" ><CSVLink data={csvTemplateData} headers={headers} filename='FERC Cyber Asset Inventory Template' target='_blank'>Download Template</CSVLink></Button>    
        <TextField type='file' name='file' onChange={handleImportCSV} accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'>Drag File here or Choose File to Upload</TextField>
    </Box>
  )
}

export default UploadCSV