import { Box, Link,useMediaQuery, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import useAuth from '../../hooks/useAuth';
import { jwtDecode } from "jwt-decode";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Sidebar from '../../components/Sidebar';
import PersonIcon from '@mui/icons-material/Person';
import FlexBetween from "../../components/FlexBetween";
import {
    ManageAccountsOutlined,
    EditOutlined,
    LocationOnOutlined,
    WorkOutlineOutlined,
    FitScreen
} from "@mui/icons-material";
import { Divider, useTheme, Grid } from '@mui/material';
import  UserImage from "../../components/UserImage";
import GanttTaskChart from '../../components/GantTaskChart';
import UserAssessmentDataWidget from '../../widgets/UserAssessmentsWidget';


const HomePage = () => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px");
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const { palette } = useTheme();
    const dark = palette.neutral.dark;
    const medium = palette.neutral.medium;
    const main = palette.neutral.main;
    const navigate = useNavigate();

    const { auth } = useAuth();
    const decoded = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined

    const roles = decoded?.UserInfo?.roles || []
    const userLoggedIn = decoded?.UserInfo.username || []
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId || {};
    const [refresh, setRefresh] = useState(false);

    const [userAssessmentData, setUserAssessmentData] = useState();


    const handleStartNewAssessment = async () => {
        navigate('/fercassessment/SelectDam');
    };

    const getUserAssessments = async () => {
        try {
            const response = await axiosPrivate.get(`/assessment/userassessments/${userId}`, {
                withCredentials: true
            });
            const data = await response.data;
            setUserAssessmentData(data);
        } catch (err) {
            // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
        }
        
    };
    useEffect(() => {
      getUserAssessments();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!userAssessmentData) {
      return null;
    }
    
    // console.log(JSON.parse(localStorage.getItem("persist")));

    // console.log("from home index");
    // console.log(window.innerWidth);
    // console.log(window.screen.width);
    // console.log(window.devicePixelRatio);

    const handleAnnualComplianceRenewal = () => {
        alert('Annual Compliance Letter');
    };

    const handleSuspiciousActivityReport = () => {
        alert('Suspicious Activity Report');
    };
     
    return(
        <Box >
        {/* <Grid container spacing={1} direction="row" margin="auto" maxWidth={window.screen.width}> */}
        <Grid container spacing={1} direction="row" margin="auto">
                {/* GANTT CHART of PROJECTS MAKE A COMPONENT */}
            {/* <Grid item xs={12}>
                <WidgetWrapper sx={{ marginTop:"0.5rem"}}>
                    <Typography padding='5px'>
                        Component Title
                    </Typography>
                    <Divider sx={{marginBottom:'5px'}}/>
                    <GanttTaskChart userAssessmentData={userAssessmentData} />
                </WidgetWrapper>
            </Grid> */}


        {/** Buttons for Annual Compliance Renewal and Suspicious Activity Reports */}
        {/* <Grid item xs={12}>
            <WidgetWrapper>
                <Button sx={{ marginRight: '1rem'}} variant='contained' onClick={() => {handleAnnualComplianceRenewal()}}>Annual Compliance Letter</Button>
                <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleSuspiciousActivityReport()}}>Suspicious Activity Report</Button>
            </WidgetWrapper>
        </Grid> */}


        {/* Current Assessments Component */}
            <Grid item xs={9} >
                <WidgetWrapper >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", paddingBottom: '0.5rem' }} >
                        <Typography>
                            Current Assessments
                        </Typography>
                        <Button sx={{ fontSize: '30', p: '1rem' }} variant="contained" onClick={handleStartNewAssessment}>New Assessment</Button>
                    </Box>
                    <Divider />
                    {userAssessmentData ? (
                        <UserAssessmentDataWidget userAssessmentData={userAssessmentData} setRefresh={setRefresh} refresh={refresh} />
                    ) : (
                        <Typography sx={{ p: "2rem" }}>No assessments.</Typography>
                    )
                }
                </WidgetWrapper>
            </Grid>
                    
            {/* RESOURCES : Still need to make this COMPONENT */}
            <Grid item xs={3}>
                <WidgetWrapper>
                    <Box>
                        <Typography>
                            Resources
                        </Typography>
                        <Divider /> 
                            <Box>
                            <Typography sx={{ pt:'0.5em', pb:'0.5em' }}>    
                                    <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/dam-safety-and-inspections">FERC - Dam Safety and Inspections</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>    
                                    <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC - Hydropower Security Revision 3A</Link>
                                </Typography>
                                <Typography  sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href="https://www.cisa.gov/topics/critical-infrastructure-security-and-resilience/critical-infrastructure-sectors/dams-sector">CISA - Dams Sector</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href="https://www.cisa.gov/resources-tools/resources/hsin-ci-dams-portal">CISA - Homeland Security Information Network (HSIN)</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href="https://nid.sec.usace.army.mil/#/">USACE - National Inventory of Dams</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href="https://hazards.fema.gov/nri/map">FEMA - National Risk Index</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href="https://training.fema.gov/is/courseoverview.aspx?code=IS-100.c&lang=en">FEMA - Incident Command System</Link>
                                </Typography>
                                <Typography sx={{ pb:'0.5em' }}>
                                    <Link rel='noreferrer' target='_blank' href=""></Link>
                                </Typography>
                            </Box>
                    </Box>
                </WidgetWrapper>
            </Grid>
            <Box
                width="100%"
                padding="1rem 2%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >
            <Box
                flexBasis={isNonMobileScreens ? "48%" : undefined}
                mt={isNonMobileScreens ? undefined : "2rem"}
            >
                
            </Box>
        </Box>
    </Grid>
    </Box>
    );
};

export default HomePage;
