import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button, Collapse, IconButton, Typography, useTheme, Grid, Divider, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from '../../../../components/DataGridCustomToolbar';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';


const CyberAssetDeterminationResults = (refresh) => {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const date = new Date();
  const currentYear = date.getFullYear();

  const [cyberAssetToDelete, setCyberAssetToDelete] = useState(null);
  const [cyberAssetName, setCyberAssetName] = useState(null);

  const [open, setOpen] = useState(false);
  const [answersOpen, setAnswersOpen] = useState(false);
  const [ra1, setRA1] = useState();
  const [ra2, setRA2] = useState();
  const [cd, setCD] = useState();
  const [par, setPAR] = useState();
  const [municipal, setMunicipal] = useState();
  const [powerGen1500MW, setPowerGen1500MW] = useState();
  const [powerGen100MW, setPowerGen100MW] = useState();
  const [blackStart, setBlackStart] = useState();

  const {assessmentID} = useParams();

  // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
  const getCyberAssets = async () => {
    try {
      const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      for (let index in data) {
        if (data[index].fercDesignation==="") {
          if (data[index].consequenceDetermination==='yes' && data[index].powerGen100MW==='no' && data[index].powerGen1500MW==='no') {
            data[index].fercDesignation = 'Operational';
          } 
        }
      }
      for (let i=0; i<data.length; i++) {
        if (data[i].cyberAssetData) {
          let assetData = JSON.parse(data[i]?.cyberAssetData);
          for (let key in assetData) {
            data[i][key] = assetData[key];
          }
        }
      }
      setRows(data);
      if (rows === null) {
          return;
    }
    } catch (err) {
      alert("Login Expired. consqeunce determination");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };
  
  // Update Database Function 
  const handleAddCyberAsset = () => {
    setRows(null);
  }

  const handleRowEditStop = (id, event) => {
    if (id.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row) => () => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
  };

  // Update Database Function for FERC Designation
const handleCyberAssetUpdate = async () => {
    for (let row in rows) {
      let id = rows[row]._id;
      let fercDesignation = rows[row].fercDesignation;
      try {
        const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
          { "fercDesignation": fercDesignation },
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
          );
          const data = await response.data;
          // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
          // The console log commands below allow you to see the responses from Axios for the response.  
          // navigate(from, { replace: true });
        } catch (err) {
          alert("Login Expired. consqeunce determination");
          console.error(err);
          navigate('/login', { state: { from: location }, replace: true });
        }
      }
    };

  // Update Database Function 
  const handleCyberAssetRowUpdate = async (updatedRow) => {
    let id = updatedRow._id;
    let assetName = updatedRow.assetName;
    let assetType = updatedRow.assetType;
    let manufacturer = updatedRow.manufacturer;
    let model = updatedRow.model;
    let macAddress = updatedRow.macAddress;
    let yearPurchased = updatedRow.yearPurchased;
    let physicalLocation = updatedRow.physicalLocation;
    let description = updatedRow.description;
    let remoteAccess = updatedRow.remoteAccess;
    let remoteAccessConnected = updatedRow.remoteAccessConnected;
    let consequenceDetermination = updatedRow.consequenceDetermination;
    let populationAtRisk = updatedRow.populationAtRisk;
    let municipal = updatedRow.municipal;
    let powerGen1500MW = updatedRow.powerGen1500MW;
    let powerGen100MW = updatedRow.powerGen100MW;
    let blackStart = updatedRow.blackStart;
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "assetName": assetName, "assetType": assetType, "manufacturer": manufacturer, "model": model, "macAddress": macAddress, "yearPurchased": yearPurchased, "physicalLocation": physicalLocation, "description": description, "remoteAccess": remoteAccess, "remoteAccessConnected": remoteAccessConnected, "consequenceDetermination": consequenceDetermination, "populationAtRisk": populationAtRisk, "municipal": municipal, "powerGen1500MW": powerGen1500MW, "powerGen100MW": powerGen100MW, "blackStart": blackStart },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
       alert("Login Expired. consqeunce determination");
       console.error(err);
       navigate('/login', { state: { from: location }, replace: true });
      }
    };

  const handleSaveClick = (row) => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    handleCyberAssetRowUpdate(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeleteAsset = async () => {
    try {
        const response = await axiosPrivate.patch(`/assets/cyberasset/${cyberAssetToDelete}`, 
            ({ "active": false }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
        console.log(response);
    } catch (err) {
        alert("Login Expired.consqeunce determination");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
     setCyberAssetToDelete(null);
     setCyberAssetName(null);
     getCyberAssets();
 }
 
 const handleClickOpen = (params) => {
     setCyberAssetToDelete(params.row._id);
     setCyberAssetName(params.row.assetName || "this device");
     setOpen(true);
   };

  const handleNo = () => {
    setOpen(false);
};

const handleYes = () => {
    handleDeleteAsset();
    setOpen(false);
};

useEffect(() => {
    getCyberAssets();
    handleCyberAssetUpdate();
    // determineFERCDesignation();
  }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!rows) {
    return null;
  }

const handleAnswerClose = () => {
  setAnswersOpen(false);
}

{/* Bring up the specific assets answers to display to the user. */}
const handleRowClick = (params) => {
  setRA1(params.row.remoteAccess);
  setRA2(params.row.remoteAccessConnected);
  setCD(params.row.consequenceDetermination);
  setPAR(params.row.populationAtRisk);
  setMunicipal(params.row.municipal);
  setPowerGen1500MW(params.row.powerGen1500MW);
  setPowerGen100MW(params.row.powerGen100MW);
  setBlackStart(params.row.blackStart);
  setAnswersOpen(true);
}

const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
      editable: true
  },
  {
      field: "fercDesignation",
      headerName: "FERC Designation",
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: ["Non-Critical", "Operational", "Critical"],
  },
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
      editable: true
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
      editable: true
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
      editable: true
  },
  {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: (params) => {
      const isInEditMode = rowModesModel[params.row._id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => handleSaveClick(params.row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.row._id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleClickOpen(params)}
          color="inherit"
        />,
      ];
    },
  },
];


  return (
    <Grid container spacing={2} sx={{mb: '1rem'}}>

      <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
        <Grid item xs={12} >
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#0A0A0A' }}
            open={answersOpen}
            onClick={handleAnswerClose}
          >
            <Grid container spacing={1} sx={{mb: '1rem'}}>
            <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  FERC Question
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22 }}>
                  Your Answer
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                1.) Does the facility/project use automated or remote (off-site) control of any of the following:
                    1. data acquisition, such as critical instrumentation or operation data?
                    2. power generation data or power generation controls?
                    3. water management data or direct control of water retention features?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {ra1}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                2.) Is there an interconnection of computer Systems from/to this facility/project to other dam(s)?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {ra2}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                3.) Could a compromise of the cyber asset dam result in any of the following to this dam or a connected dam:
                    1. dam failure, consider cascading effects from operational loss could cause the dam to fail, similar to the 2009 Sayano-Shushenskaya incident;
                    2. loss of power generation, access to power generation data, or power generation control?
                    3. release of water from the reservoir, assume all gates are open with no response for 48 hours?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {cd}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                4.) Assume all gates are open with no response for 48 hours. Could a compromised cyber asset have consequences that exceed the following thresholds:
                    1. The population at risk (PAR) within 3 miles of the dam exceeds 60 people?
                    2. The population at risk (PAR) within 60 miles of the dam exceeds 800 people?
                    3. Does the total population at risk exceed 12,500 people?
                    4. Does the total economic losses (replacement/revenue and D/S damages) exceed $300 Million USD?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {par}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                5.) Does this cause a municipal-wide disruption in power generation, water management, or navigation?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {municipal}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                6.) Is this cyber asset connected to a powerhouse that can generate at least 1,500MW?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {powerGen1500MW}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                7.) Is this cyber asset connected to a powerhouse that can generate at least 100MW but less than 1,500MW?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {powerGen100MW}
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                8.) Is this cyber asset connected to a powerhouse that qualifies as having a black start capability?
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ fontSize: 22, pl:'2rem' }}>
                  {blackStart}
                </Typography>
              </Grid>
              
            </Grid>
            
          </Backdrop>
          <Dialog
                  open={open}
                  onClose={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">
                  {"Verify Delete Command"}
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete {cyberAssetName}?
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleYes}>Yes</Button>
                  <Button onClick={handleNo} autoFocus>
                      No
                  </Button>
                  </DialogActions>
          </Dialog>
          <Box
            sx={{
              height: 500,
              width: '100%',
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.secondary.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "solid 2px black",
                  borderTop: "solid 2px black"
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary[100],
                  borderTop: "none"
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
              },
          }}
          >
            <DataGrid
              onRowClick={handleRowClick}
              rows={rows || []} 
              getRowId={(row) => row._id}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: DataGridCustomToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

export default CyberAssetDeterminationResults