import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Typography, useTheme, Grid, Link, TextField, FormControl, useMediaQuery, InputLabel } from "@mui/material";
import Dropdown from './dropdown';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ZTAEvolution from './images/ZTAEvolution.png';
import C2M2ProgramGoals from './images/C2M2ProgramGoals.png';
import csf2 from './images/csf2.png';
import rmf2 from './images/rmf2.png';
import UserEnterpriseAssessmentWidget from '../../widgets/UserEnterpriseAssessmentsWidget';
import WidgetWrapper from '../../components/WidgetWrapper';
import Header from '../../components/Header';


const EnterpriseAssessments = () => {

  const { auth } = useAuth();
    const decoded = auth?.accessToken
    ? jwtDecode(auth.accessToken)
    : undefined;
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId;
    const theme = useTheme();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    // const imageMagnifier = 0.5;
    // const ztaSizeH = Math.floor(466 * imageMagnifier);
    // const ztaSizeW = Math.floor(700 * imageMagnifier);

    const [assessmentNameZTMM, setAssessmentNameZTMM] = useState("");
    const [assessmentNameC2M2, setAssessmentNameC2M2] = useState("");
    const [assessmentNameCSF, setAssessmentNameCSF] = useState("");
    const [assessmentNameRMF, setAssessmentNameRMF] = useState("");
    const [assessmentControlBaselineRMF, setAssessmentControlBaselineRMF] = useState("");
    const [userAssessmentDataZTMM, setUserAssessmentDataZTMM] = useState();
    const [userAssessmentDataC2M2, setUserAssessmentDataC2M2] = useState();
    const [userAssessmentDataCSF, setUserAssessmentDataCSF] = useState();
    const [userAssessmentDataRMF, setUserAssessmentDataRMF] = useState();
    const [refresh, setRefresh] = useState(false);

    // Get the current user's existing assessments. 
    const getUserAssessmentsZTMM = async () => {
      try {
          const response = await axiosPrivate.get(`/zta/userassessments/${userId}`, {
              withCredentials: true
          });
          const data = await response.data;
          console.log(data);
          setUserAssessmentDataZTMM(data);
      } catch (err) {
          // alert("Login Expired.");
          console.error(err);
          // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getUserAssessmentsC2M2 = async () => {
      try {
          const response = await axiosPrivate.get(`/c2m2/userassessments/${userId}`, {
              withCredentials: true
          });
          const data = await response.data;
          console.log(data);
          setUserAssessmentDataC2M2(data);
      } catch (err) {
          // alert("Login Expired.");
          console.error(err);
          // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getUserAssessmentsCSF = async () => {
      try {
          const response = await axiosPrivate.get(`/csf/${userId}`, 
            {
              withCredentials: true
            });
          const data = await response.data;
          console.log(data);
          setUserAssessmentDataCSF(data);
      } catch (err) {
          // alert("Login Expired.");
          console.error(err);
          // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getUserAssessmentsRMF = async () => {
      try {
          const response = await axiosPrivate.get(`/rmf/userassessments/${userId}`, {
              withCredentials: true
          });
          const data = await response.data;
          console.log(data);
          setUserAssessmentDataRMF(data);
      } catch (err) {
          // alert("Login Expired.");
          console.error(err);
          // navigate('/login', { state: { from: location }, replace: true });
      }
    };


    useEffect(() => {
      getUserAssessmentsZTMM();
      getUserAssessmentsC2M2();
      getUserAssessmentsCSF();
      getUserAssessmentsRMF();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!userAssessmentDataZTMM) {
      return null;
    }
    if (!userAssessmentDataC2M2) {
      return null;
    }
    if (!userAssessmentDataCSF) {
      return null;
    }
    if (!userAssessmentDataRMF) {
      return null;
    }

    // Create the record and redirect the user to the appropriate webpage. 
    const handleCreateAssessmentZTA = async () => {
      let assessmentName = assessmentNameZTMM;
      let assessmentType = "ZTMM";
      try {
          const response = await axiosPrivate.post(`/zta/new`,
              JSON.stringify({ userId, assessmentName, assessmentType }),
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
          );
          navigate(`/ztmmassessment/${response.data._id}`);
          
          // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
          // The console log commands below allow you to see the responses from Axios for the response.  
          // navigate(from, { replace: true });
      } catch (err) {
          if (!err?.response) {
              console.log('No Server Response');
          } else {
              console.log('Action Failed')
          }
      }
  };

  // Create the record and redirect the user to the appropriate webpage. 
  const handleCreateAssessmentC2M2 = async () => {
    let assessmentName = assessmentNameC2M2;
    let assessmentType = "C2M2";
    try {
        const response = await axiosPrivate.post(`/c2m2/new`,
            JSON.stringify({ userId, assessmentName, assessmentType }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        navigate(`/C2M2Assessment/${response.data._id}`);
        
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
};

 // Create the record and redirect the user to the appropriate webpage. 
 const handleCreateAssessmentCSF = async () => {
  let assessmentName = assessmentNameCSF;
  let assessmentType = "CSF";
  try {
      const response = await axiosPrivate.post(`/csf/new`,
          JSON.stringify({ userId, assessmentName, assessmentType }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
      navigate(`/csfassessment/${response.data._id}`);
      
      // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
      // The console log commands below allow you to see the responses from Axios for the response.  
      // navigate(from, { replace: true });
  } catch (err) {
      if (!err?.response) {
          console.log('No Server Response');
      } else {
          console.log('Action Failed')
      }
  }
};

 // Create the record and redirect the user to the appropriate webpage. 
 const handleCreateAssessmentRMF = async () => {
  let assessmentName = assessmentNameRMF;
  let assessmentType = "RMF";
  try {
      const response = await axiosPrivate.post(`/rmf/new`,
          JSON.stringify({ userId, assessmentControlBaselineRMF, assessmentName, assessmentType }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
      navigate(`/rmfassessment/${response.data._id}`);
      
      // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
      // The console log commands below allow you to see the responses from Axios for the response.  
      // navigate(from, { replace: true });
  } catch (err) {
      if (!err?.response) {
          console.log('No Server Response');
      } else {
          console.log('Action Failed')
      }
  }
};


  return (
    <Box sx={{ p: "1rem" }}>
      <h1>Enterprise Assessments</h1>
      <Grid container spacing={2} columns={12} >
        <Grid item xs={12} >
          <Box>

          {/*   ZTMM  */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                CISA Zero Trust Maturity Model (ZTMM)
              </AccordionSummary>
              <Divider />
              <Grid container columns={12} spacing={1}>
                <Grid item xs={7}>
                  <AccordionDetails>
                    <Typography sx={{ p: "15px" }}>
                      <Link href="https://www.cisa.gov/sites/default/files/2023-04/CISA_Zero_Trust_Maturity_Model_Version_2_508c.pdf" target="_blank" rel="noopener noreferrer">CISA’s Zero Trust Maturity Model (ZTMM)</Link> provides an approach to achieve continued modernization efforts related to zero trust within a rapidly evolving environment and technology landscape.
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      This ZTMM is one of many paths that an organization can take in designing and implementing their transition plan to zero trust architectures in accordance with 
                      <Link href="https://www.govinfo.gov/content/pkg/FR-2021-05-17/pdf/2021-10460.pdf" target="_blank" rel="noopener noreferrer"> Executive Order (EO) 14028 “Improving the Nation’s Cybersecurity”</Link> 
                      which requires federal agencies develop a plan to implement a 
                      <Link href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-207.pdf" target="_blank" rel="noopener noreferrer"> Zero Trust Architecture (ZTA)</Link>. 
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      Zero trust provides a collection of concepts and ideas designed to minimize uncertainty in enforcing accurate, least privilege per-request access decisions in information systems and services in the face of a network viewed as compromised. 
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      ZTA is an enterprise’s cybersecurity plan that uses zero trust concepts and encompasses component relationships, workflow planning, and access policies.
                    </Typography>
                    <Grid container spacing={1} columns={12}>
                    <Grid item xs={6}>
                  <Box sx={{ border: "solid 1px", m: "1em"}}>
                    <AccordionDetails>
                      <Typography>
                        Start ZTA Assessment
                      </Typography>
                      <Typography sx={{ mt: '1rem' }}>
                                Assessment Name: 
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setAssessmentNameZTMM(e.target.value)}
                            />
                    </AccordionDetails>
                    <AccordionActions>
                      <Button disabled= {( assessmentNameZTMM==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleCreateAssessmentZTA}>Create ZTMM Assessment</Button>
                    </AccordionActions>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={1} columns={12} sx={{ m: "1em" }}>
                    <Grid item xs={8}>
                    <Typography><b>Total Questions: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      37
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Maturity Levels: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      4
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Categories: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      5
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      1 hour
                    </Grid>
                  </Grid>
                </Grid>

                    </Grid>
                  </AccordionDetails>
                </Grid>
                <Grid item xs={5}>
                  <Box
                    component="img"
                    width="100%"
                    alt="The house from the offer."
                    src={ZTAEvolution}
                    />
                </Grid>
          
              </Grid>
            </Accordion>

          {/*   C2M2  */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                DOE Cybersecurity Capability Maturity Model (C2M2)
              </AccordionSummary>
              <Divider />
              <Grid container columns={12} spacing={1}>
                <Grid item xs={7}>
                  <AccordionDetails>
                    <Typography sx={{ p: "15px" }}>
                      <Link href="https://www.energy.gov/ceser/cybersecurity-capability-maturity-model-c2m2" target="_blank" rel="noopener noreferrer">DOE's Cybersecurity Capability Maturity Model (C2M2)</Link> focuses on the implementation and management of cybersecurity practices associated with IT, OT, and information assets and the environments in which they operate.  
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      The model can be used to: 
                    </Typography>
                      <Typography>
                        1.) strengthen organizations’ cybersecurity capabilities 
                      </Typography>
                      <Typography>
                        2.) enable organizations to effectively and consistently evaluate and benchmark cybersecurity capabilities
                      </Typography>
                      <Typography>
                        3.) share knowledge, best practices, and relevant references across organizations as a means to improve cybersecurity capabilities
                      </Typography>
                      <Typography>
                        4.) enable organizations to prioritize actions and investments to improve cybersecurity capabilities
                      </Typography>
                  </AccordionDetails>
                </Grid>
                <Grid item xs={5}>
                  <Box sx={{ p: "15px" }}
                    component="img"
                    width="100%"
                    alt="C2M2 description image."
                    src={C2M2ProgramGoals}
                    />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ border: "solid 1px", m: "1em"}}>
                    
                    <AccordionDetails>
                      <Typography>
                        Start C2M2 Assessment
                      </Typography>
                      {/* <Typography sx={{ mt: '1rem' }}>
                                Assessment Name: 
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setAssessmentNameC2M2(e.target.value)}
                            /> */}
                            <Typography sx={{ p: "15px" }}>
                              DOE provides free tool for <Link href="https://c2m2.doe.gov/c2m2-assessment" target="_blank" rel="noopener noreferrer"> C2M2 Self-Assessments</Link>.
                            </Typography>
                    </AccordionDetails>
                    {/* <AccordionActions>
                      <Button disabled= {( assessmentNameC==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleCreateAssessmentC2M2}>Create C2M2 Assessment</Button>
                    </AccordionActions> */}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={1} columns={12} sx={{ m: "1em" }}>
                    <Grid item xs={8}>
                    <Typography><b>Total Questions: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      350+
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Maturity Levels: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      3
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Categories: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      10 
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      6 hours
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>

          {/*   CSF  */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                NIST Cybersecurity Framework 2.0 (CSF2)
              </AccordionSummary>
              <Divider />
              <Grid container columns={12} spacing={1}>
                <Grid item xs={8}>
                  <AccordionDetails>
                    <Typography sx={{ p: "15px" }}>
                      <Link href="https://www.nist.gov/cyberframework" target="_blank" rel="noopener noreferrer">NIST Cybersecurity Framework 2.0 (CSF2)</Link> provides guidance to industry, government agencies, and other organizations to manage cybersecurity risks. 
                    </Typography>
                      <Typography sx={{ p: "15px" }}>
                        It offers a taxonomy of high-level cybersecurity outcomes that can be used by any organization — regardless of its size, sector, or maturity — to better understand, assess, prioritize, and communicate its cybersecurity efforts.
                      </Typography>
                    <Typography sx={{ p: "15px" }}>
                      The CSF 2.0 is organized by six Functions — Govern, Identify, Protect, Detect, Respond, and Recover. 
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      The CSF describes desired outcomes that are intended to be understood by a broad audience, including executives, managers, and practitioners, regardless of their cybersecurity expertise. 
                    </Typography>
                    <Grid container spacing={1} columns={12}>
                    <Grid item xs={8}>
                  <Box sx={{ border: "solid 1px", m: "1em"}}>



                    {/* ************ Delete this message once the CSF assessment page is done.  THis is just to tell the use the page is under construction. ************** */}
                    {/* <AccordionDetails>
                    <Header title="Under Construction" subtitle="" />
                    </AccordionDetails> */}


                    {/* ************* Uncomment this to enable the CSF Assessment.  After the CSF assessment page is completed. ************** */}
                    <AccordionDetails>
                      <Typography>
                        Start CSF2 Assessment
                      </Typography>
                      <Typography sx={{ mt: '1rem' }}>
                                Assessment Name: 
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setAssessmentNameCSF(e.target.value)}
                            />
                    </AccordionDetails>
                    <AccordionActions>
                      <Button disabled= {( assessmentNameCSF==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleCreateAssessmentCSF}>Create CSF2 Assessment</Button>
                    </AccordionActions>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1} columns={12} sx={{ m: "1em" }}>
                    <Grid item xs={8}>
                    <Typography><b>Total Questions: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      107
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Maturity Levels: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      3
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Categories: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      6
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      2 hours
                    </Grid>
                  </Grid>
                </Grid>
                    </Grid>
                   
                  </AccordionDetails>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    component="img"
                    width="100%"
                    alt="CSF description image."
                    src={csf2}
                    />
                </Grid>
             
              </Grid>
            </Accordion>


          {/*   RMF */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                NIST Risk Management Framework 2.0 (RMF)
              </AccordionSummary>
              <Divider />
              <Grid container columns={12} spacing={1}>
                <Grid item xs={8}>
                  <AccordionDetails>
                    <Typography sx={{ p: "15px" }}>
                      <Link href="https://csrc.nist.gov/Projects/risk-management/about-rmf" target="_blank" rel="noopener noreferrer">NIST Risk Management Framework 2.0 (RMF)</Link> provides guidance to industry, government agencies, and other organizations to manage cybersecurity risks. 
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      The RMF provides a disciplined, structured, and flexible process for managing security and privacy risk that includes information security categorization; control selection, implementation, and assessment; system and common control authorizations; and continuous monitoring.
                    </Typography>
                    <Typography sx={{ p: "15px" }}>
                      This tool does not cover the entirety of the RMF process. Rather, it covers the security controls used in the RMF process and defined in <Link href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-53r5.pdf" target="_blank" rel="noopener noreferrer">NIST Special Publication 800-53 rev.5</Link>. 
                    </Typography>
                    <Typography sx={{ p: "15px"}}>
                      To start this assessment you must know if your Security Control Baseline is categorized as Low, Moderate, or High. 
                    </Typography>
                    <Grid container spacing={1} columns={12}>
                    <Grid item xs={8}>
                  <Box sx={{ border: "solid 1px", m: "1em"}}>


                     {/* ************ Delete this message once the RMF assessment page is done.  THis is just to tell the use the page is under construction. ************** */}
                     <AccordionDetails>
                    <Header title="Under Construction" subtitle="" />
                    </AccordionDetails>


                    {/* ************* Uncomment this to enable the RMF Assessment.  After the RMF assessment page is completed. ************** */}
                    {/* <AccordionDetails>
                      <Typography>
                        Start RMF Controls Assessment
                      </Typography>
                      <Typography sx={{ mt: '1rem' }}>
                        Assessment Name: 
                      </Typography>
                      <TextField
                        id="outlined-required"
                        label=""
                        defaultValue=""
                        fullWidth
                        onChange={(e) => setAssessmentNameRMF(e.target.value)}
                      />
                      <Typography sx={{ mt: '1rem' }}>
                        Security Control Baseline
                      </Typography>
                      <Dropdown setAssessmentControlBaselineRMF={setAssessmentControlBaselineRMF} />
                    </AccordionDetails>
                    <AccordionActions>
                      <Button disabled= {( assessmentNameRMF==="" || assessmentControlBaselineRMF==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleCreateAssessmentRMF}>Create RMF Controls Assessment</Button>
                    </AccordionActions> */}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                <Grid container spacing={1} columns={12} sx={{ m: "1em" }}>
                  <Grid item xs={8}>
                    <Typography><b>Total Questions (Low): </b></Typography>
                  </Grid>
                    <Grid item xs={4}>
                      149
                  </Grid>
                  <Grid item xs={8}>
                    <Typography><b>Total Questions (Moderate): </b></Typography>
                  </Grid>
                    <Grid item xs={4}>
                      287
                  </Grid>
                  <Grid item xs={8}>
                    <Typography><b>Total Questions (High): </b></Typography>
                  </Grid>
                    <Grid item xs={4}>
                      370
                  </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Maturity Levels: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      na
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Number of Categories: </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      20
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete (Low): </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      2.5 hours
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete (Moderate): </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      5 hours
                    </Grid>
                    <Grid item xs={8}>
                    <Typography><b>Estimated Time to Complete (High): </b></Typography>
                    </Grid>
                    <Grid item xs={4}>
                      6.5 hours
                    </Grid>
                  </Grid>
                </Grid>
                    </Grid>
                   
                  </AccordionDetails>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    component="img"
                    width="100%"
                    alt="The house from the offer."
                    src={rmf2}
                    />
                </Grid>
             
              </Grid>
            </Accordion>

          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Box
          width="100%"
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
            <Grid container spacing={1} columns={12}>
            <Grid item xs={12} >
                <WidgetWrapper >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", paddingBottom: '0.5rem' }} >
                        <Typography>
                            Current ZTMM Assessments
                        </Typography>
                        {/* <Button sx={{ fontSize: '30', p: '1rem' }} variant="contained" onClick={handleStartNewAssessment}>New Assessment</Button> */}
                    </Box>
                    <Divider />
                    {userAssessmentDataZTMM ? (
                        <UserEnterpriseAssessmentWidget userAssessmentData={userAssessmentDataZTMM} setRefresh={setRefresh} refresh={refresh} />
                    ) : (
                        <Typography sx={{ p: "2rem" }}>No assessments.</Typography>
                    )
                }
                </WidgetWrapper>
            </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
          width="100%"
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
            <Grid container spacing={1} columns={12}>
            <Grid item xs={12} >
                <WidgetWrapper >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", paddingBottom: '0.5rem' }} >
                        <Typography>
                            Current CSF Assessments
                        </Typography>
                        {/* <Button sx={{ fontSize: '30', p: '1rem' }} variant="contained" onClick={handleStartNewAssessment}>New Assessment</Button> */}
                    </Box>
                    <Divider />
                    {userAssessmentDataCSF ? (
                        <UserEnterpriseAssessmentWidget userAssessmentData={userAssessmentDataCSF} setRefresh={setRefresh} refresh={refresh} />
                    ) : (
                        <Typography sx={{ p: "2rem" }}>No assessments.</Typography>
                    )
                }
                </WidgetWrapper>
            </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
          width="100%"
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
            <Grid container spacing={1} columns={12}>
            <Grid item xs={12} >
                <WidgetWrapper >
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "end", paddingBottom: '0.5rem' }} >
                        <Typography>
                            Current RMF Assessments
                        </Typography>
                        {/* <Button sx={{ fontSize: '30', p: '1rem' }} variant="contained" onClick={handleStartNewAssessment}>New Assessment</Button> */}
                    </Box>
                    <Divider />
                    {userAssessmentDataRMF ? (
                        <UserEnterpriseAssessmentWidget userAssessmentData={userAssessmentDataRMF} setRefresh={setRefresh} refresh={refresh} />
                    ) : (
                        <Typography sx={{ p: "2rem" }}>No assessments.</Typography>
                    )
                }
                </WidgetWrapper>
            </Grid>
            </Grid>
          </Box>
        </Grid>


        {/* <Grid item xs={6}>
          Item 1
        </Grid>
        <Grid item xs={6}>
          Item 2
        </Grid> */}
      </Grid>

    {/* <Button disabled={( assessmentNameZTMM==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleCreateAssessmentZTA}>Submit</Button> */}
  </Box>
  )
}

export default EnterpriseAssessments