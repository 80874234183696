import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Collapse, Typography, IconButton, useTheme, useMediaQuery, Grid, Paper, Divider } from "@mui/material";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';


const RestrictedAreas = ({damData, setProcessValue, setTabValue}) => {
  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '5';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();

  // update this variable name.
  const [restrictedAreas, setRestrictedAreas] = useState([]);
  const [userAddedRATemp, setUserAddedRATemp] = useState("");
  const [userAddedRAIndex, setUserAddedRAIndex] = useState(0);

  const [openInstructions, setOpenInstructions] = useState(true);
  

  const GridItemQuestionXS = 6;
  const GridItemInputXS = 3;
  const GridItemInputSX = 'center';
  const inputWidth = '40px';
  const inputHeight = '40px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;
  const inputStyleWidth = '30px';
  const inputStyleHeight = '30px';


// **GET current assessment data.
const getAssessment = async () => {
  try {
    const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setRestrictedAreas(data?.restrictedAreas);
  }
  catch (err) {
      // alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }
};

// Execute these functions on load. Add functions as necessary. 
useEffect(() => {
  // getDams();
  getAssessment();
}, []);  // eslint-disable-line react-hooks/exhaustive-deps

// **UPDATE Assessment Record 
const handleUpdate = async () => {
  console.log('handleUpdate Called.' + restrictedAreas);
  try {
      const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
      JSON.stringify({ restrictedAreas }),
      // Example of how to structure the inputs to be updated. This is from the SelectDam.jsx component.
      // { "damID": damID, "nidID": nidID },
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
  );
  // console.log(JSON.stringify(response.data._id));
  // setTabValue(valueComplete);

  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
}

const handleAddNewRestrictedArea = () => {
  if (userAddedRATemp==="") {
    setUserAddedRATemp("");
  } else {
    restrictedAreas.push(userAddedRATemp);
    setUserAddedRAIndex(userAddedRAIndex+1);
    handleUpdate();
    setUserAddedRATemp("");
  }
}

const handleClearRestrictedAreas = async () => {
  let restrictedAreas = [];
  setRestrictedAreas([]);
  try {
    const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
    JSON.stringify({ restrictedAreas }),
    // Example of how to structure the inputs to be updated. This is from the SelectDam.jsx component.
    // { "damID": damID, "nidID": nidID },
    {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    }
);
// console.log(JSON.stringify(response.data._id));
// setTabValue(valueComplete);

} catch (err) {
  // alert("Login Expired.");
  console.error(err);
  // navigate('/login', { state: { from: location }, replace: true });
}
}


const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };


return (
  <Box sx={{ marginTop:'1em', flexGrow: 1 }}>
    <Header title='Restricted' subtitle='Add Restricted Areas.' />
    <Grid container spacing={2}>
      <Grid item xs={12}>
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
            <Grid item xs={12} sx={{ p:'0.5rem' }}>
      {openInstructions ? (
          <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
            <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
            <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
          </IconButton>
            ) : (
            <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
              <ExpandCircleDownIcon color='primary' fontSize="large" />
              <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
            </IconButton>
            )}
          <Divider sx={{ border:0.5, mt:'6px' }}/>
      </Grid>
      <Collapse in={openInstructions} timeout="auto" unmountOnExit>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ ml:'2rem' }}>
            <Typography>Enter the name of the restricted area and press the <AddCircleIcon sx={{ cursor:'text' }} color="primary" />.  This information is optional and does not impact the security assessment.</Typography>
            <Typography>This information is optional and does not impact the security assessment. </Typography>
            <Typography>If provided, this information will be included in the final Site Security Plan.</Typography>
            <Typography></Typography>
          </Grid>
        </Grid>
      </Collapse>
</Paper>
             
            <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', mt:'1rem', p:'4px', minHeight:'250px'}} >
            <Grid item xs={12}>
              <Typography display="inline" sx={{ fontSize:'1.5em', marginTop:'0.5em'  }}>Currently Defined Restricted Areas:</Typography>
              <input style={{ height: '40px', width: '30%', marginLeft: '2rem' }}
                type="text"
                value={userAddedRATemp}
                id="userAddedRATemp"
                onChange={(e) => setUserAddedRATemp(e.target.value)}
              />
              <IconButton color="primary"  aria-label="add a critical asset" onClick={handleAddNewRestrictedArea} >
                <AddCircleIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ m:'0.5em', border:'solid 1px'}}>
              <Grid container spacing={1} sx={{ p:'1rem' }}>
                {restrictedAreas?.map((area) => (
                  <Grid item xs={4}>
                  <label style={{ marginBottom: '1em',  marginLeft: checkboxMarginLeft, width: inputStyleWidth, height: inputStyleHeight, fontSize:"1.5em" }} >{area}</label>
                  </Grid>
                  )
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display:'flex', justifyContent:'flex-end' }}>
              <Button variant="contained" onClick={handleClearRestrictedAreas}>
                Clear Restricted Areas List
              </Button>
            </Grid>
          </Paper>
        </Grid>
       </Grid>
      </Box>  
)
}

export default RestrictedAreas