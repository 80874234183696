/* 
Integrated from "mern_app".  Not sure if this will be needed
*/

import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const FriendListWidget = ({ userId }) => {

    const dispatch = useDispatch();
    const { palette } = useTheme();
    // const token = useSelector((state) => state.token);
    // const friends = useSelector((state) => state.user.friends);

    console.log("useId passed to FriendsListWidget is " + userId);

    // const getFriends = async () => {
    //     const response = await fetch(`http://localhost:3001/users/${userId}/friends`,
    //         {
    //             method: "GET",
    //             headers: { Authorization: `Bearer ${token}`},
    //         }
    //     );
    //     const data = await response.json();
    //     dispatch(setFriends({ friends: data }));
    //     console.log("Friends List from FriendListWidget is: " + friends);
    // };

    // console.log("Friends List from FriendListWidget is: " + friends);

    useEffect(() => {
        // getFriends();
      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <WidgetWrapper>
          <Typography
            color={palette.neutral.dark}
            variant="h5"
            fontWeight="500"
            sx={{ mb: "1.5rem" }}
          >
            Friend List
          </Typography>
          <Box display="flex" flexDirection="column" gap="1.5rem">
            {/* {friends.map((friend) => (
              <Friend
                key={friend._id}
                friendId={friend._id}
                name={`${friend.firstName} ${friend.lastName}`}
                subtitle={friend.occupation}
                userPicturePath={friend.picturePath}
              /> 
            ))} */}
          </Box>
        </WidgetWrapper>
      );
};



export default FriendListWidget;