import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { AppBar, Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useTheme, useMediaQuery, Grid, Paper, Divider } from "@mui/material";
import Header from "../../../components/Header";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CriticalAssets from '../Step1/CriticalAssets';
import RestrictedAreas from '../Step1/RestrictedAreas';
import Stepper from '../../../components/Stepper';
import { useLocation, useNavigate } from 'react-router-dom';


const SecurityGroupOther = ({damData, assessmentID}) => {
  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});

  // update this variable name.
  const [data, setData] = useState();
  const [processValue, setProcessValue] = useState('1');
  const [tabValue, setTabValue] = useState('1');


  const handleChange = (event, newValue) => {
      setTabValue(newValue);
  }


// **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data);
        setAssessmentData(data);
        const damRecord = await axiosPrivate.get(`/dams/${data[0].damID}`, {
          withCredentials: true
      });
      const damRecordData = await damRecord.data;
      setCurrentDam(damRecord.data[0]);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  };

  // Execute these functions on load. Add functions as necessary. 
  useEffect(() => {
    // getDams();
    getAssessment();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  // **UPDATE Assessment Record 
  const handleUpdate = async () => {
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
        
        // Example of how to structure the inputs to be updated. This is from the SelectDam.jsx component.
        // { "damID": damID, "nidID": nidID },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // console.log(JSON.stringify(response.data._id));
    setTabValue(valueComplete);

    } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  }

  return (
    <Box>
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 5, borderColor: 'divider'}}>
                <TabList onChange={handleChange} >
                        <Tab sx={{ border: 2 }} label='Security Assessment' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                        <Tab sx={{ border: 2 }} label='Vulnerability Assessment' value='2' />
                        <Tab sx={{ border: 2 }} label='Cyber/SCADA Assessment' value='3' />
                        <Tab sx={{ border: 2 }} label='4-Template' value='4' />
                        <Tab sx={{ border: 2 }} label='5-Template' value='5' />
                        <Tab sx={{ border: 2 }} label='6-Template' value='6' />
                </TabList>
            </Box>
            <TabPanel value='1'>
               {/* <SecurityAssessment /> */}
            </TabPanel>
            <Box sx={{ backgroundColor: theme.palette.background }}>
            <TabPanel value='2'>
                <CriticalAssets damData={damData} setProcessValue={setProcessValue} setTabValue={setTabValue} assessmentID={assessmentID} />
            </TabPanel>
            <TabPanel value='3'>
                Nothing Here. 
            </TabPanel>
            <TabPanel value='4'>
                <RestrictedAreas setTabValue={setTabValue} assessmentID={assessmentID} />
            </TabPanel>
            <TabPanel value='5'>Panel Two</TabPanel>
            <TabPanel value='6'>Nothing Here</TabPanel>
            </Box>
        </TabContext>
        <Stepper />
        </Box>
  )
}

export default SecurityGroupOther
