import { memo, useState, useEffect, useContext } from 'react';
import { Handle, Position, NodeResizer, useNodeId, NodeResizeControl } from 'reactflow';
import { Box, Button, Input, Select, MenuItem, Grid, FormControl, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import PanToolIcon from '@mui/icons-material/PanTool';
import AddCounterMeasures from './DefensiveNodes/AddCounterMeasures';
import PhysicalSecurityPlan from './DefensiveNodes/PhysicalSecurityPlan';
import { NodeContext } from './index';


const options = [
  {
    value: '10',
    label: '10',
  },
  {
    value: 'default',
    label: '12 (default)',
  },
  {
    value: '14',
    label: '14',
  },
  {
    value: '16',
    label: '16',
  },
];

const ComplexNode = ({ id, data, selected }) => {

  const [textSizer, setTextSizer] = useState('6');
  const [open, setOpen] = useState(false);
  const controls = ['test input'];
  const [counterMeasure1, setCounterMeasure1] = useState("");
  const [counterMeasure2, setCounterMeasure2] = useState("");
  const [counterMeasure3, setCounterMeasure3] = useState("");
  const [counterMeasure4, setCounterMeasure4] = useState("");
  const nodeID = useNodeId();
  const [label2, setLabel2] = useState();
  // const [nodes, setNodes] = useContext(NodeContext);

  const handleStyleLeft = {
    l1: 10,
  }
  
  const handleStyleRight = {top: 100};
  
  // const controlStyle = {
  //   background: 'transparent',
  //   border: 'none',
  // };



  const handleClickOpen = (data) => {
    // console.log(data.label);
    setOpen(true);
  };

  const handleNo = () => {
    setOpen(false);
  };

  const handleYes = () => {
    // setCounterMeasure1();
    setCounterMeasure2();
    setCounterMeasure3();
    setCounterMeasure4();
    setOpen(false);
    return (
      <PhysicalSecurityPlan />
    );
  };

  // console.log(nodes);

  const handleChange1 = (event) => {
    setCounterMeasure1(event.target.value);
    controls.splice(0, 0, event.target.value);
  }

  const handleChange2 = (event) => {
    setCounterMeasure2(event.target.value);
    controls.splice(0, 0, event.target.value);
  }

  const handleChange3 = (event) => {
    setCounterMeasure3(event.target.value);
    controls.splice(0, 0, event.target.value);
    console.log(controls);
  }

  const handleChange4 = (event) => {
    setCounterMeasure4(event.target.value);
    controls.splice(0, 0, event.target.value);
  }

  const ReturnControlsList = () => {
    return (
      <>
        <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
        <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </>
      );
    }

  return (
    <Box >
      <Dialog
        fullWidth
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
          Add Countermeasures
        </DialogTitle>
        <DialogContent>
        <Box>
        
    <Grid container spacing={1} >
    <FormControl fullWidth >
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label1"
          id="demo-simple-select"
          value={counterMeasure1}
          label="counterMeasure1"
          onChange={handleChange1}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item xs>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label2"
          id="demo-simple-select"
          value={counterMeasure2}
          label="counterMeasure2"
          onChange={handleChange2}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label3"
          id="demo-simple-select"
          value={counterMeasure3}
          label="counterMeasure3"
          onChange={handleChange3}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item xs>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label4"
          id="demo-simple-select"
          value={counterMeasure4}
          label="counterMeasure4"
          onChange={handleChange4}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
    </Grid>
    </Box>
        <DialogContentText id="alert-dialog-description">
          Close dialog box and look at console.
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes}>Add</Button>
        <Button onClick={handleNo} autoFocus>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ display: 'grid', position: 'absolute', height: '50%', width: '83%',
         left: 1, bottom: 1, resize:'none', marginTop: '10%', textAlign: 'center' }} ></textarea>
          {nodeID}
        <NodeResizeControl minWidth={100} minHeight={50} maxHeight={300} maxWidth={400}>
          <ResizeIcon />
        </NodeResizeControl>
    <Handle type="target" position={Position.Left} />
    <PanTool />
    <Button sx={{marginRight: '1rem'}} size='small' variant='contained' onClick={() => {handleClickOpen(data)}}>Dialog</Button>
    {/* <div>{data.label}</div> */}
    <Handle type="source" position={Position.Right} />
  </Box>
  );
};

// function CountermeasuresForStep() {
//   return (
    
//   );
// }

function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke= "#404040"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ position: 'absolute', right: 5, bottom: 5 }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="16 20 20 20 20 16" />
      <line x1="14" y1="14" x2="20" y2="20" />
      <polyline points="8 4 4 4 4 8" />
      <line x1="4" y1="4" x2="10" y2="10" />
    </svg>
  );
}

const labelStyle = {
  display: 'flex',
  alignItems: 'bottom',
  // justifyItems: 'start'
};

const dragHandleStyle = {
  display: 'inline-block',
  width: 25,
  height: 25,
  backgroundColor: 'teal',
  marginLeft: 5,
  borderRadius: '50%',
};

function TextResizerTool({setTextSizer}) {
  

  return (
    <>
     <select className="nodrag" >
        {options.map((option) => (
          <option key={option.value} value={option.value} onChange={setTextSizer(option.value)} >
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
}

function PanTool() {
  return (
    <>
      <div style={labelStyle}>
        {/* Use the class specified at node.dragHandle here */}
        <span className="custom-drag-handle" >
          <PanToolIcon sx={{ fontSize: 'small', position: 'absolute', top: 5, right: 5 }} />
        </span>
      </div>
    </>
  );
}

export default memo(ComplexNode);




{/*
<Box >
      <Dialog
        fullWidth
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
          Add Countermeasures
        </DialogTitle>
        <DialogContent>
        <Box>
        
    <Grid container spacing={1} >
    <FormControl fullWidth >
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label1"
          id="demo-simple-select"
          value={counterMeasure1}
          label="counterMeasure1"
          onChange={handleChange1}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item xs>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label2"
          id="demo-simple-select"
          value={counterMeasure2}
          label="counterMeasure2"
          onChange={handleChange2}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label3"
          id="demo-simple-select"
          value={counterMeasure3}
          label="counterMeasure3"
          onChange={handleChange3}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
      <FormControl fullWidth >
      <Grid item xs>
        <Typography>AND</Typography>
      </Grid>
      <Grid item xs>
          <Select
          size="small"
          labelId="demo-simple-select-label4"
          id="demo-simple-select"
          value={counterMeasure4}
          label="counterMeasure4"
          onChange={handleChange4}
          >
            <MenuItem value={'NetworkMonitoring'}>Network Monitoring</MenuItem>
            <MenuItem value={'PhysicalSecurityPlan'}>Physical Security Plan</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
      </Grid>
      </FormControl>
    </Grid>
    </Box>
        <DialogContentText id="alert-dialog-description">
          Close dialog box and look at console.
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes}>Add</Button>
        <Button onClick={handleNo} autoFocus>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
    <Grid container spacing={1}>
      <Grid item xs={12} >
        <textarea name="text" wrap="soft" rows="14" cols="10" autoComplete='off' maxLength={100} style={{ display: 'grid', position: 'absolute', height: '50%', width: '83%',
         left: 1, bottom: 1, resize:'none', marginTop: '10%', textAlign: 'center' }} onChange={(e) => setLabel2(e.target.value)}></textarea>
      </Grid>
      <Grid item xs>
        <Typography>
          {nodeID}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeResizeControl minWidth={100} minHeight={50} maxHeight={300} maxWidth={400}>
          <ResizeIcon />
        </NodeResizeControl>
      </Grid>
      </Grid>
    <Handle type="target" position={Position.Left} />
    <PanTool />
    <Button sx={{marginRight: '1rem'}} size='small' variant='contained' onClick={() => {handleClickOpen(data)}}>Dialog</Button>
    {/* <div>{data.label}</div>
    <Handle type="source" position={Position.Right} />
  </Box>
*/}