// import Navbar from "../navbar";
import { Box, useTheme, Grid, Typography } from "@mui/material";

const ColorTest = () => {
    const theme = useTheme();

    return (
        <Box>
            {/* <Navbar /> */}
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>
                        Dark Mode Colors
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.primary.dark', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.primary.dark
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.primary.main', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.primary.main
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.primary.light', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.primary.light
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.dark', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.neutral.dark
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.main', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.neutral.main
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.mediumMain', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.neutral.mediumMain
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.medium', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.neutral.medium
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.light', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.neutral.light
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.background', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.background
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.background.alt', height:"200px", backgroundColor: ""}}>
                        <Typography>
                            theme.palette.background.alt
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height:"200px", backgroundColor: ""}}>
                        <Typography>
                            <h1>This is heading h1. </h1>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height:"200px", backgroundColor: ""}}>
                        <Typography>
                        <h2>This is heading h2. </h2>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height:"200px", backgroundColor: ""}}>
                        <Typography>
                            <h3>This is heading h3. </h3>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height:"200px", backgroundColor: ""}}>
                        <Typography>
                        <h4>This is heading h4. </h4>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: 'theme.palette.neutral.light', height:"200px", backgroundColor: ""}}>
                        <Typography>
                        <h5>This is heading h5. </h5>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ height:"200px", backgroundColor: ""}}>
                        <Typography>
                            <h6>This is heading h6. </h6>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            </Box> 
        </Box>
    )

}

export default ColorTest