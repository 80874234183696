import { useCallback, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, useReactFlow, useStore, getBezierPath, getSmoothStepPath,  } from 'reactflow';

import { getEdgeParams } from './utils.js';


function FloatingEdge({ id, source, target, markerEnd, style, selected }) {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const { setEdges } = useReactFlow();

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  // const [edgePath] = getBezierPath({
  //   sourceX: sx,
  //   sourceY: sy,
  //   sourcePosition: sourcePos,
  //   targetPosition: targetPos,
  //   targetX: tx,
  //   targetY: ty,
  // });


  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  return (
    <>
     <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {/* <path
      id={id}
      className="react-flow__edge-path"
      d={edgePath}

      // markerEnd={markerEnd}
      style={style}
    /> */}
    {selected && (
      <EdgeLabelRenderer >
      <div
        style={{
          position: 'absolute',
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          fontSize: 12,
          // everything inside EdgeLabelRenderer has no pointer events by default
          // if you have an interactive element, set pointer-events: all
          pointerEvents: 'all',
        }}
        className="nodrag nopan"
      >
        <button className="edgebutton" onClick={onEdgeClick}>
          ×
        </button>
      </div>
    </EdgeLabelRenderer>
    )}
    </>
  
  );
}

export default FloatingEdge;