import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Collapse, Typography, useTheme, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, IconButton } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import DataGridCustomToolbar from '../../../components/DataGridCustomToolbar';
import Header from "../../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';



const Threats = ({activeStep, setActiveStep, questionFamily, setQuestionFamily}) =>  {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();

  const date = new Date();
  const currentYear = date.getFullYear();

  const [threatToDelete, setThreatToDelete] = useState(null);
  const [threatName, setThreatName] = useState(null);

  const [open, setOpen] = useState(false);
  const [userAddedThreatTemp , setUserAddedThreatTemp] = useState("Insider");
  const [openInstructions, setOpenInstructions] = useState(true);

  const {assessmentID} = useParams();
  const [refresh, setRefresh] = useState(true);


   // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
const getThreats = async () => {
  try {
    const response = await axiosPrivate.get(`/threats/threats/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setRows(data);
    // if (data.length===0) {
    //   setRows(DefaultThreatsList);
    // } else {
    //   setRows(data);
    // }
    console.log(data);
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};

const DefaultThreatsList = [
  {
    _id: 'faksjflksaf39630sdlfas',
    threatName: 'Insider',
    threatDescription: 'Any person who has or had authorized access to or knowledge of an organization’s resources, including personnel, facilities, information, equipment, networks, and systems.'
  },
  {
    _id: '456587987654iuytrfgvbh',
    threatName: 'Small Group Lightly Equipped',
    threatDescription: 'Group of 3-5 individuals with small arms and backpack explosives.'
  },
  {
    _id: '34567ytresdfghnbvc',
    threatName: 'Small Group Heavily Equipped',
    threatDescription: 'Group of 3-5 individuals with small arms, vehicles (land and water), and VBIED (delivery/water truck size, and the equivalent for a water-side (boat) attack). '
  },
]

const AddDefaultThreats = () => {
  setRows(rows.concat(DefaultThreatsList));
}

  useEffect(() => {
      getThreats();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!rows) {
      return null;
    }

  
  // Update Database Function 
  const handleAddRow = async () => {
    const id = randomId();
    let threatName = 'CHANGE ME';
    let threatDescription = 'ADD DESCRIPTION;'
    setRows((oldRows) => [...oldRows, { '_id': id, threatName: 'CHANGE ME', threatDescription: 'ADD DESCRIPTION', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
    let active = true;
    try {
      const response = await axiosPrivate.post(`/threats/threats/new`,
        JSON.stringify({ assessmentID, threatName, threatDescription, active }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      } 
    );
    } catch (err) {
        console.log(err);
        
    }
  }

  const handleRowEditStop = (id, event) => {
    if (id.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row) => () => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
  };

  // Update Database Function 
  const handleThreatUpdate = async (updatedRow) => {
    let id = updatedRow._id;
    let threatName = updatedRow.threatName;
    let threatDescription = updatedRow.threatDescription;
    try {
      const response = await axiosPrivate.patch(`/threats/threats/${id}`,
        JSON.stringify({ assessmentID, threatName, threatDescription }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
      }
    };

  const handleSaveClick = (row) => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    handleThreatUpdate(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeleteThreat = async () => {
    try {
        const response = await axiosPrivate.delete(`/threats/${threatToDelete}`, 
            ({ "active": false }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
        // console.log(response);
    } catch (err) {
        console.error(err);
    }
     setThreatToDelete(null);
     setThreatName(null);
     setRefresh(!refresh);
 }
 
const handleClickOpen = (params) => {
  setThreatToDelete(params.row._id);
  setThreatName(params.row.assetName || "this device");
  setOpen(true);
};

const handleNo = () => {
  setOpen(false);
};

const handleYes = () => {
  handleDeleteThreat();
  setOpen(false);
};

const columns = [
  {
    field: "threatName",
    headerName: "Name",
    flex: 1,
    editable: true
  },
  {
    field: "threatDescription",
    headerName: "Description",
    flex: 1,
    editable: true
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 1,
    cellClassName: 'actions',
    getActions: (params) => {
      const isInEditMode = rowModesModel[params.row._id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => handleSaveClick(params.row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.row._id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleClickOpen(params)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<AddIcon />}
          label="Add"
          onClick={() => handleAddRow()}
          color="inherit"
        />
      ];
    },
  },
];


const handleAddNewThreat = async () => {
  if (userAddedThreatTemp.length>1) {
    let threatName = userAddedThreatTemp;
    let active = true;
    try {
      const response = await axiosPrivate.post(`/threats/threats/new`,
        JSON.stringify({ assessmentID, threatName, active }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      } 
    );
    } catch (err) {
        console.log(err);
        
    }
  }
  setUserAddedThreatTemp("");
  setRefresh(!refresh);
};

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};

const handleNextQuestion = () => {
  if (questionFamily === 3) {
    setQuestionFamily(1);
  } else {
    setQuestionFamily(questionFamily+1);
  }
};


  return (
    <Box sx={{ marginTop:'1em', flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={5}>
          <Header title='Threats' subtitle='Add Threats for this assessment.' />
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{ pt:'0.5rem', minWidth:'350px' }}
            component="img"
            width="80%"
            alt="The house from the offer."
            src={FERCRiskEquationLong}
            />
        </Grid> */}
      
        <Grid item xs={12} >
        {/* <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} > */}
          <Grid item xs={12}>
          {openInstructions ? (
              <IconButton color="secondary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='secondary' fontSize="large" />
                <Typography variant='h4' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
              </IconButton>
                ) : (
                <IconButton color="secondary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                  <ExpandCircleDownIcon color='secondary' fontSize="large" />
                  <Typography variant='h4' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                </IconButton>
                )}
             
          </Grid>
          <Collapse in={openInstructions} timeout="auto" unmountOnExit>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ ml:'2rem' }}>
                <Typography>Enter the name of the threat and press the <AddCircleIcon color="primary" /> to add it to the assessment.</Typography>
                <Typography>Click on the <DeleteIcon sx={{ cursor:'text' }}  /> to remove a threat. Click on the <EditIcon sx={{ cursor:'text' }}  /> to make changes to the threats and click the <SaveIcon sx={{ cursor:'text'}} /> to save the changes.</Typography>
                <Typography>This information is necessary to complete the vulnerability assessment.</Typography>
                <Typography>This information is included in the final Site Security Plan.</Typography>
              </Grid>
            </Grid>
          </Collapse>
        <Divider />
        </Grid>

        {/* <Grid item xs={12}>
          <Button sx={{ minWidth:'100px', width:'10%', display:'block', ml:'auto', mr:'5%' }} variant="contained" onClick={handleNextQuestion}>Next</Button>
        </Grid> */}

        <Grid item xs={12}>
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', mt:'1rem', p:'4px', minHeight:'250px'}} >
              <Grid item xs={6}>
              {/* <Typography display="inline" sx={{ fontSize:'1.5em', marginTop:'0.5em', p:'0.5em'  }}>Threats:</Typography> */}
                <input style={{ height: '40px', width: '30%', marginLeft: '2rem' }}
                  type="text"
                  id="userAddedUserTemp"
                  onChange={(e) => setUserAddedThreatTemp(e.target.value)}
                />
                <select style={{ height: '40px', width: '30%', marginLeft: '2rem'}} onChange={(e) => setUserAddedThreatTemp(e.target.value)}> 
                  {DefaultThreatsList.map((threat) => (
                    <option key={threat.threatName} value={threat.threatName}>{threat.threatName}</option>
                  ))}
               </select> 
                <IconButton color="primary"  aria-label="add a vulnerability" onClick={handleAddNewThreat}>
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid item xs={12} >
                <Dialog
                  open={open}
                  onClose={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  >
                  <DialogTitle id="alert-dialog-title">
                  {"Verify Delete Command"}
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete {threatName}?
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleYes}>Yes</Button>
                  <Button onClick={handleNo} autoFocus>
                      No
                  </Button>
                  </DialogActions>
                </Dialog>
                <Box
                  sx={{
                    width: '99%',
                    fontSize: '2em',
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.secondary.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "solid 2px black",
                        borderTop: "solid 2px black",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                    "& .MuiDataGrid-main": {
                      fontSize: "1.5em"
                    }
                  }}
                  >
                  <DataGrid
                    rows={rows || []} 
                    getRowId={(row) => row._id}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    slots={{
                      toolbar: DataGridCustomToolbar,
                    }}
                    slotProps={{
                      toolbar: { setRows, setRowModesModel },
                    }}
                  />
                </Box>
                
              </Grid>
        </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Threats