import React from 'react'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { SettingsOutlined, ChevronLeft, ChevronRightOutlined, HomeOutlined, Groups2Outlined, PublicOutlined, PointOfSaleOutlined, TodayOutlined, CalendarMonthOutlined, AdminPanelSettingsOutlined, TrendingUpOutlined, PieChartOutlined } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from '../../components/FlexBetween';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SportsMartialArtsOutlinedIcon from '@mui/icons-material/SportsMartialArtsOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SosIcon from '@mui/icons-material/Sos';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BoltIcon from '@mui/icons-material/Bolt';
import InsightsIcon from '@mui/icons-material/Insights';
import SpeedIcon from '@mui/icons-material/Speed';


const navItems = [
    {
        text: "Dashboard - Coming Soon",
        icon: <InsightsIcon />
    },
    {
        text: "Enterprise Assessment",
        icon: <AssessmentIcon />
    },
    {
        text: "Training - Coming Soon",
        icon: <Person2OutlinedIcon />,
    },
    {
        text: "FERC - Coming Soon",
        icon: <BoltIcon />
    },
    {
        text: "Cybersecurity - Coming Soon",
        icon: <ShieldOutlinedIcon />
    },
    // {
    //     text: "Physical Security - Coming Soon",
    //     icon: <SportsMartialArtsOutlinedIcon />
    // },
    // {
    //     text: "Exercises - Coming Soon",
    //     icon: <Groups2Outlined />
    // },
    {
        text: "Cybersecurity Program Development",
        icon: null,
    },
    {
        text: "Governance - Coming Soon",
        icon: <AccountBalanceIcon />
    },
    {
        text: "Operations - Coming Soon",
        icon: <TodayOutlined />
    },
    {
        text: "Risk Management - Coming Soon",
        icon: <SpeedIcon />
    },
    // {
    //     text: "Security Architecture - Coming Soon",
    //     icon: <PieChartOutlined />
    // },
    // {
    //     text: "Project Support - Coming Soon",
    //     icon: <AccountTreeOutlinedIcon />,
    // },
    {
        text: "Get Help",
        icon: <AutoAwesomeOutlinedIcon />
    },
]

const Sidebar = ({
    user,
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile

}) => {
    const { pathname } = useLocation();
    const [ active, setActive ] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);

  return (
    <Box component="nav">
        {isSidebarOpen && (
            <Drawer
                open={isSidebarOpen}
                // onClose={setIsSidebarOpen}
                variant="persistent"
                anchor="left"
                sx={{
                    width: drawerWidth,
                    "& .MuiDrawer-paper": {
                        color: theme.palette.secondary[200],
                        backgroundColor: theme.palette.background.alt,
                        boxSizing: "border-box",
                        borderWidth: isNonMobile ? 0 : "2px",
                        width: drawerWidth
                    },
                }}
            > 
                <Box width="100%">
                    <Box m="1.5rem 2rem 2rem 3rem">
                        <FlexBetween color={theme.palette.primary.main} >
                            <Box display="flex" alignItems="center" gap="0.5rem">
                                <Typography variant="h4" fontWeight="Bold">
                                    SIDEBAR
                                </Typography>
                            </Box>
                            {!isNonMobile && ( 
                                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                    <ChevronLeft />
                                </IconButton>
                            )}
                        </FlexBetween>
                    </Box>
                    <List>
                        {navItems.map(({ text, icon }) => {
                            if (!icon) {
                                return (
                                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }} >
                                        {text}
                                    </Typography>
                                );
                            }
                            const lcText = text.toLowerCase();

                            return (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton
                                        onClick={() => {navigate(`/${lcText}`); setActive(lcText); }}
                                        sx= {{
                                            backgroundColor: active === lcText ? theme.palette.secondary[300] : "transparent",
                                            color: active === lcText ?
                                                theme.palette.primary[600] : theme.palette.primary[100],
                                        }} >
                                            <ListItemIcon
                                                sx={{
                                                    ml: "2rem",
                                                    color: active === lcText ?
                                                        theme.palette.primary[600] : theme.palette.primary[200],
                                                }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text} />
                                            {active === lcText && (
                                                <ChevronRightOutlined sx={{ ml: "auto" }} />
                                            )}
                                    </ListItemButton>
                                </ListItem>
                            );
                        })
                    }
                    </List>                    
                </Box>
                <Box position="absolute" bottom="2rem">
                    <Divider />
                </Box>
            </Drawer>
        )}
    </Box>  
    )
}

export default Sidebar;