import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, useTheme, Grid, Input, IconButton, useMediaQuery, TextField, Typography, Divider, Paper, Collapse, Link } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';


const FERCSecuirtyChecklistv5 = ({activeStep, setActiveStep}) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentData, setAssessmentData] = useState();
  const { assessmentID } = useParams();
  const FERCSecurityChecklistv5TextColor = "#333333";

  const [openDetectionAssessment, setOpenDetectionAssessment] = useState(true);
  const [openDelay, setOpenDelay] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);
  const [openIntegrationRiskManagement, setOpenIntegrationRiskManagement] = useState(false);
  const [openForm2, setOpenForm2] = useState(false);

  const [damManned, setDamManned] = useState();
  const [powerhouseManned, setPowerhouseManned] = useState();
  const [damSurveillanceCameras, setDamSurveillanceCameras] = useState();
  const [powerhouseSurveillanceCameras, setPowerhouseSurveillanceCameras] = useState();
  const [howCamerasViewed, setHowCamerasViewed] = useState();
  const [walkingInspections, setWalkingInspections] = useState();
  const [lockedFence, setLockedFence] = useState();
  const [accessRestrictionsFoot, setAccessRestrictionsFoot] = useState();
  const [accessRestrictionsVehicle, setAccessRestrictionsVehicle] = useState();
  const [accessRestrictionBoat, setAccessRestrictionBoat] = useState();
  const [spillwayGatesUnauthorizeAccess, setSpillwayGatesUnauthorizeAccess] = useState();
  const [powerhouseDoorsWindowsLocked, setPowerhouseDoorsWindowsLocked] = useState();
  const [powerhouseAlarms, setPowerhouseAlarms] = useState();
  const [powerhouseMotionDetectors, setPowerhouseMotionDetectors] = useState();
  const [delaySystemsBypass, setDelaySystemsBypass] = useState();
  const [restrictedAccessWaterConveyance, setRestrictedAccessWaterConveyance] = useState();
  const [surveillanceWaterConveyance, setSurveillanceWaterConveyance] = useState();
  const [cpmeTamperResistant, setCpmeTamperResistant] = useState();
  const [phoneNumbersLEPosted, setPhoneNumbersLEPosted] = useState();
  const [redundantComms, setRedundantComms] = useState();
  const [operatorResponseTime, setOperatorResponseTime] = useState();
  const [operatorResponseDescription, setOperatorResponseDescription] = useState();
  const [quicklyNotifyLE, setQuicklyNotifyLE] = useState();
  const [etaLE, setEtaLE] = useState();
  const [threatAssessment, setThreatAssessment] = useState();
  const [vulernableFeatures, setVulernableFeatures] = useState();
  const [potentailImpacts, setPotentailImpacts] = useState();
  const [dateLastLEConsultation, setDateLastLEConsultation] = useState();
  const [securityImprovementLastYear, setSecurityImprovementLastYear] = useState();
  const [securityImprovementLongTerm, setSecurityImprovementLongTerm] = useState();
  const [hasSecurityPlan, setHasSecurityPlan] = useState();
  const [overallSecurityPlanQuality, setOverallSecurityPlanQuality] = useState();
  const [responseRecoveryPlan, setResponseRecoveryPlan] = useState();
  const [siteSpecificResponseLevels, setSiteSpecificResponseLevels] = useState();
  const [threatMeasuresRelevance, setThreatMeasuresRelevance] = useState();
  const [securityPlanRevision, setSecurityPlanRevision] = useState();
  const [exerciseLastDate, setExerciseLastDate] = useState();
  const [hasVulnerabilityAssessment, setHasVulnerabilityAssessment] = useState();
  const [hasSecurityAssessment, setHasSecurityAssessment] = useState();
  const [planActionIntegration, setPlanActionIntegration] = useState();
  const [conflictingSecurityMeasures, setConflictingSecurityMeasures] = useState();
  const [hasHazmat, setHasHazmat] = useState();
  const [hazmatSecureAccess, setHazmatSecureAccess] = useState();
  const [cdprSecured, setCdprSecured] = useState();
  const [pastYearSecurityIncidents, setPastYearSecurityIncidents] = useState();
  const [specificSecurityConcerns, setSpecificSecurityConcerns] = useState();
  const [otherAssessments, setOtherAssessments] = useState();
  const [changesSinceLastInspection, setChangesSinceLastInspection] = useState();
  const [physicalSiteSecurityChanges, setPhysicalSiteSecurityChanges] = useState();
  const [proceduralChanges, setProceduralChanges] = useState();
  const [cybersecurityChanges, setCybersecurityChanges] = useState();
  const [overallRiskReduction, setOverallRiskReduction] = useState();
  const [damMannedComments, setDamMannedComments] = useState();
  const [powerhouseMannedComments, setPowerhouseMannedComments] = useState();
  const [damSurveillanceCamerasComments, setDamSurveillanceCamerasComments] = useState();
  const [powerhouseSurveillanceCamerasComments, setPowerhouseSurveillanceCamerasComments] = useState();
  const [howCamerasViewedComments, setHowCamerasViewedComments] = useState();
  const [walkingInspectionsComments, setWalkingInspectionsComments] = useState();
  const [lockedFenceComments, setLockedFenceComments] = useState();
  const [accessRestrictionsFootComments, setAccessRestrictionsFootComments] = useState();
  const [accessRestrictionsVehicleComments, setAccessRestrictionsVehicleComments] = useState();
  const [accessRestrictionBoatComments, setAccessRestrictionBoatComments] = useState();
  const [spillwayGatesUnauthorizeAccessComments, setSpillwayGatesUnauthorizeAccessComments] = useState();
  const [powerhouseDoorsWindowsLockedComments, setPowerhouseDoorsWindowsLockedComments] = useState();
  const [powerhouseAlarmsComments, setPowerhouseAlarmsComments] = useState();
  const [powerhouseMotionDetectorsComments, setPowerhouseMotionDetectorsComments] = useState();
  const [delaySystemsBypassComments, setDelaySystemsBypassComments] = useState();
  const [restrictedAccessWaterConveyanceComments, setRestrictedAccessWaterConveyanceComments] = useState();
  const [surveillanceWaterConveyanceComments, setSurveillanceWaterConveyanceComments] = useState();
  const [cpmeTamperResistantComments, setCpmeTamperResistantComments] = useState();
  const [phoneNumbersLEPostedComments, setPhoneNumbersLEPostedComments] = useState();
  const [redundantCommsComments, setRedundantCommsComments] = useState();
  const [operatorResponseTimeComments, setOperatorResponseTimeComments] = useState();
  const [operatorResponseDescriptionComments, setOperatorResponseDescriptionComments] = useState();
  const [quicklyNotifyLEComments, setQuicklyNotifyLEComments] = useState();
  const [etaLEComments, setEtaLEComments] = useState();
  const [threatAssessmentComments, setThreatAssessmentComments] = useState();
  const [vulernableFeaturesComments, setVulernableFeaturesComments] = useState();
  const [potentailImpactsComments, setPotentailImpactsComments] = useState();
  const [dateLastLEConsultationComments, setDateLastLEConsultationComments] = useState();
  const [securityImprovementLastYearComments, setSecurityImprovementLastYearComments] = useState();
  const [securityImprovementLongTermComments, setSecurityImprovementLongTermComments] = useState();
  const [hasSecurityPlanComments, setHasSecurityPlanComments] = useState();
  const [overallSecurityPlanQualityComments, setOverallSecurityPlanQualityComments] = useState();
  const [responseRecoveryPlanComments, setResponseRecoveryPlanComments] = useState();
  const [siteSpecificResponseLevelsComments, setSiteSpecificResponseLevelsComments] = useState();
  const [threatMeasuresRelevanceComments, setThreatMeasuresRelevanceComments] = useState();
  const [securityPlanRevisionComments, setSecurityPlanRevisionComments] = useState();
  const [exerciseLastDateComments, setExerciseLastDateComments] = useState();
  const [hasVulnerabilityAssessmentComments, setHasVulnerabilityAssessmentComments] = useState();
  const [hasSecurityAssessmentComments, setHasSecurityAssessmentComments] = useState();
  const [planActionIntegrationComments, setPlanActionIntegrationComments] = useState();
  const [conflictingSecurityMeasuresComments, setConflictingSecurityMeasuresComments] = useState();
  const [hasHazmatComments, setHasHazmatComments] = useState();
  const [hazmatSecureAccessComments, setHasmatSecureAccessComments] = useState();
  const [cdprSecuredComments, setCdprSecuredComments] = useState();
  const [pastYearSecurityIncidentsComments, setPastYearSecurityIncidentsComments] = useState();
  const [specificSecurityConcernsComments, setSpecificSecurityConcernsComments] = useState();
  const [otherAssessmentsComments, setOtherAssessmentsComments] = useState();
  const [changesSinceLastInspectionComments, setChangesSinceLastInspectionComments] = useState();
  const [physicalSiteSecurityChangesComments, setPhysicalSiteSecurityChangesComments] = useState();
  const [proceduralChangesComments, setProceduralChangesComments] = useState();
  const [cybersecurityChangesComments, setCybersecurityChangesComments] = useState();
  const [overallRiskReductionComments, setOverallRiskReductionComments] = useState();
  const [damMannedCommentsHoursDay, setDamMannedCommentsHoursDay] = useState();
  const [damMannedCommentsDaysWeek, setDamMannedCommentsDaysWeek] = useState();
  const [powerhouseMannedCommentsDaysWeek, setPowerhouseMannedCommentsDaysWeek] = useState();
  const [powerhouseMannedCommentsHoursDay, setPowerhouseMannedCommentsHoursDay] = useState();

  const [openInstructions, setOpenInstructions] = useState(true);

 
  // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
const getAssessmentData = async () => {
  try {
    const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
      withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    
    setDamManned(data?.damManned?.toString());
    setPowerhouseManned(data?.powerhouseManned?.toString());
    setDamSurveillanceCameras(data?.damSurveillanceCameras?.toString());
    setPowerhouseSurveillanceCameras(data?.powerhouseSurveillanceCameras?.toString());
    setHowCamerasViewed(data?.howCamerasViewed?.toString());
    setWalkingInspections(data?.walkingInspections?.toString());
    setLockedFence(data?.lockedFence?.toString());
    setAccessRestrictionsFoot(data?.accessRestrictionsFoot?.toString());
    setAccessRestrictionsVehicle(data?.accessRestrictionsVehicle?.toString());
    setAccessRestrictionBoat(data?.accessRestrictionBoat?.toString());
    setSpillwayGatesUnauthorizeAccess(data?.spillwayGatesUnauthorizeAccess?.toString());
    setPowerhouseDoorsWindowsLocked(data?.powerhouseDoorsWindowsLocked?.toString());
    setPowerhouseAlarms(data?.powerhouseAlarms?.toString());
    setPowerhouseMotionDetectors(data?.powerhouseMotionDetectors?.toString());
    setDelaySystemsBypass(data?.delaySystemsBypass?.toString());
    setRestrictedAccessWaterConveyance(data?.restrictedAccessWaterConveyance?.toString());
    setSurveillanceWaterConveyance(data?.surveillanceWaterConveyance?.toString());
    setCpmeTamperResistant(data?.cpmeTamperResistant?.toString());
    setPhoneNumbersLEPosted(data?.phoneNumbersLEPosted?.toString());
    setRedundantComms(data?.redundantComms?.toString());
    setOperatorResponseTime(data?.operatorResponseTime?.toString());
    setOperatorResponseDescription(data?.operatorResponseDescription?.toString());
    setQuicklyNotifyLE(data?.quicklyNotifyLE?.toString());
    setEtaLE(data?.etaLE?.toString());
    setThreatAssessment(data?.threatAssessment?.toString());
    setVulernableFeatures(data?.vulernableFeatures?.toString());
    setPotentailImpacts(data?.potentailImpacts?.toString());
    setDateLastLEConsultation(data?.dateLastLEConsultation?.toString());
    setSecurityImprovementLastYear(data?.securityImprovementLastYear?.toString());
    setSecurityImprovementLongTerm(data?.securityImprovementLongTerm?.toString());
    setHasSecurityPlan(data?.hasSecurityPlan?.toString());
    setOverallSecurityPlanQuality(data?.overallSecurityPlanQuality?.toString());
    setResponseRecoveryPlan(data?.responseRecoveryPlan?.toString());
    setSiteSpecificResponseLevels(data?.siteSpecificResponseLevels?.toString());
    setThreatMeasuresRelevance(data?.threatMeasuresRelevance?.toString());
    setSecurityPlanRevision(data?.securityPlanRevision?.toString());
    setExerciseLastDate(data?.exerciseLastDate?.toString());
    setHasVulnerabilityAssessment(data?.hasVulnerabilityAssessment?.toString());
    setHasSecurityAssessment(data?.hasSecurityAssessment?.toString());
    setPlanActionIntegration(data?.planActionIntegration?.toString());
    setConflictingSecurityMeasures(data?.conflictingSecurityMeasures?.toString());
    setHasHazmat(data?.hasHazmat?.toString());
    setHazmatSecureAccess(data?.hazmatSecureAccess?.toString());
    setCdprSecured(data?.cdprSecured?.toString());
    setPastYearSecurityIncidents(data?.pastYearSecurityIncidents?.toString());
    setSpecificSecurityConcerns(data?.specificSecurityConcerns?.toString());
    setOtherAssessments(data?.otherAssessments?.toString());
    setChangesSinceLastInspection(data?.changesSinceLastInspection?.toString());
    setPhysicalSiteSecurityChanges(data?.physicalSiteSecurityChanges?.toString());
    setProceduralChanges(data?.proceduralChanges?.toString());
    setCybersecurityChanges(data?.cybersecurityChanges?.toString());
    setOverallRiskReduction(data?.overallRiskReduction?.toString());
    setDamMannedComments(data?.damMannedComments?.toString());
    setDamMannedCommentsDaysWeek(data?.damMannedCommentsDaysWeek?.toString());
    setDamMannedCommentsHoursDay(data?.damMannedCommentsHoursDay?.toString());
    setPowerhouseMannedComments(data?.powerhouseMannedComments?.toString());
    setPowerhouseMannedCommentsDaysWeek(data?.powerhouseMannedCommentsDaysWeek?.toString());
    setPowerhouseMannedCommentsHoursDay(data?.powerhouseMannedCommentsHoursDay?.toString());
    setDamSurveillanceCamerasComments(data?.damSurveillanceCamerasComments?.toString());
    setPowerhouseSurveillanceCamerasComments(data?.powerhouseSurveillanceCamerasComments?.toString());
    setHowCamerasViewedComments(data?.howCamerasViewedComments?.toString());
    setWalkingInspectionsComments(data?.walkingInspectionsComments?.toString());
    setLockedFenceComments(data?.lockedFenceComments?.toString());
    setAccessRestrictionsFootComments(data?.accessRestrictionsFootComments?.toString());
    setAccessRestrictionsVehicleComments(data?.accessRestrictionsVehicleComments?.toString());
    setAccessRestrictionBoatComments(data?.accessRestrictionBoatComments?.toString());
    setSpillwayGatesUnauthorizeAccessComments(data?.spillwayGatesUnauthorizeAccessComments?.toString());
    setPowerhouseDoorsWindowsLockedComments(data?.powerhouseDoorsWindowsLockedComments?.toString());
    setPowerhouseAlarmsComments(data?.powerhouseAlarmsComments?.toString());
    setPowerhouseMotionDetectorsComments(data?.powerhouseMotionDetectorsComments?.toString());
    setDelaySystemsBypassComments(data?.delaySystemsBypassComments?.toString());
    setRestrictedAccessWaterConveyanceComments(data?.restrictedAccessWaterConveyanceComments?.toString());
    setSurveillanceWaterConveyanceComments(data?.surveillanceWaterConveyanceComments?.toString());
    setCpmeTamperResistantComments(data?.cpmeTamperResistantComments?.toString());
    setPhoneNumbersLEPostedComments(data?.phoneNumbersLEPostedComments?.toString());
    setRedundantCommsComments(data?.redundantCommsComments?.toString());
    setOperatorResponseTimeComments(data?.operatorResponseTimeComments?.toString());
    setOperatorResponseDescriptionComments(data?.operatorResponseDescriptionComments?.toString());
    setQuicklyNotifyLEComments(data?.quicklyNotifyLEComments?.toString());
    setEtaLEComments(data?.etaLEComments?.toString());
    setThreatAssessmentComments(data?.threatAssessmentComments?.toString());
    setVulernableFeaturesComments(data?.vulernableFeaturesComments?.toString());
    setPotentailImpactsComments(data?.potentailImpactsComments?.toString());
    setDateLastLEConsultationComments(data?.dateLastLEConsultationComments?.toString());
    setSecurityImprovementLastYearComments(data?.securityImprovementLastYearComments?.toString());
    setSecurityImprovementLongTermComments(data?.securityImprovementLongTermComments?.toString());
    setHasSecurityPlanComments(data?.hasSecurityPlanComments?.toString());
    setOverallSecurityPlanQualityComments(data?.overallSecurityPlanQualityComments?.toString());
    setResponseRecoveryPlanComments(data?.responseRecoveryPlanComments?.toString());
    setSiteSpecificResponseLevelsComments(data?.siteSpecificResponseLevelsComments?.toString());
    setThreatMeasuresRelevanceComments(data?.threatMeasuresRelevanceComments?.toString());
    setSecurityPlanRevisionComments(data?.securityPlanRevisionComments?.toString());
    setExerciseLastDateComments(data?.exerciseLastDateComments?.toString());
    setHasVulnerabilityAssessmentComments(data?.hasVulnerabilityAssessmentComments?.toString());
    setHasSecurityAssessmentComments(data?.hasSecurityAssessmentComments?.toString());
    setPlanActionIntegrationComments(data?.planActionIntegrationComments?.toString());
    setConflictingSecurityMeasuresComments(data?.conflictingSecurityMeasuresComments?.toString());
    setHasHazmatComments(data?.hasHazmatComments?.toString());
    setHasmatSecureAccessComments(data?.hasmatSecureAccessComments?.toString());
    setCdprSecuredComments(data?.cdprSecuredComments?.toString());
    setPastYearSecurityIncidentsComments(data?.pastYearSecurityIncidentsComments?.toString());
    setSpecificSecurityConcernsComments(data?.specificSecurityConcernsComments?.toString());
    setOtherAssessmentsComments(data?.otherAssessmentsComments?.toString());
    setChangesSinceLastInspectionComments(data?.changesSinceLastInspectionComments?.toString());
    setPhysicalSiteSecurityChangesComments(data?.physicalSiteSecurityChangesComments?.toString());
    setProceduralChangesComments(data?.proceduralChangesComments?.toString());
    setCybersecurityChangesComments(data?.cybersecurityChangesComments?.toString());
    setOverallRiskReductionComments(data?.overallRiskReductionComments?.toString());

    } catch (err) {
      alert("Login Expirsed.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
    
    if (assessmentData === null) {
      return;
    }
  };
  
  useEffect(() => {
      getAssessmentData();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!assessmentData) {
      return null;
    }

  
 // *************** Update to use Questionnaire specific Variables***************//
 const handleSubmit = async () => {
  let fercSecurityChecklistv5Questions = true;
  const fercSecurityChecklistv5 = JSON.stringify({ damManned, powerhouseManned, damSurveillanceCameras, powerhouseSurveillanceCameras, howCamerasViewed, walkingInspections, lockedFence, accessRestrictionsFoot, accessRestrictionsVehicle, accessRestrictionBoat,
    spillwayGatesUnauthorizeAccess, powerhouseDoorsWindowsLocked, powerhouseAlarms, powerhouseMotionDetectors, powerhouseSurveillanceCameras, delaySystemsBypass, restrictedAccessWaterConveyance, surveillanceWaterConveyance, cpmeTamperResistant,
    phoneNumbersLEPosted, redundantComms, operatorResponseTime, operatorResponseDescription, quicklyNotifyLE, etaLE, threatAssessment, vulernableFeatures, potentailImpacts, dateLastLEConsultation, securityImprovementLastYear, securityImprovementLongTerm,
    hasSecurityPlan, overallSecurityPlanQuality, responseRecoveryPlan, siteSpecificResponseLevels, threatMeasuresRelevance, securityPlanRevision, hasVulnerabilityAssessment, hasSecurityAssessment, planActionIntegration, conflictingSecurityMeasures, hasHazmat, hazmatSecureAccess,
    cdprSecured, pastYearSecurityIncidents, specificSecurityConcerns, otherAssessments, changesSinceLastInspection, physicalSiteSecurityChanges, proceduralChanges, cybersecurityChanges, overallRiskReduction, 
    damMannedComments, damMannedCommentsDaysWeek, damMannedCommentsHoursDay, powerhouseMannedComments, powerhouseMannedCommentsDaysWeek, powerhouseMannedCommentsHoursDay, damSurveillanceCamerasComments, powerhouseSurveillanceCamerasComments, howCamerasViewedComments, walkingInspectionsComments, lockedFenceComments, accessRestrictionsFootComments, 
    accessRestrictionsVehicleComments, accessRestrictionBoatComments, spillwayGatesUnauthorizeAccessComments, powerhouseDoorsWindowsLockedComments, powerhouseAlarmsComments, powerhouseMotionDetectorsComments, powerhouseSurveillanceCamerasComments,
    delaySystemsBypassComments, restrictedAccessWaterConveyanceComments, surveillanceWaterConveyanceComments, cpmeTamperResistantComments, phoneNumbersLEPostedComments, redundantCommsComments, operatorResponseTimeComments, 
    operatorResponseDescriptionComments, quicklyNotifyLEComments, threatAssessmentComments, vulernableFeaturesComments, potentailImpactsComments, dateLastLEConsultationComments, securityImprovementLastYearComments, securityImprovementLongTermComments, hasSecurityPlanComments,
    overallSecurityPlanQualityComments, responseRecoveryPlanComments, siteSpecificResponseLevelsComments, threatMeasuresRelevanceComments, securityPlanRevisionComments, exerciseLastDateComments, hasVulnerabilityAssessmentComments, hasSecurityAssessmentComments, 
    planActionIntegrationComments, conflictingSecurityMeasuresComments, hasHazmatComments, hazmatSecureAccessComments, cdprSecuredComments, pastYearSecurityIncidentsComments, specificSecurityConcernsComments, otherAssessmentsComments, changesSinceLastInspectionComments, 
    physicalSiteSecurityChangesComments, proceduralChangesComments, cybersecurityChangesComments, overallRiskReductionComments });
  try {
      const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
      JSON.stringify({ fercSecurityChecklistv5Questions, fercSecurityChecklistv5 }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
  );
  } catch (err) {
      if (!err?.response) {
          console.log('No Server Response');
      } else {
          console.log('Action Failed')
      }
  }
}

const handleSaveDectectionAssessment = () => {
  handleOpenDetectionAssessment();
  handleOpenDelay();
  handleSubmit();
};

const handleSaveDelay = () => {
  handleOpenDelay();
  handleOpenResponse();
  handleSubmit();
};

const handleSaveResponse = () => {
  handleOpenResponse();
  handleOpenIntegrationRiskManagement();
  handleSubmit();
};

const handleSaveIntegrationRiskManagement = () => {
  handleOpenIntegrationRiskManagement();
  handleOpenForm2();
  handleSubmit();
};

const handleSaveForm2 = () => {
  handleOpenForm2();
  handleSubmit();
  setActiveStep(activeStep + 1);
};

const handleOpenDetectionAssessment = () => {
  setOpenDetectionAssessment(!openDetectionAssessment);
};

const handleOpenDelay = () => {
  setOpenDelay(!openDelay)
};

const handleOpenResponse = () => {
  setOpenResponse(!openResponse);
};

const handleOpenIntegrationRiskManagement = () => {
  setOpenIntegrationRiskManagement(!openIntegrationRiskManagement);
};

const handleOpenForm2 = () => {
  setOpenForm2(!openForm2);
};

  const GridItemQuestionXS = 4;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputStyleWidth = '22px';
  const inputStyleHeight = '22px';
  const GridItemCommentsXS = 6;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;

  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ p:'1rem' }}>
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
            <Grid item xs={12} sx={{ p:'0.5rem' }}>
              {openInstructions ? (
                <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                  <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                  <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
                </IconButton>
                ) : (
                <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                  <ExpandCircleDownIcon color='primary' fontSize="large" />
                  <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                </IconButton>
              )}
              <Divider sx={{ border:0.5, mt:'6px' }}/>
            </Grid>
            <Collapse in={openInstructions} timeout="auto" unmountOnExit>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ ml:'2rem' }}>
                  <Typography>This form is similar to the FERC Security Checklist v5, which is completed by the FERC auditor during the audit.</Typography>
                  <Typography>This information is optional and does not impact the security assessment. Completing this form prepares site personnel for the audit interviews.</Typography>
                  <Typography>If provided, this information will be included in the final Site Security Plan.</Typography>
                  <Typography></Typography>
                </Grid>
              </Grid>
            </Collapse>
          </Paper>
        </Grid>
      </Grid>
       
      <Paper sx={{ backgroundImage: "none", backgroundColor: '#CCCCCC',  borderRadius: "0.55rem", mt: '0.5rem', mr:'0.55rem', p:'0.5rem' }} >
      {/* RADIO BUTTON QUESTION */}
        <Grid sx={{ display: 'flex', alignItems: 'center' }} container spacing={1} direction="columns">
          <Grid item xs={GridItemQuestionXS}>
            <Typography color="black" variant= "h3"><b>Field Observations: (Provide detailed supplemental information to the right)</b></Typography>
          </Grid>
          <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography color="black" variant= "h3"><b>Answer</b></Typography>
          </Grid>
          <Grid item xs={GridItemCommentsXS} sx={{ mb:'1rem' }}>
            <Typography color="black" variant= "h3"><b>Comments (Provide additional details – especially any “No” answers – here and separate sheets, if necessary. Indicate NA if not appropriate to site.)</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Divider sx={{ width: '95%', m:'1rem'}} />
          </Grid>
        </Grid>
      </Paper>


      {/* ************DETECTION & ASSESSMENT DETECTION****************/}
      <Paper sx={{ backgroundImage: "none", backgroundColor: '#ffff99', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
        <Grid item xs={1}>
      {openDetectionAssessment ? (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenDetectionAssessment} >
        <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
        <Typography variant='h2' sx={{ ml: '2rem' }}>Detection & Assessment</Typography>
      </IconButton>
      ) : (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenDetectionAssessment} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>Detection & Assessment</Typography>
        </IconButton>
      )}
      </Grid>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Collapse in={openDetectionAssessment} timeout="auto" unmountOnExit>
      <Grid container spacing={1} sx={{ color:FERCSecurityChecklistv5TextColor}}>
    
      {/* Question 1a */}
      <Grid item xs={GridItemQuestionXS}>
      <Typography sx={{ ml:'0.5rem' }}>1a. Is the dam manned?</Typography>
      </Grid>
      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
      <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damManned'}
          type="radio"
          id={'damManned' + "Yes"}
          value="Yes"
          checked={damManned==="Yes"}
          onChange={(e) => setDamManned(e.target.value)}
      />
      <label htmlFor={"damManned" +"Yes"}>Yes</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damManned'}
          type="radio" 
          id={'damManned' + "No"}
          value="No"
          checked={damManned==="No"}
          onChange={(e) => setDamManned(e.target.value)}
      />
      <label htmlFor={'damManned' + "No"}>No</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damManned'}
          type="radio" 
          id={'damManned' + "NA"}
          value="NA"
          checked={damManned==="NA"}
          onChange={(e) => setDamManned(e.target.value)}
      />
      <label htmlFor={'damManned' + "NA"}>NA</label>
      </Grid>
      <Grid item xs={3}>
      <Grid container spacing={1}>
      <Grid item xs={7}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="damMannedCommentsDaysWeek">How many days per week:</label>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start'}}>
        <input style={{ width: '99%' }}
            type="text"
            id={'damMannedCommentsDaysWeek'}
            value={damMannedCommentsDaysWeek || ""}
            onChange={(e) => setDamMannedCommentsDaysWeek(e.target.value)}
        />
        </Grid> 
        
        <Grid item xs={7} sx={{ justifyContent: 'start'}}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="damMannedCommentsHoursDay" >How many hours per day:</label>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'left'}}>
          <input style={{ width: '99%' }}
              type="text"
              id={'damMannedCommentsHoursDay'}
              value={damMannedCommentsHoursDay || ""}
              onChange={(e) => setDamMannedCommentsHoursDay(e.target.value)}
          />
          </Grid> 
        </Grid>
      </Grid>

      <Grid item xs={3} sx={{ justifyContent: 'center'}}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="damMannedComments">Additional Comments:</label>
        <input style={{ width: '80%', marginLeft: '2rem' }}
            type="text"
            id={'damMannedComments'}
            value={damMannedComments || ""}
            onChange={(e) => setDamMannedComments(e.target.value)}
        />
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>


        {/* Question 1b */}
      <Grid item xs={GridItemQuestionXS}>
      <Typography sx={{ ml:'0.5rem' }}>1b. Is the powerhouse manned?</Typography>
      </Grid>
      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
      <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseManned'}
          type="radio"
          id={'powerhouseManned' + "Yes"}
          value="Yes"
          checked={powerhouseManned==="Yes"}
          onChange={(e) => setPowerhouseManned(e.target.value)}
      />
      <label htmlFor={"powerhouseManned" +"Yes"}>Yes</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseManned'}
          type="radio" 
          id={'powerhouseManned' + "No"}
          value="No"
          checked={powerhouseManned==="No"}
          onChange={(e) => setPowerhouseManned(e.target.value)}
      />
      <label htmlFor={'powerhouseManned' + "No"}>No</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseManned'}
          type="radio" 
          id={'powerhouseManned' + "NA"}
          value="NA"
          checked={powerhouseManned==="NA"}
          onChange={(e) => setPowerhouseManned(e.target.value)}
      />
      <label htmlFor={'powerhouseManned' + "NA"}>NA</label>
      </Grid>
      <Grid item xs={3}>
      <Grid container spacing={1}>
      <Grid item xs={7}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="powerhouseMannedCommentsDaysWeek">How many days per week:</label>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start'}}>
        <input style={{ width: '99%' }}
            type="text"
            id={'powerhouseMannedCommentsDaysWeek'}
            value={powerhouseMannedCommentsDaysWeek || ""}
            onChange={(e) => setPowerhouseMannedCommentsDaysWeek(e.target.value)}
        />
        </Grid> 
        
        <Grid item xs={7} sx={{ justifyContent: 'start'}}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="powerhouseMannedCommentsHoursDay" >How many hours per day:</label>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'left'}}>
          <input style={{ width: '99%' }}
              type="text"
              id={'powerhouseMannedCommentsHoursDay'}
              value={powerhouseMannedCommentsHoursDay || ""}
              onChange={(e) => setPowerhouseMannedCommentsHoursDay(e.target.value)}
          />
          </Grid> 
        
      </Grid>
      </Grid>
      
      
          
          <Grid item xs={3} sx={{ justifyContent: 'center'}}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="powerhouseMannedComments">Additional Comments:</label>
        <input style={{ width: '80%', marginLeft: '2rem' }}
            type="text"
            id={'powerhouseMannedComments'}
            value={powerhouseMannedComments || ""}
            onChange={(e) => setPowerhouseMannedComments(e.target.value)}
        />
        </Grid> 
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

      {/* Question 2a */}
      <Grid item xs={GridItemQuestionXS}>
      <Typography sx={{ ml:'0.5rem' }}>2a. Are there surveillance cameras in use at the Dam?</Typography>
      </Grid>
      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
      <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damSurveillanceCameras'}
          type="radio"
          id={'damSurveillanceCameras' + "Yes"}
          value="Yes"
          checked={damSurveillanceCameras==="Yes"}
          onChange={(e) => setDamSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={"damSurveillanceCameras" +"Yes"}>Yes</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damSurveillanceCameras'}
          type="radio" 
          id={'damSurveillanceCameras' + "No"}
          value="No"
          checked={damSurveillanceCameras==="No"}
          onChange={(e) => setDamSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={'damSurveillanceCameras' + "No"}>No</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'damSurveillanceCameras'}
          type="radio" 
          id={'damSurveillanceCameras' + "NA"}
          value="NA"
          checked={damSurveillanceCameras==="NA"}
          onChange={(e) => setDamSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={'damSurveillanceCameras' + "NA"}>NA</label>
      </Grid>
          
          <Grid item xs={6} sx={{ justifyContent: 'center'}}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="damSurveillanceCamerasComments">How are these cameras viewed and checked?</label>
        <input style={{ width: '80%', marginLeft: '2rem' }}
            type="text"
            id={'damSurveillanceCamerasComments'}
            value={damSurveillanceCamerasComments || ""}
            onChange={(e) => setDamSurveillanceCamerasComments(e.target.value)}
        />
        </Grid> 
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

      {/* Question 2b */}
      <Grid item xs={GridItemQuestionXS}>
      <Typography sx={{ ml:'0.5rem' }}>2b. Are there surveillance cameras in use at the powerhouse?</Typography>
      </Grid>
      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
      <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseSurveillanceCameras'}
          type="radio"
          id={'powerhouseSurveillanceCameras' + "Yes"}
          value="Yes"
          checked={powerhouseSurveillanceCameras==="Yes"}
          onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={"powerhouseSurveillanceCameras" +"Yes"}>Yes</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseSurveillanceCameras'}
          type="radio" 
          id={'powerhouseSurveillanceCameras' + "No"}
          value="No"
          checked={powerhouseSurveillanceCameras==="No"}
          onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={'powerhouseSurveillanceCameras' + "No"}>No</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'powerhouseSurveillanceCameras'}
          type="radio" 
          id={'powerhouseSurveillanceCameras' + "NA"}
          value="NA"
          checked={powerhouseSurveillanceCameras==="NA"}
          onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
      />
      <label htmlFor={'powerhouseSurveillanceCameras' + "NA"}>NA</label>
      </Grid>
          
          <Grid item xs={6} sx={{ justifyContent: 'center'}}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="powerhouseSurveillanceCamerasComments">How are these cameras viewed and checked?</label>
        <input style={{ width: '80%', marginLeft: '2rem' }}
            type="text"
            id={'powerhouseSurveillanceCamerasComments'}
            value={powerhouseSurveillanceCamerasComments || ""}
            onChange={(e) => setPowerhouseSurveillanceCamerasComments(e.target.value)}
        />
        </Grid> 
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

      {/* Question 3 */}
      <Grid item xs={GridItemQuestionXS}>
      <Typography sx={{ ml:'0.5rem' }}>3. Is the frequency of walking inspections appropriate (safety and / or security)?</Typography>
      </Grid>
      <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
      <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'walkingInspections'}
          type="radio"
          id={'walkingInspections' + "Yes"}
          value="Yes"
          checked={walkingInspections==="Yes"}
          onChange={(e) => setWalkingInspections(e.target.value)}
      />
      <label htmlFor={"walkingInspections" +"Yes"}>Yes</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'walkingInspections'}
          type="radio" 
          id={'walkingInspections' + "No"}
          value="No"
          checked={walkingInspections==="No"}
          onChange={(e) => setWalkingInspections(e.target.value)}
      />
      <label htmlFor={'walkingInspections' + "No"}>No</label>
      <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
          name={'walkingInspections'}
          type="radio" 
          id={'walkingInspections' + "NA"}
          value="NA"
          checked={walkingInspections==="NA"}
          onChange={(e) => setWalkingInspections(e.target.value)}
      />
      <label htmlFor={'walkingInspections' + "NA"}>NA</label>
      </Grid>
          
          <Grid item xs={6} sx={{ justifyContent: 'center'}}>
        {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
        <label htmlFor="walkingInspectionsComments">Additional Comments:</label>
        <input style={{ height: '60px', width: '80%', marginLeft: '2rem' }}
            type="text"
            id={'walkingInspectionsComments'}
            value={walkingInspectionsComments || ""}
            onChange={(e) => setWalkingInspectionsComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
      <Button sx={{ mr: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant="contained" onClick={handleSaveDectectionAssessment}>Save</Button>
      </Grid>
      
        </Grid>

      </Collapse>
      </Paper>
        

      {/* ************DELAY SECTION****************/}
      <Paper sx={{ backgroundImage: "none", backgroundColor: '#CCFFCC', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
        <Grid item xs={1}>
      {openDelay ? (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenDelay} >
        <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
        <Typography variant='h2' sx={{ ml: '2rem' }}>Delay</Typography>
      </IconButton>
      ) : (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenDelay} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>Delay</Typography>
        </IconButton>
      )}
      </Grid>
      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Collapse in={openDelay} timeout="auto" unmountOnExit>
        <Grid container spacing={1} sx={{ color:FERCSecurityChecklistv5TextColor}}>

        {/* Question 4 */}
        <Grid item xs={GridItemQuestionXS}>
        <Typography sx={{ ml:'0.5rem' }}>4. Is the dam site fenced with gates/doors locked (if appropriate to the site)?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'lockedFence'}
            type="radio"
            id={'lockedFence' + "Yes"}
            value="Yes"
            checked={lockedFence==="Yes"}
            onChange={(e) => setLockedFence(e.target.value)}
        />
        <label htmlFor={"lockedFence" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'lockedFence'}
            type="radio" 
            id={'lockedFence' + "No"}
            value="No"
            checked={lockedFence==="No"}
            onChange={(e) => setLockedFence(e.target.value)}
        />
        <label htmlFor={'lockedFence' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'lockedFence'}
            type="radio" 
            id={'lockedFence' + "NA"}
            value="NA"
            checked={lockedFence==="NA"}
            onChange={(e) => setLockedFence(e.target.value)}
        />
        <label htmlFor={'lockedFence' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="lockedFenceComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'lockedFenceComments'}
              value={lockedFenceComments || ""}
              onChange={(e) => setLockedFenceComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 5 */}
        <Grid item xs={12}>
        <Typography sx={{ ml:'0.5rem', mb:'1rem'}}>5. Are access restrictions to the dam and powerhouse sufficient to prevent the following types of attackers?</Typography>
        </Grid>
        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Attacker on Foot:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsFoot'}
            type="radio"
            id={'accessRestrictionsFoot' + "Yes"}
            value="Yes"
            checked={accessRestrictionsFoot==="Yes"}
            onChange={(e) => setAccessRestrictionsFoot(e.target.value)}
        />
        <label htmlFor={"accessRestrictionsFoot" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsFoot'}
            type="radio" 
            id={'accessRestrictionsFoot' + "No"}
            value="No"
            checked={accessRestrictionsFoot==="No"}
            onChange={(e) => setAccessRestrictionsFoot(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsFoot' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsFoot'}
            type="radio" 
            id={'accessRestrictionsFoot' + "NA"}
            value="NA"
            checked={accessRestrictionsFoot==="NA"}
            onChange={(e) => setAccessRestrictionsFoot(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsFoot' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="accessRestrictionsFootComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'accessRestrictionsFootComments'}
              value={accessRestrictionsFootComments || ""}
              onChange={(e) => setAccessRestrictionsFootComments(e.target.value)}
          />
          </Grid>

          <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Attacker in a Vehicle:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsVehicle'}
            type="radio"
            id={'accessRestrictionsVehicle' + "Yes"}
            value="Yes"
            checked={accessRestrictionsVehicle==="Yes"}
            onChange={(e) => setAccessRestrictionsVehicle(e.target.value)}
        />
        <label htmlFor={"accessRestrictionsVehicle" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsVehicle'}
            type="radio" 
            id={'accessRestrictionsVehicle' + "No"}
            value="No"
            checked={accessRestrictionsVehicle==="No"}
            onChange={(e) => setAccessRestrictionsVehicle(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsVehicle' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionsVehicle'}
            type="radio" 
            id={'accessRestrictionsVehicle' + "NA"}
            value="NA"
            checked={accessRestrictionsFoot==="NA"}
            onChange={(e) => setAccessRestrictionsVehicle(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsVehicle' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="accessRestrictionsVehicleComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'accessRestrictionsVehicleComments'}
              value={accessRestrictionsVehicleComments || ""}
              onChange={(e) => setAccessRestrictionsVehicleComments(e.target.value)}
          />
          </Grid> 

          <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Attacker in a Boat:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionBoat'}
            type="radio"
            id={'accessRestrictionBoat' + "Yes"}
            value="Yes"
            checked={accessRestrictionBoat==="Yes"}
            onChange={(e) => setAccessRestrictionBoat(e.target.value)}
        />
        <label htmlFor={"accessRestrictionBoat" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionBoat'}
            type="radio" 
            id={'accessRestrictionBoat' + "No"}
            value="No"
            checked={accessRestrictionBoat==="No"}
            onChange={(e) => setAccessRestrictionBoat(e.target.value)}
        />
        <label htmlFor={'accessRestrictionBoat' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'accessRestrictionBoat'}
            type="radio" 
            id={'accessRestrictionBoat' + "NA"}
            value="NA"
            checked={accessRestrictionBoat==="NA"}
            onChange={(e) => setAccessRestrictionBoat(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsBoat' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="accessRestrictionBoatComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'accessRestrictionBoatComments'}
              value={accessRestrictionBoatComments || ""}
              onChange={(e) => setAccessRestrictionBoatComments(e.target.value)}
          />
          </Grid> 

          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 6 */}
        <Grid item xs={GridItemQuestionXS}>
        <Typography sx={{ ml:'0.5rem' }}>6. Are spillway gate controls secured against unauthorized access?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'spillwayGatesUnauthorizeAccess'}
            type="radio"
            id={'spillwayGatesUnauthorizeAccess' + "Yes"}
            value="Yes"
            checked={spillwayGatesUnauthorizeAccess==="Yes"}
            onChange={(e) => setSpillwayGatesUnauthorizeAccess(e.target.value)}
        />
        <label htmlFor={"spillwayGatesUnauthorizeAccess" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'spillwayGatesUnauthorizeAccess'}
            type="radio" 
            id={'spillwayGatesUnauthorizeAccess' + "No"}
            value="No"
            checked={spillwayGatesUnauthorizeAccess==="No"}
            onChange={(e) => setSpillwayGatesUnauthorizeAccess(e.target.value)}
        />
        <label htmlFor={'spillwayGatesUnauthorizeAccess' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'spillwayGatesUnauthorizeAccess'}
            type="radio" 
            id={'spillwayGatesUnauthorizeAccess' + "NA"}
            value="NA"
            checked={spillwayGatesUnauthorizeAccess==="NA"}
            onChange={(e) => setSpillwayGatesUnauthorizeAccess(e.target.value)}
        />
        <label htmlFor={'powerhouseSurveillanceCameras' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="spillwayGatesUnauthorizeAccessComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'spillwayGatesUnauthorizeAccessComments'}
              value={spillwayGatesUnauthorizeAccessComments || ""}
              onChange={(e) => setSpillwayGatesUnauthorizeAccessComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


{/* Question 7a */}
<Grid item xs={12}>
        <Typography sx={{ ml:'0.5rem', mb:'1rem'}}>7a. Which of the following systems protect powerhouse doors and windows?</Typography>
        </Grid>
        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Locked:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseDoorsWindowsLocked'}
            type="radio"
            id={'powerhouseDoorsWindowsLocked' + "Yes"}
            value="Yes"
            checked={powerhouseDoorsWindowsLocked==="Yes"}
            onChange={(e) => setPowerhouseDoorsWindowsLocked(e.target.value)}
        />
        <label htmlFor={"accessRestrictionsFoot" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseDoorsWindowsLocked'}
            type="radio" 
            id={'powerhouseDoorsWindowsLocked' + "No"}
            value="No"
            checked={powerhouseDoorsWindowsLocked==="No"}
            onChange={(e) => setPowerhouseDoorsWindowsLocked(e.target.value)}
        />
        <label htmlFor={'powerhouseDoorsWindowsLocked' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseDoorsWindowsLocked'}
            type="radio" 
            id={'powerhouseDoorsWindowsLocked' + "NA"}
            value="NA"
            checked={powerhouseDoorsWindowsLocked==="NA"}
            onChange={(e) => setPowerhouseDoorsWindowsLocked(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsFoot' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="powerhouseDoorsWindowsLockedComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'powerhouseDoorsWindowsLockedComments'}
              value={powerhouseDoorsWindowsLockedComments || ""}
              onChange={(e) => setPowerhouseDoorsWindowsLockedComments(e.target.value)}
          />
          </Grid>

          <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Alarms:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseAlarms'}
            type="radio"
            id={'powerhouseAlarms' + "Yes"}
            value="Yes"
            checked={powerhouseAlarms==="Yes"}
            onChange={(e) => setPowerhouseAlarms(e.target.value)}
        />
        <label htmlFor={"powerhouseAlarms" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseAlarms'}
            type="radio" 
            id={'powerhouseAlarms' + "No"}
            value="No"
            checked={powerhouseAlarms==="No"}
            onChange={(e) => setPowerhouseAlarms(e.target.value)}
        />
        <label htmlFor={'powerhouseAlarms' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseAlarms'}
            type="radio" 
            id={'powerhouseAlarms' + "NA"}
            value="NA"
            checked={powerhouseAlarms==="NA"}
            onChange={(e) => setPowerhouseAlarms(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsVehicle' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="powerhouseAlarmsComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'powerhouseAlarmsComments'}
              value={powerhouseAlarmsComments || ""}
              onChange={(e) => setPowerhouseAlarmsComments(e.target.value)}
          />
          </Grid> 

          <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Motion Detectors:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseMotionDetectors'}
            type="radio"
            id={'powerhouseMotionDetectors' + "Yes"}
            value="Yes"
            checked={powerhouseMotionDetectors==="Yes"}
            onChange={(e) => setPowerhouseMotionDetectors(e.target.value)}
        />
        <label htmlFor={"powerhouseMotionDetectors" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseMotionDetectors'}
            type="radio" 
            id={'powerhouseMotionDetectors' + "No"}
            value="No"
            checked={powerhouseMotionDetectors==="No"}
            onChange={(e) => setPowerhouseMotionDetectors(e.target.value)}
        />
        <label htmlFor={'powerhouseMotionDetectors' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseMotionDetectors'}
            type="radio" 
            id={'powerhouseMotionDetectors' + "NA"}
            value="NA"
            checked={powerhouseMotionDetectors==="NA"}
            onChange={(e) => setPowerhouseMotionDetectors(e.target.value)}
        />
        <label htmlFor={'accessRestrictionsBoat' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="powerhouseMotionDetectorsComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'powerhouseMotionDetectorsComments'}
              value={powerhouseMotionDetectorsComments || ""}
              onChange={(e) => setPowerhouseMotionDetectorsComments(e.target.value)}
          />
          </Grid> 

          {/* <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Cameras:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf: GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseSurveillanceCameras'}
            type="radio"
            id={'powerhouseSurveillanceCameras' + "Yes"}
            value="Yes"
            checked={powerhouseSurveillanceCameras==="Yes"}
            onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
        />
        <label htmlFor={"powerhouseSurveillanceCameras" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseSurveillanceCameras'}
            type="radio" 
            id={'powerhouseSurveillanceCameras' + "No"}
            value="No"
            checked={powerhouseSurveillanceCameras==="No"}
            onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
        />
        <label htmlFor={'powerhouseSurveillanceCameras' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'powerhouseSurveillanceCameras'}
            type="radio" 
            id={'powerhouseSurveillanceCameras' + "NA"}
            value="NA"
            checked={powerhouseSurveillanceCameras==="NA"}
            onChange={(e) => setPowerhouseSurveillanceCameras(e.target.value)}
        />
        <label htmlFor={'powerhouseSurveillanceCameras' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
        
          <label htmlFor="powerhouseSurveillanceCamerasComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'powerhouseSurveillanceCamerasComments'}
              value={powerhouseSurveillanceCamerasComments || ""}
              onChange={(e) => setPowerhouseSurveillanceCamerasComments(e.target.value)}
          />
          </Grid>  */}
          


          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

        
{/* Question 7b */}
<Grid item xs={GridItemQuestionXS}>
        <Typography sx={{ ml:'0.5rem' }}>7b. Can these systems be easily bypassed?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'delaySystemsBypass'}
            type="radio"
            id={'delaySystemsBypass' + "Yes"}
            value="Yes"
            checked={delaySystemsBypass==="Yes"}
            onChange={(e) => setDelaySystemsBypass(e.target.value)}
        />
        <label htmlFor={"delaySystemsBypass" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'delaySystemsBypass'}
            type="radio" 
            id={'delaySystemsBypass' + "No"}
            value="No"
            checked={delaySystemsBypass==="No"}
            onChange={(e) => setDelaySystemsBypass(e.target.value)}
        />
        <label htmlFor={'delaySystemsBypass' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'delaySystemsBypass'}
            type="radio" 
            id={'delaySystemsBypass' + "NA"}
            value="NA"
            checked={delaySystemsBypass==="NA"}
            onChange={(e) => setDelaySystemsBypass(e.target.value)}
        />
        <label htmlFor={'delaySystemsBypass' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          <label htmlFor="delaySystemsBypassComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'delaySystemsBypassComments'}
              value={delaySystemsBypassComments || ""}
              onChange={(e) => setDelaySystemsBypassComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 8 */}
        <Grid item xs={12}>
          <Typography sx={{ ml:'0.5rem' }}>8. Which of the following physical security measures apply to the water conveyance system?</Typography>
        </Grid>
        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
          <Typography sx={{ m:'1rem' }}>Access Restricted:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'restrictedAccessWaterConveyance'}
            type="radio"
            id={'restrictedAccessWaterConveyance' + "Yes"}
            value="Yes"
            checked={restrictedAccessWaterConveyance==="Yes"}
            onChange={(e) => setRestrictedAccessWaterConveyance(e.target.value)}
          />
          <label htmlFor={"restrictedAccessWaterConveyance" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'restrictedAccessWaterConveyance'}
            type="radio" 
            id={'restrictedAccessWaterConveyance' + "No"}
            value="No"
            checked={restrictedAccessWaterConveyance==="No"}
            onChange={(e) => setRestrictedAccessWaterConveyance(e.target.value)}
          />
        <label htmlFor={'restrictedAccessWaterConveyance' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'restrictedAccessWaterConveyance'}
            type="radio" 
            id={'restrictedAccessWaterConveyance' + "NA"}
            value="NA"
            checked={restrictedAccessWaterConveyance==="NA"}
            onChange={(e) => setRestrictedAccessWaterConveyance(e.target.value)}
        />
        <label htmlFor={'delaySystemsBypass' + "NA"}>NA</label>
        </Grid>
            
        <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="restrictedAccessWaterConveyanceComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'restrictedAccessWaterConveyanceComments'}
              value={restrictedAccessWaterConveyanceComments || ""}
              onChange={(e) => setRestrictedAccessWaterConveyanceComments(e.target.value)}
          />
          </Grid> 

          <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', justifyContent:'flex-end', alignItems:'center' }}>
        <Typography sx={{ m:'1rem' }}>Surveillance:</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
        <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'surveillanceWaterConveyance'}
            type="radio"
            id={'surveillanceWaterConveyance' + "Yes"}
            value="Yes"
            checked={surveillanceWaterConveyance==="Yes"}
            onChange={(e) => setSurveillanceWaterConveyance(e.target.value)}
        />
        <label htmlFor={"surveillanceWaterConveyance" +"Yes"}>Yes</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'surveillanceWaterConveyance'}
            type="radio" 
            id={'surveillanceWaterConveyance' + "No"}
            value="No"
            checked={surveillanceWaterConveyance==="No"}
            onChange={(e) => setSurveillanceWaterConveyance(e.target.value)}
        />
        <label htmlFor={'surveillanceWaterConveyance' + "No"}>No</label>
        <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'surveillanceWaterConveyance'}
            type="radio" 
            id={'surveillanceWaterConveyance' + "NA"}
            value="NA"
            checked={surveillanceWaterConveyance==="NA"}
            onChange={(e) => setSurveillanceWaterConveyance(e.target.value)}
        />
        <label htmlFor={'surveillanceWaterConveyance' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="surveillanceWaterConveyanceComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'surveillanceWaterConveyanceComments'}
              value={surveillanceWaterConveyanceComments || ""}
              onChange={(e) => setSurveillanceWaterConveyanceComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

        {/* Question 9 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>9. Is Critical Performance Monitoring Equipment (CPME) secured against tampering?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cpmeTamperResistant'}
            type="radio"
            id={'cpmeTamperResistant' + "Yes"}
            value="Yes"
            checked={cpmeTamperResistant==="Yes"}
            onChange={(e) => setCpmeTamperResistant(e.target.value)}
          />
          <label htmlFor={"cpmeTamperResistant" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cpmeTamperResistant'}
            type="radio" 
            id={'cpmeTamperResistant' + "No"}
            value="No"
            checked={cpmeTamperResistant==="No"}
            onChange={(e) => setCpmeTamperResistant(e.target.value)}
          />
          <label htmlFor={'cpmeTamperResistant' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cpmeTamperResistant'}
            type="radio" 
            id={'cpmeTamperResistant' + "NA"}
            value="NA"
            checked={cpmeTamperResistant==="NA"}
            onChange={(e) => setCpmeTamperResistant(e.target.value)}
          />
          <label htmlFor={'cpmeTamperResistant' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="cpmeTamperResistantComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'cpmeTamperResistantComments'}
              value={cpmeTamperResistantComments || ""}
              onChange={(e) => setCpmeTamperResistantComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
      <Button sx={{ mr: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant="contained" onClick={handleSaveDelay}>Save</Button>
      </Grid>

        </Grid>
      </Collapse>
      </Paper>

          
      {/* ************RESPONSE SECTION****************/}
      <Paper sx={{ backgroundImage: "none", backgroundColor: '#CCFFFF', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
        <Grid item xs={1}>
      {openResponse ? (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenResponse} >
        <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
        <Typography variant='h2' sx={{ ml: '2rem' }}>Response</Typography>
      </IconButton>
      ) : (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenResponse} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>Response</Typography>
        </IconButton>
      )}
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Collapse in={openResponse} timeout="auto" unmountOnExit>
        <Grid container spacing={1} sx={{ color:FERCSecurityChecklistv5TextColor}}>

        {/* Question 10 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>10. Is Critical Performance Monitoring Equipment (CPME) secured against tampering?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'phoneNumbersLEPosted'}
            type="radio"
            id={'phoneNumbersLEPosted' + "Yes"}
            value="Yes"
            checked={phoneNumbersLEPosted==="Yes"}
            onChange={(e) => setPhoneNumbersLEPosted(e.target.value)}
          />
          <label htmlFor={"phoneNumbersLEPosted" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'phoneNumbersLEPosted'}
            type="radio" 
            id={'phoneNumbersLEPosted' + "No"}
            value="No"
            checked={cpmeTamperResistant==="No"}
            onChange={(e) => setPhoneNumbersLEPosted(e.target.value)}
          />
          <label htmlFor={'phoneNumbersLEPosted' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'phoneNumbersLEPosted'}
            type="radio" 
            id={'phoneNumbersLEPosted' + "NA"}
            value="NA"
            checked={phoneNumbersLEPosted==="NA"}
            onChange={(e) => setPhoneNumbersLEPosted(e.target.value)}
          />
          <label htmlFor={'phoneNumbersLEPosted' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="phoneNumbersLEPostedComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'phoneNumbersLEPostedComments'}
              value={phoneNumbersLEPostedComments || ""}
              onChange={(e) => setPhoneNumbersLEPostedComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 11 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>11. Are there redundant communications?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'redundantComms'}
            type="radio"
            id={'redundantComms' + "Yes"}
            value="Yes"
            checked={redundantComms==="Yes"}
            onChange={(e) => setRedundantComms(e.target.value)}
          />
          <label htmlFor={"redundantComms" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'redundantComms'}
            type="radio" 
            id={'redundantComms' + "No"}
            value="No"
            checked={redundantComms==="No"}
            onChange={(e) => setRedundantComms(e.target.value)}
          />
          <label htmlFor={'redundantComms' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'redundantComms'}
            type="radio" 
            id={'redundantComms' + "NA"}
            value="NA"
            checked={redundantComms==="NA"}
            onChange={(e) => setRedundantComms(e.target.value)}
          />
          <label htmlFor={'redundantComms' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="redundantCommsComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'redundantCommsComments'}
              value={redundantCommsComments || ""}
              onChange={(e) => setRedundantCommsComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

        {/* Question 12 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>12. How long it takes the operator if detected to respond to unauthorized access?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'operatorResponseTime'}
            type="radio"
            id={'operatorResponseTime' + "Yes"}
            value="Yes"
            checked={operatorResponseTime==="Yes"}
            onChange={(e) => setOperatorResponseTime(e.target.value)}
          />
          <label htmlFor={"operatorResponseTime" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'operatorResponseTime'}
            type="radio" 
            id={'operatorResponseTime' + "No"}
            value="No"
            checked={operatorResponseTime==="No"}
            onChange={(e) => setOperatorResponseTime(e.target.value)}
          />
          <label htmlFor={'operatorResponseTime' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'operatorResponseTime'}
            type="radio" 
            id={'operatorResponseTime' + "NA"}
            value="NA"
            checked={operatorResponseTime==="NA"}
            onChange={(e) => setOperatorResponseTime(e.target.value)}
          />
          <label htmlFor={'operatorResponseTime' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="operatorResponseTimeComments">How is detection made?</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
              type="text"
              id={'operatorResponseTimeComments'}
              value={operatorResponseTimeComments || ""}
              onChange={(e) => setOperatorResponseTimeComments(e.target.value)}
          />
          </Grid> 
          {/* <Grid item xs={2} sx={{ display: 'flex', ml:'3rem', alignItems:'center' }}>
            <Typography></Typography>
          </Grid> */}
          <Grid item xs={10} sx={{ display: 'flex', ml:'3rem', alignItems:'center', mt:'1rem' }}>
          <label htmlFor="operatorResponseDescription">What is that response?</label>
          <input style={{ width: '80%', marginLeft: '2rem' }}
              type="text"
              id={'operatorResponseDescription'}
              value={operatorResponseDescription || ""}
              onChange={(e) => setOperatorResponseDescription(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

        {/* Question 13 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>13. Can law enforcement be quickly notified?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'quicklyNotifyLE'}
            type="radio"
            id={'quicklyNotifyLE' + "Yes"}
            value="Yes"
            checked={quicklyNotifyLE==="Yes"}
            onChange={(e) => setQuicklyNotifyLE(e.target.value)}
          />
          <label htmlFor={"quicklyNotifyLE" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'quicklyNotifyLE'}
            type="radio" 
            id={'quicklyNotifyLE' + "No"}
            value="No"
            checked={quicklyNotifyLE==="No"}
            onChange={(e) => setQuicklyNotifyLE(e.target.value)}
          />
          <label htmlFor={'quicklyNotifyLE' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'quicklyNotifyLE'}
            type="radio" 
            id={'quicklyNotifyLE' + "NA"}
            value="NA"
            checked={quicklyNotifyLE==="NA"}
            onChange={(e) => setQuicklyNotifyLE(e.target.value)}
          />
          <label htmlFor={'operatorResponseTime' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          <label htmlFor="quicklyNotifyLEComments">Identify law enforcement agencies and thier capabilities:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
              type="text"
              id={'quicklyNotifyLEComments'}
              value={quicklyNotifyLEComments || ""}
              onChange={(e) => setQuicklyNotifyLEComments(e.target.value)}
          />
          <br /><br />
          <label htmlFor="etaLE">Estimated time for arrival?</label>
          <input style={{ width: '80%', marginLeft: '1rem' }}
              type="text"
              id={'etaLE'}
              value={etaLE || ""}
              onChange={(e) => setEtaLE(e.target.value)}
          />
          </Grid> 

          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
      <Button sx={{ mr: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant="contained" onClick={handleSaveResponse}>Save</Button>
      </Grid>

        </Grid>
      </Collapse>
      </Paper>


      {/* ************INTEGRATION & RISK MANAGEMENT SECTION****************/}
      <Paper sx={{ backgroundImage: "none", backgroundColor: '#F3F3F3', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
        <Grid item xs={1}>
      {openIntegrationRiskManagement ? (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenIntegrationRiskManagement} >
        <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
        <Typography variant='h2' sx={{ ml: '2rem' }}>Integration and Risk Management</Typography>
      </IconButton>
      ) : (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenIntegrationRiskManagement} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>Integration and Risk Management</Typography>
        </IconButton>
      )}
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Collapse in={openIntegrationRiskManagement} timeout="auto" unmountOnExit>
        <Grid container spacing={1} sx={{ color:FERCSecurityChecklistv5TextColor}}>
       

        {/* Question 14a */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>14a. Describe vulnerable features, including include switchyards & transmission lines, etc., and potential impacts for each, include cyberattacks. </Typography>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="vulernableFeatures"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'vulernableFeatures'}
              value={vulernableFeatures || ""}
              onChange={(e) => setVulernableFeatures(e.target.value)}
          />
          </Grid> 
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

           {/* Question 14b */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>14b. Describe threats your organization has idenfied. </Typography>
        </Grid>
        <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="threatAssessmentComments"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
            type="text"
            id={'threatAssessmentComments'}
            value={threatAssessmentComments || ""}
            onChange={(e) => setThreatAssessmentComments(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

           {/* Question 14c */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>14c. Last time a consultation with law enforcement was made to determine threat:</Typography>
        </Grid>
        <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="dateLastLEConsultation"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
            type="text"
            id={'dateLastLEConsultation'}
            value={dateLastLEConsultation || ""}
            onChange={(e) => setDateLastLEConsultation(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


         {/* Question 15a */}
         <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>15a. Describe the steps or projects you have taken to improve secuirty in the <u>last year</u>.</Typography>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="securityImprovementLastYear"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
              type="text"
              id={'securityImprovementLastYear'}
              value={securityImprovementLastYear || ""}
              onChange={(e) => setSecurityImprovementLastYear(e.target.value)}
          />
          </Grid> 
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

           {/* Question 15b */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>15b. If applicable, describe your <u>long-term</u> plans to improve secuirty.</Typography>
        </Grid>
        <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="securityImprovementLongTerm"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
            type="text"
            id={'securityImprovementLongTerm'}
            value={securityImprovementLongTerm || ""}
            onChange={(e) => setSecurityImprovementLongTerm(e.target.value)}
          />
          </Grid> 
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

         {/* Question 16a */}
         <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>16a. Is there a Security Plan as described in Chapter 7 of <Link href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects
          Revision 3 </Link> for this FERC project?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'hasSecurityPlan'}
            type="radio"
            id={'hasSecurityPlan' + "Yes"}
            value="Yes"
            checked={hasSecurityPlan==="Yes"}
            onChange={(e) => setHasSecurityPlan(e.target.value)}
          />
          <label htmlFor={"hasSecurityPlan" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'hasSecurityPlan'}
            type="radio" 
            id={'hasSecurityPlan' + "No"}
            value="No"
            checked={hasSecurityPlan==="No"}
            onChange={(e) => setHasSecurityPlan(e.target.value)}
          />
          <label htmlFor={'hasSecurityPlan' + "No"}>No</label>
        </Grid>
        <Grid item xs={4}>
        </Grid>

        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
          <Typography sx={{ ml: '3rem' }}>Is the plan acceptable?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'overallSecurityPlanQuality'}
            type="radio"
            id={'overallSecurityPlanQuality' + "Yes"}
            value="Yes"
            checked={overallSecurityPlanQuality==="Yes"}
            onChange={(e) => setOverallSecurityPlanQuality(e.target.value)}
          />
          <label htmlFor={"overallSecurityPlanQuality" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'overallSecurityPlanQuality'}
            type="radio" 
            id={'overallSecurityPlanQuality' + "No"}
            value="No"
            checked={overallSecurityPlanQuality==="No"}
            onChange={(e) => setOverallSecurityPlanQuality(e.target.value)}
          />
          <label htmlFor={'overallSecurityPlanQuality' + "No"}>No</label>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
          <Typography sx={{ ml: '3rem' }}>Is there a Response/Recovery component?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'responseRecoveryPlan'}
            type="radio"
            id={'responseRecoveryPlan' + "Yes"}
            value="Yes"
            checked={responseRecoveryPlan==="Yes"}
            onChange={(e) => setResponseRecoveryPlan(e.target.value)}
          />
          <label htmlFor={"responseRecoveryPlan" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'responseRecoveryPlan'}
            type="radio" 
            id={'responseRecoveryPlan' + "No"}
            value="No"
            checked={responseRecoveryPlan==="No"}
            onChange={(e) => setResponseRecoveryPlan(e.target.value)}
          />
          <label htmlFor={'responseRecoveryPlan' + "No"}>No</label>
        </Grid>
        <Grid item xs={4}>
        </Grid>
        <Grid item xs={GridItemQuestionXS} sx={{ display: 'flex', alignItems:'center', mt:'1rem', justifyContent:'flex-end' }}>
          <Typography sx={{ ml: '3rem' }}>Are there different site-specific response levels covered in the Security Plan for varying threat?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'siteSpecificResponseLevels'}
            type="radio"
            id={'siteSpecificResponseLevels' + "Yes"}
            value="Yes"
            checked={siteSpecificResponseLevels==="Yes"}
            onChange={(e) => setSiteSpecificResponseLevels(e.target.value)}
          />
          <label htmlFor={"siteSpecificResponseLevels" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'siteSpecificResponseLevels'}
            type="radio" 
            id={'siteSpecificResponseLevels' + "No"}
            value="No"
            checked={siteSpecificResponseLevels==="No"}
            onChange={(e) => setSiteSpecificResponseLevels(e.target.value)}
          />
          <label htmlFor={'siteSpecificResponseLevels' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS}>
          {/* <label style={{ alignSelf:{GridItemInputSX}, marginTop:'1.5rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label> */}
          <label htmlFor="siteSpecificResponseLevelsComments"></label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '2rem' }}
            type="text"
            id={'siteSpecificResponseLevelsComments'}
            value={siteSpecificResponseLevelsComments || ""}
            onChange={(e) => setSiteSpecificResponseLevelsComments(e.target.value)}
          />
          </Grid> 

          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 16b */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>16b. Has the Security Plan been revised since the last field change?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'securityPlanRevision'}
            type="radio"
            id={'securityPlanRevision' + "Yes"}
            value="Yes"
            checked={securityPlanRevision==="Yes"}
            onChange={(e) => setSecurityPlanRevision(e.target.value)}
          />
          <label htmlFor={"securityPlanRevision" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'securityPlanRevision'}
            type="radio" 
            id={'securityPlanRevision' + "No"}
            value="No"
            checked={securityPlanRevision==="No"}
            onChange={(e) => setSecurityPlanRevision(e.target.value)}
          />
          <label htmlFor={'securityPlanRevision' + "No"}>No</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'securityPlanRevision'}
            type="radio" 
            id={'securityPlanRevision' + "NA"}
            value="NA"
            checked={securityPlanRevision==="NA"}
            onChange={(e) => setSecurityPlanRevision(e.target.value)}
          />
          <label htmlFor={'securityPlanRevision' + "NA"}>NA</label>
        </Grid>
            
            <Grid item xs={GridItemCommentsXS} >
          <label htmlFor="securityPlanRevisionComments">When it was last exercised & what type?</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
              type="text"
              id={'securityPlanRevisionComments'}
              value={securityPlanRevisionComments || ""}
              onChange={(e) => setSecurityPlanRevisionComments(e.target.value)}
          />
          </Grid> 

          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 17 */}
        {assessmentData.securityGroupLocal==="Security Group 1" ? (
          <>
            <Grid item xs={GridItemQuestionXS}>
              <Typography sx={{ ml:'0.5rem' }}>17. Is there a Vulnerability Assessment?</Typography>
            </Grid>
          <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
            <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'hasVulnerabilityAssessment'}
              type="radio"
              id={'hasVulnerabilityAssessment' + "Yes"}
              value="Yes"
              checked={hasVulnerabilityAssessment==="Yes"}
              onChange={(e) => setHasVulnerabilityAssessment(e.target.value)}
            />
            <label htmlFor={"hasVulnerabilityAssessment" +"Yes"}>Yes</label>
            <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'hasVulnerabilityAssessment'}
              type="radio" 
              id={'hasVulnerabilityAssessment' + "No"}
              value="No"
              checked={hasVulnerabilityAssessment==="No"}
              onChange={(e) => setHasVulnerabilityAssessment(e.target.value)}
            />
            <label htmlFor={'hasVulnerabilityAssessment' + "No"}>No</label>
          </Grid>
          <Grid item xs={GridItemCommentsXS} >
          <label htmlFor="hasVulnerabilityAssessmentComments">Is it compliant with Chapter 5 of <Link target="_blank" rel="noopener noreferrer"  href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects
          Revision 3</Link>?</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
            type="text"
            id={'hasVulnerabilityAssessmentComments'}
            value={hasVulnerabilityAssessmentComments || ""}
            onChange={(e) => setHasVulnerabilityAssessmentComments(e.target.value)}
          />
          </Grid> 
        </>
        ) : (
          <>
            <Grid item xs={GridItemQuestionXS}>
              <Typography sx={{ ml:'0.5rem' }}>17. Is there a Vulnerability Assessment?</Typography>
            </Grid>
            <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
              <Typography>Vulnerability Assessments are only applicable for Security Group 1 FERC Projects.</Typography>
            </Grid>
          </>
        )}
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>

         {/* Question 18 */}
          <Grid item xs={GridItemQuestionXS}>
            <Typography sx={{ ml:'0.5rem' }}>18. Is there a Security Assessment?</Typography>
          </Grid>
          <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
            <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'hasSecurityAssessment'}
              type="radio"
              id={'hasSecurityAssessment' + "Yes"}
              value="Yes"
              checked={hasSecurityAssessment==="Yes"}
              onChange={(e) => setHasSecurityAssessment(e.target.value)}
            />
            <label htmlFor={"hasSecurityAssessment" +"Yes"}>Yes</label>
            <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'hasSecurityAssessment'}
              type="radio" 
              id={'hasSecurityAssessment' + "No"}
              value="No"
              checked={hasSecurityAssessment==="No"}
              onChange={(e) => setHasSecurityAssessment(e.target.value)}
            />
            <label htmlFor={'hasSecurityAssessment' + "No"}>No</label>
          </Grid>
          <Grid item xs={GridItemCommentsXS} >
          <label htmlFor="hasSecurityAssessmentComments">Is it compliant with Chapter 6 of <Link target="_blank" rel="noopener noreferrer"  href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf">FERC's FERC Security Program for Hydropower Projects
          Revision 3</Link>?</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
            type="text"
            id={'hasSecurityAssessmentComments'}
            value={hasSecurityAssessmentComments || ""}
            onChange={(e) => setHasSecurityAssessmentComments(e.target.value)}
          />
          </Grid> 
        
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


          {/* Question 19 */}
          <Grid item xs={GridItemQuestionXS}>
            <Typography sx={{ ml:'0.5rem' }}>19. Are all actions and plans fully integrated?</Typography>
          </Grid>
          <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
            <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'planActionIntegration'}
              type="radio"
              id={'planActionIntegration' + "Yes"}
              value="Yes"
              checked={planActionIntegration==="Yes"}
              onChange={(e) => setPlanActionIntegration(e.target.value)}
            />
            <label htmlFor={"planActionIntegration" +"Yes"}>Yes</label>
            <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
              name={'planActionIntegration'}
              type="radio" 
              id={'planActionIntegration' + "No"}
              value="No"
              checked={planActionIntegration==="No"}
              onChange={(e) => setPlanActionIntegration(e.target.value)}
            />
            <label htmlFor={'planActionIntegration' + "No"}>No</label>
          </Grid>
          <Grid item xs={GridItemCommentsXS} >
          <label htmlFor="planActionIntegrationComments">Additional Comments:</label>
          <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
            type="text"
            id={'planActionIntegrationComments'}
            value={planActionIntegrationComments || ""}
            onChange={(e) => setPlanActionIntegrationComments(e.target.value)}
          />
          </Grid> 
        
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>


        {/* Question 20 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>20. Do any security measures conflict with any license requirements?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'conflictingSecurityMeasures'}
            type="radio"
            id={'conflictingSecurityMeasures' + "Yes"}
            value="Yes"
            checked={conflictingSecurityMeasures==="Yes"}
            onChange={(e) => setConflictingSecurityMeasures(e.target.value)}
          />
          <label htmlFor={"conflictingSecurityMeasures" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'conflictingSecurityMeasures'}
            type="radio" 
            id={'conflictingSecurityMeasures' + "No"}
            value="No"
            checked={conflictingSecurityMeasures==="No"}
            onChange={(e) => setConflictingSecurityMeasures(e.target.value)}
          />
          <label htmlFor={'conflictingSecurityMeasures' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="conflictingSecurityMeasuresComments">Additional Comments:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'conflictingSecurityMeasuresComments'}
          value={conflictingSecurityMeasuresComments || ""}
          onChange={(e) => setConflictingSecurityMeasuresComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

          {/* Question 21 */}
          <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>21. Is there fuel storage or hazardous material on-site?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'hasHazmat'}
            type="radio"
            id={'hasHazmat' + "Yes"}
            value="Yes"
            checked={hasHazmat==="Yes"}
            onChange={(e) => setHasHazmat(e.target.value)}
          />
          <label htmlFor={"hasHazmat" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'hasHazmat'}
            type="radio" 
            id={'hasHazmat' + "No"}
            value="No"
            checked={hasHazmat==="No"}
            onChange={(e) => setHasHazmat(e.target.value)}
          />
          <label htmlFor={'hasHazmat' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="hasHazmatComments">Describe the hazardous material:</label>
        <textarea style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'hasHazmatComments'}
          value={hasHazmatComments || ""}
          onChange={(e) => setHasHazmatComments(e.target.value)}
        />
        </Grid> 

        <Grid item xs={GridItemCommentsXS} sx={{ ml:"3rem" }}>
          <label htmlFor="hazmatSecureAccess">If so, how is it secure?</label>
          <textarea style={{ width: inputCommentWidth, marginLeft: '1rem' }}
            type="text"
            id={'hazmatSecureAccess'}
            value={hazmatSecureAccess || ""}
            onChange={(e) => setHazmatSecureAccess(e.target.value)}
          />
          </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

        {/* Question 22 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>22. Are critical drawings/plans/records (CDP&R) secured from unauthorized access?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cdprSecured'}
            type="radio"
            id={'cdprSecured' + "Yes"}
            value="Yes"
            checked={cdprSecured==="Yes"}
            onChange={(e) => setCdprSecured(e.target.value)}
          />
          <label htmlFor={"cdprSecured" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cdprSecured'}
            type="radio" 
            id={'cdprSecured' + "No"}
            value="No"
            checked={cdprSecured==="No"}
            onChange={(e) => setCdprSecured(e.target.value)}
          />
          <label htmlFor={'cdprSecured' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="cdprSecuredComments">Additional Comments:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'cdprSecuredComments'}
          value={cdprSecuredComments || ""}
          onChange={(e) => setCdprSecuredComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
      <Button sx={{ mr: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant="contained" onClick={handleSaveIntegrationRiskManagement}>Save</Button>
      </Grid>
        </Grid>
      </Collapse>
      </Paper>


  {/* ************Project Security Summary Information Form 2****************/}
  <Paper sx={{ backgroundImage: "none", backgroundColor: '#fff', borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
        <Grid item xs={1}>
      {openForm2 ? (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenForm2} >
        <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
        <Typography variant='h2' sx={{ ml: '2rem' }}>Project Security Summary Information – Form 2</Typography>
      </IconButton>
      ) : (
        <IconButton color="primary"  aria-label="add a critical asset" onClick={handleOpenForm2} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>Project Security Summary Information – Form 2</Typography>
        </IconButton>
      )}
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>
      <Collapse in={openForm2} timeout="auto" unmountOnExit>
        <Grid container spacing={1} sx={{ color:FERCSecurityChecklistv5TextColor}}>
       

        {/* Question A */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>A. Number of security/surveillance incidents in the past year?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:'start' }}>
          <TextField style={{ alignSelf:GridItemInputSX, width: '25%', height: '50px' }}
            name={'pastYearSecurityIncidents'}
            type='number'
            InputProps={{
              inputProps: { 
                  max: 50, min: 0 
              }
          }}
            id={'pastYearSecurityIncidents' + "Yes"}
            onChange={(e) => setPastYearSecurityIncidents(e.target.value)}
          />
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="pastYearSecurityIncidentsComments">Describe Incidents (include if it was reported to FERC): </label>
        <textarea style={{ width: '90%', marginLeft: '1rem', height: '30vh', overflow:'visible' }}
          type="text"
          id={'pastYearSecurityIncidentsComments'}
          value={pastYearSecurityIncidentsComments || ""}
          onChange={(e) => setPastYearSecurityIncidentsComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>




 {/* Question B */}
 <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>B. Owner expressed specific security concerns or questions.</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'specificSecurityConcerns'}
            type="radio"
            id={'specificSecurityConcerns' + "Yes"}
            value="Yes"
            checked={specificSecurityConcerns==="Yes"}
            onChange={(e) => setSpecificSecurityConcerns(e.target.value)}
          />
          <label htmlFor={"specificSecurityConcerns" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'specificSecurityConcerns'}
            type="radio" 
            id={'specificSecurityConcerns' + "No"}
            value="No"
            checked={specificSecurityConcerns==="No"}
            onChange={(e) => setSpecificSecurityConcerns(e.target.value)}
          />
          <label htmlFor={'specificSecurityConcerns' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="specificSecurityConcernsComments">Additional Comments:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'specificSecurityConcernsComments'}
          value={specificSecurityConcernsComments || ""}
          onChange={(e) => setSpecificSecurityConcernsComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

{/* Question C */}
<Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>C. Number of data requests or site visits by DHS Protective Security Advisors or other assessment groups?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:'start' }}>
          <TextField style={{ alignSelf:GridItemInputSX, width: '25%', height: '50px' }}
            name={'otherAssessments'}
            type='number'
            InputProps={{
              inputProps: { 
                  max: 50, min: 0 
              }
          }}
            id={'otherAssessments' + "Yes"}
            onChange={(e) => setOtherAssessments(e.target.value)}
          />
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="otherAssessmentsComments">Describe Incidents (include if it was reported to FERC): </label>
        <textarea style={{ width: '90%', marginLeft: '1rem', height: '30vh', overflow:'visible' }}
          type="text"
          id={'otherAssessmentsComments'}
          value={otherAssessmentsComments || ""}
          onChange={(e) => setOtherAssessmentsComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>



       {/* Question D.1 */}
       <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>D.1. Have changes been made to the security program since your last inspection?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'changesSinceLastInspection'}
            type="radio"
            id={'changesSinceLastInspection' + "Yes"}
            value="Yes"
            checked={changesSinceLastInspection==="Yes"}
            onChange={(e) => setChangesSinceLastInspection(e.target.value)}
          />
          <label htmlFor={"changesSinceLastInspection" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'changesSinceLastInspection'}
            type="radio" 
            id={'changesSinceLastInspection' + "No"}
            value="No"
            checked={changesSinceLastInspection==="No"}
            onChange={(e) => setChangesSinceLastInspection(e.target.value)}
          />
          <label htmlFor={'changesSinceLastInspection' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="changesSinceLastInspectionComments">If 'No', do previous studies show your posture was adequate? Explain.</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'changesSinceLastInspectionComments'}
          value={changesSinceLastInspectionComments || ""}
          onChange={(e) => setChangesSinceLastInspectionComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>


       {/* Question D.2 */}
        <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>D.2. Were changes made to physical site security?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'physicalSiteSecurityChanges'}
            type="radio"
            id={'physicalSiteSecurityChanges' + "Yes"}
            value="Yes"
            checked={physicalSiteSecurityChanges==="Yes"}
            onChange={(e) => setPhysicalSiteSecurityChanges(e.target.value)}
          />
          <label htmlFor={"physicalSiteSecurityChanges" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'physicalSiteSecurityChanges'}
            type="radio" 
            id={'physicalSiteSecurityChanges' + "No"}
            value="No"
            checked={physicalSiteSecurityChanges==="No"}
            onChange={(e) => setPhysicalSiteSecurityChanges(e.target.value)}
          />
          <label htmlFor={'physicalSiteSecurityChanges' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="physicalSiteSecurityChangesComments">Describe Changes:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'physicalSiteSecurityChangesComments'}
          value={physicalSiteSecurityChangesComments || ""}
          onChange={(e) => setPhysicalSiteSecurityChangesComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>


      {/* Question D.3 */}
      <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>D.3. Were changes made to procedural operations (incl. threat level increase additions, employee actions, etc.)?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'proceduralChanges'}
            type="radio"
            id={'proceduralChanges' + "Yes"}
            value="Yes"
            checked={proceduralChanges==="Yes"}
            onChange={(e) => setProceduralChanges(e.target.value)}
          />
          <label htmlFor={"proceduralChanges" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'proceduralChanges'}
            type="radio" 
            id={'proceduralChanges' + "No"}
            value="No"
            checked={proceduralChanges==="No"}
            onChange={(e) => setProceduralChanges(e.target.value)}
          />
          <label htmlFor={'proceduralChanges' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="proceduralChangesComments">Describe Changes:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'proceduralChangesComments'}
          value={proceduralChangesComments || ""}
          onChange={(e) => setProceduralChangesComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>



       {/* Question D.4 */}
       <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>D.4. Were changes made to cyber/SCADA operations?</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          <input style={{ alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cybersecurityChanges'}
            type="radio"
            id={'cybersecurityChanges' + "Yes"}
            value="Yes"
            checked={cybersecurityChanges==="Yes"}
            onChange={(e) => setCybersecurityChanges(e.target.value)}
          />
          <label htmlFor={"cybersecurityChanges" +"Yes"}>Yes</label>
          <input style={{ marginLeft: '2rem', alignSelf:GridItemInputSX, width: inputStyleWidth, height: inputStyleHeight}}
            name={'cybersecurityChanges'}
            type="radio" 
            id={'cybersecurityChanges' + "No"}
            value="No"
            checked={cybersecurityChanges==="No"}
            onChange={(e) => setCybersecurityChanges(e.target.value)}
          />
          <label htmlFor={'cybersecurityChanges' + "No"}>No</label>
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="cybersecurityChangesComments">Describe Changes:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'cybersecurityChangesComments'}
          value={cybersecurityChangesComments || ""}
          onChange={(e) => setCybersecurityChangesComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>



      {/* Question D.5 */}
      <Grid item xs={GridItemQuestionXS}>
          <Typography sx={{ ml:'0.5rem' }}>D.5. Describe how the overall security risks were reduced because of the modifications described in D1 through D4.</Typography>
        </Grid>
        <Grid item xs={GridItemInputXS} sx={{ display: 'flex', justifyContent:'center', alignSelf:GridItemInputSX }}>
          
        </Grid>
        <Grid item xs={GridItemCommentsXS} >
        <label htmlFor="overallRiskReductionComments">Describe Changes:</label>
        <input style={{ width: inputCommentWidth, marginLeft: '1rem' }}
          type="text"
          id={'overallRiskReductionComments'}
          value={overallRiskReductionComments || ""}
          onChange={(e) => setOverallRiskReductionComments(e.target.value)}
        />
        </Grid> 
      
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Divider sx={{ width: '95%', m:'1rem'}} />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}> 
          <Button sx={{ mr: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant="contained" onClick={handleSaveForm2}>Save</Button>
        </Grid>









      </Grid>
      </Collapse>
      </Paper>




        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Divider sx={{ width: '95%', m:'1rem'}} />
      </Grid>

          

    </Box>
  )
}

export default FERCSecuirtyChecklistv5