import FlexBetween from '../../components/FlexBetween';
import React from 'react'
import { useState, useEffect } from "react";
import { AppBar, InputBase, Toolbar, Box, IconButton, Typography, Button, Select, Menu, MenuItem, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { Search, Message, DarkMode, LightMode, Notifications, Help, 
    Close, 
    SettingsOutlined, ArrowDropDownOutlined, 
    BuildCircle } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "../../state";
import { useNavigate } from "react-router-dom";
// import profileImage from "assets/profile.jpg";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';


const NavbarDashboard = ({ userData, isSidebarOpen, setIsSidebarOpen }) => {

    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const user = useSelector((state) => state.user);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px");

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.default;
    const primaryLight = theme.palette.primary.light;
    const alt = theme.palette.background.alt;

    const user = userData;
    console.log('NAVBAR From Layout')
    console.log(user);

    // for setting user during integration of apps.  I need to rework the users model to work for all three apps. 
    // const axiosPrivate = useAxiosPrivate(); 
    // const userId = "659102e857259da4dcdfbb95";
    // const [userData, setUserData] = useState(null);

    // const user = async () => {
    //     const response = await axiosPrivate.get(`/usersma/${userId}`, {
    //         withCredentials: true
    //     });
    //     const data = await response.data;
    //     setUserData(data);
    //     console.log(data);
    // };
    // useEffect(() => {
    //   user();
    // }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    // if (!userData) {
    //   return null;
    // }

    // if (user.firstName) {
    //     const fullName = `${user.firstName} ${user.lastName}`;
    // } else {
    //     const fullName = 'user';
    // }
   

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleLogOut = (event) => {
        dispatch(setLogout);
        navigate(`/`);
    }
    return(
        <AppBar 
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
            }}
            >
            <Toolbar sx={{ justifyContent: "space-between" }} >
                {/* LEFT SIDE */}
                <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        <MenuIcon />
                    </IconButton>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius="9px"
                        gap="3rem"
                        p="0.1rem 1.5rem"
                    >
                        <InputBase placeholder="Search..." />
                        <IconButton>
                        <Search />
                        </IconButton>
                    </FlexBetween>
                    </FlexBetween>

                {/* <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen=!(isSidebarOpen)} >
                        <MenuIcon />
                    </IconButton>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius="9px"
                        gap="3rem" 
                        p="0.1rem 1.5rem"
                    >
                        <InputBase placeholder="Search..."/>
                        <IconButton>
                            <Search />
                        </IconButton>
                    </FlexBetween> 
                </FlexBetween> */}

                {/* RIGHT SIDE */}
                <FlexBetween gap="1.5rem">
                    <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === "dark" ? (
                        <DarkMode sx={{ fontSize: "25px" }} />
                        ) : (
                        <LightMode sx={{ fontSize: "25px" }} />
                        )}
                    </IconButton>
                    <IconButton>
                        <SettingsOutlined sx={{ fontSize: "25px" }} />
                    </IconButton>
                    <FlexBetween >
                        <Button 
                        onClick={handleClick} 
                        sx={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            alignItems: "center", 
                            textTransform: "none", 
                            gap: "1rem" }}
                        >
                        <Box 
                            component="img"
                            alt="profile"
                            // src={profileImage}
                            height="32px"
                            width="32px"
                            borderRadius="50%"
                            sx={{ objectFit: "cover" }}
                        />
                        <Box textAlign="left">
                            <Typography fontWeight="bold" fontSize="0.85rem" sx={{ color: theme.palette.secondary[100] }}>
                                {'test'}
                            </Typography>
                            <Typography fontSize="0.75rem" sx={{ color: theme.palette.secondary[200] }}>
                                {'occupation'}
                            </Typography>
                        </Box>
                        <ArrowDropDownOutlined 
                            sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
                        />
                        </Button>
                        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>

                {/* <FlexBetween gap="1.5rem">
                    <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === "dark" ? (
                            <DarkMode sx={{fontSize: "25px"}} />
                        ) : (
                            <LightMode sx={{color: dark, fontSize: "25px"}} />
                        )}
                    </IconButton>
                    <IconButton>
                        <SettingsOutlined sx={{fontSize: "25px"}} />
                    </IconButton>
                </FlexBetween> */}
            </Toolbar>
        </AppBar>    
    );
};


export default NavbarDashboard;