import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, Typography, useTheme, Grid, FormControl, InputLabel, NativeSelect, Divider, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
    Paper, IconButton, Slider } from "@mui/material";
import { useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import WidgetWrapper from '../../../components/WidgetWrapper';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';
import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const ThreatProfileBuilder2 = ({
    _id,
    threatName,
    threatValue,
    vulnerabilityName,
    vulnerabilityValue,
    likelihoodValue,
    consequenceValue,
    threatProfileComments,
    refresh,
    setRefresh,
    allThreatData,
    allVulnerabilityData
}) => {
    const [userAddedThreatTemp, setUserAddedThreatTemp] = useState();
    const [userAddedVulnTemp, setUserAddedVulnTemp] = useState();
    const [userAddedLikelihoodTemp, setUserAddedLikelihoodTemp] = useState();
    const [newThreatValue, setNewThreatValue] = React.useState(threatValue);
    const [newVulnerabilityValue, setNewVulnValue] = React.useState(vulnerabilityValue);
    const [newLikelihoodValue, setNewLikelihoodValue] = React.useState(likelihoodValue);
    const [newThreatName, setNewThreatName] = useState(threatName);
    const [newVulnerabilityName, setNewVulnerabilityName] = useState(vulnerabilityName);
    const [newConsequenceValue, setNewConsequenceValue] = useState(consequenceValue);
    const Input = styled(MuiInput)` width: 42px;`;
    const [allCriticalAssetData, setAllCriticalAssetData] = useState();
    const [newThreatProfileComments, setNewThreatProfileComments] = useState(threatProfileComments);



    const { assessmentID } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const newThreatProfileID = 1;
    const threatProfiles = [];
    const theme = useTheme();

    const likelihoodDescriptions = [ 
        'Expected once or not at all every 5 years', 
        'More than once every 5 years', 
        'Once every 4 years.', 
        'More than once every 4 years.', 
        'Once every 3 years.', 
        'More than once every 3 years.', 
        'Once every 2 years.', 
        'More than once every 2 years.', 
        'Once a year.', 
        'Multiple times a year.'
    ];

    const vulnerabilityDescriptions = [
        'Not Vulnerable.',
        'Vulnerable, but Deterrence alone is a adequate measure.',
        'Vulnerable, but Deterrence alone may be an adequate measure.',
        'Vulnerable, cannot Deter Threats, but can Detect, Delay, and Defend.',
        'Vulnerability is Unknown.',
        'Vulnerable, cannot Deter Threats, but may be able to Detect, Delay, and Defend.',
        'Vulnerable, can Defend, but Detection or Delay is questionable.',
        'Vulnerable, can Defend, but Detection and Delay is inadequate.',
        'Vulnerable, cannot Defend against Threats even if Detected early.',
        'Inherently Vulnerable Asset, multiple indefensible avenues of approach.' 
    ];

    const threatDescriptions = [
        'No means, motive, or opportunity.',
        'Has Means, but no Motive nor Opportunity.',
        'Has Motive, but no Means nor Opportunity.',
        'Has Opportunity, but no Means nor Motive.',
        'Has Movtive and Opportunity, but no Means.',
        'Has Means and Opportunity, but no Motive.',
        'Has Means and Motive, but no Opportunity.',
        'Has Means, Motive, and Opportunity.',
        'Has Means, Motive, and can create their own opportunity.', 
        'Has Taken action against the Critical Asset in the last 5 years.' 
    ];

    const consequenceDescriptions = [
        'This attack could result in minor property damage or financial loss that only impacts the utility.',
        'Loss of power generation less than 100MW could occur.',
        'Loss of power generation between 100MW and 1,500MW could occur.',
        'Loss of at least 1,500MW of power generation could occur.',
        'This is a Black Start site.',
        'Municipal-wide disruption in power generation, water management, or navigation could occur.',
        'Total Economic Losses exceed $300 Million USD could occur it this attack was successful.',
        'The population at risk within 3 miles is at least 60 persons.',
        'The population at risk within 60 miles is at least 800 persons.',
        'The population at risk due to a catastrophic reservoir release exceeds 12,500 persons.',
    ];

   const handleThreatSliderChange = async (event, newValue) => {
    setNewThreatValue(newValue);
    let threatValue = event.target.value;
    try {
        const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
            JSON.stringify({ threatValue }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
            );
            const data = await responsePatch.data;
            // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
            // The console log commands below allow you to see the responses from Axios for the response.  
            // navigate(from, { replace: true });
        } catch (err) {
        //   alert("Login Expired.Conswquence Threshold");
            console.error(err);
        //   navigate('/login', { state: { from: location }, replace: true });
        }
        setRefresh(!refresh);
    
  };

    const handleThreatInputChange = async (event) => {
        setNewThreatValue(event.target.value === '' ? 0 : Number(event.target.value));
        let threatValue = event.target.value;
          try {
              const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                  JSON.stringify({ threatValue }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  );
                  const data = await responsePatch.data;
                  // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                  // The console log commands below allow you to see the responses from Axios for the response.  
                  // navigate(from, { replace: true });
              } catch (err) {
              //   alert("Login Expired.Conswquence Threshold");
                  console.error(err);
              //   navigate('/login', { state: { from: location }, replace: true });
              }
            setRefresh(!refresh);
      };
  
      const handleVulnSliderChange = async (event, newValue) => {
          setNewVulnValue(newValue);
          let vulnerabilityValue = event.target.value;
          try {
              const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                  JSON.stringify({ vulnerabilityValue }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  );
                  const data = await responsePatch.data;
                  // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                  // The console log commands below allow you to see the responses from Axios for the response.  
                  // navigate(from, { replace: true });
              } catch (err) {
              //   alert("Login Expired.Conswquence Threshold");
                  console.error(err);
              //   navigate('/login', { state: { from: location }, replace: true });
              }
              setRefresh(!refresh);
        };
      
        const handleVulnInputChange = async (event) => {
          setNewVulnValue(event.target.value === '' ? 0 : Number(event.target.value));
          let vulnerabilityValue = event.target.value;
          try {
              const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                  JSON.stringify({ vulnerabilityValue }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  );
                  const data = await responsePatch.data;
                  // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                  // The console log commands below allow you to see the responses from Axios for the response.  
                  // navigate(from, { replace: true });
              } catch (err) {
              //   alert("Login Expired.Conswquence Threshold");
                  console.error(err);
              //   navigate('/login', { state: { from: location }, replace: true });
              }
              setRefresh(!refresh);
        };
  
        const handleLikelihoodSliderChange = async (event, newValue) => {
          setNewLikelihoodValue(newValue);
          let likelihoodValue = event.target.value;
          try {
              const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                  JSON.stringify({ likelihoodValue }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  );
                  const data = await responsePatch.data;
                  // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                  // The console log commands below allow you to see the responses from Axios for the response.  
                  // navigate(from, { replace: true });
              } catch (err) {
              //   alert("Login Expired.Conswquence Threshold");
                  console.error(err);
              //   navigate('/login', { state: { from: location }, replace: true });
              }
              setRefresh(!refresh);
        };
      
        const handleLikelihoodInputChange = async (event) => {
          setNewLikelihoodValue(event.target.value === '' ? 0 : Number(event.target.value));
          let likelihoodValue = event.target.value;
            try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                    JSON.stringify({ likelihoodValue }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                    );
                    const data = await responsePatch.data;
                    // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                    // The console log commands below allow you to see the responses from Axios for the response.  
                    // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                    console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }
                setRefresh(!refresh);
        };

        const handleThreatNameChange = async (event) => {
            setNewThreatName(event.target.value);
            let threatName = event.target.value;
            try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                    JSON.stringify({ threatName }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                    );
                    const data = await responsePatch.data;
                    // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                    // The console log commands below allow you to see the responses from Axios for the response.  
                    // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                    console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }
                setRefresh(!refresh);
        };

        const handleVulnerabilityNameChange = async (event) => {
            setNewVulnerabilityName(event.target.value);
            let vulnerabilityName = event.target.value;
            try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                    JSON.stringify({ vulnerabilityName }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                    );
                    const data = await responsePatch.data;
                    // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                    // The console log commands below allow you to see the responses from Axios for the response.  
                    // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                    console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }
                setRefresh(!refresh);
        };


        
        const handleConsequenceInputChange = async (event) => {
            setNewConsequenceValue(event.target.value);
            let consequenceValue = event.target.value;
            try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                    JSON.stringify({ consequenceValue }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                    );
                    const data = await responsePatch.data;
                    // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                    // The console log commands below allow you to see the responses from Axios for the response.  
                    // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                    console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }
                setRefresh(!refresh);
        };
    
      const handleBlur = () => {
      //   if (value < 0) {
      //     setValue(0);
      //   } else if (value > 10) {
      //     setValue(10);
      //   }
          console.log('Stuff');
      };

      const onChangeComments = async (e) => {
        setNewThreatProfileComments(e);
        let threatProfileComments = e;
            try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                    JSON.stringify({ threatProfileComments }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    }
                    );
                    const data = await responsePatch.data;
                    // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                    // The console log commands below allow you to see the responses from Axios for the response.  
                    // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                    console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }

       };

       const handleDeleteThreatProfile = async (_id) => {
        console.log(_id);
        try {
            const response = await axiosPrivate.delete(`/threatprofiles/${_id}`, 
              {
                  headers: { 'Content-Type': 'application/json' },
                  withCredentials: true
              }
          );
            if (response.data.success) {
                alert(response.data.msg);
            } 
            // console.log(response);
        } catch (err) {
            console.error(err);
        }
        setRefresh(!refresh);
     };

    const handleSave = async () => {
        let criticalAssetID = _id
        let threatName = newThreatName;
        let threatValue = newVulnerabilityValue;
        let vulnerabilityName = newVulnerabilityName;
        let vulnerabiltiyValue = newVulnerabilityValue;
        let likelihoodValue = newLikelihoodValue;
        let threatProfileComments = newThreatProfileComments;
        let active = true;

        let threatProfileData = JSON.stringify({ threatName, threatValue, vulnerabilityName, vulnerabiltiyValue, likelihoodValue, threatProfileComments });

              try {
                const responsePatch = await axiosPrivate.patch(`/threatprofiles/threatprofiles/${_id}`,
                  JSON.stringify({ assessmentID, criticalAssetID, threatName, threatValue, vulnerabilityName, vulnerabiltiyValue, likelihoodValue, threatProfileComments, active }),
                  {
                      headers: { 'Content-Type': 'application/json' },
                      withCredentials: true
                  }
                  );
                  const data = await responsePatch.data;
                  // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
                  // The console log commands below allow you to see the responses from Axios for the response.  
                  // navigate(from, { replace: true });
                } catch (err) {
                //   alert("Login Expired.Conswquence Threshold");
                  console.error(err);
                //   navigate('/login', { state: { from: location }, replace: true });
                }
              };


 
    return (
    <Box sx={{ width:'100%', border:'solid 1px', mt:'1rem', p:'1rem', backgroundColor: '#E0E0E0' }}>
    
        <Grid item xs={12}>
            <Grid container spacing={2} sx={{ display:'flex', p:'1rem', justifyContent:'space-evenly' }}>
                {/* <Grid item xs={0.5} sx={{ border:'solid 1px', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <Typography variant='h2'>1</Typography>                               
                </Grid> */}
                <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <Button sx={{ ml:'3px', color:'black' }} onClick={() => {handleDeleteThreatProfile(_id)}}><DeleteForeverIcon sx={{ fontSize:'28pt' }} /></Button>
                </Box>
                <Grid item xs={12} sx={{ ml:'1rem' }}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Threat
                            </InputLabel>
                            <Select value={threatName} sx={{ height:'5vh', width:'90%', display:'flex', justifyContent:'center', alignItems:'center' }} onChange={(event) => handleThreatNameChange(event) }>
                           
                                {allThreatData?.map((threat) => (
                            <MenuItem value={threat.threatName}>{threat.threatName}</MenuItem>
                        ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Typography sx={{ fontSize:'12pt'}}>{threatDescriptions[threatValue-1]}</Typography>
                    <Box sx={{ display:'flex', alignItems:'center', mt:'5px', mb:'5px' }}>
                        <Input
                            value={threatValue}
                            size="small"
                            onChange={handleThreatInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                            step: 1,
                            min: 1,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            sx={{ background:'white', border:'solid 1px grey', pl:'5px' }}
                        />
                        <Slider
                            value={typeof threatValue === 'number' ? threatValue : 1}
                            onChange={handleThreatSliderChange}
                            aria-labelledby="input-slider"
                            min={1}
                            max={10}
                            sx={{
                                color: 'red',
                                width:'75%',
                            }}
                        />
                        
                        </Box>
                        <Divider />
                </Grid>
                <Grid item xs={12} sx={{ justifyContent:'center', alignItems:'center', ml:'1rem' }}>
                <Box sx={{ display:'flex', alignItems:'center', mt:'5px', mb:'5px' }}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Vulnerability
                            </InputLabel>
                            <Select value={vulnerabilityName} sx={{ height:'5vh', width:'90%', display:'flex', justifyContent:'center', alignItems:'center' }} onChange={(event) => handleVulnerabilityNameChange(event) }>
                           
                            {allVulnerabilityData?.map((vulnerability) => (
                            <MenuItem value={vulnerability.vulnerabilityName}>{vulnerability.vulnerabilityName}</MenuItem>
                        ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Typography sx={{ fontSize:'12pt'}}>{vulnerabilityDescriptions[vulnerabilityValue-1]}</Typography>
                    <Box sx={{ display:'flex', alignItems:'center', mt:'5px', mb:'5px' }}>
                        <Input
                            value={vulnerabilityValue}
                            size="small"
                            onChange={handleVulnInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                            step: 1,
                            min: 1,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            sx={{ background:'white', border:'solid 1px grey', pl:'5px' }}
                        />
                        <Slider
                            value={typeof vulnerabilityValue === 'number' ? vulnerabilityValue : 1}
                            onChange={handleVulnSliderChange}
                            aria-labelledby="input-slider"
                            color="secondary"
                            min={1}
                            max={10}
                            sx={{
                                color: 'orange',
                                width: '75%'
                            }}
                        />
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent:'center', alignItems:'center', ml:'1rem' }}>
                        <Typography sx={{ pt:'10px', height:'5vh', width:'90%', border:'solid 1px #b3b3cc', borderRadius:'3%' }}>Likelihood of Occurence:</Typography>
                        <Typography sx={{ fontSize:'12pt'}}>{likelihoodDescriptions[likelihoodValue-1]}</Typography>
                    <Box sx={{ display:'flex', alignItems:'center', mt:'5px', mb:'5px' }}>
                        <Input
                            value={newLikelihoodValue}
                            size="small"
                            onChange={handleLikelihoodInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                            step: 1,
                            min: 1,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            sx={{ background:'white', border:'solid 1px grey', pl:'5px' }}
                        />
                        <Slider
                            value={typeof newLikelihoodValue === 'number' ? newLikelihoodValue : 1}
                            onChange={handleLikelihoodSliderChange}
                            aria-labelledby="input-slider"
                            min={1}
                            max={10}
                            sx={{
                                color: 'yellow',
                                width:'75%',
                            }}
                        />
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent:'center', alignItems:'center', ml:'1rem' }}>
                        <Typography sx={{ pt:'10px', height:'5vh', width:'90%', border:'solid 1px #b3b3cc', borderRadius:'3%' }}>Consequence of Occurence:</Typography>
                        <Typography sx={{ fontSize:'12pt'}}>{consequenceDescriptions[consequenceValue-1]}</Typography>
                    <Box sx={{ display:'flex', alignItems:'center', mt:'5px', mb:'5px' }}>
                        <Input
                            value={newConsequenceValue}
                            size="small"
                            onChange={handleConsequenceInputChange}
                            onBlur={handleBlur}
                            inputProps={{
                            step: 1,
                            min: 1,
                            max: 10,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                            }}
                            sx={{ background:'white', border:'solid 1px grey', pl:'5px' }}
                        />
                        <Slider
                            value={typeof newConsequenceValue === 'number' ? newConsequenceValue : 1}
                            onChange={handleConsequenceInputChange}
                            aria-labelledby="input-slider"
                            min={1}
                            max={10}
                            sx={{
                                color: 'black',
                                width:'75%',
                            }}
                        />
                    </Box>

                    {/** CHECKBOX FOR CONSEQUENCE */}
                    {/* <Grid container spacing={1} >

                    <Box sx={{ display:'block', alignItems:'center', mt:'15px', mb:'5px' }}>
                        <Grid item xs={12} >
                            <input style={{ width:'22px', height:'22px'}}
                                name="minorDamage"
                                type="checkbox"
                                id="minorDamage"
                                value={1}
                                // checked={index.variable}
                                onChange={console.log('SET ZCHECKBOX')}
                            />
                            <label htmlFor='minorDamage'>This attack could result in minor property damage or financial loss that only impacts the utility.</label>
                        </Grid>
                        
                        <Grid item xs={12} >
                            <input style={{ width:'22px', height:'22px'}}
                                name="lt100MW"
                                type="checkbox"
                                id="lt100MW"
                                value={2}
                                // checked={index.variable}
                                onChange={console.log('SET ZCHECKBOX')}
                            />
                            <label htmlFor='lt100MW'>Loss of power generation less than 100MW could occur.</label>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <input style={{ width:'22px', height:'22px'}}
                                name="gt100MW"
                                type="checkbox"
                                id="gt100MW"
                                value={3}
                                // checked={index.variable}
                                onChange={console.log('SET ZCHECKBOX')}
                            />
                            <label htmlFor='gt100MW'>Loss of power generation between 100MW and 1,500MW could occur.</label>
                        </Grid>
                        
                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="1500MW"
                            type="checkbox"
                            id="1500MW"
                            value={4}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='1500MW'>Loss of at least 1,500MW of power generation could occur.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="blackstart"
                            type="checkbox"
                            id="blackstart"
                            value={5}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='blackstart'>This is a Black Start site.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="muni"
                            type="checkbox"
                            id="muni"
                            value={6}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='muni'>Municipal-wide disruption in power generation, water management, or navigation could occur.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="econ300M"
                            type="checkbox"
                            id="econ300M"
                            value={7}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='econ300M'>Total Economic Losses exceed $300 Million USD could occur it this attack was successful.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="pop60"
                            type="checkbox"
                            id="pop60"
                            value={8}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='pop60'>The population at risk within 3 miles is at least 60 persons.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="par800"
                            type="checkbox"
                            id="par800"
                            value={9}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='par800'>The population at risk within 60 miles is at least 800 persons.</label>
                        </Grid>

                        <Grid item xs={12}>
                        <input style={{ width:'22px', height:'22px'}}
                            name="par12500"
                            type="checkbox"
                            id="par12500"
                            value={10}
                            // checked={index.variable}
                            onChange={console.log('SET ZCHECKBOX')}
                        />
                        <label htmlFor='par12500'>The population at risk due to a catastrophic reservoir release exceeds 12,500 persons.</label>
                        </Grid>
                    </Box>
                    </Grid> */}

                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                    <label htmlFor="ThreatProfileComments">Comments:</label>
                    </Grid>
                    <Grid item xs={12} sx={{ display:'flex', justifyContent:'center', alignItems:'center', ml:'1rem' }}>
                    
                    <textarea style={{ height: '150px', width:'90%', maxWidth:'99%' }}
                        type="text"
                        id='ThreatProfileComments'
                        value={newThreatProfileComments || ""}
                        onChange={(e) => onChangeComments(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Grid>
    

                   
        </Box>
  )
}

export default ThreatProfileBuilder2