import { memo } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';

const AndGate = ({data, selected}) => {
  return (
    <>         
        <NodeResizer isVisible={selected} minWidth={50} minHeight={50} maxHeight={100} maxWidth={100}/>
        <svg className='orGateSVG' viewBox="0 0 50 50" width="50" hieght="50" >
          <path
            d="M 26,0 A 1,1 0 0 0 26,50 " />
          <rect
            width="30"
            height="52"
            x="24"
            y="0" />
            <title>Logic Gate: AND</title>
        </svg>
         {/* <svg className='orgateSVG'
          
          viewBox="0 0 50 50"
          width="50"
          height="50"
          version="1.1"
          id="svg1"
          >
          <defs
            id="defs1" />
          <g
            id="layer1">
            <g
              id="g4"
              transform="translate(-1.2567708,7.4083333)">
              <path
                id="path4"
                d="M 28,-8 A 1,1 0 0 0 28,43 " />
              <rect
                id="rect4"
                width="30"
                height="52"
                x="24"
                y="-8" />
            </g>
          </g>
        </svg> */}
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
    </>
  )
};

export default memo(AndGate);
