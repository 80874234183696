import React, { useState, useEffect } from 'react';
import WidgetWrapper from '../../../../components/WidgetWrapper';
import { Box, Button, Typography, useTheme, Grid, Divider, TextField } from "@mui/material";
import Header from '../../../../components/Header';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Dropdown from './dropdown';
import UploadCSV from './UploadCSV';


const WebTool = ({handleAddCyberAssetRefresh, setActiveStep}) => {

    const { assessmentID } = useParams();

    const [description, setDescription] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [macAddress, setMACAddress] = useState("");
    const [yearPurchased, setYearPurchased] = useState("");
    const [assetName, setAssetName] = useState(null);
    const [assetType, setAssetType] = useState(null);
    const [model, setModel] = useState("");
    const [physicalLocation, setPhysicalLocation] = useState("");
    const [localID, setLocalID] = useState("");
    const [operatingSystem, setOperatingSystem] = useState("");
    
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

  // Update Database Function 
  const handleAddCyberAsset = async (e) => {
    e.preventDefault();
    const cyberAssetData = JSON.stringify({ manufacturer, model, macAddress, yearPurchased, physicalLocation, description, localID, operatingSystem });
    try {
        const response = await axiosPrivate.post(`/assets/cyberasset/new`,
            JSON.stringify({ assessmentID, assetName, assetType, cyberAssetData }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        handleAddCyberAssetRefresh();

        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
    } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleCompleteCyberInventory = async () => {
    let cyberAssetInventoryComplete = true;
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ cyberAssetInventoryComplete }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    setActiveStep(2);
    } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
    }
  }

  return (
    <Box>
        <Grid container spacing={2} sx={{mb: '1rem'}}>
            <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Button disabled={(assetName === null || assetType === null)} sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={(e)=>{handleAddCyberAsset(e)}} >Add Cyber Asset</Button>
                    </Grid>
                    <Grid item xs={6}>    
                        <UploadCSV handleAddCyberAssetRefresh={handleAddCyberAssetRefresh}/>
                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid item xs={3}>
                        <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleCompleteCyberInventory} >Complete Cyber Inventory</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                   
                    {/** REQUIRED FIELDS */}
                    <Grid item xs={4}>
                    <WidgetWrapper sx={{ border:'solid 1px' }}>
                    <Header title="Required" subtitle="These are needed to complete the assessment." />
                    <Divider />
                        <Grid item xs={12}>
                            <Typography sx={{ mt: '1rem' }}>
                                Asset Name
                            </Typography>
                            <TextField
                                required
                                id="outlined-required"
                                label="Spillway Gate Control North"
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setAssetName(e.target.value)}
                            />
                            </Grid>

                            <Grid item xs={12}>
                            <Typography sx={{ mt: '1rem' }}>
                                Asset Type
                            </Typography>
                                <Dropdown setAssetType={setAssetType}/>
                            </Grid>
                    </WidgetWrapper>
                    </Grid> 
                        
                    {/** SUGGESTED ITEMS.  */}
                    <Grid item xs={4}> 
                    <WidgetWrapper sx={{ border:'solid 1px'}}>
                        <Header title="Suggested" subtitle="Optional inputs, but they help identify vulnerabilities later." />
                        <Divider />
                        <Grid container spacing={1} >
                        <Grid item xs={6}>
                            <Typography sx={{ mt: '1rem' }}>
                                Manufacturer
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setManufacturer(e.target.value)}
                            />
                        </Grid>

                            <Grid item xs={6}>
                            <Typography sx={{ mt: '1rem' }}>
                                Model
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setModel(e.target.value)}
                            />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} >
                            <Grid item xs={6}>
                            <Typography  sx={{ mt: '1rem' }}>
                                Operating System 
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setOperatingSystem(e.target.value)}
                            />
                            </Grid>

                            
                            {/* Uncomment and add header to this section to create another input field. 
                            <Grid item xs={6}>
                            <Typography sx={{ mt: '1rem' }}> // header is here. 
                                Comments
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setCyberAssetCommentYearPurchased(e.target.value)}
                            />
                            </Grid> */}
                            </Grid>
                        
                            <Grid container spacing={1} >
                            <Grid item xs={6}>
                            <Typography  sx={{ mt: '1rem' }}>
                                MAC Address
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setMACAddress(e.target.value)}
                            />
                            </Grid>

                            <Grid item xs={6}>
                            <Typography sx={{ mt: '1rem' }}>
                                Year Purchased (approximate)
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setYearPurchased(e.target.value)}
                            />
                            </Grid>
                            </Grid>

                            </WidgetWrapper>
                    </Grid>

                    {/** OPTIONAL ITEMS.  */}
                    <Grid item xs={4}>
                        <WidgetWrapper sx={{ border:'solid 1px'}}>
                        <Header title="Optional" subtitle="Not used here, but may be useful for you when reviewing the assessment later." />
                        <Divider />
                        <Grid item xs={12}>
                            <Typography sx={{ mt: '1rem' }}>
                                Physical Location
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setPhysicalLocation(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography sx={{ mt: '1rem' }}>
                                Description
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setDescription(e.target.value)}
                            />    
                        </Grid>

                        <Grid item xs={12}>
                            <Typography sx={{ mt: '1rem' }}>
                                Local ID or Property Tag
                            </Typography>
                            <TextField
                                id="outlined-required"
                                label=""
                                defaultValue=""
                                fullWidth
                                onChange={(e) => setLocalID(e.target.value)}
                            />    
                        </Grid>
                        </WidgetWrapper>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    </Box>
  )
}

export default WebTool