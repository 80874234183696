import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Box, Button, Collapse, Typography, useTheme, useMediaQuery, Grid, Divider, Dialog, DialogTitle, FormControlLabel, List, ListItem, ListItemIcon,
    DialogContent, DialogContentText, DialogActions, Paper, IconButton, Tooltip, TextField, RadioGroup, Radio, Select, MenuItem } from "@mui/material";
import WidgetWrapper from './WidgetWrapper';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from './DataGridCustomToolbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';




const RiskRegister = () => {

  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();
  const [openInstructions, setOpenInstructions] = useState(true);
  const [rows, setRows] = useState();
  const [refresh, setRefresh] = useState();
  const [allCriticalAssetData, setAllCriticalAssetData] = useState();
  const [threatProfiles, setThreatProfiles] = useState();

  const [footScore, setFootScore] = useState(0);
  const [vehicleScore, setVehicleScore]= useState(0);
  const [boatScore, setBoatScore] = useState(0);
  const [rowModesModel, setRowModesModel] = useState();


   // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
   const getCriticalAssets = async () => {
    try {
    const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    // console.log(data);
    setAllCriticalAssetData(data);
    setRows(data);
    let footScore1 = 0;
    let vehicleScore1 = 0;
    let boatScore1 = 0;

    for (let index in data) {
        let tempCriticalAsset = data[index];
        let tempScoreFoot = tempCriticalAsset.externalAccess + tempCriticalAsset.physicalSecurity + tempCriticalAsset.detection + tempCriticalAsset.delay + tempCriticalAsset.response + tempCriticalAsset.responseTime + tempCriticalAsset.assessmentExternal + tempCriticalAsset.assessmentInternal + tempCriticalAsset.securityAssessment + tempCriticalAsset.securityPlan + tempCriticalAsset.cybersecurity + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        footScore1 = footScore1 + tempScoreFoot;
        

        let tempScoreVehicle = tempCriticalAsset.externalAccessVehicle + tempCriticalAsset.physicalSecurityVehicle + tempCriticalAsset.detectionVehicle + tempCriticalAsset.delayVehicle + tempCriticalAsset.responseVehicle + tempCriticalAsset.responseTimeVehicle + tempCriticalAsset.assessmentExternalVehicle + tempCriticalAsset.assessmentInternalVehicle + tempCriticalAsset.securityAssessmentVehicle + tempCriticalAsset.securityPlanVehicle + tempCriticalAsset.cybersecurityVehicle + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        vehicleScore1 = vehicleScore1 + tempScoreVehicle;
        
        let tempScoreBoat = tempCriticalAsset.externalAccessBoat + tempCriticalAsset.physicalSecurityBoat + tempCriticalAsset.detectionBoat + tempCriticalAsset.delayBoat + tempCriticalAsset.responseBoat + tempCriticalAsset.responseTimeBoat + tempCriticalAsset.assessmentExternalBoat + tempCriticalAsset.assessmentInternalBoat + tempCriticalAsset.securityAssessmentBoat + tempCriticalAsset.securityPlanBoat + tempCriticalAsset.cybersecurityBoat + tempCriticalAsset.cpme + tempCriticalAsset.cdpr;
        boatScore1 = boatScore1 + tempScoreBoat;

    }
    setRows(data);
    setFootScore(footScore1);
    setVehicleScore(vehicleScore1);
    setBoatScore(boatScore1);

    // console.log(buildRows);
    // setRows(buildRows);

    // console.log(data);
    } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
    }
};

 // Get Threat Profile for each Critical Asset.
 const getThreatProfiles = async () => {

  try {
      const response = await axiosPrivate.get(`/threatprofiles/${assessmentID}`, {
          withCredentials: true
      });
      const data = await response.data;
      setThreatProfiles(data);
      
    } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
    // console.log('T,V,L,C Scores for all Critical Assets');
    // console.log(TVLC[allCriticalAssetData[0].assetName]);
};

useEffect(() => {
    getCriticalAssets();
    getThreatProfiles();
}, [footScore]);  // eslint-disable-line react-hooks/exhaustive-deps
if (!allCriticalAssetData) {
    return null;
};

const getSecurityScore = (index) => {
  const securityAssessmentScoreFoot = index.externalAccess + index.physicalSecurity + index.detection + index.delay + index.response + index.responseTime + index.assessmentExternal + index.assessmentInternal + index.securityAssessment + index.securityPlan + index.cybersecurity; // + index.cpme + index.cdpr;
  const securityAssessmentScoreVehicle = index.externalAccessVehicle + index.physicalSecurityVehicle + index.detectionVehicle + index.delayVehicle + index.responseVehicle + index.responseTimeVehicle + index.assessmentExternalVehicle + index.assessmentInternalVehicle + index.securityAssessmentVehicle + index.securityPlanVehicle + index.cybersecurityVehicle; // + index.cpme + index.cdpr;
  const securityScore = Math.round((((securityAssessmentScoreFoot + securityAssessmentScoreVehicle) / 2) * 10) / 44);
  return (
    securityScore
  );
};

const createScoresLists = () => {
  for (let index in rows) {
    let threatScore = 0;
    let vulnerabilityScore = 0;
    let consequenceScore = 0;
    let likelihoodScore = 0;
    let relativeRiskScore = 0;
    let c = 0;
      for (let count in threatProfiles) {
        if (rows[index]._id===threatProfiles[count].criticalAssetID) {
          c = c + 1;
          threatScore = threatProfiles[count].threatValue + threatScore;
          vulnerabilityScore = threatProfiles[count].vulnerabilityValue + vulnerabilityScore;
          consequenceScore = threatProfiles[count].consequenceValue + consequenceScore;
          likelihoodScore = threatProfiles[count].likelihoodValue + likelihoodScore;
        }
      }
      if (consequenceScore === 0 ) {
        consequenceScore = 1;
      }
      

      if (c !== 0) {
        threatScore = Math.round(threatScore / c);
        vulnerabilityScore= Math.round(vulnerabilityScore / c);
        consequenceScore = Math.round(consequenceScore / c);
        likelihoodScore = Math.round(likelihoodScore / c);
        relativeRiskScore = Math.round(threatScore + vulnerabilityScore + likelihoodScore);
        if (rows[index].security === 10) {
          relativeRiskScore = ((consequenceScore * relativeRiskScore) / 300);
        } else {
          relativeRiskScore = (((consequenceScore * relativeRiskScore) / (10-rows[index].security))/300);
        }
        rows[index].threats = threatScore;
        rows[index].vulnerabilities = vulnerabilityScore;
        rows[index].consequences = consequenceScore;
        rows[index].likelihoods = likelihoodScore;
        rows[index].relativeRisk = relativeRiskScore.toFixed(1);
      } else {
        rows[index].threats = threatScore;
        rows[index].vulnerabilities = vulnerabilityScore;
        rows[index].consequences = consequenceScore;
        rows[index].likelihoods = likelihoodScore;
        rows[index].relativeRisk = relativeRiskScore.toFixed(1);
      }
  }
};


rows.forEach(object => {
  object.security = getSecurityScore(object);
  // object.relativeRisk = getRelativeRisk(object);
  // object.threats = TVLC[object.assetName][0];
})
  
createScoresLists();
  

  const handleCyberAssetUpdate = async (updatedRow) => {
    let id = updatedRow._id;
    let assetName = updatedRow.assetName;
    let assetType = updatedRow.assetType;
    let description = updatedRow.description;
    let riskManagementDecision = updatedRow.riskManagementDecision;
    const cyberAssetData = JSON.stringify({  description, riskManagementDecision });
    try {
      const response = await axiosPrivate.patch(`/criticalassets/criticalasset/${id}`,
        JSON.stringify({ assessmentID, assetName, assetType, description, riskManagementDecision }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
      }
    };

const handleSaveClick = (row) => {
  setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
};

const handleCancelClick = () => {
  console.log('handle Cancelled Clicked');
};



// Columns for MUI Data Grid
const columns = [
  {
    field: "assetName",
    flex: 1,
    renderHeader: () => {
      return (
        <Box>
          <Typography sx={{ ml: '1em', fontSize: '1em'}}>
            <b>Critical Asset Name</b>
          </Typography>
        </Box>
      )
    }
  },
  {
    field: "security",
      flex: 1,
      sortable: false,
      editable: true,
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ ml: '1em', fontSize: '1em'}}>
              <b>Security</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: "consequences",
        flex: 1,
        sortable: false,
        editable: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography sx={{ ml: '1em', fontSize: '1em'}}>
                <b>Consequences</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
              </Typography>
            </Box>
          )
        },
      },
      {
        field: "vulnerabilities",
          flex: 1,
          sortable: false,
          editable: true,
          renderHeader: () => {
            return (
              <Box>
                <Typography sx={{ ml: '1em', fontSize: '1em'}}>
                  <b>Vulnerabilities</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
                </Typography>
              </Box>
            )
          },
        },
        {
          field: "likelihoods",
            flex: 1,
            sortable: false,
            editable: true,
            renderHeader: () => {
              return (
                <Box>
                  <Typography sx={{ ml: '1em', fontSize: '1em'}}>
                    <b>Likelihoods</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
                  </Typography>
                </Box>
              )
            },
          },
          {
    field: "threats",
      flex: 1,
      sortable: false,
      editable: true,
      renderHeader: () => {
        return (
          <Box>
            <Typography sx={{ ml: '1em', fontSize: '1em'}}>
              <b>Threats</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: "relativeRisk",
        flex: 1,
        sortable: true,
        renderHeader: () => {
          return (
            <Box>
              <Typography sx={{ ml: '1em', fontSize: '1em'}}>
                <b>Relative Risk</b> {/* <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>*/}
              </Typography>
            </Box>
          )
        },
      },
      {
        field: "riskManagementDecision",
          flex: 2,
          sortable: false,
          editable: true,
          renderHeader: () => {
            return (
              <Box>
              <Typography sx={{ ml: '1em', fontSize: '1em'}}>
                <b>Risk Management Decisions</b> <Tooltip color='primary' describeChild={true} title={<><Typography sx={{ fontSize: '1.5em', background: 'grey' }}>Typical Risk Management Decisions include: Acceptance, Transfer, Avoidance, or Mitigation.</Typography><Typography>Capture your strategy for handling each risk in the fields below.</Typography></>} placement="top-start"><HelpIcon /></Tooltip>
              </Typography>
            </Box>
            )
          },
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          cellClassName: 'actions',
          getActions: (params) => {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={() => handleSaveClick(params.row)}
              />,
              ]
        }
      }
];


  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };


  // START:  Added to update Rows not Database on table cell change

const handleRowEditStop = (id, event) => {
  if (id.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true;
  }
};

const handleEditClick = (row) => () => {
  setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
};

const processRowUpdate = (newRow) => {
  const updatedRow = { ...newRow, isNew: false };
  setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
  handleCyberAssetUpdate(updatedRow);
  return updatedRow;
};

const handleRowModesModelChange = (newRowModesModel) => {
  setRowModesModel(newRowModesModel);
};




  return (
    <Box>
    <Grid container spacing={2}>


{/* ------------------PAGE-SPECIFIC CONETENT STARTS HERE---------------------------------------------------------------------------------- */}
    
{/* DATA GRID */}
    <Grid item xs={12} >
        <WidgetWrapper>
            <Typography variant='h2'>Critical Assets - Risk Register</Typography>
                <Box
                sx={{
                    // height: 500,
                    width: '100%',
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.secondary.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "solid 2px black",
                        borderTop: "solid 2px black"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
                >
                <DataGrid
                    rows={rows || []} 
                    getRowId={(row) => row._id}
                    columns={columns}
                    editMode="row"
                    // loading={!allCyberAssetData}
                    slots={{
                      toolbar: DataGridCustomToolbar,
                    }}
                    // slotProps={{
                    //   toolbar: { setRows, setRowModesModel },
                    // }}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                />
                </Box>
                </WidgetWrapper>
            </Grid>

    </Grid>
  </Box>
  )
}

export default RiskRegister