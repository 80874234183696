import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Collapse, Typography, useTheme, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Paper, IconButton } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import DataGridCustomToolbar from '../../../components/DataGridCustomToolbar';
import Header from "../../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Vulnerabilities from './Vulnerabilities';
import Threats from './Threat';
import ThreatProfiles from './ThreatProfiles';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';



const ThreatVulnLikelihoods = ({activeStep, setActiveStep}) =>  {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();

  const date = new Date();
  const currentYear = date.getFullYear();

  const [threatToDelete, setThreatToDelete] = useState(null);
  const [threatName, setThreatName] = useState(null);

  const [questionFamily, setQuestionFamily] = useState(0);
  const [openThreatVulnLikelihood, setOpenThreatVulnLikelihood] = useState(false);

  const [open, setOpen] = useState(false);
  const [userAddedThreatTemp , setUserAddedThreatTemp] = useState("Theft");
  const [openInstructions, setOpenInstructions] = useState(true);
  const [openThreats, setOpenThreats] = useState(true);
  const [openVulnerabilities, setOpenVulnerabilities] = useState(true);
  const [openThreatProfiles, setOpenThreatProfiles] = useState(false);


  console.log(activeStep);

  const {assessmentID} = useParams();
  const [refresh, setRefresh] = useState(true);


  const handleNoThreatVulnLikelihood = () => {
    setOpenThreatVulnLikelihood(false);
  };

  const handleYesThreatVulnLikelihood = () => {
    setOpenThreatVulnLikelihood(!openThreatVulnLikelihood);
  };

  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };

  const handleOpenThreats = () => {
    setOpenThreats(!openThreats);
  };

  const handleOpenVulnerabilities = () => {
    setOpenVulnerabilities(!openVulnerabilities);
  };

  const handleOpenThreatProfiles = () => {
    setOpenThreatProfiles(!openThreatProfiles);
  };


  const handleStart = () => {
    setOpenThreatVulnLikelihood(true);
    setQuestionFamily(1);
  };

  return (
    <Box sx={{ marginTop:'1em', flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Header title='Threat Profiles' subtitle='Add Threats (T), Vulnerabilities (V), and Likelihoods (L) for each Critical Asset.' />
        </Grid>
      
      <Grid item xs={6}>
          <Box
            sx={{ pt:'0.5rem', minWidth:'350px' }}
            component="img"
            width="70%"
            alt="FERC Risk Equation from the rev 3a document."
            src={FERCRiskEquationLong}
            />
        </Grid>
        </Grid>
      <Dialog
        fullScreen
        open={openThreatVulnLikelihood}
        onClose={handleNoThreatVulnLikelihood}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {questionFamily===1 && (
         <Threats questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} /> 
        )}
        {questionFamily===2 && (
          <Vulnerabilities questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} />  
        )}
        {questionFamily===3 && (
          <ThreatProfiles />
        )}
    
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleYesThreatVulnLikelihood}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
            <Grid item xs={12} sx={{ p:'0.5rem' }}>
            {openInstructions ? (
                <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                  <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                  <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
                </IconButton>
                  ) : (
                  <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                    <ExpandCircleDownIcon color='primary' fontSize="large" />
                    <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                  </IconButton>
                  )}
                <Divider sx={{ border:0.5, mt:'6px' }}/>
            </Grid>
            <Collapse in={openInstructions} timeout="auto" unmountOnExit>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ ml:'2rem' }}>
                  <Typography display='inline' >1. Enter Threats specific to your organization in the <ExpandCircleDownIcon color='primary' sx={{ cursor:'text' }}  /> <Typography display='inline' sx={{ color:'#00D5FA '}}>Threats</Typography> section below.</Typography>
                  <Typography>2. Enter Vulnerabilities that may apply to your organization in the <ExpandCircleDownIcon color='primary' sx={{ cursor:'text' }}  /> <Typography display='inline' sx={{ color:'#00D5FA '}}>Vulnerabilities</Typography> section below.</Typography>
                  <Typography>3. Finally, use the <ExpandCircleDownIcon color='primary' sx={{ cursor:'text' }}  /> <Typography display='inline' sx={{ color:'#00D5FA '}}>Threat Profiles</Typography> section to create qualitatively estimate the threats, vulnerabilities, and likelihoods of each security event for each Critical Asset. </Typography>
                    <Typography>This information is necessary to complete the vulnerability assessment and is included in the final report.</Typography>
                  <Typography><u>Note:</u>  You may choose to complete only one threat profile for each Critical Asset.</Typography>
                </Grid>
              </Grid>
            </Collapse>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button sx={{ display:'block', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleStart}>Create Threat Profiles</Button>
        </Grid>

        {/* Start PAGE CONTENT. */}


         {/* Collapsable div for Threats page. */}
        <Grid item xs={6}>
          <Grid container spacing={0}>
            <Paper sx={{  width:'100%', backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
              <Grid item xs={12} sx={{ p:'0.5rem' }}>
              {openThreats ? (
                  <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenThreats} >
                    <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                    <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Threats</u>:</Typography>
                  </IconButton>
                    ) : (
                    <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenThreats} >
                      <ExpandCircleDownIcon color='primary' fontSize="large" />
                      <Typography variant='h3' sx={{ ml: '1.5rem' }}>Threats</Typography>
                    </IconButton>
                    )}
                  <Divider sx={{ border:0.5, mt:'6px' }}/>
              </Grid>
              <Collapse in={openThreats} timeout="auto" unmountOnExit>
                <Threats />
              </Collapse>
            </Paper>
          </Grid>
        </Grid>

        {/* Collapsable div for Vulnerabilities page. */}
        <Grid item xs={6}>
          <Grid container spacing={0}>
            <Paper sx={{ width:'100%', backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px' }} >
              <Grid item xs={12} sx={{ p:'0.5rem' }}>
              {openVulnerabilities ? (
                  <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenVulnerabilities} >
                    <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                    <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Vulnerabilities</u>:</Typography>
                  </IconButton>
                    ) : (
                    <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenVulnerabilities} >
                      <ExpandCircleDownIcon color='primary' fontSize="large" />
                      <Typography variant='h3' sx={{ ml: '1.5rem' }}>Vulnerabilities</Typography>
                    </IconButton>
                    )}
                  <Divider sx={{ border:0.5, mt:'6px' }}/>
              </Grid>
              <Collapse in={openVulnerabilities} timeout="auto" unmountOnExit>
                <Vulnerabilities />
              </Collapse>
            </Paper>
          </Grid>
        </Grid>
          
    {/* Collapsable div for Threat Profiles page. */}
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Paper sx={{  width:'100%', backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
              <Grid item xs={12} sx={{ p:'0.5rem' }}>
              {openThreatProfiles ? (
                  <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenThreatProfiles} >
                    <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                    <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Threat Profiles</u>:</Typography>
                  </IconButton>
                    ) : (
                    <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenThreatProfiles} >
                      <ExpandCircleDownIcon color='primary' fontSize="large" />
                      <Typography variant='h3' sx={{ ml: '1.5rem' }}>ThreatProfiles</Typography>
                    </IconButton>
                    )}
                  <Divider sx={{ border:0.5, mt:'6px' }}/>
              </Grid>
              <Collapse in={openThreatProfiles} timeout="auto" unmountOnExit>
                <ThreatProfiles viewOnly={false}/>
              </Collapse>
            </Paper>
          </Grid>
        </Grid>

        

      </Grid>
    </Box>
  );
}

export default ThreatVulnLikelihoods