import React from 'react';
import { Box, Button, IconButton, Typography, useTheme, Grid } from "@mui/material";
import { Panel, useReactFlow, getRectOfNodes, getTransformForBounds, getNodesBounds, getViewportForBounds } from 'reactflow';
import { toPng } from 'html-to-image';

function downloadImage(dataUrl) {
  const a = document.createElement('a');

  a.setAttribute('download', 'reactflow.png');
  a.setAttribute('href', dataUrl);
  a.click();
}

const imageWidth = 4096;
const imageHeight = 4096;

function DownloadButton() {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    // const nodesBounds = getRectOfNodes(getNodes());
    // const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);
    const nodesBounds = getNodesBounds(getNodes());
    const transform = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#1a365d',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
  };


  return (
    <Button sx={{ mr: '1rem', width: 'auto' }} variant="contained" onClick={onClick}>Download Image</Button>
  );
}

export default DownloadButton;