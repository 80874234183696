import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, IconButton, FormControlLabel , Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, ButtonBase } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import WidgetWrapper from "../../../../components/WidgetWrapper";
import GovernanceQuestions from './Governance';
import AccessControlQuestions from './AccessControl';
import AssetConfigurationManagementQuestions from './AssetConfigurationManagement';
import IntrusionDetectionResponseQuestions from './IntrusionDetectionResponseQuestions';
import SecurityArchitectureQuestions from './SecurityArchitectureQuestions';
import RiskManagementQuestions from './RiskManagementQuestions';
import SystemRestorationRecoveryQuestions from './SystemRestorationRecoveryQuestions';
import CyberTrainingQuestions from './CyberTrainingQuestions';
import QuestionnaireResults from './QuestionnaireResults';
import Header from "../../../../components/Header";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';


{/** This page will just be the Hydropower Cyber/SCADA checklist questions 5-33 from Revision 3A. */}

const FERCHydropowerCyberSCADAChecklist = ({setActiveStep, fresh, setfresh}) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [allCyberAssetData, setAllCyberAssetData] = useState();
  const theme = useTheme();
  const { assessmentID } = useParams();
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [openInstructions, setOpenInstructions] = useState(true);

// questionFamily sets which questions are active on the web page. 
// The questions are mapped as:
//   0   Governance.jsx                        5   Risk Management & Situation Awareness
//   1   AccessControl.jsx                     6   System Resotration and Recovery
//   2   Asset & Configuration Management      7   Training               
//   3   Intrusion Detection and Response      
//   4   Security Architecture

  const [questionFamily, setQuestionFamily] = useState(0); 

// Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
// const getCyberAssets = async () => {
//   const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
//       withCredentials: true
//   });
//   const data = await response.data;
//   setAllCyberAssetData(data);

//   if (allCyberAssetData === null) {
//       return;
//   }
// };

//   useEffect(() => {
//       getCyberAssets();
//     }, []);  // eslint-disable-line react-hooks/exhaustive-deps
//     if (!allCyberAssetData) {
//       return null;
//     };

  // Update Database Function 
const handleCyberAssetUpdate = async () => {
  for (let updatedRow in allCyberAssetData) {
    let id = allCyberAssetData[updatedRow]._id;
    let remoteAccess = allCyberAssetData[updatedRow].remoteAccess;
    let remoteAccessConnected = allCyberAssetData[updatedRow].remoteAccessConnected;
    let fercDesignation = allCyberAssetData[updatedRow].fercDesignation;
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "remoteAccess": remoteAccess, "remoteAccessConnected": remoteAccessConnected, "fercDesignation": fercDesignation },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        setAllCyberAssetData(null);
        setAllCyberAssetData(data);
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  };

const handleNo = () => {
  setOpen(false);
  setRefresh(!refresh);
};

const handleYes = () => {
  setQuestionFamily(0);
  setOpen(false);
};

const handleOpen = () => {
  setOpen(true);
};

const handleOpenSpecificQuestions = (index) => {
  setQuestionFamily(index);
  setOpen(true);
};

  const CyberSCADAChecklistForm3 = [
    {
      id: '6',
      questionText: 'Are physical protection measures in place for the control room/facility?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '6. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '7a',
      questionText: 'Does the facility/project have a separate Cyber/Industrial Control System (e.g. SCADA) Security Plan?',
      questionTextAug1: 'Enter name of the plan in the comments section.',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '7a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '7b',
      questionText: '7b. Is the Cyber/Industrial Control System (e.g. SCADA) Security included in another plan?',
      questionTextAug1: 'Enter name of the plan in the comments section.',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '7b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '8a',
      questionText: 'Does the project have any (hydroelectric) cyber assets which are subject to NERC-CIP Standards?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '8a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '8b',
      questionText: 'Do you have any unresolved findings from a NERC compliance audit related to this project?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '8b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '9a',
      questionText: 'Have all facility/project Cyber/ICS assets been inventoried/identified?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '9a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '9b',
      questionText: 'Have the assets been designated as critical, operational, or non-critical?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '9b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '10',
      questionText: ' Does the facility/project have Business Cyber Assets (non-industrial control systems which include corporate email, human resources, company website, etc.)? ',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '10. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '11a',
      questionText: 'Are the Industrial Control System (e.g. SCADA) and non-Industrial Control System networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '11a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '11b',
      questionText: 'Within the Industrial Control System environment (to include building services such as HVAC) are the networks segregated and access controls applied to prevent unauthorized communication between these networks? ',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '11b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '12a',
      questionText: 'Do any vendors or 3rd parties have remote access to your network?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '12a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '12b',
      questionText: 'If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '12b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '12c',
      questionText: 'Is remote access activity logged and reviewed weekly?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '12c. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '13a',
      questionText: 'Does the facility/project use wireless fidelity or wifi in the Cyber/SCADA system?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '13a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '13b',
      questionText: 'If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '13b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '14a',
      questionText: 'Are cyber security controls implemented within the ICS network that allow for logging, monitoring, detection, and isolation of an anomalous cyber event?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '14a. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '14b',
      questionText: 'Is there a dedicated team to review the information? ',
      questionTextAug1: 'If so, enter how often the review occurs in the comments section.',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '14b. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '15',
      questionText: 'Is a configuration and patch management program established for both ICS and non-ICS networks?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '1. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '16',
      questionText: 'Does a back-up site exist and are systems routinely backed-up for ICS and non-ICS networks? ',
      questionTextAug1: 'If so, enter how often the the back-ups are tested in the comments section.',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '1. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    {
      id: '17',
      questionText: ' Do you have a policy to address removable and portable media?',
      questionTextAug1: '',
      questionTextAug2: '',
      questionTextAug3: '',
      questionNumber: '1. ',
      questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },

    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },

    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },{
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
    // {
    //   id: '',
    //   questionText: '',
    //   questionTextAug1: '',
    //   questionTextAug2: '',
    //   questionTextAug3: '',
    //   questionNumber: '1. ',
    //   questionTitle: 'FERC Hydro Cyber/SCADA Security Checklist – Form 3'
    // },
  ];

  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };
  


  return (
    <Box sx={{ padding: '1rem', margin: '1rem' }}>
      
      {/**INSTRUCTION BLOCK */}
      <Grid container spacing={0} sx={{ mb:'1rem' }}>
        <Grid item xs={12} >
        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
          <Grid item xs={12} sx={{ p:'0.5rem' }}>
          {openInstructions ? (
            <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
              <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
              <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
            </IconButton>
              ) : (
              <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                <ExpandCircleDownIcon color='primary' fontSize="large" />
                <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
              </IconButton>
              )}
            <Divider sx={{ border:0.5, mt:'6px' }}/>
          </Grid>
          <Collapse in={openInstructions} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ ml:'2rem' }}>
                <Typography>1. The FERC Hydro Cyber/SCADA Security Checklist - Form 3 is a mandatory part of the FERC Security Program for Hydropower Projects Revision 3.</Typography>
                <Typography>2. This section will help you complete the checklist and identify gaps. You will answer 50 questions across 8 categories. The categories and number of questions are shown below. </Typography>
                <Typography>3. Click the <Button sx={{ cursor:'text', mr: '0.5rem', ml: '0.5rem', mb: '0.1rem', fontSize: '10pt', p: '0.2rem' }} variant="contained" >Open Assessment Questions</Button> button to get started or select each category individually to skip ahead to those questions.</Typography>
                <Typography display='inline'>4. The results of this assessment will be displayed in the table at the bottom of the page. You can export the results using the <Typography display='inline' sx={{ color:'#00D5FA', fontSize:'10pt' }}>EXPORT</Typography> option on the table's toolbar.</Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
     </Grid>

      <Paper sx={{ padding: '1rem' }}>
        <Box sx={{ p: '0.5rem', m: '1rem' }}>
          <Grid container direction="row" spacing={1} columns={12}>
            
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
                <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px', color:'black', backgroundColor: 'yellow', width: "100%" }} onClick={() => handleOpenSpecificQuestions(0)}>
                  Governance (8)
                  </ButtonBase>
            </Grid>
            
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
              <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px', color:'black', backgroundColor:'white', width: "100%" }} onClick={() => handleOpenSpecificQuestions(1)}>
                Access Control (4)
                </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
              <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'black', width: "100%", color:'white' }} onClick={() => handleOpenSpecificQuestions(2)}>
                Asset Configuration Management (3)
                </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
            <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'red', width: "100%", color:'white'  }} onClick={() => handleOpenSpecificQuestions(3)}>
              Intrusion Detection and Response (11)
              </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
              <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'blue', width: "100%", color:'white'  }} onClick={() => handleOpenSpecificQuestions(4)}>
                Security Architecture (6)
                </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
                <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'orange', width: "100%" }} onClick={() => handleOpenSpecificQuestions(5)}>
                  Risk Management (10)
                  </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
                <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'green', width: "100%" }} onClick={() => handleOpenSpecificQuestions(6)}>
                  System Restoration & Recovery (3)
                  </ButtonBase>
            </Grid>
            <Grid item xs={3} sx={{ p: '0.5rem' }}>
                <ButtonBase sx={{ p: '0.5rem', border: 'solid 1px black', backgroundColor:'purple', width: "100%", color:'white'  }} onClick={() => handleOpenSpecificQuestions(7)}>
                  Training (5)
                  </ButtonBase>
            </Grid>
          </Grid>
        </Box>
        <Typography sx={{ marginTop: '2rem' }}>
          Click the button below to start the Cyber/SCADA Security Checklist. 
        </Typography>
        <Button sx={{ marginTop: '1rem' }} onClick={handleOpen} variant="contained">
          Open Assessment Questions
        </Button> 
      </Paper>
      <Divider />
      <Paper sx={{ marginTop: '1rem', p: '1rem' }}>
        <QuestionnaireResults questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
      </Paper>
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {questionFamily===0 ? (
         <GovernanceQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} /> 
        ) : (
        questionFamily===1 ? (
          <AccessControlQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} />  
        ) : (
          questionFamily===2 ? (
            <AssetConfigurationManagementQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} /> 
          ) : (
            questionFamily===3 ? (
              <IntrusionDetectionResponseQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
            ) : (
              questionFamily===4 ? (
                <SecurityArchitectureQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
              ) : ( 
                questionFamily===5 ? (
                  <RiskManagementQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                ) : ( 
                  questionFamily===6 ? (
                    <SystemRestorationRecoveryQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                  ) : ( 
                    questionFamily===7 ? (
                      <CyberTrainingQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} setActiveStep={setActiveStep}/>
                    ) : (
                        <QuestionnaireResults questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} refresh={refresh}/>
                        )
                      )
                    )
                  )
                )
              )
            )
          )} 
         {/* <GovernanceQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} /> 
          <AccessControlQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} />  
            <AssetConfigurationManagementQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily} /> 
              <IntrusionDetectionResponseQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                <SecurityArchitectureQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                  <RiskManagementQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                    <SystemRestorationRecoveryQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/>
                    <CyberTrainingQuestions questionFamily={questionFamily} setQuestionFamily={setQuestionFamily}/> */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleYes}>Close</Button>
          {/* <Button onClick={handleNo} autoFocus>
              No
          </Button> */}
        </DialogActions>
      </Dialog>
      
    </Box>
  )
}

export default FERCHydropowerCyberSCADAChecklist