import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const AssetConfigurationManagementQuestions = ({questionFamily, setQuestionFamily}) => {

  const [knowsAssetCriticality, setKnowsAssetCriticality] = useState("");
  const [knowsAssetCriticalityComments, setKnowsAssetCriticalityComments] = useState("");

  const [doesRemoveConfigs, setDoesRemoveConfigs] = useState("");
  const [doesRemoveConfigsComments, setDoesRemoveConfigsComments] = useState("");

  const [hasConfigurationManagement, setHasConfigurationManagement] = useState("");
  const [hasConfigurationManagementComments, setHasConfigurationManagementComments] = useState("");

  const [assessmentData, setAssessmentData] = useState("");
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

  const axiosPrivate = useAxiosPrivate();
  const { assessmentID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();     

  // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setKnowsAssetCriticality(data?.knowsAssetCriticality?.toString());
    setDoesRemoveConfigs(data?.doesRemoveConfigs?.toString());
    setHasConfigurationManagement(data?.hasConfigurationManagement?.toString());
    setKnowsAssetCriticalityComments(data?.knowsAssetCriticalityComments?.toString());
    setDoesRemoveConfigsComments(data?.doesRemoveConfigsComments?.toString());
    setHasConfigurationManagementComments(data?.hasConfigurationManagementComments?.toString());
    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
  }
    

    if (assessmentData === null) {
        return;
    }
};

useEffect(() => {
    getAssessmentData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let assetConfigurationManagementQuestions = true;
    const assetConfigurationManagementQuestionData = JSON.stringify({ knowsAssetCriticality, knowsAssetCriticalityComments, doesRemoveConfigs, doesRemoveConfigsComments, hasConfigurationManagement, hasConfigurationManagementComments});
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
          JSON.stringify({ assetConfigurationManagementQuestions, assetConfigurationManagementQuestionData}),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(2) activates the Asset Management questions. 
    setQuestionFamily(3);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

  const GridItemQuestionXS = 7;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '30%';


  const AssetConfigurationQuestions = [
    {
      uid: 'ACM-1',
      variable: knowsAssetCriticality,
      GridItemQuestionXS: GridItemQuestionXS,
      questionNumber: '1',
      questionText: 'Do you review and reassess cyber asset criticality (non-critical, operational, critical) at least every 12 months?',
      GridItemInputXS: GridItemInputXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      name: "knowsAssetCriticality",
      type: 'radio',
      answerType: 'YesNo', 
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setKnowsAssetCriticality,
      comments: 'true',
      GridItemCommentsXS: GridItemCommentsXS,
      inputCommentWidth: inputCommentWidth,
      idComment: "knowsAssetCriticalityComments",
      valueComment: knowsAssetCriticalityComments,
      onChangeComments: setKnowsAssetCriticalityComments
    }, 
    {
      uid: 'ACM-2',
      variable: doesRemoveConfigs,
      GridItemQuestionXS: GridItemQuestionXS,
      questionNumber: '2',
      questionText: 'Are operational data/configurations removed from systems before they are decommissioned?',
      GridItemInputXS: GridItemInputXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      name: "doesRemoveConfigs",
      type: 'radio',
      answerType: 'YesNo', 
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesRemoveConfigs,
      comments: 'true',
      GridItemCommentsXS: GridItemCommentsXS,
      inputCommentWidth: inputCommentWidth,
      idComment: "doesRemoveConfigsComments",
      valueComment: doesRemoveConfigsComments,
      onChangeComments: setDoesRemoveConfigsComments
    },
    {
      uid: 'ACM-3',
      variable: hasConfigurationManagement,
      GridItemQuestionXS: GridItemQuestionXS,
      questionNumber: '3',
      questionText: 'Is a configuration and patch management program established for both ICS and non-ICS networks?',
      GridItemInputXS: GridItemInputXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      name: "hasConfigurationManagement",
      type: 'radio',
      answerType: 'YesNo', 
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasConfigurationManagement,
      comments: 'true',
      GridItemCommentsXS: GridItemCommentsXS,
      inputCommentWidth: inputCommentWidth,
      idComment: "hasConfigurationManagementComments",
      valueComment: hasConfigurationManagementComments,
      onChangeComments: setHasConfigurationManagementComments
    }
  ]

  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>Asset and Configuration Management</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {AssetConfigurationQuestions.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          {/* <Button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" || hasConfigurationManagement==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button> */}
          <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box> 
  )
}

export default AssetConfigurationManagementQuestions