import React, { useState, useEffect } from 'react';
import {Box, Typography, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import NavbarDashboard from "./navbardashboard";
// import Navbar from 'scenes/navbar';
import SidebarNewAssessment from "../../components/SidebarNewAssessment";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
// import { useGetUserQuery } from 'state/api';\
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const LayoutDashboard = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const userId = "659102e857259da4dcdfbb95";
  // const userId = useSelector((state) => state.userId);
  // const { data } = useGetUserQuery(userId);
  // console.log("🚀 ~ file: index.jsx:17 ~ Layout ~ data:", data);
  const [userData, setUserData] = useState(null);
  const token = useSelector((state) => state.token);
  const axiosPrivate = useAxiosPrivate();
  
  const { auth } = useAuth();

    const decoded = auth?.accessToken
    ? jwtDecode(auth.accessToken)
    : undefined
    
    const username = decoded?.UserInfo?.username || []

    // console.log(username);
  
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
       {/* <SidebarNewAssessment
         user={userData || {}}
         isNonMobile={isNonMobile}
         drawerWidth="250px"
         isSidebarOpen={isSidebarOpen}
         setIsSidebarOpen={setIsSidebarOpen}
         /> */}
       <Box flexGrow={1}>
      <NavbarDashboard
        username={username || {}}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
       </Box>
     </Box>
  );
  
};

export default LayoutDashboard;