import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, useTheme, Grid, useMediaQuery, Typography } from "@mui/material";



const GovernanceQuestions = ({questionFamily, setQuestionFamily}) => {

    // ************Template Variables*********************//

    const [assessmentData, setAssessmentData] = useState();
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { assessmentID } = useParams();
    const theme = useTheme();     

    // ************Template Variables*********************//


    // *************** Questionnaire specific Variables***************//
  
    const hasPolicy = false;
    const [hasAccessControlPolicy, setHasAccessControlPolicy] = useState(false);
    const [hasAssetManagementPolicy, setHasAssetManagementPolicy] = useState(false);
    const [hasConfigurationManagementPolicy, setHasConfigurationManagementPolicy] = useState(false);
    const [hasSupplyChainRiskManagementPolicy, setHasSupplyChainRiskManagementPolicy] = useState(false);
    const [doesAnnualPolicyReview, setDoesAnnualPolicyReview] = useState("");
    const [hasCybersecurityTeam, setHasCybersecurityTeam] = useState("");
    const [hasCyberRolesResponsibilities, setHasCyberRolesResponsibilities] = useState("");
    const [hasInternalCommunicationPlan, setHasInternalCommunicationPlan] = useState("");
    const [doesExternalCommunicationPlan, setDoesExternalCommunicationPlan] = useState("");
    const [doesResourceAssessment, setDoesResourceAssessment] = useState("");
    const [doesIndependentResourceAssessment, setDoesIndependentResourceAssessment] = useState("");
    const [hasPolicyComments, setHasPolicyComments] = useState("");

    const [doesAnnualPolicyReviewComments, setDoesAnnualPolicyReviewComments] = useState("");
    const [hasCybersecurityTeamComments, setHasCybersecurityTeamComments] = useState("");
    const [hasCyberRolesResponsibilitiesComments, setHasCyberRolesResponsibilitiesComments] = useState("");
    const [hasInternalCommunicationPlanComments, setHasInternalCommunicationPlanComments] = useState("");
    const [doesExternalCommunicationPlanComments, setDoesExternalCommunicationPlanComments] = useState("");
    const [doesResourceAssessmentComments, setDoesResourceAssessmentComments] = useState("");
    const [doesIndependentResourceAssessmentComments, setDoesIndependentResourceAssessmentComments] = useState("");
    const [hasRemovableMediaPolicy, setHasRemovableMediaPolicy] = useState(false);

   // *************** Questionnaire specific Variables***************//


 

// Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
    const getAssessmentData = async () => {
        try {
            const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
                withCredentials: true
            });
            const data = await response.data;
            console.log(data);
            // setGovernanceQuestions(data.governanceQuestions);
            setAssessmentData(data);
            
            setHasAccessControlPolicy(data?.hasAccessControlPolicy?.toString());
            setHasAssetManagementPolicy(data?.hasAssetManagementPolicy?.toString());
            setHasConfigurationManagementPolicy(data?.hasConfigurationManagementPolicy?.toString());
            setHasSupplyChainRiskManagementPolicy(data?.hasSupplyChainRiskManagementPolicy?.toString());
            setHasRemovableMediaPolicy(data?.hasRemovableMediaPolicy?.toString());
            setDoesAnnualPolicyReview(data?.doesAnnualPolicyReview?.toString());
            setHasCybersecurityTeam(data?.hasCybersecurityTeam?.toString());
            setHasCyberRolesResponsibilities(data?.hasCyberRolesResponsibilities?.toString());
            setHasInternalCommunicationPlan(data?.hasInternalCommunicationPlan?.toString());
            setDoesExternalCommunicationPlan(data?.doesExternalCommunicationPlan?.toString());
            setDoesResourceAssessment(data?.doesResourceAssessment?.toString());
            setDoesIndependentResourceAssessment(data?.doesIndependentResourceAssessment?.toString());
            setHasPolicyComments(data?.hasPolicyComments?.toString());
            setDoesAnnualPolicyReviewComments(data?.doesAnnualPolicyReviewComments?.toString());
            setHasCybersecurityTeamComments(data?.hasCybersecurityTeamComments?.toString());
            setHasCyberRolesResponsibilitiesComments(data?.hasCyberRolesResponsibilitiesComments?.toString());
            setHasInternalCommunicationPlanComments(data?.hasInternalCommunicationPlanComments?.toString());
            setDoesExternalCommunicationPlanComments(data?.doesExternalCommunicationPlanComments?.toString());
            setDoesResourceAssessmentComments(data?.doesResourceAssessmentComments?.toString());
            setDoesIndependentResourceAssessmentComments(data?.doesIndependentResourceAssessmentComments?.toString());

        } catch (err) {
            alert("Login Expirsed.");
            console.error(err);
            navigate('/login', { state: { from: location }, replace: true });
        }
        
        if (assessmentData === null) {
            return;
        }
    };
  
    useEffect(() => {
        getAssessmentData();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!assessmentData) {
        return null;
      }

    //   console.log("ACCESS CONTROL POLICY?");
    //   console.log(hasAccessControlPolicy);
    //   console.log(hasAssetManagementPolicy);
    //   console.log(hasConfigurationManagementPolicy);
    //   console.log(hasSupplyChainRiskManagementPolicy);
    //   console.log(hasRemovableMediaPolicy);

 // *************** Update to use Questionnaire specific Variables***************//
 const handleSubmit = async (e) => {
    e.preventDefault();
    let governanceQuestions = true;
    const governanceData = JSON.stringify({ hasAccessControlPolicy, hasAssetManagementPolicy, hasConfigurationManagementPolicy, hasSupplyChainRiskManagementPolicy, hasRemovableMediaPolicy, doesAnnualPolicyReview, hasCybersecurityTeam, hasCyberRolesResponsibilities, hasInternalCommunicationPlan, doesExternalCommunicationPlan, doesResourceAssessment, doesIndependentResourceAssessment, hasPolicyComments, doesAnnualPolicyReviewComments, hasCybersecurityTeamComments, hasCyberRolesResponsibilitiesComments, hasInternalCommunicationPlanComments, doesExternalCommunicationPlanComments, doesResourceAssessmentComments, doesIndependentResourceAssessmentComments });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
        JSON.stringify({ governanceQuestions, governanceData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(1) activates the Access Control questions. 
    setQuestionFamily(1);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}


const handleNewRecord = async () => {
    try {
        const response = await axiosPrivate.post(`/ferccsform3/new`,
        JSON.stringify({ assessmentID }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}


  const GridItemQuestionXS = 6;
  const GridItemInputXS = 3;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '80%';
  const checkboxMarginLeft = '10%';
  const GridItemCheckboxContainerInputXS = 3;



  //************Add category specific Questions.****************//
  const GovernanceQuestionsList = [
    //***Example of checkbox question. */
    {
        uid: 'GV-1',
        variable: hasPolicy,
        questionNumber: '1',
        questionText: 'Do you have policies and/or procedures for the following areas, check all that apply?',
        name: "hasPolicy",
        type: 'checkbox',
        answerType: 'YesNo',
        //***Only used for question type 'checkbox'. */ 
        checkboxInputs: [
            {
                variable: hasAccessControlPolicy,
                name: "hasAccessControlPolicy",
                title: "Access Control",
                onChangeInput: setHasAccessControlPolicy,
            },
            {
                variable: hasAssetManagementPolicy,
                name: "hasAssetManagementPolicy",
                title: "Asset Management",
                onChangeInput: setHasAssetManagementPolicy,
            },
            {
                variable: hasConfigurationManagementPolicy,
                name: "hasConfigurationManagementPolicy",
                title: "Configuration Management",
                onChangeInput: setHasConfigurationManagementPolicy,
            },          
            {
                variable: hasSupplyChainRiskManagementPolicy,
                name: "hasSupplyChainRiskManagementPolicy",
                title: "Supply Chain Risk Management",
                onChangeInput: setHasSupplyChainRiskManagementPolicy,
            },
            {
                variable: hasRemovableMediaPolicy,
                name: "hasRemovableMediaPolicy",
                title: "Removable Media",
                onChangeInput: setHasRemovableMediaPolicy,
            }
        ],
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'true',
        idComment: "hasPolicyComments",
        valueComment: hasPolicyComments,
        onChangeComments: setHasPolicyComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: 6,  // GridItemQuestionXS,
        GridItemInputXS: 3,     // GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
        checkboxMarginLeft: checkboxMarginLeft,
        GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-2',
    variable: doesAnnualPolicyReview,
    questionNumber: '2',
    questionText: 'Do you review and update your cyber security policies and/or procedures annually?',
    name: "doesAnnualPolicyReview",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesAnnualPolicyReview,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesAnnualPolicyReviewComments",
    valueComment: doesAnnualPolicyReviewComments,
    onChangeComments: setDoesAnnualPolicyReviewComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-3',
    variable: hasCybersecurityTeam,
    questionNumber: '3',
    questionText: 'Do you have a dedicated cyber security team?',
    name: "hasCybersecurityTeam",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHasCybersecurityTeam,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "hasCybersecurityTeamComments",
    valueComment: hasCybersecurityTeamComments,
    onChangeComments: setHasCybersecurityTeamComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-4',
    variable: hasCyberRolesResponsibilities,
    questionNumber: '4',
    questionText: 'Have you defined cyber security roles and responsibilities?',
    name: "hasCyberRolesResponsibilities",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHasCyberRolesResponsibilities,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "hasCyberRolesResponsibilitiesComments",
    valueComment: hasCyberRolesResponsibilitiesComments,
    onChangeComments: setHasCyberRolesResponsibilitiesComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-5',
    variable: hasInternalCommunicationPlan,
    questionNumber: '5',
    questionText: 'Do you have established lines of communication among the cyber security team, operations, IT, and business groups?',
    name: "hasInternalCommunicationPlan",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHasInternalCommunicationPlan,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "hasInternalCommunicationPlanComments",
    valueComment: hasInternalCommunicationPlanComments,
    onChangeComments: setHasInternalCommunicationPlanComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-6',
    variable: doesExternalCommunicationPlan,
    questionNumber: '6',
    questionText: 'If yes, do the lines of communication include outsourcers, partners, and third-parties?',
    name: "doesExternalCommunicationPlan",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesExternalCommunicationPlan,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesExternalCommunicationPlanComments",
    valueComment: doesExternalCommunicationPlanComments,
    onChangeComments: setDoesExternalCommunicationPlanComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
    uid: 'GV-7',
    variable: doesResourceAssessment,
    questionNumber: '7',
    questionText: 'Do you assess the resources available to govern and implement your security strategy?',
    name: "doesResourceAssessment",
    type: 'radio',
    answerType: 'YesNo',
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesResourceAssessment,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesResourceAssessmentComments",
    valueComment: doesResourceAssessmentComments,
    onChangeComments: setDoesResourceAssessmentComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    checkboxMarginLeft: checkboxMarginLeft,
    GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }, 
    {
        //***Example of radio button question. */
      uid: 'GV-8',
      variable: doesIndependentResourceAssessment,
      questionNumber: '8',
      questionText: 'Do you perform this assessment independently?',
      name: "doesIndependentResourceAssessment",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesIndependentResourceAssessment,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesIndependentResourceAssessmentComments",
      valueComment: doesIndependentResourceAssessmentComments,
      onChangeComments: setDoesIndependentResourceAssessmentComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
      checkboxMarginLeft: checkboxMarginLeft,
      GridItemCheckboxContainerInputXS: GridItemCheckboxContainerInputXS,
    }
  ]


  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
        <Typography variant='h2' color={theme.palette.secondary[100]} fontWeight="bold" sx={{ mb: "5px" }}>
            Governance
        </Typography>
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
     
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {GovernanceQuestionsList.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          checkboxMarginLeft,
          GridItemCheckboxContainerInputXS,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            checkboxMarginLeft={checkboxMarginLeft}
            GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasAccessControlPolicy==="" || hasAssetManagementPolicy==="" || hasConfigurationManagementPolicy==="" || hasSupplyChainRiskManagementPolicy==="" || doesAnnualPolicyReview==="" || hasCybersecurityTeam==="" || hasCyberRolesResponsibilities==="" || hasInternalCommunicationPlan==="" || doesExternalCommunicationPlan==="" || doesResourceAssessment==="" || doesIndependentResourceAssessment==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
          {/* <Button sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleNewRecord}>Add Ferc FOrm 3 Record</Button> */}
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box>
  )
}

export default GovernanceQuestions

// import React, { useRef, useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
// import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
// import WidgetWrapper from '../../../../components/WidgetWrapper';
// import { SettingsVoiceRounded } from '@mui/icons-material';


// const GovernanceQuestions = ({questionFamily, setQuestionFamily}) => {

//     const [assessmentData, setAssessmentData] = useState();
//     const axiosPrivate = useAxiosPrivate();
//     const { assessmentID } = useParams();
//     const navigate = useNavigate();

//     const userRef = useRef();
//     const errRef = useRef();
//     const [errMsg, setErrMsg] = useState('');
//     const theme = useTheme();        
//     const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
//     const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

//     const [hasAccessControlPolicy, setHasAccessControlPolicy] = useState(false);
//     const [hasAssetManagementPolicy, setHasAssetManagementPolicy] = useState(false);
//     const [hasConfigurationManagementPolicy, setHasConfigurationManagementPolicy] = useState(false);
//     const [hasSupplyChainRiskManagementPolicy, setHasSupplyChainRiskManagementPolicy] = useState(false);
//     const [doesAnnualPolicyReview, setDoesAnnualPolicyReview] = useState("");
//     const [hasCybersecurityTeam, setHasCybersecurityTeam] = useState("");
//     const [hasCyberRolesResponsibilities, setHasCyberRolesResponsibilities] = useState("");
//     const [hasInternalCommunicationPlan, setHasInternalCommunicationPlan] = useState("");
//     const [doesExternalCommunicationPlan, setDoesExternalCommunicationPlan] = useState("");
//     const [doesResourceAssessment, setDoesResourceAssessment] = useState("");
//     const [doesIndependentResourceAssessment, setDoesIndependentResourceAssessment] = useState("");
//     const [hasPolicyComments, setHasPolicyComments] = useState("");

//     const [doesAnnualPolicyReviewComments, setDoesAnnualPolicyReviewComments] = useState("");
//     const [hasCybersecurityTeamComments, setHasCybersecurityTeamComments] = useState("");
//     const [hasCyberRolesResponsibilitiesComments, setHasCyberRolesResponsibilitiesComments] = useState("");
//     const [hasInternalCommunicationPlanComments, setHasInternalCommunicationPlanComments] = useState("");
//     const [doesExternalCommunicationPlanComments, setDoesExternalCommunicationPlanComments] = useState("");
//     const [doesResourceAssessmentComments, setDoesResourceAssessmentComments] = useState("");
//     const [doesIndependentResourceAssessmentComments, setDoesIndependentResourceAssessmentComments] = useState("");
    
//     // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
//     const getAssessmentData = async () => {
//         const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
//             withCredentials: true
//         });
//         const data = await response.data;
//         // setGovernanceQuestions(data.governanceQuestions);
//         setAssessmentData(data);
        
//         if (assessmentData === null) {
//             return;
//         }
//     };
  
//     useEffect(() => {
//         getAssessmentData();
//       }, []);  // eslint-disable-line react-hooks/exhaustive-deps
//       if (!assessmentData) {
//         return null;
//       }

//     const buttonStyle = {
//         'backgroundColor': '#00D5FA',
//         'width': '35%',
//         'marginTop': '15px',
//         'padding': '10px'
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         let governanceQuestions = true;
//         try {
//             const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
//            JSON.stringify({ governanceQuestions, hasAccessControlPolicy, hasAssetManagementPolicy, hasConfigurationManagementPolicy, hasSupplyChainRiskManagementPolicy, doesAnnualPolicyReview, hasCybersecurityTeam, hasCyberRolesResponsibilities, hasInternalCommunicationPlan, doesExternalCommunicationPlan, doesResourceAssessment, doesIndependentResourceAssessment, hasPolicyComments, doesAnnualPolicyReviewComments, hasCybersecurityTeamComments, hasCyberRolesResponsibilitiesComments, hasInternalCommunicationPlanComments, doesExternalCommunicationPlanComments, doesResourceAssessmentComments, doesIndependentResourceAssessmentComments }),
//             {
//                 headers: { 'Content-Type': 'application/json' },
//                 withCredentials: true
//             }
//         );
//         setQuestionFamily(1);
//         } catch (err) {
//             if (!err?.response) {
//                 console.log('No Server Response');
//             } else {
//                 console.log('Action Failed')
//             }
//         }
//     }

//   return (
//     <Box>
//         <Grid container spacing={2} columns={12}>
//             <Grid item xs={12}>
//                 <Box
//                 width="100%"
//                 p="2rem"
//                 m="2rem auto"
//                 borderRadius="1.5rem"
//                 backgroundColor={theme.palette.background.alt}
//                 >
//                     <section>
//                         <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
//                             {errMsg}
//                         </p>
//                         <h1>Governance</h1>
//                         <form onSubmit={handleSubmit}>
//                         <Box
//                             display="grid"
//                             alignItems="downward"
//                             gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
//                             sx={{
//                                 "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
//                             }}
//                         >
//                             <Grid container spacing={1} columns={12}>

//              {/*Question #1 */}
//                             <Grid item xs={8} >
//                             <p>1. Do you have policies and/or procedures for the following areas, check all that apply? </p>
//                             </Grid>
//                             <Grid item xs={4} sx={{alignSelf:'center'}}>
//                             <p>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasAccessControlPolicy"
//                                 type="checkbox" 
//                                 id="hasAccessControlPolicy"
//                                 value="true"
//                                 checked={hasAccessControlPolicy==="true"}
//                                 onChange={(e) => setHasAccessControlPolicy(e.target.value)}
//                             />
//                             <label htmlFor="hasAccessControlPolicy">Access Control</label>
//                             </p>
//                             <p>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasAssetManagementPolicy"
//                                 type="checkbox" 
//                                 id="hasAssetManagementPolicy" 
//                                 value="true"
//                                 checked={hasAssetManagementPolicy==="true"}
//                                 onChange={(e) => setHasAssetManagementPolicy(e.target.value)}
                                
//                             />
//                             <label htmlFor="hasConfigurationManagementPolicy">Asset Management</label>
//                             </p>
//                             <p>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasConfigurationManagementPolicy"
//                                 type="checkbox" 
//                                 id="hasConfigurationManagementPolicy" 
//                                 value="true"
//                                 checked={hasConfigurationManagementPolicy==="true"}
//                                 onChange={(e) => setHasConfigurationManagementPolicy(e.target.value)}
                                
//                             />
//                             <label htmlFor="hasSupplyChainRiskManagementPolicy">Configuration Management</label>
//                             </p>
//                             <p>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasSupplyChainRiskManagementPolicy"
//                                 type="checkbox" 
//                                 id="hasSupplyChainRiskManagementPolicy" 
//                                 value="true"
//                                 checked={hasSupplyChainRiskManagementPolicy==="true"}
//                                 onChange={(e) => setHasSupplyChainRiskManagementPolicy(e.target.value)}
                                
//                             />
//                             <label htmlFor="hasSupplyChainRiskManagementPolicy">Supply Chain Risk Management</label>
//                             </p>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="hasPolicyComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="hasPolicyComments"
//                                 value={hasPolicyComments || ""}
//                                 onChange={(e) => setHasPolicyComments(e.target.value)}
//                             />
//                             </Grid>

//                             <Grid item xs={12}><Divider /></Grid>

//             {/*Question #2 */}
//                             <Grid item xs={10}>
//                             <p>2. Do you review and update your cyber security policies and/or procedures annually?</p> 
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesAnnualPolicyReview"
//                                 type="radio" 
//                                 id="doesAnnualPolicyReviewYes"
//                                 value="true"
//                                 checked={doesAnnualPolicyReview==="true"}
//                                 onChange={(e) => setDoesAnnualPolicyReview(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesAnnualPolicyReviewYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesAnnualPolicyReview"
//                                 type="radio" 
//                                 id="doesAnnualPolicyReviewNo"
//                                 value="false"
//                                 checked={doesAnnualPolicyReview==="false"}
//                                 onChange={(e) => setDoesAnnualPolicyReview(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesAnnualPolicyReviewNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="doesAnnualPolicyReviewComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="doesAnnualPolicyReviewComments"
//                                 value={hasPolicyComments || ""}
//                                 onChange={(e) => setDoesAnnualPolicyReviewComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>

//             {/*Question #3 */}
//                             <Grid item xs={10}>
//                             <p>3. Do you have a dedicated cyber security team? </p>
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasCybersecurityTeam"
//                                 type="radio" 
//                                 id="hasCybersecurityTeamYes"
//                                 value="true"
//                                 checked={hasCybersecurityTeam==="true"}
//                                 onChange={(e) => setHasCybersecurityTeam(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="hasCybersecurityTeamYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasCybersecurityTeam"
//                                 type="radio" 
//                                 id="hasCybersecurityTeamNo"
//                                 value="false"
//                                 checked={hasCybersecurityTeam==="false"}
//                                 onChange={(e) => setHasCybersecurityTeam(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="hasCybersecurityTeamNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="hasCybersecurityTeamComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="hasCybersecurityTeamComments"
//                                 value={hasCybersecurityTeamComments || ""}
//                                 onChange={(e) => setHasCybersecurityTeamComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>

//             {/*Question #4 */}
//                             <Grid item xs={10}>
//                             <p style={{ marginBottom:0, marginTop:'1rem'}}>4. Have you defined cyber security roles and responsibilities?</p> 
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}> 
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasCyberRolesResponsibilities"
//                                 type="radio"
//                                 id="hasCyberRolesResponsibilitiesYes"
//                                 value="true"
//                                 checked={hasCyberRolesResponsibilities==="true"}
//                                 onChange={(e) => setHasCyberRolesResponsibilities(e.target.value)}
//                             />
//                             <label htmlFor="hasCyberRolesResponsibilitiesYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasCyberRolesResponsibilities"
//                                 type="radio" 
//                                 id="hasCyberRolesResponsibilitiesNo"
//                                 value="false"
//                                 checked={hasCyberRolesResponsibilities==="false"}
//                                 onChange={(e) => setHasCyberRolesResponsibilities(e.target.value)}
//                             />  
//                             <label htmlFor="hasCyberRolesResponsibilitiesNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="hasCyberRolesResponsibilitiesComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="hasCyberRolesResponsibilitiesComments"
//                                 value={hasCyberRolesResponsibilitiesComments || ""}
//                                 onChange={(e) => setHasCyberRolesResponsibilitiesComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>

//             {/*Question #5 */}
//                             <Grid item xs={10}>
//                             <p>5. Have you lines of communication among the cyber security team, operations, IT, and business groups?</p>
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasInternalCommunicationPlan"
//                                 type="radio" 
//                                 id="khasInternalCommunicationPlanYes"
//                                 value="true"
//                                 checked={hasInternalCommunicationPlan==="true"}
//                                 onChange={(e) => setHasInternalCommunicationPlan(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="hasInternalCommunicationPlanYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="hasInternalCommunicationPlan"
//                                 type="radio" 
//                                 id="hasInternalCommunicationPlanNo"
//                                 value="false"
//                                 checked={hasInternalCommunicationPlan==="false"}
//                                 onChange={(e) => setHasInternalCommunicationPlan(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="hasInternalCommunicationPlanNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="hasInternalCommunicationPlanComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="hasInternalCommunicationPlanComments"
//                                 value={hasInternalCommunicationPlanComments || ""}
//                                 onChange={(e) => setHasInternalCommunicationPlanComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>

//             {/*Question #6 */}
//                             <Grid item xs={10}>
//                             <p>6. If yes, do the lines of communication include outsourcers, partners, and third-parties?</p>
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesExternalCommunicationPlan"
//                                 type="radio" 
//                                 id="doesExternalCommunicationPlanYes"
//                                 value="true"
//                                 checked={doesExternalCommunicationPlan==="true"}
//                                 onChange={(e) => setDoesExternalCommunicationPlan(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesExternalCommunicationPlanYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesExternalCommunicationPlan"
//                                 type="radio" 
//                                 id="doesExternalCommunicationPlanNo"
//                                 value="false"
//                                 checked={doesExternalCommunicationPlan==="false"}
//                                 onChange={(e) => setDoesExternalCommunicationPlan(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesExternalCommunicationPlanNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="doesExternalCommunicationPlanComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="doesExternalCommunicationPlanComments"
//                                 value={doesExternalCommunicationPlanComments || ""}
//                                 onChange={(e) => setDoesExternalCommunicationPlanComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>
//             {/*Question #7 */}  
//                             <Grid item xs={10}>
//                             <p>7. Do you assess the resources available to govern and implement your security strategy?</p>
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesResourceAssessment"
//                                 type="radio" 
//                                 id="doesResourceAssessmentYes"
//                                 value="true"
//                                 checked={doesResourceAssessment==="true"}
//                                 onChange={(e) => setDoesResourceAssessment(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesResourceAssessmentYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesResourceAssessment"
//                                 type="radio" 
//                                 id="doesResourceAssessmentNo"
//                                 value="false"
//                                 checked={doesResourceAssessment==="false"}
//                                 onChange={(e) => setDoesResourceAssessment(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesResourceAssessmentNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="doesResourceAssessmentComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="doesResourceAssessmentComments"
//                                 value={doesResourceAssessmentComments || ""}
//                                 onChange={(e) => setDoesResourceAssessmentComments(e.target.value)}
//                             />
//                             </Grid>
//                             <Grid item xs={12}><Divider /></Grid>
//             {/*Question #8 */}  
//                             <Grid item xs={10}>
//                             <p>8. Do you perform this assessment independently?</p>
//                             </Grid>
//                             <Grid item xs={2} sx={{alignSelf:'center'}}>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesIndependentResourceAssessment"
//                                 type="radio" 
//                                 id="doesIndependentResourceAssessmentYes"
//                                 value="true"
//                                 checked={doesIndependentResourceAssessment==="true"}
//                                 onChange={(e) => setDoesIndependentResourceAssessment(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesIndependentResourceAssessmentYes">Yes</label>
//                             <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
//                                 name="doesIndependentResourceAssessment"
//                                 type="radio" 
//                                 id="doesIndependentResourceAssessmentNo"
//                                 value="false"
//                                 checked={doesIndependentResourceAssessment==="false"}
//                                 onChange={(e) => setDoesIndependentResourceAssessment(e.target.value)}
//                             />
//                             <label style={{ marginTop:"1rem" }} htmlFor="doesIndependentResourceAssessmentNo">No</label>
//                             </Grid>
//                             <Grid item xs={8}>
//                             <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="doesIndependentResourceAssessmentComments">Comments:</label>
//                             <input style={{ width:'30%', marginLeft:'3rem'}}
//                                 type="text" 
//                                 id="doesIndependentResourceAssessmentComments"
//                                 value={doesIndependentResourceAssessmentComments || ""}
//                                 onChange={(e) => setDoesIndependentResourceAssessmentComments(e.target.value)}
//                             />
//                             </Grid>
//                             </Grid>
//                         {/* <button disabled={( hasAccessControlPolicy==="" && hasAssetManagementPolicy==="" && hasConfigurationManagementPolicy==="" && hasSupplyChainRiskManagementPolicy==="" && doesAnnualPolicyReview==="" && hasCybersecurityTeam==="" && hasCyberRolesResponsibilities==="" && hasInternalCommunicationPlan==="" && doesExternalCommunicationPlan==="" && doesResourceAssessment==="" && doesIndependentResourceAssessment==="" )} style={buttonStyle}>Submit</button> */}
//                         <button style={buttonStyle}>Submit</button>
//                         </Box>
//                         </form>
//                     </section>
//                 </Box>
//             </Grid>
//         </Grid>
//     </Box>
//   )
// }

// export default GovernanceQuestions