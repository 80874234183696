import React, { useState, useEffect, useMemo } from 'react';
import WidgetWrapper from '../../../../components/WidgetWrapper';
import { Box, Button, Collapse, Paper, IconButton, Typography, useTheme, Grid, Divider, TextField } from "@mui/material";
import Header from '../../../../components/Header';
import { useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Dropdown from './dropdown';
import UploadCSV from './UploadCSV';
import FullFeaturedCrudGrid from '../../../../components/DataGrid';
import WebTool from './WebTool';
import InventoryGuidance from '../../../Training/Inventory';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';


{/** This page will include the following Steps:
    1. Add Cyber/SCADA Asset widget
    2. Remote Operation Determination
    3. Covered by NERC determination
    4. Consequence Determination Table 9.2
    5. Threshold Determination Table 9.3
*/}

const CyberSCADAAssets = ({setActiveStep}) => {

    const { assessmentID } = useParams();
    const navigate = useNavigate();
    const [hasInventory, setHasInventory] = useState();
    const [allCyberAssetData, setAllCyberAssetData] = useState();

    const [description, setDescription] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [macAddress, setMACAddress] = useState("");
    const [yearPurchased, setYearPurchased] = useState("");
    const [assetName, setAssetName] = useState(null);
    const [assetType, setAssetType] = useState(null);
    const [model, setModel] = useState("");
    const [physicalLocation, setPhysicalLocation] = useState("");
    const [localID, setLocalID] = useState("");

    const [refresh, setRefresh] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const theme = useTheme();

    const [openInstructions, setOpenInstructions] = useState(true);

    // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
// const getCyberAssets = async () => {
//     const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
//         withCredentials: true
//     });
//     const data = await response.data;
//     setAllCyberAssetData(data);
//     if (data.length>0) {
//         setHasInventory(true);
//     } else setHasInventory(false);
  
//     if (allCyberAssetData === null) {
//         return;
//     }
//   };
  
// useEffect(() => {
//     getCyberAssets();
//     }, []);  // eslint-disable-line react-hooks/exhaustive-deps
//     if (!allCyberAssetData) {
//     return null;
//     }
    
    const handleUpdate = async () => {
        try {
            const response = await axiosPrivate.patch(`/assets/${assessmentID}`,
            { "assetName": assetName, "assetType": assetType, "manufacturer": manufacturer, "model": model, "macAddress": macAddress, "yearPurchased": yearPurchased, "physicalLocation": physicalLocation, "description": description, "localID": localID },
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        // setSecurityGroup(securityGroupLocal);
        } catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed')
            }
        }
    }


    // Update Database Function 
  const handleAddCyberAssetRefresh = async () => {
        setRefresh(!refresh);
  };

  // Direct the user to the asset inventory guidance or let them add cyber assets. 
  const handleYes = () => {
    setHasInventory(true);
  }

  const handleNo = () => {
    setHasInventory(false); 
    navigate('../Training/Inventory');
  }

  const handleOpenInstructions = () => {
    setOpenInstructions(!openInstructions);
  };


  return (
    <Box>
        <Grid container spacing={0} sx={{ mt:'1rem', mb:'1rem' }}>

            {/**INSTRUCTION BLOCK */}
            <Grid item xs={12} >
                <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
                    <Grid item xs={12} sx={{ p:'0.5rem' }}>
                    {openInstructions ? (
                        <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                            <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                            <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
                        </IconButton>
                            ) : (
                            <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                                <ExpandCircleDownIcon color='primary' fontSize="large" />
                                <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                            </IconButton>
                            )}
                        <Divider sx={{ border:0.5, mt:'6px' }}/>
                    </Grid>
                    <Collapse in={openInstructions} timeout="auto" unmountOnExit>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ ml:'2rem' }}>
                                <Typography>1. Add your cyber inventory either by using the tool provided by entering the 'Asset Name' and 'Asset Type' or download the CSV template from the <Button sx={{ cursor:'text', mr: '0.5rem', ml: '0.5rem', mb: '0.1rem', fontSize: '10pt', p: '0.2rem', color:'blue' }} variant="contained" >Download Template</Button> button, add your assets to the template, upload the file using the "Choose File" option.</Typography>
                                <Typography>2. Complete the "Suggested" and "Optional" fields for better results. </Typography>
                                <Typography>3. Cyber assets will appear in the table at the bottom of the page indicating they were successfully added to your inventory.</Typography>
                                <Typography>4. You can delete cyber assets using the <DeleteIcon sx={{ cursor:'text' }}  /> adjacent to the cyber asset. </Typography>
                                <Typography>5. You can edit a specific cyber asset using the <EditIcon sx={{ cursor:'text' }}  /> adjacent to teh cyber asset. You must click the save icon <SaveIcon sx={{ cursor:'text' }}/> after editing a cyber asset. </Typography>
                                <Typography>6. After adding your inventory for this site assessment, press the <Button sx={{ cursor:'text', mr: '0.5rem', ml: '0.5rem', mb: '0.1rem', fontSize: '10pt', p: '0.2rem' }} variant="contained" >Complete Cyber Inventory</Button> button </Typography>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
            </Grid>
            
        </Grid>
        <WebTool handleAddCyberAssetRefresh={handleAddCyberAssetRefresh} setActiveStep={setActiveStep}/>
        <FullFeaturedCrudGrid refresh={refresh} />
    </Box>
  )
}

export default CyberSCADAAssets