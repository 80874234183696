import { Outlet } from "react-router-dom"

const Layout = () => {
    return (
        <main className="App">
            <Outlet />
        </main>
    )
}

export default Layout;


// from mern dashboard tutorial - integrate into the above Layout component. 
{/*}
import React, { useState, useEffect } from 'react';
import {Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import NavbarDashboard from "./navbardashboard";
import Navbar from 'scenes/navbar';
import Sidebar from "components/Sidebar";
import { useGetUserQuery } from 'state/api';

const Layout = () => {

  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const userId = useSelector((state) => state.userId);
  // const { data } = useGetUserQuery(userId);
  // console.log("🚀 ~ file: index.jsx:17 ~ Layout ~ data:", data);
  const [userData, setUserData] = useState(null);
  const token = useSelector((state) => state.token);
  
  const getUser = async () => {
    const response = await fetch(`http://localhost:3001/users/${userId}`,
    {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    // console.log("🚀 ~ file: index.jsx:17 ~ Layout ~ data:", data);
    setUserData(data);
};
useEffect(() => {
  getUser();
}, []);  // eslint-disable-line react-hooks/exhaustive-deps
if (!userData) {
  return null;
}
  

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={userData || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        />
      <Box flexGrow={1}>
      <NavbarDashboard
        user={userData || {}}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        />
        {/* <Sidebar 
          isNonMobile={isNonMobile}
          drawerWidth="250px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          /> */}
        {/* <NavbarDashboard 
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
        /> */}
        {/* <Navbar 
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        /> 
        <Outlet />
      </Box>
    </Box>
  );
  
};

export default Layout;

*/}