import { React, useState, useEffect, useRef } from 'react';
import { useParams,useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
import FERCHydroCyberSCADASecurityChecklist from '../../Results/FERCHydroCyberSCADASecurityChecklist';
import FullFeaturedCrudGridReusable from '../../../../components/DataGridReusable';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from '../../../../components/DataGridCustomToolbar';


const QuestionnaireResults = (refresh) => {

  // *************** Questionnaire specific Variables***************//

  const [form3QuestionsIndex, setForm3QuestionsIndex] = useState(0);

  // Inventory 
  const [remoteAccess, setRemoteAccess] = useState("");
  const [remoteAccessConnected, setRemoteAccessConnected] = useState("");
  const [remoteAccessComments, setRemoteAccessComments] = useState("");

  // Overview.jsx
  const [scopingQuestions, setScopingQuestions] = useState(false);
  const [hasInventory, setHasInventory] = useState("");
  const [hasFERCDesignation, setHasFERCDesignation] = useState("");
  const [hasOtherFERCProjects, setHasOtherFERCProjects] = useState("");
  const [hasCybersecurityPlan, setHasCybersecurityPlan] = useState("");
  const [cybersecurityPlanName, setCybersecurityPlanName] = useState('');
  const [nERCCIPApplies, setNERCCIPApplies] = useState("");
  const [nERCCIPFindingsAddressed, setNERCCIPFindingsAddressed] = useState("");
  const [hasBusinessCyberAssets , setHasBusinessCyberAssets] = useState("");
  const [missionEssentialAssetsIdentified , setMissionEssentialAssetsIdentified] = useState("");
  const [userTraining, setUserTraining] = useState("");

  // Governance.jsx
  const hasPolicy = false;
  const [hasAccessControlPolicy, setHasAccessControlPolicy] = useState(false);
  const [hasAssetManagementPolicy, setHasAssetManagementPolicy] = useState(false);
  const [hasConfigurationManagementPolicy, setHasConfigurationManagementPolicy] = useState(false);
  const [hasSupplyChainRiskManagementPolicy, setHasSupplyChainRiskManagementPolicy] = useState(false);
  const [hasRemovableMediaPolicy, setHasRemovableMediaPolicy] = useState(false);
  const [doesAnnualPolicyReview, setDoesAnnualPolicyReview] = useState("");
  const [hasCybersecurityTeam, setHasCybersecurityTeam] = useState("");
  const [hasCyberRolesResponsibilities, setHasCyberRolesResponsibilities] = useState("");
  const [hasInternalCommunicationPlan, setHasInternalCommunicationPlan] = useState("");
  const [doesExternalCommunicationPlan, setDoesExternalCommunicationPlan] = useState("");
  const [doesResourceAssessment, setDoesResourceAssessment] = useState("");
  const [doesIndependentResourceAssessment, setDoesIndependentResourceAssessment] = useState("");
  const [hasPolicyComments, setHasPolicyComments] = useState("");
  const [doesAnnualPolicyReviewComments, setDoesAnnualPolicyReviewComments] = useState("");
  const [hasCybersecurityTeamComments, setHasCybersecurityTeamComments] = useState("");
  const [hasCyberRolesResponsibilitiesComments, setHasCyberRolesResponsibilitiesComments] = useState("");
  const [hasInternalCommunicationPlanComments, setHasInternalCommunicationPlanComments] = useState("");
  const [doesExternalCommunicationPlanComments, setDoesExternalCommunicationPlanComments] = useState("");
  const [doesResourceAssessmentComments, setDoesResourceAssessmentComments] = useState("");
  const [doesIndependentResourceAssessmentComments, setDoesIndependentResourceAssessmentComments] = useState("");


  // AccessControl.jsx
  const [hasLogicalAccessControl, setHasLogicalAccessControl] = useState("");
  const [has3rdPartyRemoteAccess, setHas3rdPartyRemoteAccess] = useState("");
  const [has3rdPartyRemoteAccessDescription, setHas3rdPartyRemoteAccessDescription] = useState("");
  const [has3rdPartyRemoteAccessList, setHas3rdPartyRemoteAccessList] = useState("");
  const [doesRemoteConnectionWeeklyReview, setDoesRemoteConnectionWeeklyReview] = useState("");
  const [knowsUnauthorizedAccessAttempts, setKnowsUnauthorizedAccessAttempts] = useState("");
  const [hasLogicalAccessControlComments, setHasLogicalAccessControlComments] = useState("");
  const [has3rdPartyRemoteAccessComments, setHas3rdPartyRemoteAccessComments] = useState("");
  const [has3rdPartyRemoteAccessDescriptionComments, setHas3rdPartyRemoteAccessDescriptionComments] = useState("");
  const [has3rdPartyRemoteAccessListComments, setHas3rdPartyRemoteAccessListComments] = useState("");
  const [doesRemoteConnectionWeeklyReviewComments, setDoesRemoteConnectionWeeklyReviewComments] = useState("");
  const [knowsUnauthorizedAccessAttemptsComments, setKnowsUnauthorizedAccessAttemptsComments] = useState("");


  //AssetConfigurationManagement.jsx
  const [knowsAssetCriticality, setKnowsAssetCriticality] = useState("");
  const [knowsAssetCriticalityComments, setKnowsAssetCriticalityComments] = useState("");
  const [doesRemoveConfigs, setDoesRemoveConfigs] = useState("");
  const [doesRemoveConfigsComments, setDoesRemoveConfigsComments] = useState("");
  const [hasConfigurationManagement, setHasConfigurationManagement] = useState("");
  const [hasConfigurationManagementComments, setHasConfigurationManagementComments] = useState("");

  //IntrusionDetectionResponseQuestions.jsx
  const [hasIntrusionDetectionResponsePolicy, setHasIntrusionDetectionResponsePolicy] = useState("");
  const [doesNotifyLawEnforcement, setDoesNotifyLawEnforcement] = useState("");
  const [doesExerciseResponsePlans, setDoesExerciseResponsePlans] = useState("");
  const [includesExerciseWithLawEnforcement, setIncludesExerciseWithLawEnforcement] = useState("");
  const [doesPostEventAnalysis, setDoesPostEventAnalysis] = useState("");
  const [includesAnalysisWithLawEnforcement, setIncludesAnalysisWithLawEnforcement] = useState("");
  const [incorporatesIDRLessonedLearned, setIncorporatesIDRLessonedLearned] = useState("");
  const [hasDedicatedSecMonTeam, setHasDedicatedSecMonTeam] = useState("");
  const [doesSecurityLogReviewFrequency, setDoesSecurityLogReviewFrequency] = useState("");
  const [hasIDRControlsImplemented, setHasIDRControlsImplemented] = useState("");
  const [doesWirelessAccessPointMonitoring, setDoesWirelessAccessPointMonitoring] = useState("");
  const [hasIntrusionDetectionResponsePolicyComments, setHasIntrusionDetectionResponsePolicyComments] = useState("");
  const [doesNotifyLawEnforcementComments, setDoesNotifyLawEnforcementComments] = useState("");
  const [doesExerciseResponsePlansComments, setDoesExerciseResponsePlansComments] = useState("");
  const [includesExerciseWithLawEnforcementComments, setIncludesExerciseWithLawEnforcementComments] = useState("");
  const [doesPostEventAnalysisComments, setDoesPostEventAnalysisComments] = useState("");
  const [includesAnalysisWithLawEnforcementComments, setIncludesAnalysisWithLawEnforcementComments] = useState("");
  const [incorporatesIDRLessonedLearnedComments, setIncorporatesIDRLessonedLearnedComments] = useState("");
  const [hasIDRControlsImplementedComments, setHasIDRControlsImplementedComments] = useState("");
  const [hasDedicatedSecMonTeamComments, setHasDedicatedSecMonTeamComments] = useState("");
  const [doesSecurityLogReviewFrequencyComments, setDoesSecurityLogReviewFrequencyComments] = useState("");
  const [doesWirelessAccessPointMonitoringComments, setDoesWirelessAccessPointMonitoringComments] = useState("");
  const [doesLogging, setDoesLogging] = useState(false);
  const [doesMonitoring, setDoesMonitoring] = useState(false);
  const [doesDetection, setDoesDetection] = useState(false);
  const [doesAnomalyIsolation, setDoesAnomalyIsolation] = useState(false);

  //SecurityArchitectureQuestions.jsx
  const [hasSecureDesignPrinciples, setHasSecureDesignPrinciples] = useState("");
  const [doesApproveNetworkConnections, setDoesApproveNetworkConnections] = useState("");
  const [hasSCADAWireless, setHasSCADAWireless] = useState("");
  const [hasSegmentedITOTNetworks, setHasSegmentedITOTNetworks] = useState("");
  const [hasSegmentedCriticalAssets, setHasSegmentedCriticalAssets] = useState("");
  const [doesDataCommNetwork, setDoesDataCommNetwork] = useState("");
  const [hasSecureDesignPrinciplesComments, setHasSecureDesignPrinciplesComments] = useState("");
  const [doesApproveNetworkConnectionsComments, setDoesApproveNetworkConnectionsComments] = useState("");
  const [hasSCADAWirelessComments, setHasSCADAWirelessComments] = useState("");
  const [hasSegmentedITOTNetworksComments, setHasSegmentedITOTNetworksComments] = useState("");
  const [hasSegmentedCriticalAssetsComments, setHasSegmentedCriticalAssetsComments] = useState("");
  const [doesDataCommNetworkComments, setDoesDataCommNetworkComments] = useState("");
  const [hasWirelessAccessControl,	setHasWirelessAccessControl	] = useState("");
  const [hasWirelessAccessControlComments,	setHasWirelessAccessControlComments] = useState("");

  //RiskManagementQuestions.jsx
  const [hasRiskManagementStrategy, setHasRiskManagementStrategy] = useState("");
  const [doesPeriodicRiskAssessments, setDoesPeriodicRiskAssessments] = useState("");
  const [doesAssessWirelessUse, setDoesAssessWirelessUse] = useState("");
  const [hasOrgWideRiskParticipation, setHasOrgWideRiskParticipation] = useState("");
  const [includesSupplyChainRiskManagement, setIncludesSupplyChainRiskManagement] = useState("");
  const [addressesInsiderThreat, setAddressesInsiderThreat] = useState("");
  const [doesThreatAssessment, setDoesThreatAssessment] = useState("");
  const [doesIndependentThreatAssessment, setDoesIndependentThreatAssessment] = useState("");
  const [hasRiskManagementStrategyComments, setHasRiskManagementStrategyComments] = useState("");
  const [doesPeriodicRiskAssessmentsComments, setDoesPeriodicRiskAssessmentsComments] = useState("");
  const [doesAssessWirelessUseComments, setDoesAssessWirelessUseComments] = useState("");
  const [hasOrgWideRiskParticipationComments, setHasOrgWideRiskParticipationComments] = useState("");
  const [includesSupplyChainRiskManagementComments, setIncludesSupplyChainRiskManagementComments] = useState("");
  const [addressesInsiderThreatComments, setAddressesInsiderThreatComments] = useState("");
  const [doesThreatAssessmentComments, setDoesThreatAssessmentComments] = useState("");
  const [doesIndependentThreatAssessmentComments, setDoesIndependentThreatAssessmentComments] = useState("");
  const [hasSituationAwareness, setHasSituationAwareness] = useState("");
  const [hasInformationSharing, setHasInformationSharing] = useState("");
  const [hasSituationAwarenessComments, setHasSituationAwarenessComments] = useState("");
  const [hasInformationSharingComments, setHasInformationSharingComments] = useState("");

  //SystemRestorationRecoveryQuestions.jsx
  const [hasRestorationRecoveryPlan, setHasRestorationRecoveryPlan] = useState("");
  const [doesTestRestorationRecoveryPlan, setDoesTestRestorationRecoveryPlan] = useState("");
  const [hasBackupSite, setHasBackupSite] = useState("");
  const [hasRestorationRecoveryPlanComments, setHasRestorationRecoveryPlanComments] = useState("");
  const [doesTestRestorationRecoveryPlanComments, setDoesTestRestorationRecoveryPlanComments] = useState("");
  const [hasBackupSiteComments, setHasBackupSiteComments] = useState("");
  
  //CyberTrainingQuestions
  const [hasUserTrainingRequirement, setHasUserTrainingRequirement] = useState("");
  const [hasAnnualUserTraining, setHasAnnualUserTraining] = useState("");
  const [hasPrivilegedUserTraining, setHasPrivilegedUserTraining] = useState("");
  const [hasSecurityRoleSpecificTraining, setHasSecurityRoleSpecificTraining] = useState("");
  const [hasUserAgreement, setHasUserAgreement] = useState("");
  const [hasUserTrainingRequirementComments, setHasUserTrainingRequirementComments] = useState("");
  const [hasAnnualUserTrainingComments, setHasAnnualUserTrainingComments] = useState("");
  const [hasPrivilegedUserTrainingComments, setHasPrivilegedUserTrainingComments] = useState("");
  const [hasSecurityRoleSpecificTrainingComments, setHasSecurityRoleSpecificTrainingComments] = useState("");
  const [hasUserAgreementComments, setHasUserAgreementComments] = useState("");

  
  const [hasAssetManagementPolicyComments, setHasAssetManagementPolicyComments] = useState("");
  const [hasConfigurationManagementPolicyComments, setHasConfigurationManagementPolicyComments] = useState("");
  const [hasSupplyChainRiskManagementPolicyComments, setHasSupplyChainRiskManagementPolicyComments] = useState("");
  const [doesSecurityLogReview, setDoesSecurityLogReview] = useState("");
  const [doesSecurityLogReviewComments, setDoesSecurityLogReviewComments] = useState("");

  
  // *************** Questionnaire specific Variables***************//


  // Inventory
  const [allCyberAssetData, setAllCyberAssetData] = useState();

  // General
  const [generalMeasure, setGeneralMeasure] = useState("");
  const [generalMeasureComments, setGeneralMeasureComments] = useState("");

  // InfoSecurityCoordinationResponsibilities
  const [infoSecurityCoordinationResponsibilities, setInfoSecurityCoordinationResponsibilities] = useState("");
  const [infoSecurityCoordinationResponsibilitiesComments, setInfoSecurityCoordinationResponsibilitiesComments] = useState("");


  // System Lifecycle
  const [systemLifecycle, setSystemLifecycle] = useState("");
  const [systemLifecycleComments, setSystemLifecycleComments] = useState("");

  // System Restoration and Recovery
  const [systemRestorationRecovery, setSystemRestorationRecovery] = useState("");
  const [systemRestorationRecoveryComments, setSystemRestorationRecoveryComments] = useState("");

  // Intrusion Detection and Response
  const [intrustionDetectionResponse, setIntrustionDetectionResponse] = useState("");
  const [intrustionDetectionResponseComments, setIntrustionDetectionResponseComments] = useState("");

  // Training
  const [training, setTraining] = useState("");
  const [trainingComments, setTrainingComments] = useState("");

  // Access Control and Functional Segregation
  const [accessControlFunctionalSegregation, setAccessControlFunctionalSegregation] = useState("");
  const [accessControlFunctionalSegregationComments, setAccessControlFunctionalSegregationComments] = useState("");

  // Access Control
  const [accessControl, setAccessControl] = useState("");
  const [accessControlComments, setAccessControlComments] = useState("");

  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();

  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    
    //Overview.jsx
    setHasInventory(data?.hasInventory?.toString());
    setHasFERCDesignation(data?.hasFERCDesignation?.toString());
    setHasOtherFERCProjects(data?.hasFERCDesignation?.toString());
    setHasCybersecurityPlan(data?.hasCybersecurityPlan?.toString());
    setCybersecurityPlanName(data?.cybersecurityPlanName?.toString());
    setNERCCIPApplies(data?.nERCCIPApplies?.toString());
    setNERCCIPFindingsAddressed(data?.nERCCIPFindingsAddressed?.toString());
    setHasBusinessCyberAssets(data?.hasBusinessCyberAssets?.toString());
    setMissionEssentialAssetsIdentified(data?.missionEssentialAssetsIdentified?.toString());

    //Governance.jsx 
    setHasAccessControlPolicy(data?.hasAccessControlPolicy?.toString());
    setHasAssetManagementPolicy(data?.hasAssetManagementPolicy?.toString());
    setHasConfigurationManagementPolicy(data?.hasConfigurationManagementPolicy?.toString());
    setHasSupplyChainRiskManagementPolicy(data?.hasSupplyChainRiskManagementPolicy?.toString());
    setHasRemovableMediaPolicy(data?.hasRemovableMediaPolicy?.toString());
    setDoesAnnualPolicyReview(data?.doesAnnualPolicyReview?.toString());
    setHasCybersecurityTeam(data?.hasCybersecurityTeam?.toString());
    setHasCyberRolesResponsibilities(data?.hasCyberRolesResponsibilities?.toString());
    setHasInternalCommunicationPlan(data?.hasInternalCommunicationPlan?.toString());
    setDoesExternalCommunicationPlan(data?.doesExternalCommunicationPlan?.toString());
    setDoesResourceAssessment(data?.doesResourceAssessment?.toString());
    setDoesIndependentResourceAssessment(data?.doesIndependentResourceAssessment?.toString());
    setDoesAnnualPolicyReviewComments(data?.doesAnnualPolicyReviewComments?.toString());

    //AccessControl.jsx
    setHasLogicalAccessControl(data?.hasLogicalAccessControl?.toString());
    setHas3rdPartyRemoteAccess(data?.has3rdPartyRemoteAccess?.toString());
    setHas3rdPartyRemoteAccessList(data?.has3rdPartyRemoteAccessList?.toString());
    setDoesRemoteConnectionWeeklyReview(data?.doesRemoteConnectionWeeklyReview?.toString());
    setKnowsUnauthorizedAccessAttempts(data?.knowsUnauthorizedAccessAttempts?.toString());
    setHasLogicalAccessControlComments(data?.hasLogicalAccessControlComments?.toString());
    setHas3rdPartyRemoteAccessComments(data?.has3rdPartyRemoteAccessComments?.toString());
    setHas3rdPartyRemoteAccessDescriptionComments(data?.has3rdPartyRemoteAccessDescriptionComments?.toString());
    setHas3rdPartyRemoteAccessListComments(data?.has3rdPartyRemoteAccessListComments?.toString());
    setDoesRemoteConnectionWeeklyReviewComments(data?.doesRemoteConnectionWeeklyReviewComments?.toString());
    setKnowsUnauthorizedAccessAttemptsComments(data?.knowsUnauthorizedAccessAttemptsComment?.toString());

    //AssetConfigurationManagement.jsx
    setKnowsAssetCriticality(data?.knowsAssetCriticality?.toString());
    setDoesRemoveConfigs(data?.doesRemoveConfigs?.toString());
    setHasConfigurationManagement(data?.hasConfigurationManagement?.toString());
    setKnowsAssetCriticalityComments(data?.knowsAssetCriticalityComments?.toString());
    setDoesRemoveConfigsComments(data?.doesRemoveConfigsComments?.toString());
    setHasConfigurationManagementComments(data?.hasConfigurationManagementComments?.toString());

    //IntrusionDetectionResponseQuestions.jsx
    setHasIntrusionDetectionResponsePolicy(data?.hasIntrusionDetectionResponsePolicy?.toString());
    setDoesNotifyLawEnforcement(data?.doesNotifyLawEnforcement?.toString());
    setDoesExerciseResponsePlans(data?.doesExerciseResponsePlans?.toString());
    setIncludesExerciseWithLawEnforcement(data?.includesExerciseWithLawEnforcement?.toString());
    setDoesPostEventAnalysis(data?.doesPostEventAnalysis?.toString());
    setIncludesAnalysisWithLawEnforcement(data?.includesAnalysisWithLawEnforcement?.toString());
    setIncorporatesIDRLessonedLearned(data?.incorporatesIDRLessonedLearned?.toString());
    setHasIDRControlsImplemented(data?.hasIDRControlsImplemented?.toString());
    setHasDedicatedSecMonTeam(data?.hasDedicatedSecMonTeam?.toString());
    setDoesSecurityLogReviewFrequency(data?.doesSecurityLogReviewFrequency?.toString());
    setDoesWirelessAccessPointMonitoring(data?.doesWirelessAccessPointMonitoring?.toString());
    setHasIntrusionDetectionResponsePolicyComments(data?.hasIntrusionDetectionResponsePolicyComments?.toString());
    setDoesNotifyLawEnforcementComments(data?.doesNotifyLawEnforcementComments?.toString());
    setDoesExerciseResponsePlansComments(data?.doesExerciseResponsePlansComments?.toString());
    setIncludesExerciseWithLawEnforcementComments(data?.includesExerciseWithLawEnforcementComments?.toString());
    setDoesPostEventAnalysisComments(data?.doesPostEventAnalysisComments?.toString());
    setIncludesAnalysisWithLawEnforcementComments(data?.includesAnalysisWithLawEnforcementComments?.toString());
    setIncorporatesIDRLessonedLearnedComments(data?.incorporatesIDRLessonedLearnedComments?.toString());
    setHasIDRControlsImplementedComments(data?.hasIDRControlsImplementedComments?.toString());
    setHasDedicatedSecMonTeamComments(data?.hasDedicatedSecMonTeamComments?.toString());
    setDoesSecurityLogReviewFrequencyComments(data?.doesSecurityLogReviewFrequencyComments?.toString());
    setDoesWirelessAccessPointMonitoringComments(data?.doesWirelessAccessPointMonitoringComments?.toString());
    setDoesLogging(data?.doesLogging?.toString());
    setDoesMonitoring(data?.doesMonitoring?.toString());
    setDoesDetection(data?.doesDetection?.toString());
    setDoesAnomalyIsolation(data?.doesAnomalyIsolation?.toString());

    //SecurityArchitectureQuestions.jsx
    setHasSecureDesignPrinciples(data?.hasSecureDesignPrinciples?.toString());
    setDoesApproveNetworkConnections(data?.doesApproveNetworkConnections?.toString());
    setHasSCADAWireless(data?.hasSCADAWireless?.toString());
    setHasSegmentedITOTNetworks(data?.hasSegmentedITOTNetworks?.toString());
    setHasSegmentedCriticalAssets(data?.hasSegmentedCriticalAssets?.toString());
    setDoesDataCommNetwork(data?.doesDataCommNetwork?.toString());
    setHasSecureDesignPrinciplesComments(data?.hasSecureDesignPrinciplesComments?.toString());
    setDoesApproveNetworkConnectionsComments(data?.doesApproveNetworkConnectionsComments?.toString());
    setHasSCADAWirelessComments(data?.hasSCADAWirelessComments?.toString());
    setHasSegmentedITOTNetworksComments(data?.hasSegmentedITOTNetworksComments?.toString());
    setHasSegmentedCriticalAssetsComments(data?.hasSegmentedCriticalAssetsComments?.toString());
    setDoesDataCommNetworkComments(data?.doesDataCommNetworkComments?.toString());
    setHasWirelessAccessControl(data?.hasWirelessAccessControl?.toString());
    setHasWirelessAccessControlComments(data?.hasWirelessAccessControlComments?.toString());

    //RiskManagementQuestions.jsx
    setHasRiskManagementStrategy(data?.hasRiskManagementStrategy?.toString());
    setDoesPeriodicRiskAssessments(data?.doesPeriodicRiskAssessments?.toString());
    setDoesAssessWirelessUse(data?.doesAssessWirelessUse?.toString());
    setHasOrgWideRiskParticipation(data?.hasOrgWideRiskParticipation?.toString());
    setIncludesSupplyChainRiskManagement(data?.includesSupplyChainRiskManagement?.toString());
    setAddressesInsiderThreat(data?.addressesInsiderThreat?.toString());
    setDoesThreatAssessment(data?.doesThreatAssessment?.toString());
    setDoesIndependentThreatAssessment(data?.doesIndependentThreatAssessment?.toString());
    setHasSituationAwareness(data?.hasSituationAwareness?.toString());
    setHasInformationSharing(data?.hasInformationSharing?.toString());
    setHasRiskManagementStrategyComments(data?.hasRiskManagementStrategyComments?.toString());
    setDoesPeriodicRiskAssessmentsComments(data?.doesPeriodicRiskAssessmentsComments?.toString());
    setDoesAssessWirelessUseComments(data?.doesAssessWirelessUseComments?.toString());
    setHasOrgWideRiskParticipationComments(data?.hasOrgWideRiskParticipationComments?.toString());
    setIncludesSupplyChainRiskManagementComments(data?.includesSupplyChainRiskManagementComments?.toString());
    setAddressesInsiderThreatComments(data?.addressesInsiderThreatComments?.toString());
    setDoesThreatAssessmentComments(data?.doesThreatAssessmentComments?.toString());
    setDoesIndependentThreatAssessmentComments(data?.doesIndependentThreatAssessmentComments?.toString());
    setHasSituationAwarenessComments(data?.hasSituationAwarenessComments?.toString());
    setHasInformationSharingComments(data?.hasInformationSharingComments?.toString());

    //SystemRestorationRecoveryQuestions.jsx
    setHasRestorationRecoveryPlan(data?.hasRestorationRecoveryPlan?.toString());
    setDoesTestRestorationRecoveryPlan(data?.doesTestRestorationRecoveryPlan?.toString());
    setHasBackupSite(data?.hasBackupSite?.toString());
    setHasRestorationRecoveryPlanComments(data?.hasRestorationRecoveryPlanComments?.toString());
    setDoesTestRestorationRecoveryPlanComments(data?.doesTestRestorationRecoveryPlanComments?.toString());
    setHasBackupSiteComments(data?.hasBackupSiteComments?.toString());
    
    //CyberTrainingQuestions
    setHasUserTrainingRequirementComments(data?.hasUserTrainingRequirementComments?.toString());
    setHasAnnualUserTrainingComments(data?.hasAnnualUserTrainingComments?.toString());
    setHasPrivilegedUserTrainingComments(data?.hasPrivilegedUserTrainingComments?.toString());
    setHasSecurityRoleSpecificTrainingComments(data?.hasSecurityRoleSpecificTrainingComments?.toString());
    setHasUserAgreementComments(data?.hasUserAgreementComments?.toString());
    setHasUserTrainingRequirement(data?.hasUserTrainingRequirement?.toString());
    setHasAnnualUserTraining(data?.hasAnnualUserTraining?.toString());
    setHasPrivilegedUserTraining(data?.hasPrivilegedUserTraining?.toString());
    setHasSecurityRoleSpecificTraining(data?.hasSecurityRoleSpecificTraining?.toString());
    setHasUserAgreement(data?.hasUserAgreement?.toString());

    } catch (err) {
      console.log('There error is in the QuestionnaireResults.jsx page');
      // navigate('/login', { state: { from: location }, replace: true });
  }
  
    if (assessmentData === null) {
        return;
    }
  };

  //Cybersecurity Measures Calculation
  const getGeneral = () => {
    setGeneralMeasureComments(hasLogicalAccessControlComments + knowsAssetCriticalityComments + doesAnnualPolicyReviewComments + doesApproveNetworkConnectionsComments + doesAssessWirelessUseComments);
    //  setGeneralMeasureComments(knowsAssetCriticalityComments + doesAnnualPolicyReviewComments + doesApproveNetworkConnectionsComments);
    if (hasLogicalAccessControl === "true" && knowsAssetCriticality === "true" && doesAnnualPolicyReview === "true" && doesApproveNetworkConnections === "true" && doesAssessWirelessUse === "true") {
      setGeneralMeasure('Yes');
    }
    else if (hasLogicalAccessControl === "false" && knowsAssetCriticality === "false" && doesAnnualPolicyReview === "false" && doesApproveNetworkConnections === "false" && doesAssessWirelessUse === "false") {
      setGeneralMeasure('No');
    }
    else {
      setGeneralMeasure('No');
    }
  };

  const getInfoSecurityCoordinationResponsibilities = () => {
    setInfoSecurityCoordinationResponsibilitiesComments(hasCybersecurityTeamComments + hasCyberRolesResponsibilitiesComments + hasInternalCommunicationPlanComments + doesExternalCommunicationPlanComments + includesSupplyChainRiskManagementComments );
    if (hasCybersecurityTeam === "true" && hasCyberRolesResponsibilities === "true" && hasInternalCommunicationPlan === "true" && doesExternalCommunicationPlan === "true" && includesSupplyChainRiskManagement === "true") {
      setInfoSecurityCoordinationResponsibilities('Yes');
    }
    else if (hasCybersecurityTeam === "false" && hasCyberRolesResponsibilities === "false" && hasInternalCommunicationPlan === "false" && doesExternalCommunicationPlan === "false" && includesSupplyChainRiskManagement === "false") {
      setInfoSecurityCoordinationResponsibilities('No');
    }
    else {
      setInfoSecurityCoordinationResponsibilities('No');
    }
  };

  // NEED TO ADD MITIGATION QUESTION TO COMPLETE THIS. 
  const getSystemLifecycle = () => {
    setInfoSecurityCoordinationResponsibilitiesComments(doesRemoveConfigsComments + hasConfigurationManagementComments + hasSecureDesignPrinciplesComments);
    if (doesRemoveConfigs === "true" && hasConfigurationManagement === "true" && hasSecureDesignPrinciples === "true" ) {
      setSystemLifecycle('Yes');
    }
    else if (doesRemoveConfigs === "false" && hasConfigurationManagement === "false" && hasSecureDesignPrinciples === "false") {
      setSystemLifecycle('No');
    }
    else {
      setSystemLifecycle('No');
    }
  };

  const getSystemRestorationRecovery = () => {
    setSystemRestorationRecoveryComments(hasRestorationRecoveryPlanComments + doesTestRestorationRecoveryPlanComments + hasBackupSiteComments);
    if (hasRestorationRecoveryPlan === "true" && doesTestRestorationRecoveryPlan === "true") {
      setSystemRestorationRecovery('Yes');
    }
    else if (doesRemoveConfigs === "false" && hasConfigurationManagement === "false" && hasSecureDesignPrinciples === "false") {
      setSystemRestorationRecovery('No');
    }
    else {
      setSystemRestorationRecovery('No');
    }
  };

  // NEED TO ADD REPORTING QUESTION TO COMPLETE THIS. 
  const getIntrusionDetectionResponse = () => {
    setIntrustionDetectionResponseComments(hasIntrusionDetectionResponsePolicyComments + hasIDRControlsImplementedComments);
    if (hasIntrusionDetectionResponsePolicy === "true" && doesLogging === "true" && doesMonitoring && doesDetection && doesAnomalyIsolation) {
      setIntrustionDetectionResponse('Yes');
    }
    else if (hasIntrusionDetectionResponsePolicy === "false" && doesLogging === "false" && doesMonitoring === "false" && doesDetection === 'false' & doesAnomalyIsolation === 'false') {
      setIntrustionDetectionResponse('No');
    }
    else {
      setIntrustionDetectionResponse('No');
    }
  };

  //NEED TO ADJUST THIS FOR TRAINING.
  const getTraining = () => {
    setTrainingComments(hasUserTrainingRequirementComments + hasAnnualUserTrainingComments + hasSecurityRoleSpecificTrainingComments);
    if (hasUserTrainingRequirement === "true" && hasAnnualUserTraining === "true" && hasSecurityRoleSpecificTraining === "true") {
      setTraining('Yes');
    }
    else if (hasUserTrainingRequirement === "false" && hasAnnualUserTraining === "false" && hasSecurityRoleSpecificTraining === "false") {
      setTraining('No');
    }
    else {
      setTraining('No');
    }
  };
 
  //NEED TO ADJUST THIS FOR ACCESS CONTROL & FUNCTIONAL SEGREGATION.
  const getAccessControlFunctionalSegregation = () => {
    setAccessControlFunctionalSegregationComments(hasPolicyComments + hasLogicalAccessControlComments + hasSegmentedITOTNetworksComments + hasUserAgreementComments);
    if (hasAccessControlPolicy === "true" && hasLogicalAccessControl === "true" && hasSegmentedITOTNetworks === "true" && hasUserAgreement === "true") {
      setAccessControlFunctionalSegregation('Yes');
    }
    else if (hasAccessControlPolicy === "false" && hasLogicalAccessControl === "false" && hasSegmentedITOTNetworks === "false" && hasUserAgreement === 'false') {
      setAccessControlFunctionalSegregation('No');
    }
    else {
      setAccessControlFunctionalSegregation('No');
    }
  };

  const getAccessControl = () => {
    setAccessControlComments(hasLogicalAccessControlComments + doesAssessWirelessUseComments);
    if (hasLogicalAccessControl === "true" && doesAssessWirelessUse === "true") {
      setAccessControl('Yes');
    }
    else if (hasLogicalAccessControl === "false" && doesAssessWirelessUse === "false") {
      setAccessControl('No');
    }
    else {
      setAccessControl('No');
    }
  };

  // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
const getCyberAssets = async () => {
  const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
      withCredentials: true
  });
  const data = await response.data;
  // console.log(data);
  setAllCyberAssetData(data);

  for (let index=0; index<data.length; index++) {
    if (data[index].remoteAccessConnected==='yes') {
      setRemoteAccessConnected('Yes');
      setRemoteAccessComments('See inventory answers for details.');
      break;
    } else if (data[index].remoteAccessConnected) {
      setRemoteAccessConnected('No');
    }
  }
  
  for (let index=0; index<data.length; index++) {
    if (data[index].remoteAccess==='yes') {
      console.log("REMOTE ACCESS IS WORKING YESSSSS")
      setRemoteAccess('Yes');
      setRemoteAccessComments('See inventory answers for details.');
      break;
    } else if (data[index].remoteAccess==='no') {
      setRemoteAccess('No');
    }
  }
  if (allCyberAssetData === null) {
      return;
  }
};

const question18aComments = (args) => {
  let temp = [];
  for (let arg in args) {
    if (args.arg === undefined) {
      continue
    } else if (args.arg === null) {
      continue
    } else {
      temp.push(args.arg);
    }
  }
  return temp;
}

  useEffect(() => {
    getAssessmentData();
    getGeneral();
    getInfoSecurityCoordinationResponsibilities();
    getSystemLifecycle();
    getSystemRestorationRecovery();
    getIntrusionDetectionResponse();
    getTraining();
    getAccessControlFunctionalSegregation();
    getAccessControl();
    getCyberAssets();
  }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }

  const mutateAnswer = (args) => {
    if (args != null) {
      if (args === 'true') {
        return 'Yes'
      }
      else return 'No'
    }
    else return 'Not answered';
  }

  // const FERCHydroCyberSCADASecurityChecklistForm3 = [
    const rows = [
    {
      id: '1',
      question: 'Does the facility/project utilize automated or remote (off-site) control of data acquisition, such as critical instrumentation or operation data?',
      answer: remoteAccess,
      comments: remoteAccessComments
    },
    {
      id: '2',
      question: 'Does the facility/project utilize automated or remote control of power generation data or power generation controls?',
      answer: remoteAccess,
      comments: remoteAccessComments
    },
    {
      id: '3',
      question: 'Does the facility/project utilize automated or remote control of water management data or direct control of water retention features?',
      answer: remoteAccess,
      comments: remoteAccessComments
    },
    {
      id: '4',
      question: 'Is there an interconnection of computer Systems from/to this facility/project to other dam(s)?',
      answer: remoteAccessConnected,
      comments: "None"
    },
    {
      id: '5',
      question: 'Are other FERC regulated projects controlled by this facility?',
      answer: mutateAnswer(hasOtherFERCProjects),
      comments: ""
    },
    {
      id: '6',
      question: 'Are physical protection measures in place for the control room/facility?',
      answer: "NOT ANSWERED YET.",
      comments: "NONE YET"
    },
    {
      id: '7a',
      question: 'Does the facility/project have a separate Cyber/Industrial Control System (e.g. SCADA) Security Plan?',
      answer: mutateAnswer(hasCybersecurityPlan),
      comments: cybersecurityPlanName
    },
    {
      id: '7b',
      question: 'If not, is Cyber/Industrial Control System (e.g. SCADA) Security included in another plan?',
      answer: mutateAnswer(hasCybersecurityPlan),
      comments: cybersecurityPlanName
    },
    {
      id: '8a',
      question: 'Does the project have any (hydroelectric) cyber assets which are subject to NERC-CIP Standards?',
      answer: mutateAnswer(nERCCIPApplies),
      comments: ""
    },
    {
      id: '8b',
      question: 'If a NERC-CIP compliance audit has been performed, have all identified deficiencies been addressed?',
      answer: mutateAnswer(nERCCIPFindingsAddressed),
      comments: ""
    },
    {
      id: '9a',
      question: 'Have all facility/project Cyber/ICS assets been inventoried/identified?',
      answer: mutateAnswer(hasInventory),
      comments: ""
    },
    {
      id: '9b',
      question: 'Have the assets been designated as critical, operational, or non-critical?',
      answer: mutateAnswer(hasFERCDesignation),
      comments: ""
    },
    {
      id: '10',
      question: 'Does the facility/project have Business Cyber Assets (non-industrial control systems which include corporate email, human resources, company website, etc.)?',
      answer: mutateAnswer(hasBusinessCyberAssets),
      comments: ""
    },
    {
      id: '11a',
      question: 'Are the Industrial Control System (e.g. SCADA) and non-Industrial Control System networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      answer: mutateAnswer(hasSegmentedITOTNetworks),
      comments: hasSegmentedCriticalAssetsComments
    },
    {
      id: '11b',
      question: 'Within the Industrial Control System environment (to include building services such as HVAC) are the networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      answer: mutateAnswer(doesDataCommNetwork),
      comments: doesDataCommNetworkComments,
    },
    {
      id: '12a',
      question: 'Do any vendors or 3rd parties have remote access to your network?',
      answer: mutateAnswer(has3rdPartyRemoteAccess),
      comments: has3rdPartyRemoteAccessComments
    },
    {
      id: '12b',
      question: 'If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations?',
      answer: mutateAnswer(doesRemoteConnectionWeeklyReview),
      comments: doesRemoteConnectionWeeklyReviewComments
    },
    {
      id: '12c',
      question: 'If yes, is activity logged and reviewed at least weekly?',
      answer: mutateAnswer(doesRemoteConnectionWeeklyReview),
      comments: doesRemoteConnectionWeeklyReviewComments
    },
    {
      id: '13a',
      question: 'Does the facility/project utilize wireless in the Cyber/SCADA system?',
      answer: mutateAnswer(hasSCADAWireless),
      comments: hasSCADAWirelessComments
    },
    {
      id: '13b',
      question: 'If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations? ',
      answer: mutateAnswer(hasWirelessAccessControl),
      comments: hasWirelessAccessControlComments
    },
    {
      id: '14a',
      question: 'Are cyber security controls implemented within the ICS network that allow for logging, monitoring, detection, and isolation of an anomalous cyber event?',
      answer: mutateAnswer(hasIntrusionDetectionResponsePolicy),
      comments: hasIntrusionDetectionResponsePolicyComments,
    },

    {
      id: '14b',
      question: 'Is there a dedicated team to review the information?',
      answer: mutateAnswer(hasDedicatedSecMonTeam),
      comments: hasDedicatedSecMonTeamComments
    },
    {
      id: '14c',
      question: 'How often does the review occur?',
      answer: mutateAnswer(doesSecurityLogReviewFrequency),
      comments: doesSecurityLogReviewFrequencyComments,
    },{
      id: '15',
      question: 'Is a configuration and patch management program established for both ICS and non-ICS networks?',
      answer: mutateAnswer(hasConfigurationManagement),
      comments: hasConfigurationManagementComments,
    },
    {
      id: '16',
      question: 'Does a back-up site exist and are systems routinely backed-up for ICS and non-ICS networks?',
      answer: mutateAnswer(hasBackupSite),
      comments: hasBackupSiteComments,
    },
    {
      id: '17',
      question: 'Do you have a policy to address removable and portable media?',
      answer: mutateAnswer(hasRemovableMediaPolicy),
      comments: ""
    },
    {
      id: '18a',
      question: 'With respect to Tables 9.3a of the Security Guidance, are “General” baseline cyber security measures being implemented?',
      answer: generalMeasure,
      // comments: hasLogicalAccessControlComments + knowsAssetCriticalityComments + doesAnnualPolicyReviewComments + doesApproveNetworkConnectionsComments + doesAssessWirelessUseComments
      // comments: generalMeasureComments
    },
    {
      id: '18a1',
      question: 'Do you restrict logical access to control systems and networks through the use of passwords, secured communication gateways (jump boxes), access control lists, authenticators, and/or other secure access methods?',
      answer: mutateAnswer(hasLogicalAccessControl),
      comments: hasLogicalAccessControlComments
    },
    {
      id: '18a2',
      question: 'Do you review and reassess cyber asset criticality (non-critical, operational, critical) at least every 12 months?',
      answer: mutateAnswer(knowsAssetCriticality),
      comments: knowsAssetCriticalityComments
    },
    {
      id: '18a3',
      question: 'Do you review and update your cyber security policies and/or procedures annually?',
      answer: mutateAnswer(doesAnnualPolicyReview),
      comments: doesAnnualPolicyReviewComments
    },
    {
      id: '18a4',
      question: 'Do you monitor and review (at least every 18months) network connections, including remote and third-party connections?',
      answer: mutateAnswer(doesApproveNetworkConnections),
      comments: doesApproveNetworkConnectionsComments
    },
    {
      id: '18a5',
      question: 'Did you conduct a risk assessment before implementing wireless networking?',
      answer: mutateAnswer(doesAssessWirelessUse),
      comments: doesAssessWirelessUseComments
    },
    {
      id: '18b',
      question: 'Are “Information Security Coordination & Responsibilities” baseline cyber security measures being implemented?',
      answer: infoSecurityCoordinationResponsibilities,
      // comments: hasCybersecurityTeamComments + hasCyberRolesResponsibilitiesComments + hasInternalCommunicationPlanComments + doesExternalCommunicationPlanComments + includesSupplyChainRiskManagementComments
    },
    {
      id: '18b1',
      question: 'Do you have a dedicated cyber security team?',
      answer: mutateAnswer(hasCybersecurityTeam),
      comments: hasCybersecurityTeamComments
    },
    {
      id: '18b2',
      question: 'Have you defined cyber security roles and responsibilities?',
      answer: mutateAnswer(hasCyberRolesResponsibilities),
      comments: hasCyberRolesResponsibilitiesComments
    },
    {
      id: '18b3',
      question: 'Do you have established lines of communication among the cyber security team, operations, IT, and business groups?',
      answer: mutateAnswer(hasInternalCommunicationPlan),
      comments: hasInternalCommunicationPlanComments
    },
    {
      id: '18b4',
      question: 'Do your lines of communication include outsourcers, partners, and third-parties?',
      answer: mutateAnswer(doesExternalCommunicationPlan),
      comments: doesExternalCommunicationPlanComments
    },
    {
      id: '18b5',
      question: 'Does your risk management strategy address cybersecurity supply chain risks?',
      answer: mutateAnswer(includesSupplyChainRiskManagement),
      comments: includesSupplyChainRiskManagementComments
    },
    {
      id: '18c',
      question: 'Are “System Lifecycle” baseline cyber security measures being implemented',
      answer: systemLifecycle,
      // comments: doesRemoveConfigsComments + hasConfigurationManagementComments + hasSecureDesignPrinciplesComments
    },
    {
      id: '18c1',
      question: 'Are operational data/configurations removed from systems before they are decommissioned?',
      answer: mutateAnswer(doesRemoveConfigs),
      comments: doesRemoveConfigsComments
    },
    {
      id: '18c2',
      question: 'Is a configuration and patch management program established for both ICS and non-ICS networks?',
      answer: mutateAnswer(hasConfigurationManagement),
      comments: hasConfigurationManagementComments
    },
    {
      id: '18c3',
      question: 'Do you follow secure design principles (security architecture principles) when building a new system or modifying an existing system?',
      answer: mutateAnswer(hasSecureDesignPrinciples),
      comments: hasSecureDesignPrinciplesComments
    },
    {
      id: '18d',
      question: 'Are “System Restoration & Recovery” baseline cyber security measures being implemented?',
      answer: systemRestorationRecovery,
      // comments: hasRestorationRecoveryPlanComments + doesTestRestorationRecoveryPlanComments + hasBackupSiteComments
    },
    {
      id: '18d1',
      question: 'Do you have restoration and recovery of plan for control systems?',
      answer: mutateAnswer(hasRestorationRecoveryPlan),
      comments: hasRestorationRecoveryPlanComments
    },
    {
      id: '18d2',
      question: 'Do you review, update, and test the plan at least annually?',
      answer: mutateAnswer(doesTestRestorationRecoveryPlan),
      comments: doesTestRestorationRecoveryPlanComments
    },
    {
      id: '18d3',
      question: 'Does a back-up site exist and are systems routinely backed-up for ICS and non-ICS networks?',
      answer: mutateAnswer(hasBackupSite),
      comments: hasBackupSiteComments
    },
    {
      id: '18e',
      question: 'Are “Intrusion Detection & Response” baseline cyber security measures being implemented?',
      answer: mutateAnswer(intrustionDetectionResponse),
      // comments: hasIntrusionDetectionResponsePolicyComments + hasIDRControlsImplementedComments
    },
    {
      id: '18e1',
      question: 'Do you maintain plans, procedures, and technologies to detect, analyze, and respond to cybersecurity events (and their physical protection)?',
      answer: mutateAnswer(hasIntrusionDetectionResponsePolicy),
      comments: hasIntrusionDetectionResponsePolicyComments
    },
    {
      id: '18e2',
      question: 'Are cyber security controls implemented within the ICS network that allow for Logging?',
      answer: mutateAnswer(doesLogging),
      comments: hasIDRControlsImplementedComments
    },
    {
      id: '18e2i',
      question: 'Are cyber security controls implemented within the ICS network that allow for Monitoring?',
      answer: mutateAnswer(doesMonitoring),
      comments: ""
    },
    {
      id: '18e2ii',
      question: 'Are cyber security controls implemented within the ICS network that allow for Detection?',
      answer: mutateAnswer(doesDetection),
      comments: ""
    },
    {
      id: '18e2iii',
      question: 'Are cyber security controls implemented within the ICS network that allow for Isolation of Anomalies?',
      answer: mutateAnswer(doesAnomalyIsolation),
      comments: ""
    },
    {
      id: '18f',
      question: 'Are “Training” baseline cyber security measures being implemented?',
      answer: mutateAnswer(training),
      // comments: hasUserTrainingRequirementComments + hasAnnualUserTrainingComments + hasSecurityRoleSpecificTrainingComments
    },
    {
      id: '18f1',
      question: 'Are control system users provided information security awareness before they are allowed to access control systems?',
      answer: mutateAnswer(hasUserTrainingRequirement),
      comments: hasUserTrainingRequirementComments
    },
    {
      id: '18f2',
      question: 'Are control system users provided information security awareness training at least annually?',
      answer: mutateAnswer(hasAnnualUserTraining),
      comments: hasAnnualUserTrainingComments
    },
    {
      id: '18f3',
      question: 'Are users with significant control systems secuirty roles given advanced training specific to their role?',
      answer: mutateAnswer(hasSecurityRoleSpecificTraining),
      comments: hasSecurityRoleSpecificTrainingComments
    },
    {
      id: '18g',
      question: 'With respect to the tables in Section 9.3a, are “Access Control & Functional Segregation” baseline cyber security measures being implemented?',
      answer: mutateAnswer(accessControlFunctionalSegregation),
      comments: hasPolicyComments + hasLogicalAccessControlComments + hasSegmentedITOTNetworksComments + hasUserAgreementComments
    },
    {
      id: '18g1',
      question: 'Do you have an Access Control Policy',
      answer: mutateAnswer(hasAccessControlPolicy),
      comments: ""
    },
    {
      id: '18g1i',
      question: 'Do you have an Asset Management Policy',
      answer: mutateAnswer(hasAssetManagementPolicy),
      comments: ""
    },
    {
      id: '18g1ii',
      question: 'Do you have a Configuration Management Policy',
      answer: mutateAnswer(hasConfigurationManagementPolicy),
      comments: ""
    },
    {
      id: '18g1iii',
      question: 'Do you have a Supply Chain Risk Management Policy',
      answer: mutateAnswer(hasSupplyChainRiskManagementPolicy),
      comments: ""
    },
    {
      id: '18g1iv',
      question: 'Do you have a Removable Media Policy',
      answer: mutateAnswer(hasRemovableMediaPolicy),
      comments: ""
    },
    {
      id: '18g2',
      question: 'Do you restrict logical access to control systems and networks through the use of passwords, secured communication gateways (jump boxes), access control lists, authenticators, and/or other secure access methods?',
      answer: mutateAnswer(hasLogicalAccessControl),
      comments: hasLogicalAccessControlComments
    },
    {
      id: '18g3',
      question: 'Are the Industrial Control System (e.g. SCADA) and non-Industrial Control System networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      answer: mutateAnswer(hasSegmentedITOTNetworks),
      comments: hasSegmentedCriticalAssetsComments
    },
    {
      id: '18g4',
      question: 'Do users know to only use cyber assets for approved activities, such as through a signed User Agreement?',
      answer: mutateAnswer(hasUserAgreement),
      comments: hasUserAgreementComments
    },
    {
      id: '18h',
      question: 'With respect to Tables 9.3b of the Security Guidance, are “Access Control” enhanced cyber security measures being implemented?',
      answer: mutateAnswer(accessControl),
      // comments: hasLogicalAccessControlComments + doesAssessWirelessUseComments
    },
    {
      id: '18h1',
      question: 'Do you restrict logical access to control systems and networks through the use of passwords, secured communication gateways (jump boxes), access control lists, authenticators, and/or other secure access methods?',
      answer: mutateAnswer(hasLogicalAccessControl),
      comments: hasLogicalAccessControlComments
    },
    {
      id: '18h2',
      question: 'Did you conduct a risk assessment before implementing wireless networking?',
      answer: mutateAnswer(doesAssessWirelessUse),
      comments: doesAssessWirelessUseComments
    },
    {
      id: '18i',
      question: 'Are “Vulnerability Assessment” enhanced cyber security measures being implemented?',
      answer: mutateAnswer(doesPeriodicRiskAssessments),
      comments: doesPeriodicRiskAssessmentsComments
    },
    {
      id: '19a',
      question: 'Do you maintain an inventory of your technology systems, software, and assets?',
      answer: mutateAnswer(hasInventory),
      comments: "",
    },
    {
      id: '19b',
      question: 'Is operational data/configurations removed from systems before they are decommissioned?',
      answer: mutateAnswer(doesRemoveConfigs),
      comments: doesRemoveConfigsComments,
    },
    {
      id: '20',
      question: 'Have you identified the systems, assets, information, and processes that are essential to your organizational mission?',
      answer: mutateAnswer(missionEssentialAssetsIdentified),
      comments: ""
    },
    {
      id: '21',
      question: 'Do you have appropriate access control policies and procedures in place for all systems and assets with particular focus on those that are critical?',
      answer: mutateAnswer(hasAccessControlPolicy),
      comments: hasLogicalAccessControlComments
    },
    {
      id: '22',
      question: 'Are your critical systems and assets appropriately separated or secured from your non-critical systems and assets?',
      answer: mutateAnswer(hasSegmentedCriticalAssets),
      comments: hasSegmentedCriticalAssetsComments
    },
    {
      id: '23a',
      question: 'Do you assess the threats to your organization and the resources available for an appropriate defense?',
      answer: mutateAnswer(doesThreatAssessment),
      comments: doesThreatAssessmentComments
    },
    {
      id: '23b',
      question: 'Do you perform this assessment independently? ',
      answer: mutateAnswer(doesIndependentThreatAssessment),
      comments: doesIndependentThreatAssessmentComments
    },
    {
      id: '24a',
      question: 'Do you assess the resources available to govern and implement your security strategy?',
      answer: mutateAnswer(doesResourceAssessment),
      comments: doesResourceAssessmentComments
    },
    {
      id: '24b',
      question: 'Do you perform this assessment independently?',
      answer: mutateAnswer(doesIndependentResourceAssessment),
      comments: doesIndependentResourceAssessmentComments
    },
    {
      id: '25a',
      question: 'Do you maintain plans, procedures, and technologies to detect, analyze, and respond to cybersecurity events (and their physical protection)?',
      answer: mutateAnswer(hasIntrusionDetectionResponsePolicy),
      comments: hasIntrusionDetectionResponsePolicyComments
    },
    {
      id: '25b',
      question: 'Do these include notifying with law enforcement and government security agencies?',
      answer: mutateAnswer(doesNotifyLawEnforcement),
      comments: doesNotifyLawEnforcementComments
    },
    {
      id: '26a',
      question: 'Do you routinely exercise your cyber response plans and procedures?',
      answer: mutateAnswer(doesExerciseResponsePlans),
      comments: doesExerciseResponsePlansComments
    },
    {
      id: '26b',
      question: 'Does this include working with law enforcement and government security agencies?',
      answer: mutateAnswer(includesExerciseWithLawEnforcement),
      comments: includesExerciseWithLawEnforcementComments
    },
    {
      id: '27a',
      question: 'Do you perform post-event analysis?',
      answer: mutateAnswer(doesPostEventAnalysis),
      comments: doesPostEventAnalysisComments
    },
    {
      id: '27b',
      question: 'Does this include working with law enforcement and government security agencies?',
      answer: mutateAnswer(includesAnalysisWithLawEnforcement),
      comments: includesAnalysisWithLawEnforcementComments
    },
    {
      id: '28',
      question: 'Do you incorporate lessons learned into your policies, plans, and procedures?',
      answer: mutateAnswer(incorporatesIDRLessonedLearned),
      comments: incorporatesIDRLessonedLearnedComments
    },
    {
      id: '29',
      question: 'Do you have an enterprise-wide all-hazards risk management strategy?',
      answer: mutateAnswer(hasRiskManagementStrategy),
      comments: hasRiskManagementStrategyComments
    },
    {
      id: '30',
      question: 'Are your operations, cyber, and physical security teams engaged in your risk management strategy?',
      answer: mutateAnswer(hasOrgWideRiskParticipation),
      comments: hasOrgWideRiskParticipationComments
    },
    {
      id: '31',
      question: 'Do you periodically conduct risk assessments, including outsourced vulnerability assessments, and are the results reported to you?',
      answer: mutateAnswer(doesPeriodicRiskAssessments),
      comments: doesPeriodicRiskAssessmentsComments
    },
    {
      id: '32a',
      question: 'Does your risk management strategy address cybersecurity supply chain risks?',
      answer: mutateAnswer(includesSupplyChainRiskManagement),
      comments: includesSupplyChainRiskManagementComments
    },
    {
      id: '32b',
      question: 'Does your risk management strategy address insider threat risks?',
      answer: mutateAnswer(addressesInsiderThreat),
      comments: addressesInsiderThreatComments
    },
    {
      id: '33a',
      question: 'Do you maintain and integrate situational awareness of operations, cyber and physical threats?',
      answer: mutateAnswer(hasSituationAwareness),
      comments: hasSituationAwarenessComments
    },
    {
      id: '33b',
      question: 'Do you maintain informational sharing relationships with external entities (both government and commercial) to collect and provide cybersecurity and physical security information?',
      answer: mutateAnswer(hasInformationSharing),
      comments: hasInformationSharingComments,
    }
  ];

  const columns = [
    {
        field: "id",
        headerName: "#",
        flex: 0.25,
    },
    {
        field: "question",
        headerName: "Field Observations: (Provide detailed supplemental information to the right)",
        flex: 6,
    },
    {
        field: "answer",
        headerName: "Answer",
        flex: 0.5,
        // editable: true
    },
    {
        field: "comments",
        headerName: "Comments (Provide additional details – especially any “No” answers – here and separate sheets, if necessary. Indicate NA if not appropriate to site.)",
        flex: 3,
        // editable: true
    }
  ];


  return (
    <Grid container spacing={2} sx={{mb: '1rem'}}>
    <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
      <h2>FERC Hydro Cyber/SCADA Security Checklist - Form 3</h2>
      <Grid item xs={12} >
        <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid 
        getRowHeight={() => 'auto'}
        // rowHeight={100}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal"
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            // Forced to use important since overriding inline styles
            maxHeight: "168px !important"
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
            lineHeight: "normal",
          }, 
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.secondary.alt,
            color: theme.palette.secondary[100],
            borderBottom: "solid 2px black",
            borderTop: "solid 2px black"
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary[100],
            borderTop: "none"
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
        },
        }}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: DataGridCustomToolbar,
        }}
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 5,
        //     },
        //   },
        // }}
        // pageSizeOptions={[5]}
        // checkboxSelection
        disableRowSelectionOnClick
      />
      </Box>
      </Grid>

        {/* Question 1 */}
        {/* <Typography sx={{ pL:'1.rem' }}><b>    FACILITY Cyber/SCADA CONCERNS</b></Typography> */}
        {/* {FERCHydroCyberSCADASecurityChecklistForm3.map(({
          id,
          question,
          answer,
          comments
        }) => (
          <FERCHydroCyberSCADASecurityChecklist
            id={id}
            question={question}
            answer={answer}
            comments={comments}
            />
            ))
        } */}
        {/* <FullFeaturedCrudGridReusable /> */}
    </Box>
    </Grid>
  )
}

export default QuestionnaireResults