import React from 'react';
import { Position, NodeResizer } from 'reactflow';

const rx = 0;
const ry = 0;
const width = 100;
const height = 100;


const AttackVector = ({data, selected}) => {
  return (
    <>        
      <NodeResizer isVisible={selected} maxHeight={100} maxWidth={100} />
      <svg viewBox="0 0 100 100" className='orGateSVG' height={height} width={width} xmlns="http://www.w3.org/2000/svg">
          <polygon points="50,5 100,30 50,55 5,30" fill="#cc0000" stroke="black" strokeWidth={2} />
          <title>Attack Vector</title>
      </svg>
    </>
  )
};


export default AttackVector;

