import React, { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import {v4} from 'uuid';

export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  selected,
  ...props
}) {
  const { getNode, setNodes, addNodes, setEdges } = useReactFlow();

  const getId = () => {
    let id = v4();
    return (
      `${id}`
    )
  }
  const node = getNode(id);
  const duplicateNode = useCallback(() => {
    console.log('FROM CONTEXT MENU Component');
    console.log(selected);
    
    if ( node === undefined ) {
      if ( selected?.length > 1 ) {
        console.log(`selected length is: ${selected.length}` );
        for (let index=0; index < selected.length; index++) {
          let tempId = getId();
          let position = {
            x: selected[index]?.position.x + 50,
            y: selected[index]?.position.y + 50,
          };
          console.log(tempId);
          addNodes({
            ...selected[index],
            selected: false,
            dragging: false,
            id: tempId,
            position,
          });
        }
      }
    } else {
    
    const position = {
      x: node?.position.x + 50,
      y: node?.position.y + 50,
    };

    addNodes({
      ...node,
      selected: false,
      dragging: false,
      id: `${node?.id}-copy`,
      position,
    });
}}, [id, getNode, addNodes, setNodes]);

  const deleteNode = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
  }, [id, setNodes, setEdges]);

  
  return (
    <div
      style={{ top, left, right, bottom }}
      className="context-menu"
      {...props}
    >
      <p style={{ margin: '0.5em' }}>
        <small>node: {id}</small>
      </p>
      <button onClick={duplicateNode}>duplicate</button>
      <button onClick={deleteNode}>delete</button>
    </div>
  );
}