import FlexBetween from '../../components/FlexBetween';
import React from 'react';
import { useState, useRef } from "react";
import { AppBar, InputBase, Toolbar, Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton, Typography, Button, Select, Menu, MenuItem, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { Search, Message, DarkMode, LightMode, Notifications, Help, 
    Close, ChevronRightOutlined,
    SettingsOutlined, ArrowDropDownOutlined, 
    BuildCircle } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useDispatch } from "react-redux";
import { setMode } from "../../state";
import { useNavigate } from "react-router-dom";
import useLogout from '../../hooks/useLogout';
import WidgetWrapper from '../../components/WidgetWrapper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import apexpng from './apexpng.png';



const NavbarDashboard = ({ username, isSidebarOpen, setIsSidebarOpen }) => {

    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
    const dispatch = useDispatch();

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.alt;
    const primaryLight = theme.palette.primary.light;
    const primary = theme.palette.primary.main;
    const alt = theme.palette.background.alt;

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [organization, setOrganization] = useState('');
    const [validOrganization, setValidOrganization] = useState(false);
    const [organizationFocus, setOrganizationFocus] = useState(false);

    const [message, setMessage] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [openGetHelp, setOpenGetHelp] = useState(false);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
    const axiosPrivate = useAxiosPrivate();

    const userRef = useRef();
    const errRef = useRef();

    const navigate = useNavigate();
    
    const logout = useLogout();
    
    const signOut = async () => {
        await logout();
        navigate('/');
    }

    const goToProfilePage = async () => {
        navigate('/profile');
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    const handleNo = () => {
        setOpenGetHelp(!openGetHelp);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.post(`/helprequest/new`,
                JSON.stringify({ user, email, organization, message }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            setOpenGetHelp(!openGetHelp);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                errRef.current.focus();
        }
    } 
    };

    const buttonStyle = {
        'background-color': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    };


    return(
        <WidgetWrapper sx={{ padding:"0rem" }}>
        <Dialog
              open={openGetHelp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title"> </DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  <>
                  <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Request Help</h1>
                    <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? "span 4" : "span 8"},
                        }}
                    >
                        <label htmlFor="username" sx={{gridColumn: "span 4"}}>
                            Username:
                            <span className={validName ? "valid" : "hide"}>
                                <CheckCircleOutlineIcon />
                            </span>
                            <span className={validName || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span>
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                            />
                            <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                4 to 24 characters. <br />
                                Must begin with a letter. <br />
                                Letters, numbers, underscores, and hyphens are allowed.
                            </p>

                            <label htmlFor="email" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Email:
                            </label>
                            <input 
                                type="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />

                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Organization:
                            </label>
                            <input 
                                type="organization"
                                id="organization"
                                onChange={(e) => setOrganization(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />


                        
                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            How can we help?:
                            </label>
                            <input 
                                type="message"
                                id="message"
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />


                        <button style={buttonStyle} >Get Help</button>
                        </Box>
                    </form>
            
                </section>
                  </>
              </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleNo} autoFocus>
                      Close
                  </Button>
                  </DialogActions>
            </Dialog>
        <AppBar 
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
            }}
            >
            <Toolbar sx={{ justifyContent: "space-between" }} >
                {/* LEFT SIDE */}
                <FlexBetween>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {isSidebarOpen ? (
                                <Box />
                            ) : (
                                <ChevronRightOutlined />
                            )}
                    </IconButton>
                    {/* <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        border='solid 1px'
                        borderRadius="9px"
                        gap="3rem"
                        p="0.1rem 1.5rem"
                    >
                        <InputBase placeholder="Search..." />
                        <IconButton>
                        <Search />
                        </IconButton>
                        
                    </FlexBetween> */}
                    {/* <FlexBetween sx={{ m:'0', p:'0'}}>
                        <Box
                            component="img"
                            width="85px"
                            alt="APEX Image."
                            src={apexpng}
                            />
                    </FlexBetween> */}
                    
                    <FlexBetween sx={{ marginLeft:"2rem" }}>
                        
                        <Typography 
                        fontWeight="bold"
                        fontSize="clamp(1rem, 2rem, 2.25rem)"
                        color="primary"
                        onClick={() => navigate("/")} 
                        sx={{
                            "&:hover":  {
                                color: primaryLight,
                                cursor: "pointer",
                            },
                        }}
                        >
                            FERC Hydropower Security Program Tool
                        </Typography>
                   
                    </FlexBetween>
                    </FlexBetween>

                {/* RIGHT SIDE */}
                <FlexBetween gap="1.5rem">
                    <IconButton onClick={() => dispatch(setMode())}>
                        {theme.palette.mode === "dark" ? (
                        <DarkMode sx={{ fontSize: "25px" }} />
                        ) : (
                        <LightMode sx={{ fontSize: "25px" }} />
                        )}
                    </IconButton>
                    {/* <IconButton>
                        <SettingsOutlined sx={{ fontSize: "25px" }} />
                    </IconButton> */}
                    <IconButton onClick={()=> setOpenGetHelp(!openGetHelp)}>
                        <HelpCenterIcon sx={{ fontSize: "25px" }} /> 
                       
                    </IconButton>
                    <FlexBetween >
                        <Button 
                        onClick={handleClick} 
                        sx={{ 
                            display: "flex", 
                            justifyContent: "space-between", 
                            alignItems: "center", 
                            textTransform: "none", 
                            gap: "1rem" }}
                        >
                        <Box textAlign="left">
                            <Typography fontWeight="bold" fontSize="1.5rem" sx={{ color: theme.palette.secondary[100] }}>
                                {username}
                            </Typography>
                        </Box>
                        <ArrowDropDownOutlined 
                            sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
                        />
                        </Button>
                        <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            {/* <MenuItem onClick={goToProfilePage}>Profile</MenuItem> */}
                            <MenuItem onClick={signOut}>Log Out</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>
            </Toolbar>
        </AppBar>    
        </WidgetWrapper>
        

    );
};


export default NavbarDashboard;