import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Input, Typography, Divider, IconButton, useTheme, Grid, FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from "@mui/material";
import WidgetWrapper from '../../../../components/WidgetWrapper';
import CounterMeasure from './DefensiveNodes/CounterMeasures';
import AddCustomNode from './AddCustomNode';
import { SwatchesPicker } from 'react-color';
import useAuth from '../../../../hooks/useAuth.js';
import { jwtDecode } from "jwt-decode";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 } from 'uuid';
import zIndex from '@mui/material/styles/zIndex.js';

export default () => {

  const [newNodeName, setNewNodeName] = useState();
  const [newNodeColor, setNewNodeColor] = useState();
  const [open, setOpen] = useState(false);
  const [allUserDefinedCountermeasures, setAllUserDefinedCountermeasures] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;


  const { auth } = useAuth();
  const decoded = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined

    const roles = decoded?.UserInfo?.roles || []
    const userLoggedIn = decoded?.UserInfo.username || []
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId || {};
    const [cyberAssets, setCyberAssets] = useState([]);
    
    const { assessmentID } = useParams();


   // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
   const getCyberAssets = async () => {
    const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setCyberAssets(data);
    console.log(data);
    };


  const getUserDefinedCountermeasures = async () => {
    try {
      const response = await axiosPrivate.get(`/countermeasures/${userId}`, {
          withCredentials: true
      });
      const data = await response.data;
      setAllUserDefinedCountermeasures(data[0].countermeasuresObject);
    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    getUserDefinedCountermeasures();
    getCyberAssets();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  const updateUserDefinedCountermeasures = async (newNode) => {
    let countermeasureList = allUserDefinedCountermeasures.concat(newNode);
    try {
      const response = await axiosPrivate.patch(`/countermeasures/${userId}`,
      { "countermeasuresObject": countermeasureList },    
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    );
    const data = await response.data;
    // setAllUserDefinedCountermeasures(data[0].countermeasuresObject);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
  }

  const onDragStart = (event, {ListItem}, parentId) => {
    const nodeType = ListItem.nodeType;
    const title = ListItem.title;
    const color = ListItem.color
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('title', title);
    event.dataTransfer.setData('color', color);
    event.dataTransfer.effectAllowed = 'move';
  }

  const TextFontSize = '1em';
  const TopMargin = '0.25em';
  const TextPosition = 'auto'

  const defaultNodeList = [
    {
      id: 'n4',
      name: 'attackerObjective',
      nodeType: 'resizableObjective',
      className: 'attackerObjective',
      title: 'Attacker Objective',
      color: '#e5b8oB'
    },
    {
      id: 'n1',
      name: 'attackerstep', 
      nodeType: 'resizableNodeSelected',
      title: 'Attacker Step',
      color: "#000", 
    },
    {
      id: 'n2',
      name: 'orgate', 
      nodeType: 'orgate',
      title: 'Or Gate',
      color: "#e6e600", 
    },
    {
      id: 'n3',
      name: 'andgate', 
      nodeType: 'andgate',
      title: 'And Gate',
      color: "#ac3939", 
    },
    {
      id: 'n5',
      name: 'resizableattackvector',
      nodeType: 'resizableAttackVector',
      title: 'Attack Vector',
    },
    {
      id: 'n6',
      name: 'group',
      nodeType: 'group',
      title: 'Group',
    },
    {
      id: 'n7',
      name: 'input',
      nodeType: 'input',
      title: 'Input',
    },
  ];


  const handleNo = () => {
    setOpen(false);
  };

  const CreateDefensiveNode = () => {
    let id = v4();
    const newNode = {
      id: `cs${id}`,
      name: `${newNodeName}`,
      nodeType: 'countermeasure',
      title: `${newNodeName}`,
      color: `${newNodeColor}`, 
    }
    updateUserDefinedCountermeasures(newNode);
    setAllUserDefinedCountermeasures((nds) => nds.concat(newNode));
  }
  
  const handleYes = () => {
    CreateDefensiveNode();
    // console.log('UPDATE USER DEFINDED CMs.');
    // console.log(allUserDefinedCountermeasures);
    setOpen(false);
  };

  // console.log('UPDATE USER DEFINDED CMs.JSUT BEFORE RETURN BLOCK');
  // console.log(allUserDefinedCountermeasures);

  const handleRemove = (e) => {
    e.preventDefault();
    console.log("TESTING REMVOE FEATURE");
  }

  return (
    // <aside>
      <Box sx= {{
        backgroundColor: palette.background.default,
        border: 'solid 1px',
        p: '0.1rem'
    }}>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        {"Add Custom Node"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>Name: </Typography>
          <Input onChange={(e)=> {setNewNodeName(e.target.value)}}/>
          <Typography>Color</Typography>
          <SwatchesPicker 
            onChangeComplete={(color) => {setNewNodeColor(color.hex)}}/>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes}>Save</Button>
        <Button onClick={handleNo} autoFocus>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={1}>
        <Grid item xs={12} >
          <Typography sx={{ mb: '1rem', fontSize: "1rem" }}><b>Drag Items to Canvas</b></Typography>
          <Divider />
        </Grid>
        {defaultNodeList.map(ListItem =>
         <Grid item xs={12} key={ListItem.id} >
            <WidgetWrapper  sx={{ p: '0.25em', textAlign: TextPosition, mt: TopMargin }} className="node" onDragStart={(event) => onDragStart(event, {ListItem})} draggable>
              <Typography sx={{ fontSize: TextFontSize }}>
                {ListItem.title}
              </Typography>
            </WidgetWrapper>
          </Grid>
        )}
        <Grid item xs={12} >
        <Typography sx={{ mt: '1em', mb: '1rem', fontSize: "1rem" }}><b>Counter Measures</b></Typography>
        <Divider />
        </Grid>
          {allUserDefinedCountermeasures.map(ListItem =>
            <Grid item xs={12} key={ListItem.id}>
                <WidgetWrapper sx={{ p: '0.2em', textAlign: TextPosition }} className="node" onDragStart={(event) => onDragStart(event, {ListItem})} draggable>
                  <Grid container spacing={0} sx={{ p: '0.2em', textAlign: TextPosition, alignContent: 'center' }} >
                  <Grid item xs={11} >
                    <Typography sx={{ p: '0', fontSize: TextFontSize}}>
                      <CounterMeasure key={ListItem.id} data={ListItem} /> {ListItem.title}
                    </Typography>
                  </Grid> 
                  <Grid item xs={1} >
                    <IconButton sx={{ p: '0'}} onClick={(e)=> handleRemove(e)}><DeleteForeverIcon size='xs' /></IconButton>
                  </Grid>
                  </Grid>
                </WidgetWrapper>
                </Grid>
          )}

      </Grid>
        <Button size='small' variant='contained' sx={{ p: '0.25em', textAlign: TextPosition, mt: TopMargin }} onClick={() => {setOpen(true)}} >Add Custom CounterMeasure</Button>

        {/* <Typography sx={{ mt: '1em', mb: '1rem', fontSize: "1rem" }}><b>Other Nodes </b></Typography>
        <Divider />
        <WidgetWrapper sx={{ p: '0.25em', textAlign: TextPosition, mt: TopMargin }} className="node" onDragStart={(event) => onDragStart(event, 'attackvector')} draggable >
          <Typography sx={{ fontSize: TextFontSize }} >
            Threat Vector
          </Typography>
        </WidgetWrapper>
        <WidgetWrapper sx={{ p: '0.25em', textAlign: TextPosition, mt: TopMargin }} className="node" onDragStart={(event) => onDragStart(event, 'attackerobjective')} draggable >
        <Typography sx={{ fontSize: TextFontSize }}  >
          Attacker Objective
        </Typography>
        </WidgetWrapper> */}
      </Box>
    // </aside>
  );
};