import React, { useState, useCallback, useRef, createContext, useEffect } from 'react';
import ReactFlow, { Controls, Background, applyEdgeChanges, applyNodeChanges, 
  MiniMap, addEdge, useNodesState, useEdgesState, ReactFlowProvider, updateEdge, Panel, updateNode, useOnSelectionChange,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Alert, Box, Button, Collapse, Divider, IconButton, Paper, Input, Typography, Grid, FormControl, Select, MenuItem, InputLabel, useTheme, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from "@mui/material";
import ResizableNodeSelected from './ResizableNodeSelected.jsx';
import ComplexNode from './ComplexNode.jsx';
import OrGate from './OrGate.jsx';
import AndGate from './AndGate.jsx';
import PhysicalSecurityPlan from './DefensiveNodes/PhysicalSecurityPlan.jsx';
import ConfigurationManagementProgram from './DefensiveNodes/ConfigurationManagementPlan.jsx';
import VulnerabilityManagementProgram from './DefensiveNodes/VulnerabilityManagementProgram.jsx';
import Firewall from './DefensiveNodes/Firewall.jsx';
import IntrusionDetectionSystem from './DefensiveNodes/IntrusionDetectionSystem.jsx';
import NetworkMonitoring from './DefensiveNodes/NetworkMonitoring.jsx';
import CounterMeasure from './DefensiveNodes/CounterMeasures.jsx'
import {v4} from 'uuid';

import AttackerObjective from './OtherNodes/objectiveNode.jsx';
import AttackVector from './OtherNodes/attackNodes.jsx';
import Group from './OtherNodes/group.jsx';

import './index.css';

import FloatingEdge from './FloatingEdge';
import FloatingConnectionLine from './FloatingConnectionLine';
import { createNodesAndEdges } from './utils.js';
import Sidebar from './Sidebar.js';
import DownloadButton from './DownloadImage.jsx';
import ContextMenu from './ContextMenu.js';

import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ResizableObjective from './resizableObjective.jsx';
import ResizableAttackVector from './resizableAttackVector.jsx';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
 

export const NodeContext = createContext();


const nodeTypes = {
  resizableNodeSelected: ResizableNodeSelected,
  orgate: OrGate,
  andgate: AndGate,
  physicalsecurityplan: PhysicalSecurityPlan,
  configurationmanagementplan: ConfigurationManagementProgram,
  vulnerabilitymanagementplan: VulnerabilityManagementProgram,
  firewall: Firewall,
  intrusiondetectionsystem: IntrusionDetectionSystem,
  networkmonitoring: NetworkMonitoring,
  attackvector: AttackVector,
  attackerobjective: AttackerObjective,
  resizableObjective: ResizableObjective,
  resizableAttackVector: ResizableAttackVector,
  complexNode: ComplexNode,
  countermeasure: CounterMeasure,
  group: Group,
};

const edgeTypes = {
  floating: FloatingEdge,
};


const AttackTrees2 = () => {

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const theme = useTheme();

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [open, setOpen] = useState(false);
  const [openToDelete, setOpenToDelete] = useState(false);
  const [openSaveAs, setOpenSaveAs] = useState(false);
  const [attackTreeName, setAttackTreeName] = useState("");
  const [allAssessmentAttackTrees, setAllAssessmentAttackTrees] = useState([]);
  const [menu, setMenu] = useState(null);
  const ref = useRef(null);
  const [target, setTarget] = useState(null);
  const dragRef = useRef(null);

  const { assessmentID } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();

  const [openInstructions, setOpenInstructions] = useState(true);

  const handleChange = async (event) => {
    setAttackTreeName(event.target.value);
    try {
      const response = await axiosPrivate.get(`/attacktree/${assessmentID}/${event.target.value}`, {
          withCredentials: true
      });
      const data = await response.data;
      // setAllAssessmentAttackTrees(data);

      setNodes(data[0]?.graphObjects?.nodes);
      setEdges(data[0]?.graphObjects?.edges);

    } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };


  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );

  const getAssessmentAttackTrees = async () => {
    try {
      const response = await axiosPrivate.get(`/attacktree/${assessmentID}`, {
          withCredentials: true
      });
      const data = await response.data;
      setAllAssessmentAttackTrees(data);
  
      if (allAssessmentAttackTrees === null) {
          return;
      }
    } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };
  
    useEffect(() => {
      getAssessmentAttackTrees();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  

  const rfStyle = {
    backgroundColor: main,
  };

  const defaultViewport = { x: 0, y: 0, zoom: 1 };

  const minimapStyle = {
    height: 300,
    width: 300
  };

  // const onConnect = useCallback((params) => setEdges((eds) => addEdge({...params, type: 'floating', animated: false, style: {stroke: '#000', background: '#000', strokeWidth: '2' }}, eds)), [setEdges]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge({...params, type: 'step', animated: false, style: {stroke: '#000', background: '#000', strokeWidth: '2' }}, eds)), [setEdges]);

  const getId = () => {
    let id = v4();
    return (
      `dndnode_${id}`
    )
  }

  const onNodeDragStart = (event, node) => {
    dragRef.current = node;
  };

  const onNodeDrag = (event, node) => {
    const centerX = node.position.x + node.width / 2;
    const centerY = node.position.y + node.height / 2;

    const targetNode = nodes.find(
      (n) =>
        centerX > n.position.x &&
        centerX < n.position.x + n.width &&
        centerY > n.position.y &&
        centerY < n.position.y + n.height &&
        n.id !== node.id // this is needed, otherwise we would always find the dragged node
    );

    setTarget(targetNode);
  };

  const onNodeDragStop = (event, node) => {
    // if (target?.type === 'group' ) {
    //   const index = nodes.indexOf((nds) => nds.id === target.id);
    //   console.log('Index of Target is:');
    //   console.log(index);
    //   nodes.unshift(nodes.splice(index, 1)[0]);
      // node.parentId = target?.id;
      // node.extent = 'parent';
      // const nodeToUpdate = nodes.find((nds) => nds.id === node.id);
      // console.log(nodeToUpdate);
      // Object.assign(nodeToUpdate, node);
    // } else {
    //   return;
    // }
    for (let i=0; i < nodes.length; i++) { 
      // const index = nodes.find((nds) => nds.type === 'group');
      if (nodes[i].type === 'group') {
        // const nodeToUpdate = nodes.find((nds) => nds.id === nodes[i].id);;
        nodes.unshift(nodes.splice(i, 1)[0]);
        // console.log(nodeToUpdate);
        // tempList.push(nodeToUpdate);
      }
    }
    // console.log(nodes);
    

    setTarget(null);
    dragRef.current = null;
    // console.log(nodes);
    setNodes(nodes);
  }

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();


      const type = event.dataTransfer.getData('application/reactflow');
      const title = event.dataTransfer.getData('title');
      const color = event.dataTransfer.getData('color');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const centerX = position.x + 50;
      const centerY = position.y + 50;

      const targetNode = nodes.find(
        (n) => 
        centerX > n.position.x && 
        centerX < n.position.x + n.width &&
        centerY > n.position.y &&
        centerY < n.position.y + n.height
    );

    if (type === 'resizableNodeSelected') { 
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: 'Enter Description' },
      dragHandle: '.custom-drag-handle'
    };

    setNodes((nds) => nds.concat(newNode));

  } else if (type === 'resizableObjective') {
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: 'Enter Description' },
      dragHandle: '.custom-drag-handle'
    }; 

    setNodes((nds) => nds.concat(newNode));

  } else if (type === 'resizableAttackVector') {
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: 'Enter Description' },
      dragHandle: '.custom-drag-handle'
    }; 

    setNodes((nds) => nds.concat(newNode));
  } else if (type === 'group') {
    const newNode = [{
      id: getId(),
      type,
      position,
      data: { label: 'Enter Description' },
      // dragHandle: '.custom-drag-handle'
    }]; 
    setNodes((nds) => newNode.concat(nds));
   
    // setNodes((nds) => nds.concat(newNode));
    // setNodes(nodes);
  } else {
    const newNode = {
      id: getId(),
      type,
      position,
      data: { label: `${type} node`, title: `${title}`, color: `${color}`  },
    };
    setNodes((nds) => nds.concat(newNode));
  }  
    },
    [reactFlowInstance],
  );

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(attackTreeName));

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
      }
    };

    restoreFlow();
  }, [setNodes]);

  const onStore = useCallback(() => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      localStorage.setItem(attackTreeName, JSON.stringify(flow));
    }
  }, [reactFlowInstance]);

  const onSave = async () => {
    if (reactFlowInstance) {
      const flow = reactFlowInstance.toObject();
      // console.log(flow);
      // localStorage.setItem(flowKey, JSON.stringify(flow));
      if (typeof attackTreeName === 'undefined' || !attackTreeName) {
        setOpen(true);
        return;
      } else {
        try {
          const response = await axiosPrivate.patch(`/attacktree/${assessmentID}/${attackTreeName}`,
          { "graphObjects": flow },    
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );
        const data = await response.data;
        console.log(response.data);
        
        } catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed')
            }
        }
      }
    }
    setOpen(false);
  };

  const handleCreateRecord = async () => {
    let attackerObjective = attackTreeName;
    let graphObjects = {};
    try {

        const response = await axiosPrivate.post(`/attacktree/new`,
            JSON.stringify({ assessmentID, attackerObjective, graphObjects }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        onSave();
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
};

const handleNo = () => {
  setOpen(false);
};

const handleYes = async () => {
  for (let i=0; i<allAssessmentAttackTrees.length+1; i++) {
    if (attackTreeName.trim() === allAssessmentAttackTrees[i]) {
      alert("Names must be unique");
      console.log("Match Found");
      return;
    } 
  }
  setNodes([]);
  const newFile = await handleCreateRecord();
  getAssessmentAttackTrees();
  setOpen(false);
};

const handleNoSaveAs = () => {
  setOpenSaveAs(false);
}

const handleSaveAs = async () => {
  for (let i=0; i<allAssessmentAttackTrees.length+1; i++) {
    if (attackTreeName.trim() === allAssessmentAttackTrees[i]) {
      alert("Names must be unique");
      console.log("Match Found");
      return;
    } 
  }
  const newFile = await handleCreateRecord();
  getAssessmentAttackTrees();
  setOpenSaveAs(false);
};

const handleDeleteAttackTree = async () => {
  try {
      const response = await axiosPrivate.delete(`/attacktree/${assessmentID}/${attackTreeName}`, {
            withCredentials: true
        }
    );
      if (response.data.success) {
          alert(response.data.msg);
      }
  } catch (err) {
      console.error(err);
  }
  setOpenToDelete(false);
  setNodes([]);
  getAssessmentAttackTrees();
  };

  const handleClickOpen = () => {
    setOpenToDelete(true);
  };

  const onSelectedContextMenu = useCallback(
    (event, selected) => {
      // Prevent native context menu from showing
      event.preventDefault();
      // console.log("FROM ON SELECTED CONTEXT MENU");
      // console.log(selected);
     
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();
      setMenu({
        position: selected[0].position,
        top: event.clientY-300,
        left: event.clientX-100,
        selected: selected,
        // top: event.clientY < pane.height - 200 && event.clientY,
        // left: event.clientX < pane.width - 200 && event.clientX,
        // right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        // bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu],
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();
     
      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();
      setMenu({
        id: node.id,
        top: event.clientY-300,
        left: event.clientX-100
        // top: event.clientY < pane.height - 200 && event.clientY,
        // left: event.clientX < pane.width - 200 && event.clientX,
        // right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        // bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu],
  );

  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);
  
  const onNodeClick = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();


      // Will eventually make this a dialog box with additional information about the node. 
      console.log(node);
});

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};


  return (
    <NodeContext.Provider value = {[ nodes, setNodes ]}>
    <Box>

        {/**INSTRUCTION BLOCK */}
        <Grid container spacing={0} sx={{ mb:'1rem' }}>
        <Grid item xs={12} >
        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
          <Grid item xs={12} sx={{ p:'0.5rem' }}>
          {openInstructions ? (
            <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
              <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
              <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
            </IconButton>
              ) : (
              <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                <ExpandCircleDownIcon color='primary' fontSize="large" />
                <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
              </IconButton>
              )}
            <Divider sx={{ border:0.5, mt:'6px' }}/>
          </Grid>
          <Collapse in={openInstructions} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ ml:'2rem' }}>
                <Typography>1. Create cybersecurity or physical security attack trees by dragging attack elements over from menu on the left side of the screen.</Typography>
                <Typography>2. Add details about each acttack element in the text box. You may use the 'AND' and 'OR' logic gates to connect attack elements.</Typography>
                <Typography>3. Add countermeasures from your cybersecurity program to indicate that specific attack elements are mitigated or require a more sophisticated adversary to be successful.</Typography>
                <Typography display='inline'><u>Note</u>:  Attack trees are never done. You can always add more detail or nuance to an attack tree. Create basic attack trees to get you started and refine them slowly over time. </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
     </Grid>

      <Dialog
        open={openSaveAs}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        {"Save as..."}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Save as... <Input onChange={(e) => {setAttackTreeName(e.target.value)}} />
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleSaveAs}>Save</Button>
        <Button onClick={handleNoSaveAs}>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        {"New File..."}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Filename... <Input onChange={(e) => {setAttackTreeName(e.target.value)}}/>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        {/* <Button onClick={handleYes}>Save</Button> */}
        <Button onClick={onStore}>Save</Button>
        <Button onClick={handleNo}>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openToDelete}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
        {"Delete AttackTree"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {attackTreeName}?
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDeleteAttackTree}>Yes</Button>
        <Button onClick={handleNo} autoFocus>
            Cancel
        </Button>
        </DialogActions>
      </Dialog>
      <ReactFlowProvider>
        <Grid container spacing={2}>
          <Grid item xs={10} >
        <div className="reactflow-wrapper" style={{ flexDirection: 'column', display: 'flex', flexGrow: '1', height: '90vh' }} ref={reactFlowWrapper}>  
          <ReactFlow
            ref={ref}
            nodes={nodes}
            onNodesChange={onNodesChange}
            onNodeContextMenu={onNodeContextMenu}
            onSelectionContextMenu={onSelectedContextMenu}
            onNodeDoubleClick={onNodeClick}
            edges={edges}
            onEdgesChange={onEdgesChange}
            onEdgeUpdate={onEdgeUpdate}
            onPaneClick={onPaneClick}
            defaultViewport={defaultViewport}
            fitView
            minZoom={0}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionLineComponent={FloatingConnectionLine}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDragStart={onNodeDragStart}
            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            deleteKeyCode={['Backspace', 'Delete']}
            style={rfStyle}
            >
            {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
            <MiniMap style={minimapStyle} zoomable pannable 
              nodeColor={(n) => { 
                if (n.type ==='resizableNodeSelected') return 'grey';
                if (n.type === 'resizableObjective') return '#e5b80B';
                if (n.type === 'resizableAttackVector') return '#ff0000';
              }}
            />
            <Background gap={22} size={2} />
            <Controls />  
            <Panel position='top-center' style={{ background: palette.background.alt, width:'auto', minWidth:'700px', border: 'solid 1px', padding: '5px', margin: 'auto' }}>
              <Button sx={{ ml: '1rem', mr: '1rem', width: 'auto' }} variant="contained" onClick={() => {setOpen(true)}}>New</Button>
              <Button sx={{ mr: '1rem', width: 'auto' }} variant="contained" onClick={onSave}>Save</Button>  
              {/* <Button sx={{ mr: '1rem', width: 'auto' }} variant="contained" onClick={onStore}>Save</Button> */}
              <Button sx={{ mr: '1rem', width: 'auto' }} variant="contained" onClick={() => {setOpenSaveAs(true)}}>
                Save As
              </Button>
              <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleClickOpen()}}>Delete</Button>
              <DownloadButton />
              <FormControl  sx={{ minWidth: '200px' }} >
                <InputLabel sx={{ mt: '5px' }} id="demo-simple-select-label">Open Attack Tree</InputLabel>
                <Select
                  // sx={{ color: 'black' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={attackTreeName}
                  label="Open Attack Tree"
                  onChange={handleChange}
                  // onChange={onRestore}
                >
                  {allAssessmentAttackTrees.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Panel>
          </ReactFlow>
        </div>
        </Grid>
        <Grid item xs={2}>
        <Sidebar />
        </Grid>
        </Grid>
      </ReactFlowProvider>
    </Box>
    </NodeContext.Provider>
  )
}

export default AttackTrees2;