// from node.js and react login tutorials
import Register from './components/Register';
import Login from './components/Login';
import Home from './components/Home';
// import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Lounge from './components/Lounge';
import LoginHelp from './components/LoginHelp.js'
// END from node.js and react login tutorials

// from leaflet tutorial
// import MapBuilder from './scenes/damSelection';
// END from leaflet tutorial 

// from mern dashboard tutorial
import LayoutDashboard from './scenes/layoutPage';
// import AdminMD from "./scenes/adminPage";
import Dashboard from "./scenes/dashboardPage";
// import HydroPowerMap from "./scenes/hydropowerMapPage";
// END from mern dashboard tutorial

// From mern_app project
import HomePage from './scenes/homePage';
import ProfilePage from './scenes/profilePage';
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
// END from mern_app project.

// Color Test
import ColorTest from './scenes/colorDisplay';

import LayoutNewAssessment from './scenes/layoutNewAssessmentPage';
import FercAssessment from './scenes/fercAssessmentPage';
import SecurityGroup1 from './scenes/fercAssessmentPage/SecurityGroup1';
import SecurityGroup2 from './scenes/fercAssessmentPage/SecurityGroup2';
import SecurityGroup3 from './scenes/fercAssessmentPage/SecurityGroup3';
// import NewAssessment2 from './scenes/newAssessmentPage2';

import LayoutHomePage, { isSidebarOpen } from './scenes/layoutHomePage';
import InventoryGuidance from './scenes/Training/Inventory';
import CybersecurityPlan from './scenes/Training/CybersecurityPlan';
import UserTraining from './scenes/Training/UserTraining';
import EnterpriseAssessments from './scenes/EnterpriseAssessments';
import ZTAAssessment from './scenes/EnterpriseAssessments/ZTAAssessment.jsx';
// import C2M2Assessment from './scenes/EnterpriseAssessments/C2M2Assessment.jsx';
import CSFAssessment from './scenes/EnterpriseAssessments/CSFAssessment.jsx';
import RMFAssessment from './scenes/EnterpriseAssessments/RMFAssessment.jsx';

import Training from './scenes/Training';
import Operations from './scenes/OperationsPage';
// import GetHelp from './scenes/EnterpriseAssessments';
import GetHelp from './scenes/GetHelpPage';


const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
};


function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  
  return (
    <main className="App">
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <Routes>
          <Route element={<Layout />}>
            {/* public routes */}
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            {/* <Route path="linkpage" element={<LinkPage />} /> */}
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="loginhelp" element={<LoginHelp />} />
            
            {/* we want to protect these routes */}
            <Route element={<PersistLogin />} >
              <Route element={<LayoutHomePage />} >
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="/" element={<HomePage />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="/profile" element={<ProfilePage />} />
                </Route>
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
                <Route path="/home" element={<Home />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
                <Route path="editor" element={<Editor />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="admin" element={<Admin />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                <Route path="lounge" element={<Lounge />} />
              </Route>
{/* 
              <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                <Route path="mapbuilder" element={<MapBuilder />} />
              </Route> */}

              {/* <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                <Route path="newassessment2" element={<NewAssessment2 />} />
              </Route> */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                <Route path="profile/:userId" element={<ProfilePage />} />
              </Route>

              <Route element={<LayoutDashboard />} >
                {/* Routes from mern dashboard tutorial */}

                {/* <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />} >
                  <Route path="adminmd" element={<AdminMD />} />
                </Route> */}

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />} >
                  <Route path="dashboard" element={<Dashboard />} />
                </Route>

              {/* 
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />} >
                  <Route path="/hydropower map" element={<HydroPowerMap />} />
                </Route> */}

              </Route>

              <Route element={<LayoutNewAssessment />} >
                {/* Routes for starting a new assessment. */}
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="fercassessment/:assessmentID" element={<FercAssessment />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="fercassessment/securitygroup1/:assessmentID" element={<SecurityGroup1 />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="fercassessment/securitygroup2/:assessmentID" element={<SecurityGroup2 />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="fercassessment/securitygroup3/:assessmentID" element={<SecurityGroup3 />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="colortest" element={<ColorTest />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="Training/Inventory" element={<InventoryGuidance />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="Training/CybersecurityPlan" element={<CybersecurityPlan />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="Training/UserTraining" element={<UserTraining />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />} >
                  <Route path="/enterprise assessment" element={<EnterpriseAssessments />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="ztmmassessment/:assessmentID" element={<ZTAAssessment />} />
                </Route>
                {/* <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="c2m2assessment/:assessmentID" element={<C2M2Assessment />} />
                </Route> */}
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="csfassessment/:assessmentID" element={<CSFAssessment />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />}>
                  <Route path="rmfassessment/:assessmentID" element={<RMFAssessment />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Editor, ROLES.Admin]} />} >
                  <Route path="/training" element={<Training />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Editor, ROLES.Admin]} />} >
                  <Route path="/operations" element={<Operations />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.User, ROLES.Editor, ROLES.Admin]} />} >
                  <Route path="/get help" element={<GetHelp />} />
                </Route>
              </Route>
            </Route>
          </Route>
          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Routes>
      </ThemeProvider>
    </main>
    
  )
}

export default App;
