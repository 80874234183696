import React, { useState, useEffect } from 'react';
import { Box, Button, Collapse, IconButton, FormControlLabel , Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import { all } from 'axios';



const ConsequenceThreshold = ({questionIndex, setQuestionIndex}) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [allCyberAssetData, setAllCyberAssetData] = useState();
  const theme = useTheme();
  const { assessmentID } = useParams();

    const getCyberAssetsDesignationCT = async () => {
      try {
        const response = await axiosPrivate.get(`/assets/designationct/${assessmentID}`, {
          withCredentials: true
          });
          const data = await response.data;
          setAllCyberAssetData(data);
          if (data.length<1 || data.length===undefined) {
            setQuestionIndex(8);
          }

          if (allCyberAssetData === null) {
              return;
          }
      } catch (err) {
        alert("Login Expired. Consequ Threshold");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    };
    
      useEffect(() => {
        getCyberAssetsDesignationCT();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!allCyberAssetData) {
        return null;
      }
  
  // Remote Access Determination Questions
const assetDeterminationQuestions = [
  {
    id: 'consequenceThresholdsPAR',
    questionText: 'Assume all gates are open with no response for 48 hours. Could a compromised cyber asset have consequences that exceed the following thresholds:',
    questionTextAug1: '1. The population at risk (PAR) within 3 miles of the dam exceeds 60 people?',
    questionTextAug2: '2. The population at risk (PAR) within 60 miles of the dam exceeds 800 people?',
    questionTextAug3: '3. Does the total population at risk exceed 12,500 people?',
    questionTextAug4: '4. Does the total economic losses (replacement/revenue and D/S damages) exceed $300 Million USD?',
    questionNumber: '1. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds'
  },
  {
    id: 'consequenceThresholdMunicipal',
    questionText: 'Does this cause a municipal-wide disruption in power generation, water management, or navigation?',
    questionNumber: '2. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds',
  },
  {
    id: 'consequenceThresholdPowerGen',
    questionText: 'Is this cyber asset connected to a powerhouse that can generate at least 1,500MW?',
    questionNumber: '3. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds',
  },
  {
    id: 'consequenceThresholdPowerGen',
    questionText: 'Is this cyber asset connected to a powerhouse that can generate at least 100MW but less than 1,500MW?',
    questionNumber: '4. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds',
  },
  {
    id: 'consequenceThresholdBlackStart',
    questionText: 'Is this cyber asset connected to a powerhouse that qualifies as having a black start capability?',
    questionNumber: '5. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds',
  },
  {
    id: 'internetAccessible',
    questionText: 'Can this cyber asset access the internet without going through a proxy (bastion host, jump host)?',
    questionNumber: '6. ',
    questionTitle: 'Cyber Asset Designation: Consequence Thresholds',
  },
]


// Update Database Function 
const handleCyberAssetUpdate = async () => {
  for (let updatedRow in allCyberAssetData) {
    let id = allCyberAssetData[updatedRow]._id;
    let fercDesignation = allCyberAssetData[updatedRow].fercDesignation;
    let populationAtRisk = allCyberAssetData[updatedRow].populationAtRisk;
    let municipal = allCyberAssetData[updatedRow].municipal;
    let powerGen1500MW = allCyberAssetData[updatedRow].powerGen1500MW;
    let powerGen100MW = allCyberAssetData[updatedRow].powerGen100MW;
    let blackStart = allCyberAssetData[updatedRow].blackStart;
    let internetAccessible = allCyberAssetData[updatedRow].internetAccessible;
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "fercDesignation": fercDesignation, "populationAtRisk": populationAtRisk, "municipal": municipal, "powerGen1500MW": powerGen1500MW, "powerGen100MW": powerGen100MW, "blackStart": blackStart, "internetAccessible": internetAccessible },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.Conswquence Threshold");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  };

const UpdateRemoteAccessQuestions = (e, params) => {
  if (questionIndex===3) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].populationAtRisk = e;
          if (allCyberAssetData[index].populationAtRisk==='yes') {
            allCyberAssetData[index].fercDesignation = 'Critical';
            }
        }
    }
  } else if (questionIndex===4) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].municipal = e;
          if (allCyberAssetData[index].municipal==='yes') {
            allCyberAssetData[index].fercDesignation = 'Critical';
            }
        } 
    }
  } else if (questionIndex===5) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].powerGen1500MW = e;
          if (allCyberAssetData[index].powerGen1500MW==='yes') {
            allCyberAssetData[index].fercDesignation = 'Critical';
            }
        }
    }
  } else if (questionIndex===6) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].powerGen100MW = e;
          if (allCyberAssetData[index].powerGen100MW==='yes') {
            allCyberAssetData[index].fercDesignation = 'Operational';
            }
        }
    }
  } else if (questionIndex===7) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].blackStart = e;
          if (allCyberAssetData[index].blackStart==='yes') {
            allCyberAssetData[index].fercDesignation = 'Operational';
            }
        }
    }
  }
}

const handleNextQuestion = async () => {
    const waiteForIt = await handleCyberAssetUpdate();
    setQuestionIndex(questionIndex+1);
    setAllCyberAssetData(null);
    getCyberAssetsDesignationCT();
}

const handlePreviousQuestion = () => {
  setQuestionIndex(questionIndex-1);
}

// Columns for MUI Data Grid
const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
  },
  {
      field: "assetType",
      headerName: "Asset Type/Function",
      flex: 1,
},
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
  },
  {
      field: "description",
      headerName: "Description",
      flex: 1,
  },
  {
      field: "macAddress",
      headerName: "MAC Address",
      flex: 0.5,
  },
  {
      field: "yearPurchased",
      headerName: "Year Purchased (approximate)",
      flex: 0.5,
  },
  {
      field: "physicalLocation",
      headerName: "Physical Location",
      flex: 1,
  },
  {
    field: "Answer",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            }
          }}>

        <RadioGroup row>
          <FormControlLabel
          label="Yes"
          control={
            <Radio
              value="yes"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
          <FormControlLabel
          label="No"
          control={
            <Radio
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
        </RadioGroup>
        </Box>
      )
    }
  }
];

// const assetDesignationQuestions = ['remoteAccess', 'remoteAccessConnected', 'consequenceDetermination', 'consequenceThreshold'];

  return (
    <Box sx={{ margin: '1rem' }}>

    <Grid container spacing={2}> 
  {/** Question box to determine Remote Operations and Consequences. */}
    {questionIndex<=7 ? ( 
      <Grid item xs={12}>
        <Paper sx={{ marginTop:'1rem', backgroundImage: "none", borderRadius: "0.55rem", mr:'0.55rem', p:'1rem', minHeight:'250px'}} >
          <Typography>
              {assetDeterminationQuestions[questionIndex-3].questionTitle}
          </Typography>
        
          <Box sx={{ fontSize: 24, p: '2rem', mt: '1rem' }}>
            {assetDeterminationQuestions[questionIndex-3].questionText}
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
            {assetDeterminationQuestions[questionIndex-3].questionTextAug1 || ""}
            </Typography>
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
              {assetDeterminationQuestions[questionIndex-3].questionTextAug2 || ""}
            </Typography>
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
              {assetDeterminationQuestions[questionIndex-3].questionTextAug3 || ""}
            </Typography>
            <Typography sx={{ fontSize: 22, ml: '3rem' }}>
              {assetDeterminationQuestions[questionIndex-3].questionTextAug4 || ""}
            </Typography>
          </Box>
             
          {questionIndex>0 ? (
                  <Grid sx={{ display:'flex', justifyContent:'space-between' }}>
                    <Button variant="contained" onClick={handlePreviousQuestion}>Previous Question</Button>
                    <Button variant="contained" onClick={handleNextQuestion}>Next Question</Button>
                  </Grid>
                ):(
                  <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
                )}
          {/* <Grid>
            <Typography>
              <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
            </Typography> 
          </Grid> */}
        </Paper>
      </Grid> 
    ) : (
      <Typography>
        move to Cyber Asset Review.
      </Typography>
    )} 
          {/** DATA GRID OF CYBER ASSETS */}
          <Grid container spacing={2}>
          <Box sx={{ mt:'1rem', flexGrow: 1, padding: "1.5rem 1.5rem 0.75rem 2rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
      <Grid item xs={12} >
        <Box
          sx={{
            height: 500,
            width: '100%',
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.secondary.alt,
                color: theme.palette.secondary[100],
                borderBottom: "solid 2px black",
                borderTop: "solid 2px black"
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary[100],
                borderTop: "none"
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
            },
        }}
        >
          <DataGrid
            rows={allCyberAssetData || []} 
            getRowId={(row) => row._id}
            columns={columns}
            editMode="row"
            loading={!allCyberAssetData}
          />
        </Box>
      </Grid>
    </Box>
          </Grid>
    </Grid>
    </Box>
  );
};

export default ConsequenceThreshold