import React, { useState, useEffect } from 'react';
import {Box, Typography, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import NavbarDashboard from "./navbardashboard";
// import Navbar from 'scenes/navbar';
import Sidebar from "../../components/Sidebar";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
// import { useGetUserQuery } from 'state/api';

const LayoutDashboard = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true);
  const userId = "659102e857259da4dcdfbb95";
  // const userId = useSelector((state) => state.userId);
  // const { data } = useGetUserQuery(userId);
  // console.log("🚀 ~ file: index.jsx:17 ~ Layout ~ data:", data);
  const [userData, setUserData] = useState(null);
  const token = useSelector((state) => state.token);
  const axiosPrivate = useAxiosPrivate();
  
  const getUser = async () => {
    const response = await axiosPrivate.get(`/usersma/${userId}`, {
        withCredentials: true
    });
    const data = await response.data;
    setUserData(data);
};
useEffect(() => {
  getUser();
}, []);  // eslint-disable-line react-hooks/exhaustive-deps
if (!userData) {
  return null;
}
  
  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={userData || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        />
      <Box flexGrow={1}>
      <NavbarDashboard
        user={userData || {}}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        />
        {/* <Sidebar 
          isNonMobile={isNonMobile}
          drawerWidth="250px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          /> */}
        {/* <NavbarDashboard 
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
        /> */}
        {/* <Navbar 
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        /> */}
        <Outlet />
      </Box>
    </Box>
  );
  
};

export default LayoutDashboard;