import React, { useState, useEffect } from 'react';
import FlexBetween from '../../components/FlexBetween';
import Header from '../../components/Header';
import { DownloadOutlined, Email, PointOfSale, PersonAdd, Traffic } from '@mui/icons-material';
import { Box, Typography, Button, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from '../../components/BreakdownChart';
import OverviewChart from '../../components/OverviewChart';
import StatBox from "../../components/StatBox";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import WidgetWrapper from '../../components/WidgetWrapper';


const Dashboard = () => {
//   const [dashboardData, setDashboardData] = useState();
//   const theme = useTheme();
//   // const isNonMobileScreens = useMediaQuery("(min-width: 500px)");
//   const isNonMobileScreens = true;
  
//   const axiosPrivate = useAxiosPrivate();

//   const getDashboardData = async () => {
//       const response = await axiosPrivate.get(`/general/dashboard`, {
//           withCredentials: true
//       });
//       const data = await response.data;
//       setDashboardData(data);
//   };
//   useEffect(() => {
//     getDashboardData();
//   }, []);  // eslint-disable-line react-hooks/exhaustive-deps
//   if (!dashboardData) {
//     return null;
// };
 
// const columns = [
//   {
//       field: "_id",
//       headerName: "ID",
//       flex: 1,
//       // sortingOrder: ['desc', 'asc', null],
//   },
//   {
//       field: "userId",
//       headerName: "User ID",
//       flex: 1,
//       // sortingOrder: ['desc', 'asc', null],
//   },
//   {
//       field: "createdAt",
//       headerName: "Created At",
//       flex: 1,
//       // sortingOrder: ['desc', 'asc', null],
//   },
//   {
//       field: "products",
//       headerName: "# of Products",
//       flex: 0.5,
//       sortable: false,
//       renderCell: (params) => params.value.length
//   },
//   {
//       field: "cost",
//       headerName: "Cost",
//       flex: 1,
//       renderCell: (params) => `$${Number(params.value).toFixed(2)}`
//   },
// ];

  return (
    <Box m="1.5rem 2.5rem">

      <WidgetWrapper sx={{ display:'flex', justifyContent:'center', alignItems:'center', mt:'1rem' }}>
        <Typography>Dashboard coming soon</Typography>
      </WidgetWrapper>

      {/* <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} /> 
            Download Reports
          </Button>
        </Box>
      </FlexBetween>
      <Box mt="20px" display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="160px" gap="20px" sx={{
        "& > div": { gridColumn: isNonMobileScreens ? undefined : "span 12"}
      }}>
        {/* ROW NUMBER ONE */}
      
      
      {/*  <StatBox
          title="Total Customers"
          value={dashboardData && dashboardData.totalCustomers}
          increase="14%"
          description="Since last month"
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
         <StatBox
          title="Sales Today"
          value={dashboardData && dashboardData.todayStats.totalSales}
          increase="+21%"
          description="Since last month"
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <OverviewChart view="sales" isDashboard={true} />
        </Box>
        <StatBox
          title="Monthly Sales"
          value={dashboardData && dashboardData.thisMonthStats.totalSales}
          increase="+5%"
          description="Since last month"
          icon={
            <PersonAdd
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Yearly Sales"
          value={dashboardData && dashboardData.yearlySalesTotal}
          increase="+43%"
          description="Since last month"
          icon={
            <Traffic
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      {/* ROW NUMBER TWO */}
     
     {/* <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              borderRadius: "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.background.alt,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            // loading={!dashboardData}
            getRowId={(row) => row._id}
            rows={(dashboardData && dashboardData.transactions) || []}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Sales By Category
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of real states and information via category for revenue
            made for this year and total sales.
          </Typography>
        </Box>

      </Box> */}
    </Box>

    
  );
};

export default Dashboard;