import React from 'react';
import QuestionnaireForm from '../../components/QuestionnaireForm';

const ListMapFunc = ({questionsList}) => {

  console.log("FROM LIST MAP FUNCTION");
  console.log(questionsList);


  return (
  <>
    {questionsList?.map(({
        uid,
        variable,
        GridItemQuestionXS,
        questionNumber,
        questionText,
        GridItemInputXS,
        GridItemInputSX,
        inputStyleWidth,
        inputStyleHeight,
        checkboxMarginLeft,
        GridItemCheckboxContainerInputXS,
        name,
        type,
        answerType,
        checkboxInputs,
        radiogroupInputs,
        onChangeInput,
        comments,
        GridItemCommentsXS,
        inputCommentWidth,
        idComment,
        valueComment,
        onChangeComments
      }) => (
        <QuestionnaireForm 
          key={uid}
          variable={variable}
          GridItemQuestionXS={GridItemQuestionXS}
          questionNumber={questionNumber}
          questionText={questionText}
          GridItemInputXS={GridItemInputXS}
          GridItemInputSX={GridItemInputSX}
          inputStyleWidth={inputStyleWidth}
          inputStyleHeight={inputStyleHeight}
          checkboxMarginLeft={checkboxMarginLeft}
          GridItemCheckboxContainerInputXS={GridItemCheckboxContainerInputXS}
          name={name}
          type={type}
          answerType={answerType}
          checkboxInputs={checkboxInputs}
          radiogroupInputs={radiogroupInputs}
          onChangeInput={onChangeInput}
          comments={comments}
          GridItemCommentsXS={GridItemCommentsXS}
          inputCommentWidth={inputCommentWidth}
          idComment={idComment}
          valueComment={valueComment}
          onChangeComments={onChangeComments}
          />
        )
      )}
    </>
  )
}

export default ListMapFunc