import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const CyberTrainingQuestions = ({questionFamily, setQuestionFamily, setActiveStep}) => {


    // *************** Questionnaire specific Variables***************//
  
    const [hasUserTrainingRequirement, setHasUserTrainingRequirement] = useState("");
    const [hasAnnualUserTraining, setHasAnnualUserTraining] = useState("");
    const [hasPrivilegedUserTraining, setHasPrivilegedUserTraining] = useState("");
    const [hasSecurityRoleSpecificTraining, setHasSecurityRoleSpecificTraining] = useState("");
    const [hasUserAgreement, setHasUserAgreement] = useState("");
    const [hasUserTrainingRequirementComments, setHasUserTrainingRequirementComments] = useState("");
    const [hasAnnualUserTrainingComments, setHasAnnualUserTrainingComments] = useState("");
    const [hasPrivilegedUserTrainingComments, setHasPrivilegedUserTrainingComments] = useState("");
    const [hasSecurityRoleSpecificTrainingComments, setHasSecurityRoleSpecificTrainingComments] = useState("");
    const [hasUserAgreementComments, setHasUserAgreementComments] = useState("");


   // *************** Questionnaire specific Variables***************//

  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();     

const getAssessmentData = async () => {
  try {
    const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
      withCredentials: true
  });
  const data = await response.data;
  setAssessmentData(data);
  setHasUserTrainingRequirementComments(data?.hasUserTrainingRequirementComments?.toString());
  setHasAnnualUserTrainingComments(data?.hasAnnualUserTrainingComments?.toString());
  setHasPrivilegedUserTrainingComments(data?.hasPrivilegedUserTrainingComments?.toString());
  setHasSecurityRoleSpecificTrainingComments(data?.hasSecurityRoleSpecificTrainingComments?.toString());
  setHasUserAgreementComments(data?.hasUserAgreementComments?.toString());
  setHasUserTrainingRequirement(data?.hasUserTrainingRequirement?.toString());
  setHasAnnualUserTraining(data?.hasAnnualUserTraining?.toString());
  setHasPrivilegedUserTraining(data?.hasPrivilegedUserTraining?.toString());
  setHasSecurityRoleSpecificTraining(data?.hasSecurityRoleSpecificTraining?.toString());
  setHasUserAgreement(data?.hasUserAgreement?.toString());
  
  } catch (err) {
    alert("Login Expired.");
    console.error(err);
    navigate('/login', { state: { from: location }, replace: true });
}

  if (assessmentData === null) {
      return;
  }
};

useEffect(() => {
  getAssessmentData();
}, []);  // eslint-disable-line react-hooks/exhaustive-deps
if (!assessmentData) {
  return null;
}

 // *************** Update to use Questionnaire specific Variables***************//
  const handleSubmit = async (e) => {
    e.preventDefault();
    let cyberTrainingQuestions = true;
    let cybersecurityChecklistForm3 = true;
    const cyberTrainingData = JSON.stringify({ hasUserTrainingRequirement,
      hasAnnualUserTraining,
      hasPrivilegedUserTraining,
      hasSecurityRoleSpecificTraining,
      hasUserAgreement,
      hasUserTrainingRequirementComments,
      hasAnnualUserTrainingComments,
      hasPrivilegedUserTrainingComments,
      hasSecurityRoleSpecificTrainingComments,
      hasUserAgreementComments,
    });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ cyberTrainingQuestions, cybersecurityChecklistForm3, cyberTrainingData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    setQuestionFamily(8)
    // setActiveStep(4);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
  }

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

  const GridItemQuestionXS = 7;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '100%';



  //************Add category specific Questions.****************//
  const CyberTrainingQuestionsList = [
    {
        // 1	Are control system users provided information security awareness before they are allowed to access control systems?
        // 	hasUserTrainingRequirement	setHasUserTrainingRequirement	hasUserTrainingRequirementComments	setHasUserTrainingRequirementComments
      uid: 'TRN-1',
      variable: hasUserTrainingRequirement,
      questionNumber: '1',
      questionText: 'Are control system users provided information security awareness before they are allowed to access control systems?',
      name: "hasUserTrainingRequirement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasUserTrainingRequirement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasUserTrainingRequirementComments",
      valueComment: hasUserTrainingRequirementComments,
      onChangeComments: setHasUserTrainingRequirementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 2	Are control system users provided information security awareness training at least annually?
        // 	hasAnnualUserTraining	setHasAnnualUserTraining	hasAnnualUserTrainingComments	setHasAnnualUserTrainingComments
      uid: 'TRN-2',
      variable: hasAnnualUserTraining,
      questionNumber: '2',
      questionText: 'Are control system users provided information security awareness training at least annually?',
      name: "hasAnnualUserTraining",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasAnnualUserTraining,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasAnnualUserTrainingComments",
      valueComment: hasAnnualUserTrainingComments,
      onChangeComments: setHasAnnualUserTrainingComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 3	Do privileged users get specific training that reflects the risks associated with root or administrative privileges?
        // 	hasPrivilegedUserTraining	setHasPrivilegedUserTraining	hasPrivilegedUserTrainingComments	setHasPrivilegedUserTrainingComments
      uid: 'TRN-3',
      variable: hasPrivilegedUserTraining,
      questionNumber: '3',
      questionText: 'Do privileged users get specific training that reflects the risks associated with root or administrative privileges?',
      name: "hasPrivilegedUserTraining",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasPrivilegedUserTraining,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasPrivilegedUserTrainingComments",
      valueComment: hasPrivilegedUserTrainingComments,
      onChangeComments: setHasPrivilegedUserTrainingComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
      
        // 4	Are users with significant control systems secuirty roles given advanced training specific to their role?
        // 	hasSecurityRoleSpecificTraining	setHasSecurityRoleSpecificTraining	hasSecurityRoleSpecificTrainingComments	setHasSecurityRoleSpecificTrainingComments
      uid: 'TRN-4',
      variable: hasSecurityRoleSpecificTraining,
      questionNumber: '4',
      questionText: 'Are users with significant control systems secuirty roles given advanced training specific to their role?',
      name: "hasSecurityRoleSpecificTraining",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasSecurityRoleSpecificTraining,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasSecurityRoleSpecificTrainingComments",
      valueComment: hasSecurityRoleSpecificTrainingComments,
      onChangeComments: setHasSecurityRoleSpecificTrainingComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
        // 5	Do users know to only use cyber assets for approved activities, such as through a signed User Agreement?
        // 	hasUserAgreement	setHasUserAgreement	hasUserAgreementComments	setHasUserAgreementComments

      uid: 'TRN-5',
      variable: hasUserAgreement,
      questionNumber: '5',
      questionText: 'Do users know to only use cyber assets for approved activities, such as through a signed User Agreement?',
      name: "hasUserAgreement",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasUserAgreement,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasUserAgreementComments",
      valueComment: hasUserAgreementComments,
      onChangeComments: setHasUserAgreementComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }
  ]


  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>Training</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {CyberTrainingQuestionsList.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasUserTrainingRequirement ==="" || hasAnnualUserTraining ==="" || hasPrivilegedUserTraining ==="" || hasSecurityRoleSpecificTraining ==="" || hasUserAgreement ==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box> 
  )
}

export default CyberTrainingQuestions