import React, { useState, useEffect } from 'react';
import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import Navbar from "../navbar";
// import Step1 from './Step1';
import SelectDam from './SelectDam';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from "../../hooks/useAuth";
import { jwtDecode } from "jwt-decode";
import { useParams } from "react-router-dom";
import SecurityGroup1 from './SecurityGroup1';
import SecurityGroup2 from './SecurityGroup2';
import SecurityGroup3 from './SecurityGroup3';
import SecurityGroupOther from './SecurityGroupOther';


const FercAssessment = () => {
    const [processValue, setProcessValue] = useState('1');
    const { assessmentID } = useParams();
    const [damData, setDamData] = useState(null);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const decoded = auth?.accessToken
    ? jwtDecode(auth.accessToken)
    : undefined;
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId;
    const [securityGroup, setSecurityGroup] = useState();

    const handleChange = (event, newValue) => {
        setProcessValue(newValue);
    }

    const handleReturnAssessment = () => {
        if (securityGroup === 'Security Group 3') {
            return (
            <TabContext value={processValue}>
            <Box marginTop='0.5rem' sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label='Tabs example' onChange={handleChange} >
                    <Tab label='1-Select Dam' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                    <Tab label='2-Security Assessment' value='2' />
                    <Tab label='3-Vulnerability Assessment' value='3' />
                    <Tab label='4-Cyber/SCADA Assessment' value='4' />
                    <Tab label='Add Cyber Asset' value='5' />
                    <Tab label='Add Cyber Asset' value='6' />
                </TabList>
            </Box>
            <TabPanel value='1'>
                <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
            </TabPanel>
            <TabPanel value='2'>Panel Two</TabPanel>
            <TabPanel value='3'>
                
            </TabPanel>
            <TabPanel value='4'>Panel One</TabPanel>
            <TabPanel value='5'>Panel Two</TabPanel>
            <TabPanel value='6'>

            </TabPanel>
        </TabContext>
            )
        } else if (securityGroup === 'Security Group 2') {
            return (
                <TabContext value={processValue}>
            <Box marginTop='0.5rem' sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label='Tabs example' onChange={handleChange} >
                    <Tab label='1-Select Dam' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                    <Tab label='2-Security Assessment' value='2' />
                    <Tab label='3-Vulnerability Assessment' value='3' />
                    <Tab label='4-Cyber/SCADA Assessment' value='4' />
                    <Tab label='Add Cyber Asset' value='5' />
                    <Tab label='Add Cyber Asset' value='6' />
                </TabList>
            </Box>
            <TabPanel value='1'>
                <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
            </TabPanel>
            <TabPanel value='2'>Panel Two</TabPanel>
            <TabPanel value='3'>
                
            </TabPanel>
            <TabPanel value='4'>Panel One</TabPanel>
            <TabPanel value='5'>Panel Two</TabPanel>
            <TabPanel value='6'>

            </TabPanel>
        </TabContext>
            )
        } else if (securityGroup === 'Security Group 1') {
            <TabContext value={processValue}>
            <Box marginTop='0.5rem' sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <TabList aria-label='Tabs example' onChange={handleChange} >
                    <Tab label='1-Select Dam' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
                    <Tab label='2-Security Assessment' value='2' />
                    <Tab label='3-Vulnerability Assessment' value='3' />
                    <Tab label='4-Cyber/SCADA Assessment' value='4' />
                    <Tab label='Add Cyber Asset' value='5' />
                    <Tab label='Add Cyber Asset' value='6' />
                </TabList>
            </Box>
            <TabPanel value='1'>
                <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
            </TabPanel>
            <TabPanel value='2'>Panel Two</TabPanel>
            <TabPanel value='3'>
                
            </TabPanel>
            <TabPanel value='4'>Panel One</TabPanel>
            <TabPanel value='5'>Panel Two</TabPanel>
            <TabPanel value='6'>

            </TabPanel>
        </TabContext>
        } else {
            return (
                <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
            )
        }
    };
    useEffect(() => {
        handleReturnAssessment();
      }, [securityGroup]);  // eslint-disable-line react-hooks/exhaustive-deps
      
  return (
    <Box>
        {!securityGroup ? (
            <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
        ) : (
            <Box>
                {securityGroup === 'Security Group 1' ? (
                    <SecurityGroup1 damData={damData} assessmentID={assessmentID} />
                    ) : (
                        <Box>
                        {securityGroup === 'Security Group 2' ? ( 
                            <SecurityGroup2 damData={damData} assessmentID={assessmentID} />
                        ) : (
                            <Box>
                             {securityGroup === 'Security Group 3' ? (
                                <SecurityGroup3 damData={damData} assessmentID={assessmentID} />
                             ) : (
                                <SecurityGroupOther damData={damData} assessmentID={assessmentID} />
                             )
                            }  
                            </Box> 
                        )
                    }
                        </Box>
                    )
                }
            </Box>
        ) 
        }
    </Box>
  );
}

export default FercAssessment


{/* <TabContext value={processValue}>
<Box marginTop='0.5rem' sx={{ borderBottom: 1, borderColor: 'divider'}}>
    <TabList aria-label='Tabs example' onChange={handleChange} >
        <Tab label='1-Select Dam' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
//         <Tab label='2-Security Assessment' value='2' />
//         <Tab label='3-Vulnerability Assessment' value='3' />
//         <Tab label='4-Cyber/SCADA Assessment' value='4' />
//         <Tab label='Add Cyber Asset' value='5' />
//         <Tab label='Add Cyber Asset' value='6' />
//     </TabList>
// </Box>
// <TabPanel value='1'>
//     <SelectDam damData={damData} setDamData={setDamData} setProcessValue={setProcessValue} assessmentID={assessmentID} setSecurityGroup={setSecurityGroup} />
// </TabPanel>
// <TabPanel value='2'>Panel Two</TabPanel>
// <TabPanel value='3'>
    
// </TabPanel>
// <TabPanel value='4'>Panel One</TabPanel>
// <TabPanel value='5'>Panel Two</TabPanel>
// <TabPanel value='6'>

// </TabPanel>
// </TabContext> */}