import React from 'react';
import { Position } from 'reactflow';

const rx = 0;
const ry = 0;
const width = 20;
const height = 20;

const VulnerabilityManagementProgram = ({selected}) => {
  return (
    <>         
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} rx={rx} ry={ry} fill="#e6e600" stroke="black" strokeWidth={2}>
            <title>Vulnerability Management Program</title>
        </rect>
      </svg>
    </>
  )
};

export default VulnerabilityManagementProgram;
