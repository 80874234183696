import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const SecurityArchitectureQuestions = ({questionFamily, setQuestionFamily}) => {


    // *************** Questionnaire specific Variables***************//
  
    const [hasSecureDesignPrinciples, setHasSecureDesignPrinciples] = useState("");
    const [doesApproveNetworkConnections, setDoesApproveNetworkConnections] = useState("");
    const [hasSCADAWireless, setHasSCADAWireless] = useState("");
    const [hasSegmentedITOTNetworks, setHasSegmentedITOTNetworks] = useState("");
    const [hasSegmentedCriticalAssets, setHasSegmentedCriticalAssets] = useState("");
    const [doesDataCommNetwork, setDoesDataCommNetwork] = useState("");
    const [hasSecureDesignPrinciplesComments, setHasSecureDesignPrinciplesComments] = useState("");
    const [doesApproveNetworkConnectionsComments, setDoesApproveNetworkConnectionsComments] = useState("");
    const [hasSCADAWirelessComments, setHasSCADAWirelessComments] = useState("");
    const [hasSegmentedITOTNetworksComments, setHasSegmentedITOTNetworksComments] = useState("");
    const [hasSegmentedCriticalAssetsComments, setHasSegmentedCriticalAssetsComments] = useState("");
    const [doesDataCommNetworkComments, setDoesDataCommNetworkComments] = useState("");
    const [hasWirelessAccessControl,	setHasWirelessAccessControl	] = useState("");
    const [hasWirelessAccessControlComments,	setHasWirelessAccessControlComments] = useState("");

   // *************** Questionnaire specific Variables***************//

  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();     


  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setHasSecureDesignPrinciples(data?.hasSecureDesignPrinciples?.toString());
    setDoesApproveNetworkConnections(data?.doesApproveNetworkConnections?.toString());
    setHasSCADAWireless(data?.hasSCADAWireless?.toString());
    setHasSegmentedITOTNetworks(data?.hasSegmentedITOTNetworks?.toString());
    setHasSegmentedCriticalAssets(data?.hasSegmentedCriticalAssets?.toString());
    setDoesDataCommNetwork(data?.doesDataCommNetwork?.toString());
    setHasSecureDesignPrinciplesComments(data?.hasSecureDesignPrinciplesComments?.toString());
    setDoesApproveNetworkConnectionsComments(data?.doesApproveNetworkConnectionsComments?.toString());
    setHasSCADAWirelessComments(data?.hasSCADAWirelessComments?.toString());
    setHasSegmentedITOTNetworksComments(data?.hasSegmentedITOTNetworksComments?.toString());
    setHasSegmentedCriticalAssetsComments(data?.hasSegmentedCriticalAssetsComments?.toString());
    setDoesDataCommNetworkComments(data?.doesDataCommNetworkComments?.toString());
    setHasWirelessAccessControl(data?.hasWirelessAccessControl?.toString());
    setHasWirelessAccessControlComments(data?.hasWirelessAccessControlComments?.toString());

    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }

    if (assessmentData === null) {
        return;
    }
};

useEffect(() => {
    getAssessmentData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }

 // *************** Update to use Questionnaire specific Variables***************//
  const handleSubmit = async (e) => {
    e.preventDefault();
    let securityArchitectureQuestions = true;
    const securityArchitectureData = JSON.stringify({ hasSecureDesignPrinciples,
      doesApproveNetworkConnections,
      hasSCADAWireless,
      hasSegmentedITOTNetworks,
      hasSegmentedCriticalAssets,
      doesDataCommNetwork,
      hasSecureDesignPrinciplesComments,
      doesApproveNetworkConnectionsComments,
      hasSCADAWirelessComments,
      hasSegmentedITOTNetworksComments,
      hasSegmentedCriticalAssetsComments,
      doesDataCommNetworkComments,
      hasWirelessAccessControl,
      hasWirelessAccessControlComments
    });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ securityArchitectureQuestions, securityArchitectureData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(2) activates the Asset Management questions. 
    setQuestionFamily(5);
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

  const GridItemQuestionXS = 7;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '100%';



  //************Add category specific Questions.****************//
  const SecurityArchitectureQuestionsLIST = [
    {
        // 1	Do you follow secure design principles (security architecture principles) when building a new system or modifying an existing system?
        //	hasSecureDesignPrinciples	setHasSecureDesignPrinciples	hasSecureDesignPrinciplesComments	setHasSecureDesignPrinciplesComments
      uid: 'SA-1',
      variable: hasSecureDesignPrinciples,
      questionNumber: '1',
      questionText: 'Do you follow secure design principles (security architecture principles) when building a new system or modifying an existing system?',
      name: "hasSecureDesignPrinciples",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasSecureDesignPrinciples,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasSecureDesignPrinciplesComments",
      valueComment: hasSecureDesignPrinciplesComments,
      onChangeComments: setHasSecureDesignPrinciplesComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    },
    {
    // 2	Do you monitor and review (at least every 18months) network connections, including remote and third-party connections?
    //	doesApproveNetworkConnections	setDoesApproveNetworkConnections	doesApproveNetworkConnectionsComments	setDoesApproveNetworkConnectionsComments
      uid: 'SA-2',
      variable: doesApproveNetworkConnections,
      questionNumber: '2',
      questionText: 'Do you monitor and review (at least every 18months) network connections, including remote and third-party connections?',
      name: "doesApproveNetworkConnections",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesApproveNetworkConnections,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesApproveNetworkConnectionsComments",
      valueComment: doesApproveNetworkConnectionsComments,
      onChangeComments: setDoesApproveNetworkConnectionsComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
    // 3	Does the facility/project utilize wireless in the Cyber/SCADA system?
    //	hasSCADAWireless	setHasSCADAWireless	hasSCADAWirelessComments	setHasSCADAWirelessComments
      uid: 'SA-3',
      variable: hasSCADAWireless,
      questionNumber: '3',
      questionText: 'Does the facility/project utilize wireless in the Cyber/SCADA system?',
      name: "hasSCADAWireless",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasSCADAWireless,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasSCADAWirelessComments",
      valueComment: hasSCADAWirelessComments,
      onChangeComments: setHasSCADAWirelessComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
      // 4	If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations?
      //	hasWirelessAccessControl	setHasWirelessAccessControl	hasWirelessAccessControlComments	setHasWirelessAccessControlComments
    uid: 'SA-4',
    variable: hasWirelessAccessControl,
    questionNumber: '4',
    questionText: 'If yes, are access controls implemented to prevent and monitor for unauthorized attempts and access to systems and operations??',
    name: "hasWirelessAccessControl",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
      {
        // answer options for checkbox
      },
      {
        // answer options for checkbox
      }
    ], 
    onChangeInput: setHasWirelessAccessControl,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "hasWirelessAccessControlComments",
    valueComment: hasWirelessAccessControlComments,
    onChangeComments: setHasWirelessAccessControlComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
  }, 
  {
    // 5	Are the Industrial Control System (e.g. SCADA) and non-Industrial Control System networks segregated and access controls applied to prevent unauthorized communication between these networks?
    //	hasSegmentedITOTNetworks	setHasSegmentedITOTNetworks	hasSegmentedITOTNetworksComments	setHasSegmentedITOTNetworksComments
      uid: 'SA-5',
      variable: hasSegmentedITOTNetworks,
      questionNumber: '5',
      questionText: 'Are the Industrial Control System (e.g. SCADA) and non-Industrial Control System networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      name: "hasSegmentedITOTNetworks",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasSegmentedITOTNetworks,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasSegmentedITOTNetworksComments",
      valueComment: hasSegmentedITOTNetworksComments,
      onChangeComments: setHasSegmentedITOTNetworksComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
// 6	Are your critical systems and assets appropriately separated or secured from your non-critical systems and assets?
//	hasSegmentedCriticalAssets	setHasSegmentedCriticalAssets	hasSegmentedCriticalAssetsComments	setHasSegmentedCriticalAssetsComments
      uid: 'SA-6',
      variable: hasSegmentedCriticalAssets,
      questionNumber: '6',
      questionText: 'Are your critical systems and assets appropriately separated or secured from your non-critical systems and assets?',
      name: "hasSegmentedCriticalAssets",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasSegmentedCriticalAssets,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasSegmentedCriticalAssetsComments",
      valueComment: hasSegmentedCriticalAssetsComments,
      onChangeComments: setHasSegmentedCriticalAssetsComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
    // 7	Within the Industrial Control System environment (to include building services such as HVAC) are the networks segregated and access controls applied to prevent unauthorized communication between these networks?
    //	doesDataCommNetwork	setDoesDataCommNetwork	doesDataCommNetworkComments	setDoesDataCommNetworkComments
      uid: 'SA-7',
      variable: doesDataCommNetwork,
      questionNumber: '7',
      questionText: 'Within the Industrial Control System environment (to include building services such as HVAC) are the networks segregated and access controls applied to prevent unauthorized communication between these networks?',
      name: "doesDataCommNetwork",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesDataCommNetwork,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesDataCommNetworkComments",
      valueComment: doesDataCommNetworkComments,
      onChangeComments: setDoesDataCommNetworkComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
  ]


  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>Security Architecture</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
  
        {SecurityArchitectureQuestionsLIST.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasSecureDesignPrinciples ==="" || doesApproveNetworkConnections ==="" || hasSCADAWireless ==="" || hasSegmentedITOTNetworks ==="" || hasSegmentedCriticalAssets ==="" || doesDataCommNetwork ==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box> 
  )
}

export default SecurityArchitectureQuestions