import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Link, Tab, Typography, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import CriticalAssets from './CriticalAssets';
import RestrictedAreas from './RestrictedAreas';
import FERCSecuirtyChecklistv5 from './FERCSecuirtyChecklistv5';
import SecurityAssessmentTool from './SecurityAssessmentTool';
import CorrectiveActions from './CorrectiveActions';
import SiteSecurityAssessment from './SiteSecurityAssessment';
import WidgetWrapper from '../../../components/WidgetWrapper';
import Header from '../../../components/Header';


const SecurityAssessment = ({securityGroup, damData, assessmentID, setProcessValue}) => {
    const [tabValue, setTabValue] = useState('1');
    const theme = useTheme();  
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
  
    const steps = ['Overview', 'Critical Assets', 'Security Assessment', 'Corrective Actions', 'Restricted Areas (Optional)', 'FERC Security Checklist v5', 'Site Security Assessment'];
    // const steps = ['Overview', 'Critical Assets', 'Security Assessment', 'Corrective Actions', 'Restricted Areas (Optional)', 'FERC Security Checklist v5 (Optional)'];
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

   return (
        <Box>
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                    </StepButton>
                </Step>
                ))}
            </Stepper>
           
            <div>
                {allStepsCompleted() ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
                ) : (
                <React.Fragment>
                    {/* <Typography sx={{ mt: 0, mb: 0, py: 1 }}>
                    Step {activeStep + 1} */}
                    {/* </Typography> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 1 }}>
                    <Button
                        variant='contained'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                        Next
                    </Button>
                    {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                        <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                        </Typography>
                        ) : (
                        <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                            ? 'Finish'
                            : 'Complete Step'}
                        </Button>
                        ))}
                    </Box>
                </React.Fragment>
                )}
                <Divider />
            </div>
            <Box>
                {activeStep===0 && (
                    <Box> 
                      <WidgetWrapper sx={{ mt:'2rem' }}>
                        <Typography sx={{ m: '1rem' }}>
                        The Security Assessment (SA) is an evaluation of the current state and appropriateness of the on-site security system/procedures.  The SA addresses:
                        </Typography>
                        <Typography sx={{ marginLeft: '3rem' }}>
                          •	Security measures at a project or facility, such as fences, gates, cameras, increased guards, etc;
                        </Typography>
                        <Typography sx={{ marginLeft: '3rem' }}>
                          •	Any identified security enhancements; 
                        </Typography>
                        <Typography sx={{ marginLeft: '3rem' }}>
                          •	The state of maintenance and readiness of the security systems and procedures. 
                        </Typography>

                        <Typography sx={{ m: '1rem' }}>
                          The Critical Assets, Security Assessment, and Corrective Actions steps are required.  
                        </Typography>

                        <Typography sx={{ m: '1rem' }}>
                          The Restricted Areas and FERC Security Checklist v5 tools are optional.  The <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link> is completed by FERC auditors during your assessment.  The auditors should provide you a copy of this document.
                        </Typography>

                        <Typography sx={{ m: '1rem' }}>
                          If you have a copy of last year's <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link>, then you should include it in your Security Assessment Report.    
                        </Typography>

                        <Typography sx={{ m: '1rem' }}>
                          <u>RECOMMENDATION:</u> You may choose to complete the <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link> either in this tool or offline in Word document provided by FERC <Link href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5">here</Link> to prepare for the audit and review what questions the auditors will ask during the site visit.    
                        </Typography>
                      </WidgetWrapper>

                      <WidgetWrapper sx={{ mt:'2rem' }}>
                        <Header title="Security Assessment Report" subtitle="This report will populate as you complete each section of the Secuirty Assessment tab." />
                        <SiteSecurityAssessment />
                      </WidgetWrapper>

                      {securityGroup==='securityGroup3' &&
                        <Typography sx={{ mr: '3rem' }}>
                          Security Group 3 description and requirements. 
                        </Typography>
                      }

                      {securityGroup==='securityGroup2' &&
                        <Typography>
                          
                        </Typography>
                      }

                      {securityGroup==='securityGroup1' &&
                        <Typography>
                          Security Group 1 description and requirements. 

                        </Typography>
                      }

                        
                    </Box>
                )}
                {activeStep===1 && (
                    <CriticalAssets />
                )}
                 {activeStep===2 && (
                  <SecurityAssessmentTool handleNext={handleNext} activeStep={activeStep} setActiveStep={setActiveStep} />
                    
                )}
                {activeStep===3 && (
                  <CorrectiveActions handleNext={handleNext}  activeStep={activeStep} setActiveStep={setActiveStep} />
                    
                )}
                {activeStep===4 && (
                    <RestrictedAreas />
                )}
                {activeStep===5 && (
                  <FERCSecuirtyChecklistv5 activeStep={activeStep} setActiveStep={setActiveStep} />
                )}
                {activeStep===6 && (
                  <SiteSecurityAssessment handleNext={handleNext} />
                )}
            </Box>
            </Box>
        </Box>
    )
}
        
export default SecurityAssessment