import React from 'react';
import { Position } from 'reactflow';

const rx = 0;
const ry = 0;
const width = 105;
const height = 105;


const AttackerObjective = ({data, selected}) => {
  return (
    <>        
        <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
        <circle cx="50%" cy="50%" r="40" fill="#00b8e6" stroke="black" strokeWidth={2} />
        <title>Attacker Objective</title>
        </svg>
    </>
  )
};


export default AttackerObjective;


