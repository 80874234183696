import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Grid, Tab, Typography, Link, useTheme } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
// import { TabContext, TabList, TabPanel } from '@mui/lab';
// import VulnerabilityOverview from './VulnerabilityOverview';
// import Security from './Security';
// import Consequences from './Consequences';
// import Vulnerabilities from './Vulnerabilities';
// import Likelihood from './Likelihood';
// import Threats from './Threat';
import VulnerabilityReview from './VulnerabilityReview';
import CriticalAssets from '../SecurityAssessment/CriticalAssets';
import SecurityAssessmentTool from '../SecurityAssessment/SecurityAssessmentTool';
import FERCSecuirtyChecklistv5 from '../SecurityAssessment/FERCSecuirtyChecklistv5';
import ThreatVulnLikelihoods from './ThreatVulnLikelihood';
import CorrectiveActions from '../SecurityAssessment/CorrectiveActions';
import WidgetWrapper from '../../../components/WidgetWrapper';
import Header from '../../../components/Header';
import FERCRiskEquationLong from './images/FERCRiskEquationLong.png';
import VulnAssessmentReport from './VulnAssessmentReport';


const VulnerabilityAssessment = ({securityGroup, damData, assessmentID, setProcessValue}) => {
    const [tabValue, setTabValue] = useState('1');
    const theme = useTheme();  
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const handleChange = (event, newValue) => {
    setTabValue(newValue);
    }
    
    const steps = ['Overview', 'Critical Assets', 'Security Assessment', 'Threat Profiles', 'Risk Register', 'Corrective Actions', 'FERC Security Checklist v5', 'Vulnerability Assessment Report' ];
      
    const totalSteps = () => {
        return steps.length;
    };
    
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    
    const handleNext = () => {
        const newActiveStep =
        isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

   return (
    <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                        </StepButton>
                    </Step>
                    ))}
                </Stepper>
               
                <div>
                    {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                    ) : (
                    <React.Fragment>
                        {/* <Typography sx={{ mt: 0, mb: 0, py: 1 }}>
                        Step {activeStep + 1} */}
                        {/* </Typography> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: 1 }}>
                        <Button
                            variant='contained'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                            Next
                        </Button>
                        {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                Step {activeStep + 1} already completed
                            </Typography>
                            ) : (
                            <Button onClick={handleComplete}>
                                {completedSteps() === totalSteps() - 1
                                ? 'Finish'
                                : 'Complete Step'}
                            </Button>
                            ))}
                        </Box>
                    </React.Fragment>
                    )}
                    <Divider />
                </div>
                <Box sx={{ mt:'0.5em' }}>
                    {activeStep===0 && (
                        <Box> 
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <WidgetWrapper>
                                        <Typography sx={{ m: '1rem' }}>
                                        The Security Assessment (SA) is an evaluation of the current state and appropriateness of the on-site security system/procedures.  The SA addresses:
                                        </Typography>
                                        <Typography sx={{ marginLeft: '3rem' }}>
                                        •	Security measures at a project or facility, such as fences, gates, cameras, increased guards, etc;
                                        </Typography>
                                        <Typography sx={{ marginLeft: '3rem' }}>
                                        •	Any identified security enhancements; 
                                        </Typography>
                                        <Typography sx={{ marginLeft: '3rem' }}>
                                        •	The state of maintenance and readiness of the security systems and procedures. 
                                        </Typography>

                                        <Typography sx={{ m: '1rem' }}>
                                        The Critical Assets, Security Assessment, Threat Profiles, Risk Register, and Corrective Actions steps are required.  
                                        </Typography>

                                        <Typography sx={{ m: '1rem' }}>
                                        The Restricted Areas and FERC Security Checklist v5 tools are optional.  The <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link> is completed by FERC auditors during your assessment.  The auditors should provide you a copy of this document.
                                        </Typography>

                                        <Typography sx={{ m: '1rem' }}>
                                        If you have a copy of last year's <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link>, then you should include it in your Security Assessment Report.    
                                        </Typography>

                                        <Typography sx={{ m: '1rem' }}>
                                        <u>RECOMMENDATION:</u> You may choose to complete the <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5"><i>FERC Security Checklist v5</i></Link> either in this tool or offline in Word document provided by FERC <Link href="https://www.ferc.gov/media/ferc-physical-security-checklist-version-5">here</Link> to prepare for the audit and review what questions the auditors will ask during the site visit.    
                                        </Typography>
                                    </WidgetWrapper>
                                </Grid>

                                <Grid item xs={12}>
                                    <WidgetWrapper>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                            <Typography variant='h4' sx={{ mb:'1rem' }}>Caveats:</Typography>
                                                <Typography sx={{ mb:'1rem' }}>The process used in this tool is based on the Vulernability Assessment as defined by FERC in <Link rel='noreferrer' target='_blank' href="https://www.ferc.gov/sites/default/files/2020-04/security.pdf"><i>Federal Energy Regulatory Commission Division of Dam Safety and Inspections FERC Security Program for Hydropower Projects Revision 3A </i></Link>.</Typography>

                                                {/* <Typography>Security for each Critical Asset is evaulated in <u>Step 3: Security Assessment.</u></Typography>
                                                <Typography>Consequences, Vulnerabilities, Likelihoods, and Threats is determined in <u>Step 4: Threat Profiles</u></Typography> */}
                                                
                                                {/* <Typography sx={{ mb:'1rem' }}>FERC recognizes that "...a "pure" Risk value is extremely difficult to obtain...this analysis will lead to a function of defining vulnerabilities which implies "relative" or "conditional" risk."</Typography> */}
                                                <Typography sx={{ ml:'10px', mb:'0.5rem' }}><u>Modifications to FERC's Process:</u></Typography>
                                                <Typography sx={{ mb:'1rem' }}>1. The maker of this tool defined some criteria* for the scale of each risk factor. You may choose to use our scale or make your own determination.</Typography>
                                                <Typography sx={{ mb:'1rem' }}>2. Consequence criteria is adapted from FERC's Consequence Determination and Threshold Criteria found in Chapter 9 of Revision 3A.</Typography>
                                                <Typography sx={{ mb:'1rem' }}>3. Vulnerability criteria is based on some combination of the Detect, Deter, Delay, Defend categories commonly associated iwth physical security.</Typography>
                                                <Typography sx={{ mb:'1rem' }}>4. Likelihood criteria is based on the estimated or actual rate of occurence over a 5-year period.</Typography>
                                                <Typography sx={{ mb:'1rem' }}>5. Threat criteria is based on the perceived Means, Motive, and Opportunity of potential adversaries.</Typography>
                                                <Typography sx={{ mb:'1rem' }}>6. The Risk Register represents the average of all Threat Profiles created for a Critical Asset.</Typography>       
                                                <Typography sx={{ mb:'1rem' }}>7. Only the score for the 'Attacker on Foot' from the Security Assessment is used in determining the Relative Risk Score.</Typography>
                                                <Typography sx={{ mt:'2rem' }}>*The developers of this tool recognize that these criteria are neither perfect nor comprehensive. The criteria is meant to serve as a means to bound your thinking and make these factors somewhat consistent across threat profiles.</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant='h4' sx={{ mb:'1rem' }}>FERC's Vulnerability Assessment Process</Typography>
                                                <Typography sx={{ ml:'10px', mb:'0.5rem' }}><u>Definition:</u></Typography>
                                                <Typography sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}><i>A Vulnerability Assessment (VA) is a formal document and provides an analysis of the factors that define full Security Risk. This assessment ultimately leads to recommended changes to physical security or operational procedures that will serve to decrease overall risk. Traditional risk analysis is defined as (Impact x Threat x Vulnerability). If Risk Analysis is desired by the licensee, a suggested general comparative risk equation that is currently being used by the FERC is shown below, although any method is acceptable (source: DAMSVR; refer to Appendix E) provided it is fully substantiated: </i></Typography>
                                                <Typography sx={{ ml:'10px', mb:'0.5rem' }}><u>Calculation:</u></Typography>
                                                <Box
                                                    sx={{ pl:'2rem', pr:'2rem', minWidth:'350px', mb:'1rem' }}
                                                    component="img"
                                                    width="100%"
                                                    alt="The house from the offer."
                                                    src={FERCRiskEquationLong}
                                                    />
                                                <Typography sx={{ ml:'10px', mb:'0.5rem' }}><u>Scale:</u></Typography>
                                                <Typography display='inline' sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>Consequence: 1-10</Typography>
                                                <Typography display='inline' sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>Vulnerability: 1-10</Typography>
                                                <Typography display='inline' sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>Likelihood: 1-10</Typography>
                                                <Typography display='inline' sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>Threat: 1-10</Typography>
                                                <Typography display='inline' sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>Security: 10-1</Typography>
                                                <Typography sx={{ pr:'2rem', pl:'2rem', mb:'1rem' }}>300 is a scaling factor to normalize to the maximum result.</Typography>
                                            </Grid>
                                        </Grid>
                                    </WidgetWrapper>
                                </Grid>

                                <Grid item xs={12} sx={{ mt:'2rem' }}>
                                    <Header title="Vulnerability Assessment Report" subtitle="This report will populate as you complete each section of the Vulnerability Assessment tab." />
                                    <VulnAssessmentReport />
                                </Grid>
                                
                                

                            </Grid>
                                  

                        </Box>
                    )}
                     {activeStep===1 && (
                        <CriticalAssets activeStep={activeStep} setActiveStep={setActiveStep} />
                    )}
                    {activeStep===2 && (
                        <SecurityAssessmentTool activeStep={activeStep} setActiveStep={setActiveStep} />
                    )}
                    {activeStep===3 && (
                        <ThreatVulnLikelihoods activeStep={activeStep} setActiveStep={setActiveStep} />
                    )}
                    {activeStep===4 && (
                        <VulnerabilityReview activeStep={activeStep} setActiveStep={setActiveStep} />
                    )}
                    {activeStep===5 && (
                        <CorrectiveActions handleNext={handleNext} />
                    )}
                    {activeStep===6 && (
                        <FERCSecuirtyChecklistv5 handleNext={handleNext} />
                    )}
                    {activeStep===7 && (
                        <VulnAssessmentReport activeStep={activeStep} handleNext={handleNext} />
                    )}
                </Box>
            </Box>


    // <Box>
    //     <TabContext value={tabValue}>
    //         <Box sx={{ borderBottom: 5, borderColor: 'divider'}}>
    //             <TabList onChange={handleChange} >
    //                     <Tab sx={{ border: 2 }} label='1-Overview' value='1' />  {/* iconPosition accepts start, bottom, top, end as values*/}
    //                     <Tab sx={{ border: 2 }} label='2-Consequences' value='2' />
    //                     <Tab sx={{ border: 2 }} label='3-Vulnerabilities' value='3' />
    //                     <Tab sx={{ border: 2 }} label='4-Likelihood' value='4' />
    //                     <Tab sx={{ border: 2 }} label='5-Threat' value='5' />
    //                     <Tab sx={{ border: 2 }} label='6-Review Vulnerability Assessment' value='6' />
    //             </TabList>
    //         </Box>
    //         <TabPanel value='1'>
    //             <VulnerabilityOverview damData={damData} setProcessValue={setProcessValue} setTabValue={setTabValue} assessmentID={assessmentID} />
    //         </TabPanel>
    //         <Box sx={{ backgroundColor: theme.palette.background }}>
    //         <TabPanel value='2'>
    //             <Consequences setTabValue={setTabValue} assessmentID={assessmentID} />
    //         </TabPanel>
    //         <TabPanel value='3'>
    //             <Vulnerabilities />
    //         </TabPanel>
    //         <TabPanel value='4'>
    //             <Likelihood />
    //         </TabPanel>
    //         <TabPanel value='5'>
    //             <Threat />
    //         </TabPanel>
    //         <TabPanel value='6'>
    //             <VulnerabilityReview />
    //         </TabPanel>
    //         </Box>
    //     </TabContext>
    //     </Box>
         )
        }
        
        export default VulnerabilityAssessment