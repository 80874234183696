import React from 'react'

const CSFAssessment = () => {
  return (
    <div>Coming Soon!</div>
  )
}

export default CSFAssessment


// import React, { useRef, useState, useEffect } from 'react';
// import { useParams, useNavigate, useLocation } from 'react-router-dom';
// import { Box, Button, Collapse, Divider, FormControl, FormGroup, FormLabel, FormControlLabel, FormHelperText, IconButton, Input, InputLabel, Link, ListItem, ListItemButton, 
//   Radio, RadioGroup, Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
// import useAxiosPrivate from '../../hooks/useAxiosPrivate';
// import QuestionnaireForm from '../../components/QuestionnaireForm';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepButton from '@mui/material/StepButton';
// import ListMapFunc from './listMapFunc';
// import Header from '../../components/Header';
// import WidgetWrapper from '../../components/WidgetWrapper';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
// import csfTiers from './images/csfTiers.png';
// import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Legend } from 'recharts';


// const CSFAssessment = () => {

//   const [questions, setQuestions] = useState();
//   const axiosPrivate = useAxiosPrivate();
//   const { assessmentID } = useParams();
//   const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
//   const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
//   const theme = useTheme();

//   const [openInstructions, setOpenInstructions] = useState(true);
//   const [assessmentCSFData, setAssessmentCSFData] = useState();

//   const [activeFunction, setActiveFunction] = useState(0);




//     // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
// const getAssessmentData = async () => {
//   try {
//     const response = await axiosPrivate.get(`/csf/data/${assessmentID}`, {
//       withCredentials: true
//     });
//     const data = await response.data;

//     console.log(data);
//     setAssessmentCSFData(JSON.parse(data.assessmentAnswersData));

//     } catch (err) {
//     // alert("Login Expirsed.");
//     console.error(err);
//     // navigate('/login', { state: { from: location }, replace: true });
//   }
// };

// useEffect(() => {
//   // getCSFQuestions();
//   // getAllCSFQuestions();
//   getAssessmentData();
//   // createList(activeFunction, activeStep);
// }, []);  // eslint-disable-line react-hooks/exhaustive-deps
// // if (!questions2) {
// //   return null;
// // }


// const handleSubmit = async (e) => {
//   e.preventDefault();

//   const formData = new FormData(e.target);
//   const assessmentAnswersData = Object.fromEntries(formData);

//   // console.log("Submit called.");
//   // console.log(JSON.stringify(payload));

//   // const assessmentAnswersData = JSON.stringify({ payload });
//   try {
//     const response = await axiosPrivate.patch(`/csf/csf/${assessmentID}`,
//       JSON.stringify( { assessmentAnswersData } ),
//     {
//         headers: { 'Content-Type': 'application/json' },
//         withCredentials: true
//     }
// );
// // questionFamily(1) activates the Access Control questions. 
// // handleNext();
// window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
// } catch (err) {
//     if (!err?.response) {
//         console.log('No Server Response');
//     } else {
//         console.log('Action Failed')
//     }
//   }
// }

//   const handleOpenInstructions = () => {
//     setOpenInstructions(!openInstructions);
//   };

//   console.log(assessmentCSFData);

//   return (
//     <Box
//     display="grid"
//     alignItems="downward"
//     gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
//     sx={{
//       p: '1em',
//         "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
//     }}
//   >
//     <Grid container spacing={2} >
      
//       <Grid item xs={12}>
//         <WidgetWrapper>
//        CSF PAGE
//         </WidgetWrapper>
//       </Grid>

//       {/**INSTRUCTIONS BLOCK */}
//       <Grid item xs={12}>
//         <Paper >
//           {openInstructions ? (
//             <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
//                 <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
//                 <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
//             </IconButton>
//             ) : (
//               <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
//                 <ExpandCircleDownIcon color='primary' fontSize="large" />
//                 <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
//               </IconButton>
//             )}
//           <Divider sx={{ border:0.5, mt:'6px' }}/> 
//           <Collapse in={openInstructions} timeout="auto" unmountOnExit>
//             <Grid container spacing={2} direction='columns' sx={{ display:'flex'}}>
              
//               <Grid item xs={12} sx={{ ml:'1rem' }}>
//                 <Typography sx={{ p:'6px' }}>
//                   Select the answer that best describes your organization's current posture for each CSF subcategory.
//                 </Typography>
//                 <Typography sx={{ p:'6px' }}>
//                   Below are notional descriptions for this function as described in Appendix B: CSF Tiers of <Link rel='noreferrer' target='_blank' href='https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.29.pdf'><i>NIST Cybersecurity Framework 2.0</i>.</Link>
//                 </Typography>
//               </Grid>

//               <Grid item xs={9}>
//                 <Grid container spacing={1}>
//                 {activeFunction === 0 ? (
//                   <>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Partial: </Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>Application of the organizational cybersecurity risk strategy is managed in an ad hoc manner.</li></Typography> 
//                       <Typography sx={{ p:'6px' }}><li>Prioritization is ad hoc and not formally based on objectives or threat environment.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Informed</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>Risk management practices are approved by management but may not be established as organization-wide policy.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The prioritization of cybersecurity activities and protection needs is directly informed by organizational risk objectives, the threat environment, or business/mission requirements.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Repeatable</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>The organization’s risk management practices are formally approved and expressed as policy.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Risk-informed policies, processes, and procedures are defined, implemented as intended, and reviewed.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Organizational cybersecurity practices are regularly updated based on the application of risk management processes to changes in business/mission requirements, threats, and technological landscape.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Adaptive</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>There is an organization-wide approach to managing cybersecurity risks that uses risk-informed policies, processes, and procedures to address potential cybersecurity events. The relationship between cybersecurity risks and organizational objectives is clearly understood and considered when making decisions. Executives monitor cybersecurity risks in the same context as financial and other organizational risks. The organizational budget is based on an understanding of the current and predicted risk environment and risk tolerance. Business units implement executive vision and analyze system-level risks in the context of the organizational risk tolerances.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Cybersecurity risk management is part of the organizational culture. It evolves from an awareness of previous activities and continuous awareness of activities on organizational systems and networks. The organization can quickly and efficiently account for changes to business/mission objectives in how risk is approached and communicated.</li></Typography>
//                     </Grid>
//                   </>

//                 ) : (
//                   <>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Partial</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>There is limited awareness of cybersecurity risks at the organizational level.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization implements cybersecurity risk management on an irregular, case-by-case basis.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization may not have processes that enable cybersecurity information to be shared within the organization.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization is generally unaware of the cybersecurity risks associated with its suppliers and the products and services it acquires and uses.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Informed</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>There is an awareness of cybersecurity risks at the organizational level, but an organization-wide approach to managing cybersecurity risks has not been established.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Consideration of cybersecurity in organizational objectives and programs may occur at some but not all levels of the organization. Cyber risk assessment of organizational and external assets occurs but is not typically repeatable or reoccurring.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Cybersecurity information is shared within the organization on an informal basis.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization is aware of the cybersecurity risks associated with its suppliers and the products and services it acquires and uses, but it does not act consistently or formally in response to those risks.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Repeatable</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>There is an organization-wide approach to managing cybersecurity risks. Cybersecurity information is routinely shared throughout the organization.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Consistent methods are in place to respond effectively to changes in risk. Personnel possess the knowledge and skills to perform their appointed roles and responsibilities.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization consistently and accurately monitors the cybersecurity risks of assets. Senior cybersecurity and non-cybersecurity executives communicate regularly regarding cybersecurity risks. Executives ensure that cybersecurity is considered through all lines of operation in the organization.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization risk strategy is informed by the cybersecurity risks associated with its suppliers and the products and services it acquires and uses. Personnel formally act upon those risks through mechanisms such as written agreements to communicate baseline requirements, governance structures (e.g., risk councils), and policy implementation and monitoring. These actions are implemented consistently and as intended and are continuously monitored and reviewed.</li></Typography>
//                     </Grid>
//                     <Grid item xs={1} sx={{ ml:'2rem', alignContent:'center' }}>
//                       <Typography>Adaptive</Typography>
//                     </Grid>
//                     <Grid item xs={10} sx={{ ml:'2rem' }}>
//                       <Typography sx={{ p:'6px' }}><li>The organization adapts its cybersecurity practices based on previous and current cybersecurity activities, including lessons learned and predictive indicators. Through a process of continuous improvement that incorporates advanced cybersecurity technologies and practices, the organization actively adapts to a changing technological landscape and responds in a timely and effective manner to evolving, sophisticated threats.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>The organization uses real-time or near real-time information to understand and consistently act upon the cybersecurity risks associated with its suppliers and the products and services it acquires and uses.</li></Typography>
//                       <Typography sx={{ p:'6px' }}><li>Cybersecurity information is constantly shared throughout the organization and with authorized third parties.</li></Typography>
//                     </Grid>
//                   </>
//                 )}
//               </Grid> 
//               </Grid>

//               <Grid item xs={3}>
//                 <Box
//                   component="img"
//                   width="100%"
//                   alt="Description of CSF Tiers 1 through 4."
//                   src={csfTiers}
//                   />
//                   <Typography><i>Tiers</i> characterize the rigor of an organization’s cybersecurity risk governance and management practices, and they provide context for how an organization views cybersecurity risks and the processes in place to manage those risks.</Typography>
//               </Grid>
//             </Grid>
//             <Divider sx={{ border:0.5, mt:'6px', mb:'6px' }}/>                 
//           </Collapse>
//         </Paper>
//       </Grid>
//       {/**END - INSTRUCTIONS BLOCK */}
      
//       <Grid item xs={12} >
//         <form onSubmit={handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={2}>
//               <Typography>GVOC-01: </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <FormControl>
//                 <Typography>Add the question here. </Typography>
//                 {/* <InputLabel htmlFor="my-input">Email address</InputLabel> */}
//                 <Input 
//                   id="my-input" 
//                   aria-describedby="my-helper-text" 
//                   type='email'
//                   name='gvoc1'
                  
//                   />
//               <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
//               </FormControl>
//             </Grid>
//             <Grid item xs={2}>
//               <Typography>GVOC-02: </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <FormControl>
//                 <Typography>Add the question here.</Typography>
//               {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
//               <RadioGroup
//                 aria-labelledby="demo-radio-buttons-group-label"
//                 defaultValue="female"
//                 name="gvoc2"
//               >
//                 <FormControlLabel name="gvoc2" value="female" checked={assessmentCSFData?.gvoc2==='female'} control={<Radio />} label="Female" />
//                 <FormControlLabel name="gvoc2" value="male"  control={<Radio />} label="Male" />
//                 <FormControlLabel name="gvoc2" value="other"  control={<Radio />} label="Other" />
//               </RadioGroup>
//               <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
//               </FormControl>
//             </Grid>
            


//           </Grid>
          

//         <Grid item xs={12}>
//           <Button variant='contained' type='submit'>Submit</Button>
//         </Grid>
        

//         </form>
//       </Grid>

//     </Grid>
//   </Box>
//   )
// }

// export default CSFAssessment