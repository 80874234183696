import React from 'react';
import { Position } from 'reactflow';

const rx = 0;
const ry = 0;
const width = 20;
const height = 20;


const IntrusionDetectionSystem = ({data, selected}) => {
  return (
    <>         
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} rx={rx} ry={ry} fill="#99004d" stroke="black" strokeWidth={2}>
            <title>Intrusion Detection System</title>
        </rect>
      </svg>
    </>
  )
};


export default IntrusionDetectionSystem;
