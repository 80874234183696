import React, { useState, useEffect } from 'react';
import { Alert, Box, Card, CardContent, CardActions, Collapse, Button, Typography, Divider, useTheme, useMediaQuery,
     Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';


const AssessmentCards = ({
    _id,
    assessmentID,
    damName,
    assessmentType,
    assessmentYear,
    status,
    startDate,
    renewByDate,
    estimatedSecurityGroup,
    securityGroupChosen,
    securityGroupVerified,
    securityGroupLocal,
    refresh,
    setRefresh

}) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const [assessmentToDelete, setAssessmentToDelete] = useState();
    const [damNameToDelete, setDamNameToDelete] = useState();
    const [open, setOpen] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    
    const handleDeleteAssessment = async () => {
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentToDelete}`, 
            ({ "active": false }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
    } catch (err) {
        console.error(err);
    }
    setRefresh(!refresh);
    setAssessmentToDelete(null);
    setDamNameToDelete(null);
    }
    
    const handleClickOpen = (assessmentID, damName) => {
        setAssessmentToDelete(assessmentID);
        setDamNameToDelete(damName);
        setOpen(true);
      };
    
    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = () => {
        handleDeleteAssessment();
        setOpen(false);
    };
    
    const handleEditRequest = (assessmentID, securityGroupLocal) => {
        if (securityGroupLocal==="Security Group 3") {
            navigate(`/fercassessment/securitygroup3/${assessmentID}`);
        } else if (securityGroupLocal==="Security Group 2") {
            navigate(`/fercassessment/securitygroup2/${assessmentID}`); 
        } else if (securityGroupLocal==="Security Group 1") {
            navigate(`/fercassessment/securitygroup1/${assessmentID}`);
        } else {
            navigate(`/fercassessment/${assessmentID}`);
        }
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Verify Delete Command"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the {damNameToDelete} assessment?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleYes}>Yes</Button>
                <Button onClick={handleNo} autoFocus>
                    No
                </Button>
                </DialogActions>
            </Dialog>
            <Card sx={{ width: 2/2, backgroundImage: "none", backgroundColor: theme.palette.background, borderRadius: "0.55rem", border: '4px'}} >
                <CardContent>
                    <Typography color={theme.palette.secondary[700]} gutterBottom>
                        {assessmentYear} {damName}
                    </Typography>
                    <Divider />
                    <Typography color={theme.palette.secondary[400]}>
                        Type: {(assessmentType==="annualAssessment") ? ("Annual") : (assessmentType==="closureRequest") ? ("Closure Request") : ("Other") }
                    </Typography>
                    <Typography>
                        {securityGroupLocal}
                        {/* {(securityGroupVerified==='yes') ? (estimatedSecurityGroup) : (securityGroupChosen) }             */}
                    </Typography>
                    <Typography color={theme.palette.secondary[400]}>
                        Status: {(status==="inprogress") ? ("In-progress") : ("Complete") }
                    </Typography>
                    <Typography sx={{ mb: "1rem" }}  component="div">
                        Renew By: {renewByDate ? (renewByDate) : ("Not set.") }
                    </Typography>
                    <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleClickOpen(assessmentID, damName)}}>Delete</Button>
                    {/* <Button sx={{marginRight: '1rem'}} variant='contained' onClick={() => {handleDeleteAssessment(assessmentID, damName)}}>Delete</Button> */}
                    <Button variant='contained' onClick={() => {handleEditRequest(assessmentID, securityGroupLocal)}}>Edit</Button>
                    {/* <Rating value={rating} readOnly /> */}
                    {/* <Typography variant="body2">{description}</Typography> */}
                </CardContent>
                <CardActions>
                    <Button 
                        variant="primary"
                        size="small"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        See More
                    </Button>
                </CardActions>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
                    <CardContent>
                        <Typography>Assessment Started: {startDate}</Typography>
                        <Typography>Other: {assessmentID}</Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Box>
    )
 }

const UserAssessmentWidget = ({ userAssessmentData, setRefresh, refresh }) => {

    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    

    const handleFormatDate = (date) => {
        const d = new Date(date);
        const dateFormatted = d.toLocaleDateString("en-US");
        return dateFormatted;
    }

    return (
        <Box m="1.5rem 2.5rem">
            <Box mt="20px" display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))" 
                justifyContent="space-between" 
                rowGap="20px" 
                columnGap="1.33%" 
                sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
            >
                {userAssessmentData.map(

                    ({  
                    _id,
                    damName,
                    assessmentType,
                    startDate,
                    renewByDate,
                    assessmentYear,
                    currentStatus,                  // add status to assessment model. 
                    estimatedSecurityGroup,
                    securityGroupChosen,
                    securityGroupVerified,
                    securityGroupLocal
                }) => (
                    <AssessmentCards
                        key={_id}
                        assessmentID={_id}
                        damName={damName}
                        assessmentType={assessmentType}
                        startDate={handleFormatDate(startDate)}
                        assessmentYear={assessmentYear}
                        status={currentStatus}                      // change after adding status to assessmetn model.
                        renewByDate={handleFormatDate(renewByDate)}
                        estimatedSecurityGroup={estimatedSecurityGroup}
                        securityGroupChosen={securityGroupChosen}
                        securityGroupVerified={securityGroupVerified}
                        securityGroupLocal={securityGroupLocal}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                    )
                )}
            </Box>
        </Box>
    )
};

export default UserAssessmentWidget;