import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Collapse, Typography, IconButton, useTheme, useMediaQuery, Link, Grid, Paper, Divider, RadioGroup, 
  FormControlLabel, MenuItem, List, ListItem, ListItemIcon, Radio, Select, Tooltip } from "@mui/material";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridColDef
} from '@mui/x-data-grid';
import CircleIcon from '@mui/icons-material/Circle';
import HelpIcon from '@mui/icons-material/Help';
import SecurityAssessmentResults from './SecurityAssessmentResults';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';


const SecurityAssessmentTool = ({activeStep, setActiveStep}) => {

  const valueComplete = '5';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();

  const [refresh, setRefresh] = useState();
  const [rows, setRows] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [openInstructions, setOpenInstructions] = useState(true);

  const [externalAccess, setExternalAccess] = useState(0);
  const [allCriticalAssetData, setAllCriticalAssetData] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});

  // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
const getCriticalAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setRows(data);
    setAllCriticalAssetData(data);
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};

  useEffect(() => {
      getCriticalAssets();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!rows) {
      return null;
    }
    


    // Update Database Function 
  const handleAssetUpdate2 = async (updatedRow) => {
    let id = updatedRow._id;
    let externalAccess = updatedRow.externalAccess;
    let physicalSecurity = updatedRow.physicalSecurity;
    let detection = updatedRow.detection;
    let delay = updatedRow.delay;
    let response = updatedRow.response;
    let responseTime = updatedRow.responseTime;
    let assessmentExternal = updatedRow.assessmentExternal;
    let assessmentInternal = updatedRow.assessmentInternal;
    let securityAssessment = updatedRow.securityAssessment;
    let securityPlan = updatedRow.securityPlan;
    let cybersecurity = updatedRow.cybersecurity;
    let cpme = updatedRow.cpme;
    let cdpr = updatedRow.cdpr;
    
    let externalAccessVehicle = updatedRow.externalAccessVehicle;
    let physicalSecurityVehicle = updatedRow.physicalSecurityVehicle;
    let detectionVehicle = updatedRow.detectionVehicle;
    let delayVehicle = updatedRow.delayVehicle;
    let responseVehicle = updatedRow.responseVehicle;
    let responseTimeVehicle = updatedRow.responseTimeVehicle;
    let assessmentExternalVehicle = updatedRow.assessmentExternalVehicle;
    let assessmentInternalVehicle = updatedRow.assessmentInternalVehicle;
    let securityAssessmentVehicle = updatedRow.securityAssessmentVehicle;
    let securityPlanVehicle = updatedRow.securityPlanVehicle;
    let cybersecurityVehicle = updatedRow.cybersecurityVehicle;
    let cpmeVehicle = updatedRow.cpmeVehicle;
    let cdprVehicle = updatedRow.cdprVehicle;

    let externalAccessBoat = updatedRow.externalAccessBoat;
    let physicalSecurityBoat = updatedRow.physicalSecurityBoat;
    let detectionBoat = updatedRow.detectionBoat;
    let delayBoat = updatedRow.delayBoat;
    let responseBoat = updatedRow.responseBoat;
    let responseTimeBoat = updatedRow.responseTimeBoat;
    let assessmentExternalBoat = updatedRow.assessmentExternalBoat;
    let assessmentInternalBoat = updatedRow.assessmentInternalBoat;
    let securityAssessmentBoat = updatedRow.securityAssessmentBoat;
    let securityPlanBoat = updatedRow.securityPlanBoat;
    let cybersecurityBoat = updatedRow.cybersecurityBoat;
    let cpmeBoat = updatedRow.cpmeBoat;
    let cdprBoat = updatedRow.cdprBoat;

    try {
      const responsePatch = await axiosPrivate.patch(`/criticalassets/criticalasset/${id}`,
        // { "externalAccess": externalAccess, "physicalSecurity": physicalSecurity, "detection": detection },
        JSON.stringify({ 
          externalAccess, physicalSecurity, detection, delay, response, responseTime, assessmentExternal, assessmentInternal, securityAssessment, securityPlan, cybersecurity, cpme, cdpr,
          externalAccessVehicle, physicalSecurityVehicle, detectionVehicle, delayVehicle, responseVehicle, responseTimeVehicle, assessmentExternalVehicle, assessmentInternalVehicle, securityAssessmentVehicle, securityPlanVehicle, cybersecurityVehicle, cpmeVehicle, cdprVehicle,
          externalAccessBoat, physicalSecurityBoat, detectionBoat, delayBoat, responseBoat, responseTimeBoat, assessmentExternalBoat, assessmentInternalBoat, securityAssessmentBoat, securityPlanBoat, cybersecurityBoat, cpmeBoat, cdprBoat,
         }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await responsePatch.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.Conswquence Threshold");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    };

// Update Database Function 
const handleAssetUpdate = async (e, params) => {
  for (let index in allCriticalAssetData) {
    if (allCriticalAssetData[index]._id===params.row._id) {
      allCriticalAssetData[index][params.field] = e;
      
      // console.log(allCriticalAssetData[index].externalAccess);
      let id = allCriticalAssetData[index]._id
      let externalAccess = allCriticalAssetData[index].externalAccess;
      let physicalSecurity = allCriticalAssetData[index].physicalSecurity;
      let detection = allCriticalAssetData[index].detection;
      let delay = allCriticalAssetData[index].delay;
      let response = allCriticalAssetData[index].response;
      let responseTime = allCriticalAssetData[index].responseTime;
      let assessmentExternal = allCriticalAssetData[index].assessmentExternal;
      let assessmentInternal = allCriticalAssetData[index].assessmentInternal;
      let securityAssessment = allCriticalAssetData[index].securityAssessment;
      let securityPlan = allCriticalAssetData[index].securityPlan;
      let cybersecurity = allCriticalAssetData[index].cybersecurity;
      let cpme = allCriticalAssetData[index].cpme;
      let cdpr = allCriticalAssetData[index].cdpr;
      
      let externalAccessVehicle = allCriticalAssetData[index].externalAccessVehicle;
      let physicalSecurityVehicle = allCriticalAssetData[index].physicalSecurityVehicle;
      let detectionVehicle = allCriticalAssetData[index].detectionVehicle;
      let delayVehicle = allCriticalAssetData[index].delayVehicle;
      let responseVehicle = allCriticalAssetData[index].responseVehicle;
      let responseTimeVehicle = allCriticalAssetData[index].responseTimeVehicle;
      let assessmentExternalVehicle = allCriticalAssetData[index].assessmentExternalVehicle;
      let assessmentInternalVehicle = allCriticalAssetData[index].assessmentInternalVehicle;
      let securityAssessmentVehicle = allCriticalAssetData[index].securityAssessmentVehicle;
      let securityPlanVehicle = allCriticalAssetData[index].securityPlanVehicle;
      let cybersecurityVehicle = allCriticalAssetData[index].cybersecurityVehicle;
      let cpmeVehicle = allCriticalAssetData[index].cpmeVehicle;
      let cdprVehicle = allCriticalAssetData[index].cdprVehicle;

      let externalAccessBoat = allCriticalAssetData[index].externalAccessBoat;
      let physicalSecurityBoat = allCriticalAssetData[index].physicalSecurityBoat;
      let detectionBoat = allCriticalAssetData[index].detectionBoat;
      let delayBoat = allCriticalAssetData[index].delayBoat;
      let responseBoat = allCriticalAssetData[index].responseBoat;
      let responseTimeBoat = allCriticalAssetData[index].responseTimeBoat;
      let assessmentExternalBoat = allCriticalAssetData[index].assessmentExternalBoat;
      let assessmentInternalBoat = allCriticalAssetData[index].assessmentInternalBoat;
      let securityAssessmentBoat = allCriticalAssetData[index].securityAssessmentBoat;
      let securityPlanBoat = allCriticalAssetData[index].securityPlanBoat;
      let cybersecurityBoat = allCriticalAssetData[index].cybersecurityBoat;
      let cpmeBoat = allCriticalAssetData[index].cpmeBoat;
      let cdprBoat = allCriticalAssetData[index].cdprBoat;

    try {
      const responsePatch = await axiosPrivate.patch(`/criticalassets/criticalasset/${id}`,
        // { "externalAccess": externalAccess, "physicalSecurity": physicalSecurity, "detection": detection },
        JSON.stringify({ 
          externalAccess, physicalSecurity, detection, delay, response, responseTime, assessmentExternal, assessmentInternal, securityAssessment, securityPlan, cybersecurity, cpme, cdpr,
          externalAccessVehicle, physicalSecurityVehicle, detectionVehicle, delayVehicle, responseVehicle, responseTimeVehicle, assessmentExternalVehicle, assessmentInternalVehicle, securityAssessmentVehicle, securityPlanVehicle, cybersecurityVehicle, cpmeVehicle, cdprVehicle,
          externalAccessBoat, physicalSecurityBoat, detectionBoat, delayBoat, responseBoat, responseTimeBoat, assessmentExternalBoat, assessmentInternalBoat, securityAssessmentBoat, securityPlanBoat, cybersecurityBoat, cpmeBoat, cdprBoat,
         }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await responsePatch.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        alert("Login Expired.Conswquence Threshold");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  }
  };

const physicalSecurityScenario = [
  {
    id: 'foot',
    name: 'Attacker on Foot',
    description: 'A single attacker or small group of attackers (2-3 persons) with no equipment attempts to access your critical assets. These attackers only have publicly available information about your organization, your procedures, and your critical assets.'
  },
  {
    id: 'vehicle',
    name: 'Attacker in Vehicle',
    description: 'Vehicle attacker scenario'
  },
  {
    id: 'boat',
    name: 'Attacker on Boat',
    description: 'Boat attacker scenario'
  }
];

const columnFieldsFoot = [ 'externalAccess', 'physicalSecurity', 'detection', 'delay', 'response', 'responseTime', 'assessmentExternal', 'assessmentInternal', 'securityAssessment', 'securityPlan', 'cybersecurity', 'cpme', 'cdpr' ]
const columnFieldsVehicle = [ 'externalAccessVehicle', 'physicalSecurityVehicle', 'detectionVehicle', 'delayVehicle', 'responseVehicle', 'responseTimeVehicle', 'assessmentExternalVehicle', 'assessmentInternalVehicle', 'securityAssessmentVehicle', 'securityPlanVehicle', 'cybersecurityVehicle', 'cpmeVehicle', 'cdprVehicle'
 ]
const columnFieldsBoat = [ 'externalAccessBoat', 'physicalSecurityBoat', 'detectionBoat', 'delayBoat', 'responseBoat', 'responseTimeBoat', 'assessmentExternalBoat', 'assessmentInternalBoat', 'securityAssessmentBoat', 'securityPlanBoat', 'cybersecurityBoat', 'cpmeBoat', 'cdprBoat' ]



     // FERC Security Assessment Tool Determination Questions
const assetDeterminationQuestions = [
  {
    id: 'externalAccess-1',
    questionCategory: 'External Access',
    questionCategoryDescription: 'Which statement best describes the visual access with asset protections (fences, signs, etc.) based on physical location, structure/equipment, resiliency and facility security?',
    questionMetricTitle: '',
    questionMetrics1: '',
    questionMetrics2: '',
    questionMetrics3: '',
    questionMetrics4: '',
    questionMetrics5: '',
    questionTextAug1: '1. High visibility/Highly Accessible.',
    questionTextAug2: '2. High visibility/good access control (barrier/electronic/human).',
    questionTextAug3: '3. Low visibility/good access control (barrier/electronic/human).',
    questionTextAug4: '4. Low visibility/inaccessible.',
    questionNumber: '1. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'physicalSecurity-1',
    questionCategory: 'Physical Security',
    questionCategoryDescription: 'Which statement best describes the physical security of each Critical Asset with respect to the security measures listed below?',
    questionMetricTitle: 'Security Measure:',
    questionMetric1: 'Key and lock protection on doors and windows for this critical asset.',
    questionMetric2: 'Perimeter protection, such as gates, fences, walls, and bollards.',
    questionMetric3: 'Photo ID and badge program, including required wearing of badges where appropriate and users trained to look for photo/IDs or badges.',
    questionMetric4: 'Electronic Access Control, such as using your badge to access buildings or gates.',
    questionMetric5: 'Guards posted or patrolling.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '2. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'detection-1',
    questionCategory: 'Detection',
    questionCategoryDescription: 'Which statement best describes the detection systems available at the associated Critical Asset with respect to the systems and processes listed below?',
    questionMetricTitle: 'Detection Systems or Processes:',
    questionMetric1: 'This site is manned and the Critical Asset can be observed.',
    questionMetric2: 'Surveillance cameras are in use and monitored by employees and/or guards.',
    questionMetric3: 'Personnel are required to wear their Photo ID or badge when on site.',
    questionMetric4: 'Walking inspections/patrols are regularly completed by employees and/or guards.',
    questionMetric5: '',
    questionTextAug1: '1. None of the security measures listed above apply to this critical asset.',
    questionTextAug2: '2. Only one or two of the security measures listed above apply to this critical asset.',
    questionTextAug3: '3. Three of the security measures listed above apply to this critical asset.',
    questionTextAug4: '4. All four of the security measures listed above apply to this critical asset.',
    questionNumber: '3. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'delay-1',
    questionCategory: 'Delay',
    questionCategoryDescription: 'Which statement best describes the systems designed to delay an attacker at the associated Critical Asset with respect to the delay systems and measures listed below?',
    questionMetricTitle: 'Delay Systems:',
    questionMetric1: 'Dam/assets fenced with gates/doors access controlled.',
    questionMetric2: 'Access restrictions in-place (foot/vehicle/boat) and appropriate.',
    questionMetric3: 'Spillway, spillway gate controls secured from unauthorized access.',
    questionMetric4: 'Powerhouse-accessed controlled/alarmed/CCTV/motion detection.',
    questionMetric5: 'Physical protection equipment secured against tampering.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '4. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'response-1',
    questionCategory: 'Response',
    questionCategoryDescription: "Which statement best describes site personnel's ability to notify law enforcement and the law enforcement response for the associated Critical Asset with respect to the security measures listed below?",
    questionMetricTitle: 'Law Enforcement Notification and Response:',
    questionMetric1: 'Law Enformcement phone numbers are posted and personnel notify them quickly when necessary.',
    questionMetric2: 'The site has redundant communications methods.',
    questionMetric3: 'The site quickly detects and responds to unauthorized access attempts, including notifying law enforcement when necessary.',
    questionMetric4: 'Law Enforcement responders include State and Local agencies.',
    questionMetric5: 'Law Enforcement reponders may include federal agencies.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '5. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'responseTime-1',
    questionCategory: 'Response Time',
    questionCategoryDescription: 'Which statement best describes the expected response time of law enforcement to the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. The Critical Asset is in a remote area with no law enforcement patrol and no intrusion alerting.',
    questionTextAug2: '2. Intrusion alert with 30 minute plus law enforcement response. ',
    questionTextAug3: '3. Intrusion alert with under 15 minute law enforcement response.',
    questionTextAug4: '4. Guard service/law enforcement on duty at location.',
    questionNumber: '6. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'assessmentExternal-1',
    questionCategory: 'External Threat Assessment',
    questionCategoryDescription: 'Based on your experience and law enforcement experience or intelligence, which statement best describes the relative measure of external threats against the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Current active threats/vandalism/theft/trespass.',
    questionTextAug2: '2. Current oral threats/vandalism/theft/trespass.',
    questionTextAug3: '3. There have benn active threats/vandalism/theft/trespass at the associated Critical Asset.',
    questionTextAug4: '4. There are no known threats or past activity.',
    questionNumber: '7. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'assessmentInternal-1',
    questionCategory: 'Internal Threat Assessment',
    questionCategoryDescription: 'Based on your experience and law enforcement experience or intelligence, which statement best describes the relative measure of internal threats against the associated Critical Asset?',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Current Insider Threat: There is at least one active insider threat.',
    questionTextAug2: '2. Current Suspected Insider Threat: In the last two years, there has been at least one oral or written threat from a current employee or recently dismissed employee.',
    questionTextAug3: '3. Former Insdier Threat: In the last two years, a former employee was an active threat to other personnel or equipment.',
    questionTextAug4: '4. No Insider Threat: There are no known insider threats.',
    questionNumber: '8. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'securityPlan-1',
    questionCategory: 'Security Plan',
    questionCategoryDescription: 'Which statement best describes the completeness and accuracy of the Security Plan with respect to the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: 'Documentation of restricted areas, physical dam related assets, physical security protections, descriptions, layout and inventory.',
    questionMetric2: 'Security Operational Procedures (employee duties, education, training, document controls) w/Emergency.',
    questionMetric3: 'Emergency notification, communication and redundancies (EAP coordination).',
    questionMetric4: 'DHS Threat Level Contingency Planning.',
    questionMetric5: 'Responses (Bomb Threats, Civil Distrubance, Temporary Project Closure, etc...), procedures for SiteAccess Control and Security equipment maintenance and testing (NERC.CIP 6, R6).',
    questionTextAug1: '1. Unacceptable: The Security Plan needs significant improvements to thoroughly cover the topics listed above for the associated Critical Asset.',
    questionTextAug2: '2. Limit Acceptability: The Security Plan needs some improvements but most of the security topics listed above are addressed.',
    questionTextAug3: '3. Acceptable with Modifications: The Security Plan needs some limited improvements and the organization knows which areas to address.',
    questionTextAug4: '4. Acceptable: The Security Plan is designed to "reasonably" deter, detect, deny, and delay external threats defined by the organization until law enforcement can respond.',
    questionNumber: '10. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'Cybersecuirty-1',
    questionCategory: 'Cybersecurity',
    questionCategoryDescription: 'Which statement best describes the Operational Technology (OT) systems and networks at the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. OT systems and/or networks are not protected from Internet intrusion.',
    questionTextAug2: '2. OT systems and/or networks are stand alone (air gapped) or has firewall protection from Internet intrusion.',
    questionTextAug3: '3. Firewalls and internal systems received regular software patches.',
    questionTextAug4: '4. NERC/CIP standards are in place and audited for all OT systems and/or networks at the associated Critical Asset or, there are no cyber/SCADA systems used at the project.',
    questionNumber: '11. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'securityAssessment-1',
    questionCategory: 'Security Assessment',
    questionCategoryDescription: "Which statement best describes your confidence level in this Security Assessment for the associated Critical Asset with respect to the factors listed below?",
    questionMetricTitle: 'Security Assessment Factors:',
    questionMetric1: 'List of critical physical dam assets, structures, appurtenant structures.',
    questionMetric2: 'Inherent accessibility and visibility of each identified asset.',
    questionMetric3: 'Security system procedures/responses to potential adversaries.',
    questionMetric4: 'Security improvement recommendations with plans and schedules.',
    questionMetric5: '',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '12. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'CPME-1',
    questionCategory: 'Critical Performance Monitoring Equipment',
    questionCategoryDescription: 'Which statement best describes the monitoring equipment available at the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Requires attention (provide plan and schedule).',
    questionTextAug2: '2. Limited acceptability (requires additions, upgrades-provide plan and schedule).',
    questionTextAug3: '3. Acceptable-under modification (plan and schedule available).',
    questionTextAug4: '4. Acceptable.',
    questionNumber: '13. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'CDP&R-1',
    questionCategory: 'Critical Drawings, Plans, and Records',
    questionCategoryDescription: 'Which statement best describes the security documentation for the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Requires attention (provide plan and schedule).',
    questionTextAug2: '2. Limited acceptability (requires additions, upgrades-provide plan and schedule).',
    questionTextAug3: '3. Acceptable-under modification (plan and schedule available).',
    questionTextAug4: '4. Acceptable.',
    questionNumber: '14. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
]

const UpdateRemoteAccessQuestions = async (e, params) => {

  for (let index in allCriticalAssetData) {
    if (allCriticalAssetData[index]._id===params.row._id) {
      allCriticalAssetData[index].externalAccess = e;
      }
      console.log(allCriticalAssetData[index].externalAccess);
  }
  handleAssetUpdate();
  
};

// START:  Added to update Rows not Database on table cell change

const handleRowEditStop = (id, event) => {
  if (id.reason === GridRowEditStopReasons.rowFocusOut) {
    event.defaultMuiPrevented = true;
  }
};

const handleEditClick = (row) => () => {
  setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
};

const processRowUpdate = (newRow) => {
  const updatedRow = { ...newRow, isNew: false };
  setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
  // handleAssetUpdate2(updatedRow);
  return updatedRow;
};

const handleRowModesModelChange = (newRowModesModel) => {
  setRowModesModel(newRowModesModel);
};


// END:  Added to update Rows not Database on table cell change



const handleNextQuestion = () => {
  setQuestionIndex(questionIndex+1);
  getCriticalAssets();
};

const handlePreviousQuestion = () => {
  setQuestionIndex(questionIndex-1);
}


  // Columns for MUI Data Grid
const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
  },
  {
      field: "assetType",
      headerName: "Asset Type/Function",
      flex: 1,
},
  {
    field: columnFieldsFoot[questionIndex],
    flex: 2,
    sortable: false,
    renderHeader: () => {
      return (
        <Box>
          <Typography sx={{ fontSize: '1.5em'}}>
          <b>{physicalSecurityScenario[scenarioIndex].name}</b> <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[scenarioIndex].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>
        </Typography>
        <Typography>
          {assetDeterminationQuestions[questionIndex].questionCategory}
        </Typography>

        </Box>
      )
    },
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            },
          }}>
            <Select
              value={params.row[columnFieldsFoot[questionIndex]] || 0}
              onChange={(e) => handleAssetUpdate(e.target.value, params)}
              sx={{ width: '500px', maxWidth: '100%' }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>{assetDeterminationQuestions[questionIndex].questionTextAug1}</MenuItem>
              <MenuItem value={2}>{assetDeterminationQuestions[questionIndex].questionTextAug2}</MenuItem>
              <MenuItem value={3}>{assetDeterminationQuestions[questionIndex].questionTextAug3}</MenuItem>
              <MenuItem value={4}>{assetDeterminationQuestions[questionIndex].questionTextAug4}</MenuItem>
              </Select>
        </Box>
      )
    }
  },
  {
    field: columnFieldsVehicle[questionIndex],
    flex: 2,
    sortable: false,
    renderHeader: () => {
      return (
        <Box>
          <Typography sx={{ fontSize: '1.5em'}}>
          <b>{physicalSecurityScenario[1].name}</b> <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[1].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>
        </Typography>
        <Typography>
          {assetDeterminationQuestions[questionIndex].questionCategory}
        </Typography>

        </Box>
      )
    },
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            },
          }}>
            <Select
              // value={getSelectValue(params)}
              value={params.row[columnFieldsVehicle[questionIndex]] || 0}
              // onClick={handleEditClick(params.row)}
              onChange={(e) => handleAssetUpdate(e.target.value, params)}
              sx={{ width: '500px', maxWidth: '100%' }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>{assetDeterminationQuestions[questionIndex].questionTextAug1}</MenuItem>
              <MenuItem value={2}>{assetDeterminationQuestions[questionIndex].questionTextAug2}</MenuItem>
              <MenuItem value={3}>{assetDeterminationQuestions[questionIndex].questionTextAug3}</MenuItem>
              <MenuItem value={4}>{assetDeterminationQuestions[questionIndex].questionTextAug4}</MenuItem>
              </Select>
          {/* <FormControlLabel
          label="No"
          control={
            <Select
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          /> */}
        </Box>
      )
    }
  },
  {
    field: columnFieldsBoat[questionIndex],
    flex: 2,
    sortable: false,
    renderHeader: () => {
      return (
        <Box>
          <Typography sx={{ fontSize: '1.5em'}}>
          <b>{physicalSecurityScenario[2].name}</b> <Tooltip color='primary' describeChild={true} title={<Typography sx={{ fontSize: '1.5em', background: 'grey' }}>{physicalSecurityScenario[2].description}</Typography>} placement="top-start"><HelpIcon /></Tooltip>
        </Typography>
        <Typography>
          {assetDeterminationQuestions[questionIndex].questionCategory}
        </Typography>

        </Box>
      )
    },
    flex: 2,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            },
          }}>
            <Select
              // value={getSelectValue(params)}
              value={params.row[columnFieldsBoat[questionIndex]] || 0}
              onChange={(e) => handleAssetUpdate(e.target.value, params)}
              // onChange={handleEditClick(params.row)}
              sx={{ width: '500px', maxWidth: '100%' }}
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>{assetDeterminationQuestions[questionIndex].questionTextAug1}</MenuItem>
              <MenuItem value={2}>{assetDeterminationQuestions[questionIndex].questionTextAug2}</MenuItem>
              <MenuItem value={3}>{assetDeterminationQuestions[questionIndex].questionTextAug3}</MenuItem>
              <MenuItem value={4}>{assetDeterminationQuestions[questionIndex].questionTextAug4}</MenuItem>
              </Select>
          {/* <FormControlLabel
          label="No"
          control={
            <Select
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          /> */}
        </Box>
      )
    }
  }
];

const handleResults = () => {
  setQuestionIndex(13);
}

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};

  return (
    <Box sx={{ marginTop:'1em', flexGrow: 1 }}>
      
      {questionIndex<=12 ? ( 
        <>
          <Header title='Security Assessment' subtitle='Reference Chapter 6 of FERC Security Program for Hydropower Projects Revision 3.' />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.25rem", mr:'0.55rem', p:'4px'}} >
                  <Grid item xs={12} sx={{ p:'0.5rem' }}>
                    {openInstructions ? (
                        <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
                            <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
                            <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
                        </IconButton>
                          ) : (
                            <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                              <ExpandCircleDownIcon color='primary' fontSize="large" />
                              <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
                            </IconButton>
                          )}
                        <Divider sx={{ border:0.5, mt:'6px' }}/>
                    </Grid>
                    <Collapse in={openInstructions} timeout="auto" unmountOnExit>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ ml:'2rem' }}>
                                <Typography>Select all the factors that apply to the corresponding Critical Asset for each attack scenario.</Typography>
                                <Typography>This assessment tool is derived from FERC's <Link rel='noreferrer' target='_blank' href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.ferc.gov%2Fsites%2Fdefault%2Ffiles%2F2020-04%2Fsecurity-tool.xls&wdOrigin=BROWSELINK">Security Assessment Analysis Tool.</Link> The metrics, attack scenarios, and resulting quantitative score were created by FERC</Typography>
                                <Typography></Typography>
                                <Typography></Typography>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
              </Grid>
            </Grid>
        
            <Paper sx={{ display: 'block', marginTop:'1rem', backgroundImage: "none", borderRadius: "0.55rem", m:'0.55rem', p:'1rem', minHeight:'150px', justifyContent: 'space-between' }} >
              <Grid container spacing={0} direction="column">
                <Grid item xs={12}>
                  <Box sx={{ fontSize: 26, p: '2rem' }}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography display='inline' variant='h2'>Security Questions</Typography>
                    <Typography display='inline' variant='h2' sx={{ mr: '1rem' }}>{questionIndex+1} / {assetDeterminationQuestions.length}</Typography>
                    </Box>
                    <Box sx={{ p: '1rem', border: 'solid 1px'}}>
                      <Typography sx={{ fontSize: '1em', ml: '3rem', mb:'1rem' }}>
                        <b>Category: {assetDeterminationQuestions[questionIndex].questionCategory}</b>
                      </Typography>
                      <Typography sx={{ fontSize: 22, ml: '4rem' }}>
                        {assetDeterminationQuestions[questionIndex].questionCategoryDescription}
                      </Typography>
                      <Typography sx={{ fontSize: 24, ml: '7rem', mt: '1rem' }}>
                        {assetDeterminationQuestions[questionIndex].questionMetricTitle}
                      </Typography>
                      <List>
                      {assetDeterminationQuestions[questionIndex].questionMetric1?.length>=2 && (
                        <ListItem>
                        <ListItemIcon sx={{ ml: '8rem' }}>
                            <CircleIcon sx={{ fontSize: '8pt' }}  />
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                          {assetDeterminationQuestions[questionIndex].questionMetric1}
                          </Typography>
                        </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric2?.length>=2 && (
                        <ListItem>
                          <ListItemIcon sx={{ ml: '8rem' }}>
                            <CircleIcon sx={{ fontSize: '8pt' }}  />
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                            {assetDeterminationQuestions[questionIndex].questionMetric2}
                          </Typography>
                        </ListItem>
                      )}
                        {assetDeterminationQuestions[questionIndex].questionMetric3?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '8rem' }}>
                            <CircleIcon sx={{ fontSize: '8pt' }}  />
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                        {assetDeterminationQuestions[questionIndex].questionMetric3 || ""}
                      </Typography>
                      </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric4?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '8rem' }}>
                            <CircleIcon sx={{ fontSize: '8pt' }}  />
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                      
                        {assetDeterminationQuestions[questionIndex].questionMetric4 || ""}
                      </Typography>
                      </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric5?.length>=2 && (
                        <ListItem>
                        <ListItemIcon sx={{ ml: '8rem' }}>
                          <CircleIcon sx={{ fontSize: '8pt' }}  />
                        </ListItemIcon>
                        <Typography sx={{ fontSize: 24 }}>
                        {assetDeterminationQuestions[questionIndex].questionMetric5}
                    </Typography>
                    </ListItem>
                      )}
                      </List>
                    </Box>
                  </Box>
                  <Grid item xs={12}>
                    <Typography sx={{ display:'flex', justifyContent:'space-between'}}>
                      <Button disabled={questionIndex===0} sx={{ ml:'5%', mt: '1rem'}} variant="contained" onClick={handlePreviousQuestion}>Previous Question</Button>
                      <Button sx={{ mr:'5%', mt: '1rem'}} variant="contained" onClick={handleNextQuestion}>Next Question</Button>
                      {/* <Button sx={{ display:'block', ml:'auto', mr:'5%', mt: '1rem'}} variant="contained" onClick={handleResults}>Results</Button>  */}
                    </Typography> 
                  </Grid>
                </Grid> 
              </Grid>

              
              {/** DATA GRID OF CYBER ASSETS */}
              <Grid container spacing={0}>
              <Box sx={{ mt:'1rem', flexGrow: 1, padding: "1.5rem 1.5rem 0.75rem 2rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
          <Grid item xs={12} >
            <Box
              sx={{
                height: 1000,
                width: '100%',
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: theme.palette.secondary.alt,
                    color: theme.palette.secondary[100],
                    borderBottom: "solid 2px black",
                    borderTop: "solid 2px black"
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: theme.palette.primary.light,
                },
                "& .MuiDataGrid-footerContainer": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary[100],
                    borderTop: "none"
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${theme.palette.secondary[200]} !important`,
                },
            }}
            >
              <DataGrid
                rows={rows || []} 
                getRowId={(row) => row._id}
                columns={columns}
                editMode="row"
                loading={!rows}
                columnHeaderHeight={112}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
              />
            </Box>
          </Grid>
        </Box>
              </Grid>
            </Paper>
            </>
    ) : (
      <>
      {/* RESULTS CALLED AFTER THE SECURITY ASSESSMENT QUESTIONS HAVE BEEN ANSWERED. */}
      <Header title='Security Assessment' subtitle='Reference Chapter 6 of FERC Security Program for Hydropower Projects Revision 3.' />
        <Grid container spacing={0} direction='columns' sx={{ mb:'1rem' }}>
          <Grid item xs={12}>
            <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", m:'0.55rem', p:'4px', minHeight:'150px'}} >
              <Grid item xs={12} sx={{ p:'1rem' }}>
                <Typography sx={{ fontSize:'1.5em' }}><u>Results</u>:</Typography>
                <Typography sx={{ fontSize:'1.5em' }}>The table shows the score for each individual critical asset by attack type.  The Project Score is the average score of all critical assets by attack type.</Typography>
                <Typography sx={{ fontSize:'1.5em' }}>This assessment tool is derived from FERC's <Link rel='noreferrer' target='_blank' href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.ferc.gov%2Fsites%2Fdefault%2Ffiles%2F2020-04%2Fsecurity-tool.xls&wdOrigin=BROWSELINK">Security Assessment Analysis Tool.</Link> The metrics, attack scenarios, and resulting quantitative score were created by FERC.</Typography>
                <Typography sx={{ fontSize:'1.5em' }}></Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', p:'1rem', alignItems:'center' }}>
                <Typography sx={{ fontSize:'1.5em' }}>Generally, a score of 44 is acceptable and requires no additional information. Anything below a 44 will require a plan and schedule to improve your secuirty program.</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', pl:'2rem', alignItems:'center' }}>
                <Typography sx={{ fontSize:'1.5em' }}><FiberManualRecordIcon sx= {{ color: 'red' }} /> Requires attention. Develop a plan and schedule.
                  </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', pl:'2rem', alignItems:'center' }}>
                <Typography sx={{ fontSize:'1.5em' }}><FiberManualRecordIcon sx= {{ color: 'orange' }} /> Limited Acceptability. Requires additions and/or upgrades to the security program.  Develop a plan and schedule. 
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', pl:'2rem', alignItems:'center' }}>
                <Typography sx={{ fontSize:'1.5em' }}><FiberManualRecordIcon sx= {{ color: 'yellow' }} /> Acceptable-under Modification. Develop a plan and schedule. 
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', pl:'2rem', alignItems:'center' }}>
                <Typography sx={{ fontSize:'1.5em' }}><FiberManualRecordIcon sx= {{ color: 'green' }} /> Acceptable.
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <Divider sx={{ border:0.5, mt:'6px' }}/>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      <SecurityAssessmentResults />
      </>
    )} 
       
    </Box>
  )
}

export default SecurityAssessmentTool