/* 
Integrated from "mern_app".  Not sure if this will be needed
*/

import { ChatBubbleOutlineOutlined, FavoriteBorderOutlined, FavoriteOutlined, ShareOutlined } from "@mui/icons-material";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "../state";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const PostWidget = ({
    postId,
    postUserId,
    name,
    description,
    location,
    picturePath,
    userPicturePath,
    likes,
    comments,
}) => {
    const [ isComments, setIsComments ] = useState(false);
    const dispatch = useDispatch();
    const _id = "659102e857259da4dcdfbb95";
    const loggedInUserId = "659102e857259da4dcdfbb95";
    const isLiked = Boolean(likes[loggedInUserId]);
    const likeCount = Object.keys(likes)?.length;
    const axiosPrivate = useAxiosPrivate();

    const { palette } = useTheme();
    const main = palette.neutral.main;
    const primary = palette.primary.main;

    // console.log("The postUserId passed to PostWidget is: " + postUserId);

    const patchLike = async () => {
        try {
            const response = await axiosPrivate.get('/posts', {params: {postId}}, {
                withCredentials: true
            });
            const data = await response.data;
            dispatch(setPost({ posts: data }));
        } catch (err) {
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
        }
        

        // const response = await fetch(`http://localhost:3001/posts/${postId}/like`, 
        //     {
        //         method: "PATCH",
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //             "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify({ userId: loggedInUserId })
        //     });
        //     const updatedPost = await response.json();
        //     dispatch(setPost({ post: updatedPost }));
    };

    return (
        <WidgetWrapper m="2rem 0">
            <Friend 
                friendId={postUserId}
                name={name}
                subtitle={location}
                userPicturePath={userPicturePath}
            /> 
            <Typography color={main} sx={{ mt: "1rem" }} >
                {description}
            </Typography>
            {picturePath && (
                <img 
                    width="100%"
                    height="auto"
                    alt="post"
                    style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
                    src={`http://localhost:3500/assets/${picturePath}`}
                />
            )}
            <FlexBetween mt="0.25rem" >
                <FlexBetween gap="1rem" >
                    <FlexBetween gap="0.3rem">
                        <IconButton onClick={patchLike}>
                            {isLiked ? (
                                <FavoriteOutlined sx={{ color: primary }} />
                            ) : (
                                <FavoriteBorderOutlined />
                            )}
                        </IconButton>
                        <Typography>{likeCount}</Typography>
                    </FlexBetween>


                    {/* ADD THE NUMBER OF COMMENTS TO EACH POST  */}
                    <FlexBetween gap="0.3rem" >
                    <IconButton onClick={() => setIsComments(!isComments)}>
                        <ChatBubbleOutlineOutlined />
                        </IconButton>
                        <Typography>{comments.length}</Typography>
                    </FlexBetween>
                </FlexBetween>
                <IconButton>
                    <ShareOutlined />
                </IconButton>
            </FlexBetween>

            {/* RENDER ALL THE POSTS */}
            {isComments && (
                <Box mt="0.5rem">
                    {comments.map((comment, i) => (
                        // <Box key={`${name}+${Math.floor(Math.random() * 100)}`}>  {/* this gaurantees each post will have a unique index 
                        <Box key={`${name}-${i}}`}>
                            <Divider /> 
                            <Typography sx={{ color: main, m: "0.5rem", pl: "1rem" }}>
                                {comment}
                            </Typography>
                        </Box>
                    ))}
                    <Divider />
                </Box>
            )}
        </WidgetWrapper>
    );
}; 

export default PostWidget;