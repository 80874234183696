import axios from '../api/axios';
import useAuth from './useAuth';
import { useDispatch } from "react-redux";
import { setLogin } from "../state";

const useRefreshToken = () => {
    const dispatch = useDispatch();
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        // console.log("the Refresh Token is: ");
        // console.log(response);
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data.accessToken);
            return { 
                ...prev,
                roles: response.data.roles,
                accessToken: response.data.accessToken
            }
        });
        return response.data.accessToken;
    }
    
    return refresh;
};

export default useRefreshToken;