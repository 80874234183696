import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Box, Button, Collapse, Typography, IconButton, useTheme, useMediaQuery, Link, Grid, Paper, Divider, RadioGroup, TextField,
  FormControlLabel, MenuItem, List, ListItem, ListItemIcon, Radio, Select, Tooltip, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleIcon from '@mui/icons-material/Circle';
import HelpIcon from '@mui/icons-material/Help';
import SecurityAssessmentResults from './SecurityAssessmentResults';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { all } from 'axios';
import WidgetWrapper from '../../../components/WidgetWrapper';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from '../../../components/DataGridCustomToolbar';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';





const CorrectiveActions = ({handleNext}) => {

  const valueComplete = '5';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentdata, setAssessmentData] = useState();
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();

  const [refresh, setRefresh] = useState();
  const [rows, setRows] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [openInstructions, setOpenInstructions] = useState(true);
  
  const [allCriticalAssetData, setAllCriticalAssetData] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [open, setOpen] = useState(false);
  const [assetToDelete, setAssetToDelete] = useState();

  const [correctiveActionPlanData, setCorrectiveActionPlanData] = useState();
  const [externalAccessCAP, setExternalAccessCAP] = useState();
  const [externalAccessCAPAnswers, setExternalAccessCAPAnswers] = useState({ taskNumber: '', taskName: '', taskDescription: '', dueDate: '' });

  const [footScore, setFootScore] = useState(0);
  const [vehicleScore, setVehicleScore]= useState(0);
  const [boatScore, setBoatScore] = useState(0);

  const footAttackerTasks = {};
  const vehicleAttackerTasks = [];
  const boatAttackerTasks = [];

  const assessmentMetricsList = [
    'externalAccess', 'physicalSecurity', 'detection', 'delay', 'response', 'responseTime', 'assessmentExternal', 'assessmentInternal', 'securityAssessment', 'securityPlan', 'cybersecurity', 'cpme', 'cdpr',
    'externalAccessVehicle', 'physicalSecurityVehicle', 'detectionVehicle', 'delayVehicle', 'responseVehicle', 'responseTimeVehicle', 'assessmentExternalVehicle', 'assessmentInternalVehicle', 'securityAssessmentVehicle', 'securityPlanVehicle', 'cybersecurityVehicle',
    'externalAccessBoat', 'physicalSecurityBoat', 'detectionBoat', 'delayBoat', 'responseBoat', 'responseTimeBoat', 'assessmentExternalBoat', 'assessmentInternalBoat', 'securityAssessmentBoat', 'securityPlanBoat', 'cybersecurityBoat'
  ];

  const accessVisibilityMetrics = [ 'externalAccess', 'externalAccessVehicle', 'externalAccessBoat' ];
  const detectionFinding = [ 'detection', 'detectionVehicle', 'detectionBoat' ];
  const delayFinding = [ 'delay', 'delayVehicle', 'delayBoat' ];
  const physicalSecurityFinding = [ 'physicalSecurity', 'physicalSecurityVehicle', 'physicalSecurityBoat' ];
  const responseFinding = [ 'response', 'responseVehicle', 'responseBoat' ];
  const responseTimeFinding = [ 'responseTime', 'responseTimeVehicle', 'responseTimeBoat' ]; 
  const assessmentExternalFinding = [ 'assessmentExternal', 'assessmentExternalVehicle', 'assessmentExternalBoat' ]   
  const assessmentInternalFinding = [ 'assessmentInternal', 'assessmentInternalVehicle', 'assessmentInternalBoat' ];
  const securityAssessmentFinding = [ 'securityAssessment', 'securityAssessmentVehicle', 'securityAssessmentBoat' ];
  const securityPlanFinding =  [ 'securityPlan', 'securityPlanVehicle', 'securityPlanBoat' ];
  const cybersecurityFinding  = [ 'cybersecurity', 'cybersecurityVehicle', 'cybersecurityBoat' ];
  const cpmeFinding  = [ 'cpme' ];
  const cdprFinding  = [ 'cdpr' ];

  // const fercMetrics = [ 'externalAccess', 'physicalSecurity', 'detection', 'delay', 'response', 'responseTime', 'assessmentExternal', 'assessmentInternal', 'securityAssessment', 'securityPlan', 'cybersecurity', 'cpme', 'cdpr' ]
  const fercMetrics = [ accessVisibilityMetrics, physicalSecurityFinding, detectionFinding, delayFinding, responseFinding, responseTimeFinding, assessmentExternalFinding, assessmentInternalFinding, securityAssessmentFinding, securityPlanFinding, cybersecurityFinding, cpmeFinding, cdprFinding ]

  const findingsList =[];
  const [criticalAssetName, setCriticalAssetName] = useState([]);
  const otherList =[];

  const [taskNumber, setTaskNumber] = useState();
  const [taskLead, setTaskLead] = useState();
  const [taskName, setTaskName] = useState();
  const [taskDescription, setTaskDescription] = useState();
  const [dueDate, setDueDate] = useState();

  // Get Critical Assets Function.  This should pull cyberAssets for the current assessment. 
  const getCriticalAssets = async () => {
    try {
    const responseData = await axiosPrivate.get(`/criticalassets/criticalassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await responseData.data;

    setAllCriticalAssetData(data);
    const tempList = [];
    for (let obj in data) {
      for(let index in data[obj]) {
        if ( assessmentMetricsList.includes(index) === true && data[obj][index] < 4) {
          tempList.push(data[obj]);
          break;
        }
      }
    }
    setCriticalAssetName(tempList);
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    // navigate('/login', { state: { from: location }, replace: true });
  }
};

  // Get Corrective Actions Function.  This should pull cyberAssets for the current assessment. 
  const getCorrectiveActions = async () => {
    try {
      const response = await axiosPrivate.get(`/correctiveactionplan/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      setRows(data);
    } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    getCorrectiveActions();
    getCriticalAssets();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!rows) {
      return null;
    };


    const handleRowEditStop = (id, event) => {
      if (id.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    };
  
    const handleEditClick = (row) => () => {
      setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
    };
  
    // Update Database Function 
    const handleCyberAssetUpdate = async (updatedRow) => {
      let id = updatedRow._id;
      let assetName = updatedRow.assetName;
      let assetType = updatedRow.assetType;
      let manufacturer = updatedRow.manufacturer;
      let model = updatedRow.model;
      let macAddress = updatedRow.macAddress;
      let yearPurchased = updatedRow.yearPurchased;
      let physicalLocation = updatedRow.physicalLocation;
      let description = updatedRow.description;
      const cyberAssetData = JSON.stringify({ manufacturer, model, macAddress, yearPurchased, physicalLocation, description });
      try {
        const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
          JSON.stringify({ assessmentID, assetName, assetType, cyberAssetData }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
          );
          const data = await response.data;
          // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
          // The console log commands below allow you to see the responses from Axios for the response.  
          // navigate(from, { replace: true });
        } catch (err) {
          if (!err?.response) {
              console.log('No Server Response');
          } else {
              console.log('Action Failed')
          }
        }
      };
  
    const handleSaveClick = (row) => {
      setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
    };
  
    const handleDeleteClick = (id) => () => {
      setRows(rows.filter((row) => row.id !== id));
    };
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
  
      const editedRow = rows.find((row) => row._id === id);
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id));
      }
    };
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
      handleCyberAssetUpdate(updatedRow);
      return updatedRow;
    };
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel);
    };
  
    const handleDeleteAsset = async () => {
      try {
          const response = await axiosPrivate.delete(`/correctiveactionplan/${assetToDelete}`, 
              ({ "active": false }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
          if (response.data.success) {
              alert(response.data.msg);
          } 
          // console.log(response);
      } catch (err) {
          console.error(err);
      }
       setAssetToDelete(null);
       setCriticalAssetName(null);
       setRefresh(!refresh);
   }
   
   const handleClickOpen = (params) => {
      setAssetToDelete(params.row._id);
      setCriticalAssetName(params.row.assetName || "this device");
      setOpen(true);
    };
  
  const handleNo = () => {
    setOpen(false);
  };
  
  const handleYes = () => {
      handleDeleteAsset();
      setOpen(false);
  };


  const columns = [
    {
      field: "taskNumber",
      headerName: "Task#",
      flex: 0.25,
      editable: true
    },
    {
        field: "taskName",
        headerName: "Task Name",
        flex: 0.25,
        editable: true
    },
    {
        field: "taskDescription",
        headerName: "Task Description",
        flex: 1,
        editable: true
    },
    {
      field: "taskLead",
      headerName: "Task Lead",
      flex: 0.25,
      editable: true
    },
    {
        field: "dueDate",
        headerName: "Completion Date",
        flex: 0.25,
        editable: true
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.row._id]?.mode === GridRowModes.Edit;
  
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleSaveClick(params.row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.row._id)}
              color="inherit"
            />,
          ];
        }
  
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleClickOpen(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];

        // FERC Security Assessment Tool Determination Questions
const assetDeterminationQuestions = [
  {
    id: 'externalAccess-1',
    questionCategory: 'External Access',
    questionCategoryDescription: 'Which statement best describes the visual access with asset protections (fences, signs, etc.) based on physical location, structure/equipment, resiliency and facility security?',
    questionMetricTitle: '',
    questionMetrics1: '',
    questionMetrics2: '',
    questionMetrics3: '',
    questionMetrics4: '',
    questionMetrics5: '',
    questionTextAug1: '1. High visibility/good access control (barrier/electronic/human).',
    questionTextAug2: '2. Low visibility/good access control (barrier/electronic/human).',
    questionTextAug3: '3. Low visibility/good access control (barrier/electronic/human).',
    questionTextAug4: '4. Low visibility/inaccessible.',
    questionNumber: '1. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'physicalSecurity-1',
    questionCategory: 'Physical Security',
    questionCategoryDescription: 'Which statement best describes the physical security of each Critical Asset with respect to the security measures listed below?',
    questionMetricTitle: 'Security Measure:',
    questionMetric1: 'Key and lock protection on doors and windows for this critical asset.',
    questionMetric2: 'Perimeter protection, such as gates, fences, walls, and bollards.',
    questionMetric3: 'Photo ID and badge program, including required wearing of badges where appropriate and users trained to look for photo/IDs or badges.',
    questionMetric4: 'Electronic Access Control, such as using your badge to access buildings or gates.',
    questionMetric5: 'Guards posted or patrolling.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '2. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'detection-1',
    questionCategory: 'Detection',
    questionCategoryDescription: 'Which statement best describes the detection systems available at the associated Critical Asset with respect to the systems and processes listed below?',
    questionMetricTitle: 'Detection Systems or Processes:',
    questionMetric1: 'This site is manned and the Critical Asset can be observed.',
    questionMetric2: 'Surveillance cameras are in use and monitored by employees and/or guards.',
    questionMetric3: 'Personnel are required to wear their Photo ID or badge when on site.',
    questionMetric4: 'Walking inspections/patrols are regularly completed by employees and/or guards.',
    questionMetric5: '',
    questionTextAug1: '1. None of the security measures listed above apply to this critical asset.',
    questionTextAug2: '2. Only one or two of the security measures listed above apply to this critical asset.',
    questionTextAug3: '3. Three of the security measures listed above apply to this critical asset.',
    questionTextAug4: '4. All four of the security measures listed above apply to this critical asset.',
    questionNumber: '3. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'delay-1',
    questionCategory: 'Delay',
    questionCategoryDescription: 'Which statement best describes the systems designed to delay an attacker at the associated Critical Asset with respect to the delay systems and measures listed below?',
    questionMetricTitle: 'Delay Systems:',
    questionMetric1: 'Dam/assets fenced with gates/doors access controlled.',
    questionMetric2: 'Access restrictions in-place (foot/vehicle/boat) and appropriate.',
    questionMetric3: 'Spillway, spillway gate controls secured from unauthorized access.',
    questionMetric4: 'Powerhouse-accessed controlled/alarmed/CCTV/motion detection.',
    questionMetric5: 'Physical protection equipment secured against tampering.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '4. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'response-1',
    questionCategory: 'Response',
    questionCategoryDescription: "Which statement best describes site personnel's ability to notify law enforcement and the law enforcement response for the associated Critical Asset with respect to the security measures listed below?",
    questionMetricTitle: 'Law Enforcement Notification and Response:',
    questionMetric1: 'Law Enformcement phone numbers are posted and personnel notify them quickly when necessary.',
    questionMetric2: 'The site has redundant communications methods.',
    questionMetric3: 'The site quickly detects and responds to unauthorized access attempts, including notifying law enforcement when necessary.',
    questionMetric4: 'Law Enforcement responders include State and Local agencies.',
    questionMetric5: 'Law Enforcement reponders may include federal agencies.',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '5. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'responseTime-1',
    questionCategory: 'Response Time',
    questionCategoryDescription: 'Which statement best describes the expected response time of law enforcement to the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. The Critical Asset is in a remote area with no law enforcement patrol and no intrusion alerting.',
    questionTextAug2: '2. Intrusion alert with 30 minute plus law enforcement response. ',
    questionTextAug3: '3. Intrusion alert with under 15 minute law enforcement response.',
    questionTextAug4: '4. Guard service/law enforcement on duty at location.',
    questionNumber: '6. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'assessmentExternal-1',
    questionCategory: 'External Threat Assessment',
    questionCategoryDescription: 'Based on your experience and law enforcement experience or intelligence, which statement best describes the relative measure of external threats against the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Current active threats/vandalism/theft/trespass.',
    questionTextAug2: '2. Current oral threats/vandalism/theft/trespass.',
    questionTextAug3: '3. There have benn active threats/vandalism/theft/trespass at the associated Critical Asset.',
    questionTextAug4: '4. There are no known threats or past activity.',
    questionNumber: '7. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'assessmentInternal-1',
    questionCategory: 'Internal Threat Assessment',
    questionCategoryDescription: 'Based on your experience and law enforcement experience or intelligence, which statement best describes the relative measure of internal threats against the associated Critical Asset?',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Current Insider Threat: There is at least one active insider threat.',
    questionTextAug2: '2. Current Suspected Insider Threat: In the last two years, there has been at least one oral or written threat from a current employee or recently dismissed employee.',
    questionTextAug3: '3. Former Insdier Threat: In the last two years, a former employee was an active threat to other personnel or equipment.',
    questionTextAug4: '4. No Insider Threat: There are no known insider threats.',
    questionNumber: '8. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'securityPlan-1',
    questionCategory: 'Security Plan',
    questionCategoryDescription: 'Which statement best describes the completeness and accuracy of the Security Plan with respect to the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: 'Documentation of restricted areas, physical dam related assets, physical security protections, descriptions, layout and inventory.',
    questionMetric2: 'Security Operational Procedures (employee duties, education, training, document controls) w/Emergency.',
    questionMetric3: 'Emergency notification, communication and redundancies (EAP coordination).',
    questionMetric4: 'DHS Threat Level Contingency Planning.',
    questionMetric5: 'Responses (Bomb Threats, Civil Distrubance, Temporary Project Closure, etc...), procedures for SiteAccess Control and Security equipment maintenance and testing (NERC.CIP 6, R6).',
    questionTextAug1: '1. Unacceptable: The Security Plan needs significant improvements to thoroughly cover the topics listed above for the associated Critical Asset.',
    questionTextAug2: '2. Limit Acceptability: The Security Plan needs some improvements but most of the security topics listed above are addressed.',
    questionTextAug3: '3. Acceptable with Modifications: The Security Plan needs some limited improvements and the organization knows which areas to address.',
    questionTextAug4: '4. Acceptable: The Security Plan is designed to "reasonably" deter, detect, deny, and delay external threats defined by the organization until law enforcement can respond.',
    questionNumber: '10. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'Cybersecuirty-1',
    questionCategory: 'Cybersecurity',
    questionCategoryDescription: 'Which statement best describes the Operational Technology (OT) systems and networks at the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. OT systems and/or networks are not protected from Internet intrusion.',
    questionTextAug2: '2. OT systems and/or networks are stand alone (air gapped) or has firewall protection from Internet intrusion.',
    questionTextAug3: '3. Firewalls and internal systems received regular software patches.',
    questionTextAug4: '4. NERC/CIP standards are in place and audited for all OT systems and/or networks at the associated Critical Asset or, there are no cyber/SCADA systems used at the project.',
    questionNumber: '11. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'securityAssessment-1',
    questionCategory: 'Security Assessment',
    questionCategoryDescription: "Which statement best describes your confidence level in this Security Assessment for the associated Critical Asset with respect to the factors listed below?",
    questionMetricTitle: 'Security Assessment Factors:',
    questionMetric1: 'List of critical physical dam assets, structures, appurtenant structures.',
    questionMetric2: 'Inherent accessibility and visibility of each identified asset.',
    questionMetric3: 'Security system procedures/responses to potential adversaries.',
    questionMetric4: 'Security improvement recommendations with plans and schedules.',
    questionMetric5: '',
    questionTextAug1: '1. Only one of the security measures listed above.',
    questionTextAug2: '2. Two of the security measures listed above.',
    questionTextAug3: '3. Three of the security measures listed above.',
    questionTextAug4: '4. At least four of the security measures listed above.',
    questionNumber: '12. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'CPME-1',
    questionCategory: 'Critical Performance Monitoring Equipment',
    questionCategoryDescription: 'Which statement best describes the monitoring equipment available at the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Requires attention (provide plan and schedule).',
    questionTextAug2: '2. Limited acceptability (requires additions, upgrades-provide plan and schedule).',
    questionTextAug3: '3. Acceptable-under modification (plan and schedule available).',
    questionTextAug4: '4. Acceptable.',
    questionNumber: '13. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
  {
    id: 'CDP&R-1',
    questionCategory: 'Critical Drawings, Plans, and Records',
    questionCategoryDescription: 'Which statement best describes the security documentation for the associated Critical Asset?',
    questionMetricTitle: '',
    questionMetric1: '',
    questionMetric2: '',
    questionMetric3: '',
    questionMetric4: '',
    questionMetric5: '',
    questionTextAug1: '1. Requires attention (provide plan and schedule).',
    questionTextAug2: '2. Limited acceptability (requires additions, upgrades-provide plan and schedule).',
    questionTextAug3: '3. Acceptable-under modification (plan and schedule available).',
    questionTextAug4: '4. Acceptable.',
    questionNumber: '14. ',
    questionTitle: 'External Access: Visual access w/asset protections based on physical location, structure/equipment, resiliency and facility security:'
  },
]

const handleAddNewCorrectiveAction = async () => {
  let capData = JSON.stringify({ taskNumber, taskLead, taskName, dueDate, taskDescription });
    try {
      const response = await axiosPrivate.post(`/correctiveactionplan/new`,
        JSON.stringify({ assessmentID, capData }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      } 
    );
    } catch (err) {
        console.log(err);
    }
    setTaskNumber();
    setTaskLead();
    setTaskName();
    setTaskDescription();
    setDueDate();
    setRefresh(!refresh);
  }

 const handleNextCategory = () => {
  if (questionIndex<assetDeterminationQuestions.length-1) {
    setQuestionIndex(questionIndex+1);
  } else {
    setQuestionIndex(0);
  }
 }

 const handleNextStep = () => {
    handleNext();
 }

 const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};


  return (
    <div>
        <Grid container spacing={2} sx={{ mt:'1rem', pr:'1rem', pb: '1rem', border: 'solid 1px'}}>
        <Grid item xs={12} sx={{ p:'1rem' }}>
        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
    <Grid item xs={12} sx={{ p:'0.5rem' }}>
    {openInstructions ? (
        <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
          <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
          <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
        </IconButton>
          ) : (
          <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
            <ExpandCircleDownIcon color='primary' fontSize="large" />
            <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
          </IconButton>
          )}
        <Divider sx={{ border:0.5, mt:'6px' }}/>
    </Grid>
    <Collapse in={openInstructions} timeout="auto" unmountOnExit>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ ml:'2rem' }}>
          <Typography>Review the metrics for each FERC Security Assessment Category.</Typography>
          <Typography>Review the Critical Assets and their associated scores for the three types of attackers.</Typography>
          <Typography>Create Tasks to address each deficency. One task may cover more than one critical asset and/or type of attacker.</Typography>
          <Typography>Repeat these steps for all 13 FERC Security Categories.  Click the 'Next Category' button to display metrics and scores for each critical asset.</Typography>
        </Grid>
      </Grid>
    </Collapse>
</Paper>
              </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: '0.5rem', display: 'flex', justifyContent: 'space-between' }}>
              <Typography display='inline' variant='h2'>{questionIndex+1} / {assetDeterminationQuestions.length}</Typography>
              <Typography display='inline' variant='h2'>FERC Security Assessment Category: {assetDeterminationQuestions[questionIndex].questionCategory}</Typography>
              {questionIndex<12 ? ( 
                <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleNextCategory}>Next Category</Button>
                ): (
                  <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleNextStep}>FINISH</Button>
                )}
              
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <WidgetWrapper>
              <form>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                        <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleAddNewCorrectiveAction}>Add Task</Button>
                        <Divider />
                    </Grid>
                <Grid item xs={4} >
                  <Typography>
                    Task #
                  </Typography>
                  <input style={{ height: '60px', width: '100%', marginLeft: '' }}
                    type="text"
                    name='taskNumber'
                    value={taskNumber || ""}
                    onChange={(e) => setTaskNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    Task Lead
                  </Typography>
                  <input style={{ height: '60px', width: '100%', marginLeft: '' }}
                    type="text"
                    name='taskLead'
                    value={taskLead || ""}
                    onChange={(e) => setTaskLead(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Task Name
                  </Typography>
                  <input style={{ height: '60px', width: '100%', marginLeft: '' }}
                    type="text"
                    name='taskName'
                    value={taskName || ""}
                    onChange={(e) => setTaskName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    Due Date
                  </Typography>
                  <input style={{ fontSize:'inherit', height: '60px', width: '100%', marginLeft: '' }}
                    type="date"
                    name='dueDate'
                    value={dueDate || ""}
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Description
                  </Typography>
                  <textarea style={{ height: '20vh', width: '100%', marginLeft: '' }}
                    type="text"
                    name='taskDescription'
                    value={taskDescription || ""}
                    onChange={(e) => setTaskDescription(e.target.value)}
                  />
                </Grid>
                </Grid>
              </form>
            </WidgetWrapper>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={1}>

          {/* FERC METRIC DESCRIPTION WIDGET */}
            <Grid item xs={12}>
              <Grid container spacing={0} sx={{ display:'grid' }} >
              <Box sx={{ maxHeight: '30vh', overflow: 'auto', border: 'solid 0.5rem', borderRadius:'10px',  borderColor: theme.palette.primary.main}}>
                <Grid item xs={12}>
                      <Typography sx={{ fontSize: '1em', ml: '3rem', mb:'1rem' }}>
                        <b>Category: {assetDeterminationQuestions[questionIndex].questionCategory} Metrics</b>
                      </Typography>
                      <Typography sx={{ fontSize: 22, ml: '1rem' }}>
                        {assetDeterminationQuestions[questionIndex].questionCategoryDescription}
                      </Typography>
                      <Typography sx={{ fontSize: 22, ml: '2rem', mt: '1rem' }}>
                        {assetDeterminationQuestions[questionIndex].questionMetricTitle}
                      </Typography>
                      <List>
                      {assetDeterminationQuestions[questionIndex].questionMetric1?.length>=2 && (
                        <ListItem>
                        <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                          {assetDeterminationQuestions[questionIndex].questionMetric1}
                          </Typography>
                        </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric2?.length>=2 && (
                        <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                            {assetDeterminationQuestions[questionIndex].questionMetric2}
                          </Typography>
                        </ListItem>
                      )}
                        {assetDeterminationQuestions[questionIndex].questionMetric3?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                        {assetDeterminationQuestions[questionIndex].questionMetric3 || ""}
                      </Typography>
                      </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric4?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                      
                        {assetDeterminationQuestions[questionIndex].questionMetric4 || ""}
                      </Typography>
                      </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionMetric5?.length>=2 && (
                        <ListItem>
                        <ListItemIcon sx={{ ml: '2rem' }}>
                          {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                        </ListItemIcon>
                        <Typography sx={{ fontSize: 24 }}>
                        {assetDeterminationQuestions[questionIndex].questionMetric5}
                    </Typography>
                    </ListItem>
                      )}

                      {assetDeterminationQuestions[questionIndex].questionTextAug1?.length>=2 && (
                        <ListItem>
                        <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                          {assetDeterminationQuestions[questionIndex].questionTextAug1}
                          </Typography>
                        </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionTextAug2?.length>=2 && (
                        <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                            {assetDeterminationQuestions[questionIndex].questionTextAug2}
                          </Typography>
                        </ListItem>
                      )}
                        {assetDeterminationQuestions[questionIndex].questionTextAug3?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                        {assetDeterminationQuestions[questionIndex].questionTextAug3 || ""}
                      </Typography>
                      </ListItem>
                      )}
                      {assetDeterminationQuestions[questionIndex].questionTextAug4?.length>=2 && (
                      <ListItem>
                          <ListItemIcon sx={{ ml: '2rem' }}>
                            {/* <CircleIcon sx={{ fontSize: '8pt' }}  /> */}
                          </ListItemIcon>
                          <Typography sx={{ fontSize: 24 }}>
                      
                        {assetDeterminationQuestions[questionIndex].questionTextAug4 || ""}
                      </Typography>
                      </ListItem>
                      )}
                     
                      </List>
                </Grid> 
                </Box>
              </Grid>
              
          </Grid>


        {/* CRITICAL ASSET FINDINGS WIDGET */}
          <Grid item xs={12} >
            <Box sx={{ border: 'solid 0.5rem', borderColor: theme.palette.primary.main, borderRadius: '10px',  }}>
                <Grid container spacing={1} sx={{ display:'flex' }} >
                   <Grid item xs={3}>
                      <Typography sx={{ textAlign:'center', fontWeight:'bold' }} variant='h3'>Critical Asset</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ textAlign:'center', fontWeight:'bold' }} variant='h3'>Attacker on Foot</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ textAlign:'center', fontWeight:'bold' }} variant='h3'>Attacker in Vehicle</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ textAlign:'center', fontWeight:'bold' }} variant='h3'>Attacker in Boat </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  {(() => {
                    const findingsList =[];
                    const criticalAssetName2 = []
                    const otherList =[];
                    for(let index in criticalAssetName) {
                      criticalAssetName2.push(
                        <Grid container spacing={0} key={criticalAssetName[index]._id}>
                        <Grid item xs={3} >
                            <Typography sx={{ pl:'1rem' }} variant='h3'>{criticalAssetName[index].assetName}</Typography>
                        </Grid>
                        <Grid item xs={3} >
                          <Typography textAlign='center' variant='h3'>{criticalAssetName[index][fercMetrics[questionIndex][0]]}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                         <Typography textAlign='center' variant='h3'>{criticalAssetName[index][fercMetrics[questionIndex][1]]}</Typography>
                        </Grid>
                        <Grid item xs={3} >
                         <Typography textAlign='center' variant='h3'>{criticalAssetName[index][fercMetrics[questionIndex][2]]}</Typography>
                         </Grid>
                         </Grid>
                      )

                      // criticalAssetName2.push(
                      // <Grid item xs={3} key={criticalAssetName[index]._id}>
                      //     <Typography sx={{ pl:'1rem' }} variant='h3'>{criticalAssetName[index].assetName}</Typography>
                      // </Grid>)
                      
                      //   criticalAssetName2.push(<Grid item xs={3} key={criticalAssetName[index]._id}>
                      //   <Typography textAlign='center' variant='h3'>{criticalAssetName[index].externalAccess}</Typography>
                      // </Grid>)
                    
                      // criticalAssetName2.push(<Grid item xs={3} key={criticalAssetName[index]._id}>
                      //   <Typography textAlign='center' variant='h3'>{criticalAssetName[index].externalAccessVehicle}</Typography>
                      // </Grid>)
                      
                      // criticalAssetName2.push(<Grid item xs={3} key={criticalAssetName[index]._id}>
                      //   <Typography textAlign='center' variant='h3'>{criticalAssetName[index].externalAccessBoat}</Typography>
                      // </Grid>)
                  }
                  return (
                  <Box sx={{ maxHeight:'20vh',  overflow: 'auto', width:'100%'}}>
                    {/* <Grid container spacing={1}> */}
                      {criticalAssetName2}
                    {/* </Grid> */}
                  </Box>
                );
                  })()}
                </Grid>
              </Box>
            </Grid>
            </Grid>
          </Grid>
          
        </Grid>



        <Grid item xs={12} >
        <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
          <Dialog
            open={open}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
            {"Verify Delete Command"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleYes}>Yes</Button>
            <Button onClick={handleNo} autoFocus>
                No
            </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              height: 400,
              width: '99%',
              fontSize: '2em',
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.secondary.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "solid 2px black",
                  borderTop: "solid 2px black",
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary[100],
                  borderTop: "none"
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
              },
              "& .MuiDataGrid-main": {
                fontSize: "1.5em"
              }
            }}
            >
            <DataGrid
              rows={rows || []} 
              getRowId={(row) => row._id}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: DataGridCustomToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
          </Box>
        </Grid>


  </div>
)
  
}

export default CorrectiveActions