import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption, viewDate, handleWidth } from '../components/gantt-task-react';
import "../components/gantt-task-react/dist/index.css";
import { Box, useTheme, Typography } from '@mui/material';
import React, { useState } from 'react';
// import "gantt-task-react/dist/index.css";


const GanttTaskChart = (userAssessmentData) => {
    const { palette } = useTheme();


    // console.log(userAssessmentData.userAssessmentData.length>0);

    const date = new Date(2025, 4, 1);
    const viewDate = new Date(2024, 5, 15);

    const tempTasks = [];
    if (userAssessmentData.userAssessmentData.length>0) {
      for (let i in userAssessmentData.userAssessmentData) {
        let tempRenew = Date.parse(userAssessmentData.userAssessmentData[i].renewByDate)
        let tempStart = Date.parse(userAssessmentData.userAssessmentData[i].startDate)
        let localRenewByDate = new Date(tempRenew);
        let localStartDate = new Date(tempStart);
        // const getStartDate = Date.parse(getStartDate1);
        // const startDate = new Date(getStartDate);
        // const renewByDate = new Date(getRenewByDate);
        // console.log(userAssessmentData.userAssessmentData[i].renewByDate.getFullYear());
        // console.log(newDateYear);
        // const renewByYear = newDateYear.getFullYear();
        
        
        // const NewRenewDate = new Date(msecDate);
        //   console.log(NewRenewDate.getFullYear());
  
        tempTasks.push({
          'start': localStartDate, 
          'end': localRenewByDate, 
          'name': userAssessmentData.userAssessmentData[i].damName, 
          'id': userAssessmentData.userAssessmentData[i]._id, 
          'progress': 25,
          'isDisabled': true,
          'styles': { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },  
        })
      }

    }
    
    const tasks = [
        {
          start: new Date(2024, 1, 1),
          end: new Date(2024, 4, 1),
          name: 'Annual Compliance Letter',
          id: 'Task 0',
          type:'task',
          progress: 25,
          isDisabled: true,
          styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
        },
        {
            start: new Date(2024, 2, 10),
            end: new Date(2024, 6, 2),
            name: 'Dam2 Vulnerability Assessment',
            id: 'Task 1',
            type:'task',
            progress: 15,
            isDisabled: true,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          },
          {
            start: new Date(2024, 6, 25),
            end: new Date(2024, 11, 20),
            name: 'Dam 3 Vulnerability Assessment',
            id: 'Task 2',
            type:'task',
            dependencies: ['Task1'],
            progress: 10,
            isDisabled: true,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          },
    ]

    const ganttView = ViewMode.Month;


    return (
      <Box>
        {userAssessmentData.userAssessmentData.length<1 ? (
          <Typography>
            Add Annual Compliance Letter Due Date.
          </Typography>
        ) : (
          <Gantt
              tasks={tempTasks}
              viewMode={ganttView}
              viewDate={viewDate}
              preStepsCount={0}
              maxWidth='100%'
              barBackgroundSelectedColor='palette.primary.dark'
              listCellWidth='180px'
              // columnWidth={70}
              
              // onDateChange={onTaskChange}
              // onTaskDelete={onTaskDelete}
              // onProgressChange={onProgressChange}
              // onDoubleClick={onDblClick}
              // onClick={onClick}
          />
        )}
      </Box>
    );
};

export default GanttTaskChart;