import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';


const AccessControlQuestions = ({questionFamily, setQuestionFamily}) => {

    // ************Template Variables*********************//

    const [assessmentData, setAssessmentData] = useState();
    const axiosPrivate = useAxiosPrivate();
    const { assessmentID } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const theme = useTheme();        
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

    // ************Template Variables*********************//


    // *************** Questionnaire specific Variables***************//

    const [hasLogicalAccessControl, setHasLogicalAccessControl] = useState("");
    const [has3rdPartyRemoteAccess, setHas3rdPartyRemoteAccess] = useState("");
    const [has3rdPartyRemoteAccessDescription, setHas3rdPartyRemoteAccessDescription] = useState("");
    const [has3rdPartyRemoteAccessList, setHas3rdPartyRemoteAccessList] = useState("");
    const [doesRemoteConnectionWeeklyReview, setDoesRemoteConnectionWeeklyReview] = useState("");
    const [knowsUnauthorizedAccessAttempts, setKnowsUnauthorizedAccessAttempts] = useState("");
    const [hasLogicalAccessControlComments, setHasLogicalAccessControlComments] = useState("");
    const [has3rdPartyRemoteAccessComments, setHas3rdPartyRemoteAccessComments] = useState("");
    const [has3rdPartyRemoteAccessDescriptionComments, setHas3rdPartyRemoteAccessDescriptionComments] = useState("");
    const [has3rdPartyRemoteAccessListComments, setHas3rdPartyRemoteAccessListComments] = useState("");
    const [doesRemoteConnectionWeeklyReviewComments, setDoesRemoteConnectionWeeklyReviewComments] = useState("");
    const [knowsUnauthorizedAccessAttemptsComments, setKnowsUnauthorizedAccessAttemptsComments] = useState("");

    // *************** Questionnaire specific Variables***************//
    

    // Get assessmnet data Function.  This should pull data for the assessment for the current assessment. 
    const getAssessmentData = async () => {
        try {
            const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
                withCredentials: true
            });
            const data = await response.data;
            // setAccessControlQuestions(data.accessControlQuestions);
            setAssessmentData(data);
            setHasLogicalAccessControl(data?.hasLogicalAccessControl?.toString());
            setHas3rdPartyRemoteAccess(data?.has3rdPartyRemoteAccess?.toString());
            // setHas3rdPartyRemoteAccessDescription(data.has3rdPartyRemoteAccessDescription.toString());
            setHas3rdPartyRemoteAccessList(data?.has3rdPartyRemoteAccessList?.toString());
            setDoesRemoteConnectionWeeklyReview(data?.doesRemoteConnectionWeeklyReview?.toString());
            setKnowsUnauthorizedAccessAttempts(data?.knowsUnauthorizedAccessAttempts?.toString());
            setHasLogicalAccessControlComments(data?.hasLogicalAccessControlComments?.toString());
            setHas3rdPartyRemoteAccessComments(data?.has3rdPartyRemoteAccessComments?.toString());
            setHas3rdPartyRemoteAccessDescriptionComments(data?.has3rdPartyRemoteAccessDescriptionComments?.toString());
            setHas3rdPartyRemoteAccessListComments(data?.has3rdPartyRemoteAccessListComments?.toString());
            setDoesRemoteConnectionWeeklyReviewComments(data?.doesRemoteConnectionWeeklyReviewComments?.toString());
            setKnowsUnauthorizedAccessAttemptsComments(data?.knowsUnauthorizedAccessAttemptsComment?.toString());
    
            if (assessmentData === null) {
                return;
            }
        } catch (err) {
            alert("Login Expired. access control");
            console.error(err);
            navigate('/login', { state: { from: location }, replace: true });
        }
        
    };
  
    useEffect(() => {
        getAssessmentData();
      }, []);  // eslint-disable-line react-hooks/exhaustive-deps
      if (!assessmentData) {
        return null;
      }

    const buttonStyle = {
        'backgroundColor': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let accessControlQuestions = true;
        const accessControlPageData = JSON.stringify({ hasLogicalAccessControl, has3rdPartyRemoteAccess, has3rdPartyRemoteAccessDescription, has3rdPartyRemoteAccessList, doesRemoteConnectionWeeklyReview, knowsUnauthorizedAccessAttempts, hasLogicalAccessControlComments, has3rdPartyRemoteAccessComments, has3rdPartyRemoteAccessDescriptionComments, has3rdPartyRemoteAccessListComments, doesRemoteConnectionWeeklyReviewComments, knowsUnauthorizedAccessAttemptsComments });
        try {
            const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
           JSON.stringify({ accessControlQuestions, accessControlPageData}),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            }
        );
        // questionFamily(2) activates the Asset Management questions. 
        setQuestionFamily(2);
        } catch (err) {
            if (!err?.response) {
                console.log('No Server Response');
            } else {
                console.log('Action Failed')
            }
        }
    }

    const GridItemQuestionXS = 7;
    const GridItemInputXS = 2;
    const GridItemInputSX = 'center';
    const inputWidth = '18px';
    const inputHeight = '18px';
    const GridItemCommentsXS = 3;
    const inputCommentWidth = '100%';

     //************Add category specific Questions.****************//
  const GovernanceQuestionsList = [
    {
        //***Example of radio button question. */
    uid: 'AC-1',
    variable: hasLogicalAccessControl,
    questionNumber: '1',
    questionText: 'Do you restrict logical access to control systems and networks through the use of passwords, secured communication gateways (jump boxes), access control lists, authenticators, and/or other secure access methods?',
    name: "hasLogicalAccessControl",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHasLogicalAccessControl,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "setHasLogicalAccessControlComments",
    valueComment: hasLogicalAccessControlComments,
    onChangeComments: setHasLogicalAccessControlComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    }, 
    {
        //***Example of radio button question. */
    uid: 'AC-2',
    variable: has3rdPartyRemoteAccess,
    questionNumber: '2',
    questionText: 'Do any vendors or 3rd parties have remote access to your network?',
    name: "has3rdPartyRemoteAccess",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHas3rdPartyRemoteAccess,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "has3rdPartyRemoteAccessComments",
    valueComment: has3rdPartyRemoteAccessComments,
    onChangeComments: setHas3rdPartyRemoteAccessComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    }, 
    {
        //***Example of radio button question. */
    uid: 'AC-3',
    variable: has3rdPartyRemoteAccessList,
    questionNumber: '3',
    questionText: 'Can you provide a list of 3rd parties that have remote access to your network?',
    name: "has3rdPartyRemoteAccessList",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setHas3rdPartyRemoteAccessList,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "has3rdPartyRemoteAccessListComments",
    valueComment: has3rdPartyRemoteAccessListComments,
    onChangeComments: setHas3rdPartyRemoteAccessListComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
    }, 
    {
    //***Example of radio button question. */
    uid: 'AC-4',
    variable: doesRemoteConnectionWeeklyReview,
    questionNumber: '4',
    questionText: 'Are remote connection attempts logged and reviewed at least weekly?',
    name: "doesRemoteConnectionWeeklyReview",
    type: 'radio',
    answerType: 'YesNo', 
    //***Only used for question type 'checkbox'. */
    checkboxInputs: [
        {
        // answer options for checkbox
        },
        {
        // answer options for checkbox
        }
    ], 
    onChangeInput: setDoesRemoteConnectionWeeklyReview,
    //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
    comments: 'true',
    idComment: "doesRemoteConnectionWeeklyReviewComments",
    valueComment: doesRemoteConnectionWeeklyReviewComments,
    onChangeComments: setDoesRemoteConnectionWeeklyReviewComments,
    //***Modify these settings if you want to change them from the defaults for this specific question. */
    GridItemQuestionXS: GridItemQuestionXS,
    GridItemInputXS: GridItemInputXS,
    GridItemCommentsXS: GridItemCommentsXS,
    GridItemInputSX: GridItemInputSX,
    inputStyleWidth: inputWidth,
    inputStyleHeight: inputHeight,
    inputCommentWidth: inputCommentWidth,
       }, 
  ]

//   return (
//     <Box>
//      <Grid container spacing={2} columns={12}>
//       <Grid item xs={12}>
//           <Box
//           width="100%"
//           p="1rem"
//           // m="1rem auto"
//           borderRadius="1.5rem"
//           backgroundColor={theme.palette.background.alt}
//           >
//     <section>
//       <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
//           {errMsg}
//       </p>
//       <h1>Governance</h1>
//       <form>
//       <Box
//           display="grid"
//           alignItems="downward"
//           gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
//           sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
//           }}
//       >
//         {GovernanceQuestionsList.map(({
//           uid,
//           variable,
//           GridItemQuestionXS,
//           questionNumber,
//           questionText,
//           GridItemInputXS,
//           GridItemInputSX,
//           inputStyleWidth,
//           inputStyleHeight,
//           name,
//           type,
//           answerType,
//           checkboxInputs,
//           onChangeInput,
//           comments,
//           GridItemCommentsXS,
//           inputCommentWidth,
//           idComment,
//           valueComment,
//           onChangeComments
//         }) => (
//           <QuestionnaireForm 
//             key={uid}
//             variable={variable}
//             GridItemQuestionXS={GridItemQuestionXS}
//             questionNumber={questionNumber}
//             questionText={questionText}
//             GridItemInputXS={GridItemInputXS}
//             GridItemInputSX={GridItemInputSX}
//             inputStyleWidth={inputStyleWidth}
//             inputStyleHeight={inputStyleHeight}
//             name={name}
//             type={type}
//             answerType={answerType}
//             checkboxInputs={checkboxInputs}
//             onChangeInput={onChangeInput}
//             comments={comments}
//             GridItemCommentsXS={GridItemCommentsXS}
//             inputCommentWidth={inputCommentWidth}
//             idComment={idComment}
//             valueComment={valueComment}
//             onChangeComments={onChangeComments}
//             />
//           )
//           )}
//           {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
//           <Button disabled={( hasLogicalAccessControl==="" || has3rdPartyRemoteAccess==="" || has3rdPartyRemoteAccessList==="" || doesRemoteConnectionWeeklyReview==="")} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
//         </Box>
//       </form>
//     </section>
//     </Box>
//     </Grid>
//     </Grid>
//    </Box> 

//   )
// }

// export default AccessControlQuestions


  return (
    <Box>
        <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
                <Box
                width="100%"
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background.alt}
                >
                    <section>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                            {errMsg}
                        </p>
                        <h1>Access Control</h1>
                        <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            alignItems="downward"
                            gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Grid container spacing={1} columns={12}>

             {/*Question #1 */}
                            <Grid item xs={10} >
                            <p>1. Do you restrict logical access to control systems and networks through the use of passwords, secured communication gateways (jump boxes), access control lists, authenticators, and/or other secure access methods?</p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasLogicalAccessControl"
                                type="radio" 
                                id="hasLogicalAccessControlYes"
                                value="true"
                                checked={hasLogicalAccessControl==="true"}
                                onChange={(e) => setHasLogicalAccessControl(e.target.value)}
                            />
                            <label htmlFor="hasLogicalAccessControlYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="hasLogicalAccessControl"
                                type="radio" 
                                id="hasLogicalAccessControlNo" 
                                value="false"
                                checked={hasLogicalAccessControl==="false"}
                                onChange={(e) => setHasLogicalAccessControl(e.target.value)}
                                
                            />
                            <label htmlFor="hasLogicalAccessControlNo">No</label>
                            </Grid>
                            <Grid item xs={8}>
                            <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="hasLogicalAccessControlComments">Comments:</label>
                            <input style={{ width:'30%', marginLeft:'3rem'}}
                                type="text" 
                                id="hasLogicalAccessControlComments"
                                value={hasLogicalAccessControlComments || ""}
                                onChange={(e) => setHasLogicalAccessControlComments(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>

            {/*Question #2 */}
                            <Grid item xs={10}>
                            <p>2. Do any vendors or 3rd parties have remote access to your network?</p> 
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="has3rdPartyRemoteAccess"
                                type="radio" 
                                id="has3rdPartyRemoteAccessYes"
                                value="true"
                                checked={has3rdPartyRemoteAccess==="true"}
                                onChange={(e) => setHas3rdPartyRemoteAccess(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="has3rdPartyRemoteAccessYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="has3rdPartyRemoteAccess"
                                type="radio" 
                                id="has3rdPartyRemoteAccessNo"
                                value="false"
                                checked={has3rdPartyRemoteAccess==="false"}
                                onChange={(e) => setHas3rdPartyRemoteAccess(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="has3rdPartyRemoteAccessNo">No</label>
                            </Grid>
                            <Grid item xs={11}>
                            <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="has3rdPartyRemoteAccessComments">If yes, briefly describe the access controls protecting these connections:</label>
                            <input style={{ width:'100%', marginLeft:'3rem'}}
                                type="text" 
                                id="has3rdPartyRemoteAccessComments"
                                value={has3rdPartyRemoteAccessComments || ""}
                                onChange={(e) => setHas3rdPartyRemoteAccessComments(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>

            {/*Question #3 */}
                            <Grid item xs={10}>
                            <p>3. Can you provide a list of 3rd parties that have remote access to your network?</p>
                            </Grid>
                            
                            {has3rdPartyRemoteAccess==='false' ? (
                                <Grid item xs={2} sx={{alignSelf:'center'}}>
                                    <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                    name="na1"
                                    type="radio" 
                                    id="na1"
                                    value="na"
                                    disabled={has3rdPartyRemoteAccess==='false'}
                                    checked={has3rdPartyRemoteAccess==="false"}
                                    />
                                    <label style={{ marginTop:"1rem" }} htmlFor="na1">NA</label>
                                    {setHas3rdPartyRemoteAccessList==="false"}
                                </Grid>
                            ) : (
                                <>
                                <Grid item xs={2} sx={{alignSelf:'center'}}>
                                        <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                        name="has3rdPartyRemoteAccessList"
                                        type="radio" 
                                        id="has3rdPartyRemoteAccessListYes"
                                        value="true"
                                        // disabled={has3rdPartyRemoteAccess==='false'}
                                        checked={has3rdPartyRemoteAccessList==="true"}
                                        onChange={(e) => setHas3rdPartyRemoteAccessList(e.target.value)}
                                        />
                                        <label style={{ marginTop:"1rem" }} htmlFor="has3rdPartyRemoteAccessListYes">Yes</label>
                                        <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                        name="has3rdPartyRemoteAccessList"
                                        type="radio" 
                                        id="has3rdPartyRemoteAccessListNo"
                                        value="false"
                                        // disabled={has3rdPartyRemoteAccess==='false'}
                                        checked={has3rdPartyRemoteAccessList==="false"}
                                        onChange={(e) => setHas3rdPartyRemoteAccessList(e.target.value)}
                                        />
                                        <label style={{ marginTop:"1rem" }} htmlFor="has3rdPartyRemoteAccessListNo">No</label>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="has3rdPartyRemoteAccessListComments">Comments:</label>
                                        <input style={{ width:'30%', marginLeft:'3rem'}}
                                            type="text" 
                                            id="has3rdPartyRemoteAccessListComments"
                                            value={has3rdPartyRemoteAccessListComments || ""}
                                            onChange={(e) => setHas3rdPartyRemoteAccessListComments(e.target.value)}
                                        />
                                    </Grid> 
                                </>
                            )}
                            

                            <Grid item xs={12}><Divider /></Grid>

            {/*Question #4 */}
                            <Grid item xs={10}>
                            <p style={{ marginBottom:0, marginTop:'1rem'}}>4. Are remote connection attempts logged and reviewed at least weekly?</p> 
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}> 
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="doesRemoteConnectionWeeklyReview"
                                type="radio"
                                id="doesRemoteConnectionWeeklyReviewYes"
                                value="true"
                                checked={doesRemoteConnectionWeeklyReview==="true"}
                                onChange={(e) => setDoesRemoteConnectionWeeklyReview(e.target.value)}
                            />
                            <label htmlFor="doesRemoteConnectionWeeklyReviewYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="doesRemoteConnectionWeeklyReview"
                                type="radio" 
                                id="doesRemoteConnectionWeeklyReviewNo"
                                value="false"
                                checked={doesRemoteConnectionWeeklyReview==="false"}
                                onChange={(e) => setDoesRemoteConnectionWeeklyReview(e.target.value)}
                            />  
                            <label htmlFor="doesRemoteConnectionWeeklyReviewNo">No</label>
                            </Grid>
                            <Grid item xs={8}>
                            <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="doesRemoteConnectionWeeklyReviewComments">Comments:</label>
                            <input style={{ width:'30%', marginLeft:'3rem'}}
                                type="text" 
                                id="doesRemoteConnectionWeeklyReviewComments"
                                value={doesRemoteConnectionWeeklyReviewComments || ""}
                                onChange={(e) => setDoesRemoteConnectionWeeklyReviewComments(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid>

            {/*Question #5 */}
                            {/* <Grid item xs={10}>
                            <p>5. Do you know how many unauthorized attempts to access your network occurred in the last year? </p>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="knowsUnauthorizedAccessAttempts"
                                type="radio" 
                                id="knowsUnauthorizedAccessAttemptsYes"
                                value="true"
                                checked={knowsUnauthorizedAccessAttempts==="true"}
                                onChange={(e) => setKnowsUnauthorizedAccessAttempts(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="knowsUnauthorizedAccessAttemptsYes">Yes</label>
                            <input style={{ marginLeft:'3rem', width:'18px', height:'18px'}}
                                name="knowsUnauthorizedAccessAttempts"
                                type="radio" 
                                id="knowsUnauthorizedAccessAttemptsNo"
                                value="false"
                                checked={knowsUnauthorizedAccessAttempts==="false"}
                                onChange={(e) => setKnowsUnauthorizedAccessAttempts(e.target.value)}
                            />
                            <label style={{ marginTop:"1rem" }} htmlFor="knowsUnauthorizedAccessAttemptsNo">No</label>
                            </Grid>
                            <Grid item xs={8}>
                            <label style={{ marginTop:0, marginLeft:'3rem' }} htmlFor="knowsUnauthorizedAccessAttemptsComments">Comments:</label>
                            <input style={{ width:'30%', marginLeft:'3rem'}}
                                type="text" 
                                id="knowsUnauthorizedAccessAttemptsComments"
                                value={knowsUnauthorizedAccessAttemptsComments || ""}
                                onChange={(e) => setKnowsUnauthorizedAccessAttemptsComments(e.target.value)}
                            />
                            </Grid>
                            <Grid item xs={12}><Divider /></Grid> */}
                        </Grid>
                        <button disabled={( hasLogicalAccessControl==="" && has3rdPartyRemoteAccess==="" && has3rdPartyRemoteAccessList==="" && doesRemoteConnectionWeeklyReview==="" && knowsUnauthorizedAccessAttempts==="" )} style={buttonStyle}>Submit</button>
                       
                        {/* <Button disabled={( hasLogicalAccessControl==="" || has3rdPartyRemoteAccess==="" || has3rdPartyRemoteAccessList==="" || doesRemoteConnectionWeeklyReview==="" || knowsUnauthorizedAccessAttempts==="")} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button> */}
                        </Box>
                        </form>
                    </section>
                </Box>
            </Grid>
        </Grid>
    </Box>
  )
}

export default AccessControlQuestions
