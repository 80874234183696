import React from 'react';
import FlexBetween from '../../components/FlexBetween';
import { useState, useRef } from "react";
import { AppBar, InputBase, Toolbar, Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton, Typography, Button, Select, Menu, MenuItem, FormControl, useTheme, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMode } from "../../state";
import { useNavigate } from "react-router-dom";
import useLogout from '../../hooks/useLogout';
import WidgetWrapper from '../../components/WidgetWrapper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Header from '../../components/Header';


const GetHelp = () => {

    const userRef = useRef();
    const errRef = useRef();
    const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
    const dispatch = useDispatch();

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.alt;
    const primaryLight = theme.palette.primary.light;
    const primary = theme.palette.primary.main;
    const alt = theme.palette.background.alt;

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [organization, setOrganization] = useState('');
    const [validOrganization, setValidOrganization] = useState(false);
    const [organizationFocus, setOrganizationFocus] = useState(false);

    const [message, setMessage] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [openGetHelp, setOpenGetHelp] = useState(false);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [openAck, setOpenAck] = useState(false);

    const buttonStyle = {
        'backgroundColor': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosPrivate.post(`/helprequest/new`,
                JSON.stringify({ user, email, organization, message }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            alert("Message received!")
            navigate('/');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                errRef.current.focus();
        }
    } 
    };


  return (
    <div>
        <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <Box sx={{  ml:'33%', mr:'33%', width:'30%', mb:'2rem' }}>
                    <Header title='Request Help' subtitle='Ask us anything.' />
                </Box>
                    
                    <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? "span 4" : "span 8"},
                            ml:'33%',
                            mr:'33%',
                            width:'30%'
                        }}
                    >
                        <label htmlFor="username" sx={{gridColumn: "span 4"}}>
                            Username:
                            {/* <span >
                                <CheckCircleOutlineIcon />
                            </span>
                            <span className={validName || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span> */}
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            // onFocus={() => setUserFocus(true)}
                            // onBlur={() => setUserFocus(false)}
                            />
                            <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                4 to 24 characters. <br />
                                Must begin with a letter. <br />
                                Letters, numbers, underscores, and hyphens are allowed.
                            </p>

                            <label htmlFor="email" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Email:
                            </label>
                            <input 
                                type="email"
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />

                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            Organization:
                            </label>
                            <input 
                                type="organization"
                                id="organization"
                                onChange={(e) => setOrganization(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                />


                        
                        <label htmlFor="organization" sx={{gridColumn: "span 4", marginTop:'2rem' }}>
                            How can we help?:
                            </label>
                            <textarea 
                                type="message"
                                id="message"
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                aria-describedby="uidnote"
                                style={{ height:'200px' }}
                                />


                        <button style={buttonStyle} >Get Help</button>
                        </Box>
                    </form>
            
                </section>
    </div>
  )
}

export default GetHelp