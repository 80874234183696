// from mern_app but may not use this at all. 

import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPost, setFriends } from "../state";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const Friend = ({ friendId, name, subtitle, userPicturePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _id = "659102e857259da4dcdfbb95";
  const friends = useSelector((state) => state.user?.friends);
  const axiosPrivate = useAxiosPrivate();

  const isNotUserSelf = Boolean(_id !== friendId);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isFriend = friends?.find((friend) => friend._id === friendId);

  const patchFriend = async () => {
    if (_id === friendId) {
        return;
    } else {
      try {
        const response = await axiosPrivate.get('/usersma', 
        {
          params: {
            id: _id,
            friendId: friendId
           }}, 
          {
            withCredentials: true
        });
        const data = await response.data;
        dispatch(setPost({ posts: data }));
    } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }



        // const response = await fetch(
        //     `http://localhost:3001/users/${_id}/${friendId}`,
        //     {
        //       method: "PATCH",
        //       headers: {
        //         Authorization: `Bearer ${token}`,
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   );
        //   const data = await response.json();
        //   dispatch(setFriends({ friends: data }));
    }
  };

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={userPicturePath} size="55px" />
        <Box
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
          }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
        {/* <IconButton
                onClick={() => patchFriend()}
                sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
            >
                {isFriend ? (
                <PersonRemoveOutlined sx={{ color: primaryDark }} />
                ) : (
                <PersonAddOutlined sx={{ color: primaryDark }} />
                )}
            </IconButton> */}
        <Box alignContent='right'>
            {isNotUserSelf && (
            <IconButton alignConent="right"
                onClick={() => patchFriend()}
                sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
            >
                {isFriend ? (
                <PersonRemoveOutlined sx={{ color: primaryDark }} />
                ) : (
                <PersonAddOutlined sx={{ color: primaryDark }} />
                )}
            </IconButton>
      )}
        </Box>

      
      
    </FlexBetween>
    
  );
};

export default Friend;