import { Link } from "react-router-dom";
import UserWidget from "../widgets/UserWidget";
import UserImage from "./UserImage";

const Editor = () => {
    const _id = "659102e857259da4dcdfbb95";
    const picturePath = "p3.jpeg";
    return (
        <section>
            <h1>Editors Page</h1>
            <br />
            <p>You must have been assigned an Editor role.</p>
            <div className="flexGrow">
                <UserImage />
                <Link to="/">Home</Link>
            </div>
        </section>
    )
}

export default Editor