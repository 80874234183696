/* 
Integrated from "mern_app".  Not sure if this will be needed
*/

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostWidget from "./PostWidget";
import { setPosts } from "../state";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Typography } from "@mui/material";


const PostsWidget = ({ userId, isProfile = false }) => {
    const dispatch = useDispatch();
    const posts = useSelector((state) => state.posts);
    const axiosPrivate = useAxiosPrivate();
    
    const getPosts = async () => {
        try {
            const response = await axiosPrivate.get('/posts', {
                withCredentials: true
            });
            const data = await response.data;
            dispatch(setPosts({ posts: data }));
        } catch (err) {
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
        }
    };

    

    const getUsersPosts = async () => {
         try {
            const response = await axiosPrivate.get('/posts', {
                withCredentials: true
            });
            const data = await response.data;
            dispatch(setPosts({ posts: data }));
        } catch (err) {
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
        }
    };

    // console.log("The posts are here userId should be friendID: " + JSON.stringify(posts));

    useEffect(() => {
        if (isProfile) {
            getUsersPosts();
        } else {
            getPosts();
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    


    return (
        <>
        <Typography>
            TEST MESSAGE
        </Typography>
            {posts.map(
                ({
                    _id,
                    userId,
                    firstName,
                    lastName,
                    description,
                    location,
                    picturePath,
                    userPicturePath,
                    likes,
                    comments,
                }) => (
                    <PostWidget 
                        key={_id}
                        postId={_id}
                        postUserId={userId}
                        name={`${firstName} ${lastName}`}
                        description={description}
                        location={location}
                        picturePath={picturePath}
                        userPicturePath={userPicturePath}
                        likes={likes}
                        comments={comments}
                    />
                )
            )}
        </>
    )
};

export default PostsWidget;