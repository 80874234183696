import { Box, Link,useMediaQuery, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import WidgetWrapper from "../../components/WidgetWrapper";
import useAuth from '../../hooks/useAuth';
import { jwtDecode } from "jwt-decode";
import * as React from 'react';
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Sidebar from '../../components/Sidebar';
import PersonIcon from '@mui/icons-material/Person';
import FlexBetween from "../../components/FlexBetween";

import { Divider, useTheme, Grid } from '@mui/material';
import  UserImage from "../../components/UserImage";
import GanttTaskChart from '../../components/GantTaskChart';
import StatBox from "../../components/StatBox";
import { DownloadOutlined, Email, PointOfSale, PersonAdd, Traffic } from '@mui/icons-material';


const Operations = () => {

const isNonMobileScreens = useMediaQuery("(min-width:1000px");
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const { palette } = useTheme();
    const dark = palette.neutral.dark;
    const medium = palette.neutral.medium;
    const main = palette.neutral.main;
    const navigate = useNavigate();

    const { auth } = useAuth();
    const decoded = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined

    const roles = decoded?.UserInfo?.roles || []
    const userLoggedIn = decoded?.UserInfo.username || []
    const userInfo = decoded?.UserInfo;
    const userId = userInfo.userId || {};
    const [refresh, setRefresh] = useState(false);

    const [userAssessmentData, setUserAssessmentData] = useState();

    const theme = useTheme();


const getUserAssessments = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/userassessments/${userId}`, {
            withCredentials: true
        });
        const data = await response.data;
        setUserAssessmentData(data);
    } catch (err) {
        // alert("Login Expired.");
    console.error(err);
    navigate('/login', { state: { from: location }, replace: true });
    }
    
};
useEffect(() => {
  getUserAssessments();
}, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
if (!userAssessmentData) {
  return null;
}


  return (
    <Box sx={{ m:'1rem'}}>
        <Grid container spacing={2}>
            
            {/** STATS BOX Annual Certification Letter, # of outstanding COAs, # of Suspicious Activity Reports, Stat Box for each dam that has been assessed. */}
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <StatBox
                            title="Annual Certification Letter"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Suspicious Activity Reports"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Outstanding COAs"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Cyber Inventory"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Score for 'Dam 1'"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Score for 'Dam 1'"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatBox
                            title="Score for 'Dam 1'"
                            value={88}             // {dashboardData && dashboardData.todayStats.totalSales}
                            increase="+21%"
                            description="Since last month"
                            icon={
                                <PointOfSale
                                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* GANTT CHART of PROJECTS MAKE A COMPONENT */}
            <Grid item xs={8}>
                <WidgetWrapper>
                    <Typography padding='5px'>
                        Component Title
                    </Typography>
                    <Divider sx={{marginBottom:'5px'}}/>
                    <GanttTaskChart userAssessmentData={userAssessmentData} />
                </WidgetWrapper> 
            </Grid>  

            {/** KANBAN BOARD, PUT ALL TASKS HERE ORGANIZED BY DAM. */}
            <Grid item xs={12}>
                <WidgetWrapper>
                    <Typography padding='5px'>
                        Kanban Board
                    </Typography>
                    <Divider sx={{marginBottom:'5px'}}/>
                    <Typography padding='5px'>
                        Coming Soon...
                    </Typography>
                </WidgetWrapper> 
            </Grid>  
            
        </Grid>
        
        
        
        
        
        
    </Box>
  )
}

export default Operations