import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Login from './Login';
import axios from '../api/axios';

// START from mern_app project
// import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Box, Button, TextField, useMediaQuery, Typography, useTheme } from "@mui/material";
// END from mern_app project



// REGEX Validation for user and password inputs
// Username must start with a lower or uppercase letter, only letters and the '-' or '_' can be used in the username and the length must be between at least 4 characters but less than 23 characters.
const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
// Email regex Email must have an @symbol and end with '.com' or '.gov' or '.net' or '.org'
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-_]+\.[a-zA-Z]{2,}$/;
// Passwords must have a lowercase letter, an uppercase letter, a number, and one of the following special characters '[!@#$%]' AND it must be between 8 and 24 characters long.
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';


const Register = () => {

    const theme = useTheme();                                           // From mern_app project 
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");    // From mern_app project
    const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project

    const userRef = useRef();
    const errRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        const result = USER_REGEX.test(user);
        setValidName(result);
    }, [user])

    useEffect(() => {
        const result = PASSWORD_REGEX.test(password);
        setValidPassword(result);
        setValidMatch(password === matchPassword);
    }, [password, matchPassword])

    useEffect(() => {
        setErrMsg('');
    }, [user, password, matchPassword] );   // [user, password, matchPassword] 

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PASSWORD_REGEX.test(password);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post('/register',
                JSON.stringify({ user, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const data = await response.data;
            console.log(data);

            // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
            // The console log commands below allow you to see the responses from Axios for the response.  
            
            //clear state and controlled inputs
            //need value attrib on inputs for this
            setUser('');
            setPassword('');
            setMatchPassword('');
            // navigate(from, { replace: true });
            navigate(`/login`);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err?.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
            // navigate(from, { replace: true });
        }
    }

    const buttonStyle = {
        'backgroundColor': '#00D5FA',
        'width': '35%',
        'marginTop': '15px',
        'padding': '10px'
    }

    return (
        <Box>
            <Box width="100%" backgroundColor={theme.palette.background.alt} p="1rem 6%" textAlign="center">
                <Typography 
                    fontWeight="bold"
                    fontSize="32px"
                    color="primary"
                >
                    FERC Hydro Power Security       
                </Typography>

            </Box>
            <Box
                width='40%'
                p="1rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={theme.palette.background.alt}
            >
                <Typography fontWeight="500" variant="h5" sx={{mb: "1.5rem"}}>
                    Hydro Power Security
                </Typography>
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1>Register</h1>
                    <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? "span 4" : "span 8"},
                        }}
                    >
                        <label htmlFor="username" sx={{gridColumn: "span 4"}}>
                            Username:
                            <span className={validName ? "valid" : "hide"}>
                                <CheckCircleOutlineIcon />
                            </span>
                            <span className={validName || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span>
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                            />
                            <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                4 to 24 characters. <br />
                                Must begin with a letter. <br />
                                Letters, numbers, underscores, and hyphens are allowed.
                            </p>
                        
                        <label htmlFor="password">
                        Password:
                            <span className={validPassword ? "valid" : "hide"}>
                                <CheckCircleOutlineIcon />
                            </span>
                            <span className={validPassword || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span>
                        </label>
                        <input
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            aria-invalid={validPassword ? "false" : "true"}
                            aria-describedby="passworddnote"
                            onFocus={() => setPasswordFocus(true)}
                            onBlur={() => setPasswordFocus(false)}
                        />
                        <p id="passworddnote" className={passwordFocus && !validPassword ? "instructions" : "offscreen"}>
                            <InfoOutlinedIcon />
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>

                        <label htmlFor="confirm_password">
                            Confirm Password:
                            <span className={validMatch ? "valid" : "hide"}>
                                <InfoOutlinedIcon />
                            </span>
                            <span className={validMatch || !user ? "hide" : "invalid"}>
                                <CloseIcon />
                            </span>
                        </label>
                        <input
                            type="password"
                            id="confirm_pwd"
                            autoComplete="new-password"
                            onChange={(e) => setMatchPassword(e.target.value)}
                            value={matchPassword}
                            required
                            aria-invalid={validMatch ? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                        />
                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                            <InfoOutlinedIcon />
                            Must match the first password input field.
                        </p>

                        <button style={buttonStyle} disabled={!validName || !validPassword || !validMatch ? true : false}>Sign Up</button>
                        </Box>
                    </form>
                    <p>
                        Already registered?<br />
                        <span className="line">
                            {/*put router link here*/}
                            <a href="/login">Sign In</a>
                        </span>
                    </p>
                </section>
        </Box>
        </Box>
    )
}

export default Register
