import { memo } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';

const OrGate = ({data, selected}) => {
  return (
    <>         
        <NodeResizer isVisible={selected} minWidth={50} minHeight={50} maxHeight={100} maxWidth={100}/>
         <svg className='orGateSVG' viewBox="0 0 50 50" width="50" hieght="50">
            <polygon points="5,25 50,50 50,0"/>
            <title>Logic Gate: OR</title>
        </svg>
        <Handle type="target" position={Position.Left} />
        <Handle type="source" position={Position.Right} />
    </>
  )
};

export default memo(OrGate);
