import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton,
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import WidgetWrapper from '../../../components/WidgetWrapper';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useReactToPrint } from 'react-to-print';


const AssessmentCards = ({
  _id,
  assessmentID,
  sectionTitle,
  sectionContent,
  refresh,
  setRefresh

}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [assessmentToDelete, setAssessmentToDelete] = useState();
  const [damNameToDelete, setDamNameToDelete] = useState();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [newThreatName, setNewThreatName] = useState(sectionTitle);
  const [newThreatDescription, setNewThreatDescription] = useState(sectionContent);
 
  // const newSections = [];

  // const createSections = () => {
  //   JSON.parse(sections).forEach(section => {newSections.push(section); });
  // };

  //   createSections();
  
  const handleDeleteAssessment = async () => {
    let id = _id;
    try {
        const response = await axiosPrivate.delete(`/internalresponse/${id}`, 
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
    } catch (err) {
        console.error(err);
    }
    setRefresh(!refresh);
    setAssessmentToDelete(null);
    setDamNameToDelete(null);
    };
  
  const handleClickOpen = (_id, sectionTitle) => {
      setAssessmentToDelete(_id);
      setDamNameToDelete(sectionTitle);
      setOpen(true);
    };
  
  const handleNo = () => {
      setOpen(false);
  };

  const handleYes = () => {
      handleDeleteAssessment();
      setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sectionTitle = newThreatName;
    let sectionContent = newThreatDescription;
    let id = _id;
    let allAssessmentInternalResponseData = JSON.stringify({sectionTitle, sectionContent})
    try {
      const response = await axiosPrivate.patch(`/internalresponse/internalresponse/${id}`,
       JSON.stringify({ allAssessmentInternalResponseData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
  
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
    setRefresh(!refresh)
};

const handleAddSection = (e) =>{
  // newSections.push(
  //   {
  //     name: '',
  //     procedures: ['']
  //   }
  // )
  // handleSubmit(e);
};

const handleRemoveSection = (e, index) =>{
  // newSections.splice(index, 1);
  // handleSubmit(e);
};

const handleAddMeasure = (e, index) => {
  // console.log(index, locale);
  // console.log(newSections[index].procedures);
  // newSections[index].procedures.push("newMeasure");
  // handleSubmit(e);
};

const handleRemoveMeasure = (e, index, locale) => {
  // newSections[index].procedures.splice(locale, 1);
  // handleSubmit(e);
};

  return (
    <Box>
        <Dialog
          open={open}
          onClose={handleNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {"Verify Delete Command"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the {damNameToDelete} assessment?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleYes}>Yes</Button>
          <Button onClick={handleNo} autoFocus>
              No
          </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <WidgetWrapper>
          {isExpanded ? (
            <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
              <ExpandCircleDownIcon sx={{ transform: 'rotate(270deg)'}} color='primary' fontSize="large" />
              <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
            </IconButton>
            ) : (
            <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
              <ExpandCircleDownIcon color='primary' fontSize="large" />
              <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
            </IconButton>
            )}
            <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
            <Box sx={{ display:'flex', justifyContent:'end' }}>
              <Button sx={{ marginRight: '1rem' }} variant='contained' onClick={() => {handleClickOpen(assessmentID, sectionTitle)}}>Delete</Button>
            </Box>
            <Typography variant='h3' sx={{textAlign: 'center' }}>Introduction Section</Typography>
            <Typography>Section Title</Typography>
              <textarea
                value={newThreatName || []}
                style={{ width:'100%', maxWidth:'100%' }}
                onChange={(e) => setNewThreatName(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
              </textarea>
              {/* <Button 
                      variant="primary"
                      size="small"
                      onClick={() => setIsExpanded(!isExpanded)}
                  >
                      See More
                  </Button> */}
                  
                  <Divider />
                  <Typography>Section Content</Typography>
                  <textarea 
                    value = {newThreatDescription || []}
                    style={{ height: '150px', width:'100%', maxWidth:'100%' }} 
                    onChange={(e) => setNewThreatDescription(e.target.value)}
                    onBlur={(e)=> handleSubmit(e)}
                    >
                    
                  </textarea>
                  <Divider />
                
            </Collapse>
        </WidgetWrapper>

          </Grid>
        </Grid>
       
    </Box>
  )
};


const InternalEmergencyResponse = () => {

  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentData, setAssessmentData] = useState(null);
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();
  const [overviewScopeData, setOverviewScopeData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const componentRef = useRef();
  const [allInternalEmergencyResponseData, setAllAssessmentInternalEmergencyResponse] = useState([]);
  const marginTop = '2em';
  const marginBottom = '4em';
  const marginLeft = '1em';
  const marginRight = '1em';

  // **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data);
        setAssessmentData(data);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const getInternalEmergencyResponseData = async () => {
    try {
      const response = await axiosPrivate.get(`/internalresponse/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      setAllAssessmentInternalEmergencyResponse(data); 
      console.log(data);
    } catch (err) {
      // alert("Login Expired. access control");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  }


    // Execute these functions on load. Add functions as necessary. 
    useEffect(() => {
      // getDams();
      getInternalEmergencyResponseData();
      getAssessment();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps


    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'emp-data',
      // onAfterPrint: () => alert('Print Success!')
    });
  
    const createDoc = () => {
      let tempList = [];
      tempList.push(<Typography variant='h2' sx={{ mt: '2rem', textAlign:'center' }}><u><b>Internal Emergency Response Plan</b></u></Typography>)
      tempList.push(
        <TableContainer sx={{ mt:'1rem' }}>
          <Table aria-label='table for rapid recovery procedures'>
            <TableBody>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Dam:</b></TableCell>
                <TableCell colSpan={3} sx={{ border:'solid 1px'}}>{assessmentData.damName}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Assessment Type:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}} >{assessmentData.assessmentType}</TableCell>
                <TableCell sx={{ border:'solid 1px'}}><b>Prepared for Fiscal Year:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}}>{assessmentData.assessmentYear}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
      for (let section in allInternalEmergencyResponseData) {
        tempList.push( <Typography variant='h2' sx={{ mt: '2rem' }} ><u><b>{allInternalEmergencyResponseData[section].sectionTitle} Condition</b></u></Typography>);
        tempList.push(<style>{getPageMargins()}</style>);
        tempList.push( <Typography sx={{ m:'2rem', whiteSpace:'pre-wrap' }}>{allInternalEmergencyResponseData[section].sectionContent}</Typography>);
    }
      return (
        tempList
      )
    };

    const getPageMargins = () => {
      return `@page {margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    }

    {/** ADD NEW INTERNAL RESPOSE SECTION */}
const handleAddInternalResponse = async () => {
  
  let sectionTitle = 'New Title';
  let sectionContent = 'New Section';
  let allAssessmentInternalResponseData = JSON.stringify({sectionTitle, sectionContent})
  const response = await axiosPrivate.post('/internalresponse/new',
    JSON.stringify({ assessmentID, allAssessmentInternalResponseData }),
      {
        headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    );
    setRefresh(!refresh);
  };
  


  return (
    <Box>

      {assessmentData===null ? (
        // LOADING
        <Dialog
          open={assessmentData===null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title"> </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Typography variant='h2'>Loading...</Typography>
          </DialogContentText>
          </DialogContent>
        </Dialog>
      ):(
        // Check for hasInternalEmergencyResponseRapidRecoveryProcedures
        (assessmentData?.hasInternalEmergencyResponseRapidRecoveryProcedures ? (
          <>
            <WidgetWrapper sx={{ display:'flex', justifyContent:'center', alignItems:'center', mt:'1rem' }}>
              <Typography>Add your existing Internal Emergency Response Procedure to this site's Security Plan.</Typography>
            </WidgetWrapper>
          </>
        ):(
         
        

      <Grid container spacing={2}>
      <Grid item xs={12}>
             <Box sx={{ display:'flex', justifyContent:'space-between'}}>
              <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handleAddInternalResponse}>ADD New Section</Button>
              <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handlePrint}>Print Emergency Response Plan</Button>
             </Box>
          
           </Grid>
           <Grid item xs={6}>
           <Box m="1.5rem 2.5rem">
            <Box mt="20px" display="grid" gridTemplateColumns="repeat(1, minmax(0, 1fr))" 
                justifyContent="space-between" 
                rowGap="20px" 
                columnGap="1.33%" 
                // sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
            >
                {allInternalEmergencyResponseData?.map(
                  ({  
                    _id,
                    assessmentID,
                    sectionTitle,
                    sectionContent,
                }) => (
                    <AssessmentCards
                        key={_id}
                        _id={_id}
                        assessmentID={assessmentID}
                        sectionTitle={sectionTitle}
                        sectionContent={sectionContent}
                        refresh={refresh}
                        setRefresh={setRefresh}
                    />
                    )
                )}
            </Box>
        </Box>
           </Grid>

             <Grid item xs={6}>
              <Paper sx={{ pt:'2em', pr:'5em', pl:'5em' }} ref={componentRef}>
              
                {createDoc()}
               </Paper>
             </Grid>

           </Grid>
      ))
    )}

    </Box>
  )
}


export default InternalEmergencyResponse