import React from 'react';
import { Search } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import FlexBetween from './FlexBetween';
import {
    GridToolbarDensitySelector,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton, 
    GridCsvExportOptions
} from "@mui/x-data-grid";
import { date } from 'yup';

const today = new Date().toISOString().slice(0, 10);

const exportFileName = document.title + today;

const DataGridCustomToolbar = ({ searchInput, setSearchInput, setSearch, setIsChanged }) => {
  return (
    <GridToolbarContainer>
        <FlexBetween width="100%">
            <FlexBetween>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport 
                    csvOptions={{
                        fileName: `${exportFileName}`,
                      }}
                />
            </FlexBetween>
            {/* <TextField
                label="Search..."
                sx={{ mb: "0.5rem", width: "15rem"}}
                onChange={(e)=>setSearchInput(e.target.value)}
                value={searchInput}
                variant="standard"
                InputProps={{
                    endAdornment:  (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={()=> {
                                    setSearch(searchInput);
                                    setIsChanged(true);
                                    console.log("FROM DATAGRID CUSTOM TOOLBAR " + searchInput);
                                    setSearchInput("");
                                }}
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            /> */}
        </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;