import React, { useState, useEffect } from 'react';
import { Box, Button, FormControlLabel, Collapse, IconButton, Typography, useTheme, Grid, Paper, Divider, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox } from "@mui/material";
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import RemoteAccessDetermination from './remoteAccessDetermination';
import ConsequenceDetermination from './consequenceDetermination';
import ConsequenceThreshold from './consequenceThreshold';
import CyberAssetDeterminationResults from './cyberAssetDeterminationResults';
import WidgetWrapper from '../../../../components/WidgetWrapper';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';


const CyberSCADAAssetDesignation = ({setActiveStep}) => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [allCyberAssetData, setAllCyberAssetData] = useState();

  const [dialogTitle, setDialogTitle] = useState();
  const [dialogMessage, setDialogMessage] = useState();
  const [dialogReference, setDialogReference] = useState();
  const [dialogReferenceLink, setDialogReferenceLink] = useState();

  const [questionIndex, setQuestionIndex] = useState();
  const [open, setOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState("remoteAccess");
  
  const theme = useTheme();

  const { assessmentID } = useParams();
  const [openInstructions, setOpenInstructions] = useState(true);

// Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
const getCyberAssets = async () => {
  try {
    const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    for (let i=0; i<data.length; i++) {
      if (data[i].cyberAssetData) {
        let assetData = JSON.parse(data[i]?.cyberAssetData);
        for (let key in assetData) {
          data[i][key] = assetData[key];
          console.log("Designation data conditioning");
          console.log(data);
        }
      }
    }
    setAllCyberAssetData(data);
    if (allCyberAssetData === null) {
        return;
    } 
  } catch (err) {
    // alert("Login Expired.");
    console.error(err);
    navigate('/login', { state: { from: location }, replace: true });
  }
};

  const checkFERCDesignations = async () => {
    try {
      const response = await axiosPrivate.get(`/assets/checkDesignations/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      // console.log(data);
      if (data.length===0) {
        setQuestionIndex(0);
      } else {
        setQuestionIndex(8);
      }
    } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
      getCyberAssets();
      checkFERCDesignations();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!allCyberAssetData) {
      return null;
    }

// Update Database Function 
const handleCyberAssetUpdate = async () => {
  for (let updatedRow in allCyberAssetData) {
    let id = allCyberAssetData[updatedRow]._id;
    let assetName = allCyberAssetData[updatedRow].assetName;
    let assetType = allCyberAssetData[updatedRow].assetType;
    let manufacturer = allCyberAssetData[updatedRow].manufacturer;
    let model = allCyberAssetData[updatedRow].model;
    let macAddress = allCyberAssetData[updatedRow].macAddress;
    let yearPurchased = allCyberAssetData[updatedRow].yearPurchased;
    let physicalLocation = allCyberAssetData[updatedRow].physicalLocation;
    let description = allCyberAssetData[updatedRow].description;
    let fercDesignation = allCyberAssetData[updatedRow].fercDesignation;
    let remoteAccess = allCyberAssetData[updatedRow].remoteAccess;
    let remoteAccessConnected = allCyberAssetData[updatedRow].remoteAccessConnected;
    let consequenceDetermination = allCyberAssetData[updatedRow].consequenceDetermination;
    let consequenceThresholds = allCyberAssetData[updatedRow].consequenceThresholds;
    let populationAtRisk = allCyberAssetData[updatedRow].populationAtRisk;
    let municipal = allCyberAssetData[updatedRow].municipal;
    let powerGen1500MW = allCyberAssetData[updatedRow].powerGen1500MW;
    let powerGen100MW = allCyberAssetData[updatedRow].powerGen100MW;
    let blackStart = allCyberAssetData[updatedRow].blackStart;

    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        { "assetName": assetName, "assetType": assetType, "manufacturer": manufacturer, "model": model, "macAddress": macAddress, "yearPurchased": yearPurchased, "physicalLocation": physicalLocation, "description": description, "fercDesignation": fercDesignation, "remoteAccess": remoteAccess, "remoteAccessConnected": remoteAccessConnected, "consequenceDetermination": consequenceDetermination, "consequenceThresholds": consequenceThresholds, "populationAtRisk": populationAtRisk, "municipal": municipal, "powerGen1500MW": powerGen1500MW, "powerGen100MW": powerGen100MW, "blackStart": blackStart },
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        // alert("Login Expired.");
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

 const handleNo = () => {
   setOpen(false);
};

const handleYes = () => {
  handleStartOver();
  setOpen(false);
};

const handleClose = () => {
    setDialogTitle();
    setDialogMessage();
    setDialogReference();
    setDialogReferenceLink();
    setOpen(false);
};

const UpdateRemoteAccessQuestions = (e, params) => {
  if (questionIndex===0) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].remoteAccess = e;
        }
    }
  } else if (questionIndex===1) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].remoteAccessConnected = e;
        }
    }

  } else if (questionIndex===2) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].consequenceDetermination = e;
        }
    }
  } else if (questionIndex===3) {
    for (let index in allCyberAssetData) {
      if (allCyberAssetData[index]._id===params.row._id) {
          allCyberAssetData[index].consequenceThresholds = e;
        }
    }
  }
  // for (let index in allCyberAssetData) {
  //   console.log(index + 'remote access ' + allCyberAssetData[index].remoteAccess);
  //   console.log(index + 'connected ' + allCyberAssetData[index].remoteAccessConnected);
  //   console.log(index + 'conseq Determination' + allCyberAssetData[index].consequenceDetermination);
  //   console.log(index + 'conseq thresholds' + allCyberAssetData[index].consequenceThresholds);
  // }
  
}

const handleStartOver = async () => {
  setQuestionIndex(0);
  const waitForIt = await getCyberAssets();
  for (let index in allCyberAssetData) {
    allCyberAssetData[index].fercDesignation = "";
    allCyberAssetData[index].remoteAccess = "";
    allCyberAssetData[index].remoteAccessConnected = "";
    allCyberAssetData[index].consequenceDetermination = "";
    allCyberAssetData[index].populationAtRisk = "";
    allCyberAssetData[index].municipal = "";
    allCyberAssetData[index].powerGen1500MW = "";
    allCyberAssetData[index].powerGen100MW = "";
    allCyberAssetData[index].blackStart = "";
  }
  handleCyberAssetUpdate();
}

const handleNextQuestion = () => {
    handleCyberAssetUpdate();
    setQuestionIndex(questionIndex+1);
    setAllCyberAssetData(null);
}

const handleCompleteCyberDesignation = async () => {
  let cyberAssetDesignationComplete = true;
  try {
      const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
     JSON.stringify({ cyberAssetDesignationComplete }),
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
  );
  setActiveStep(3);
  } catch (err) {
      // alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }
}

// Columns for MUI Data Grid
const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
  },
  {
      field: "type",
      headerName: "Asset Type/Function",
      flex: 1,
},
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
  },
  {
      field: "assetDescription",
      headerName: "Description",
      flex: 1,
  },
  {
      field: "macAddress",
      headerName: "MAC Address",
      flex: 0.5,
  },
  {
      field: "yearPurchased",
      headerName: "Year Purchased (approximate)",
      flex: 0.5,
  },
  {
      field: "physicalLocation",
      headerName: "Physical Location",
      flex: 1,
  },
  {
    field: "Answer",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Box 
          sx={{ 
            "& .MuiRadio-root": {
              display:'inline',
            }
          }}>

        <RadioGroup row>
          <FormControlLabel
          label="Yes"
          control={
            <Radio
              value="yes"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
          <FormControlLabel
          label="No"
          control={
            <Radio
              value="no"
              onChange={(e) => UpdateRemoteAccessQuestions(e.target.value, params)}
              size="small"
            />
          }
          />
        </RadioGroup>
        </Box>
      )
    }
  }
];

const handleOpenInstructions = () => {
  setOpenInstructions(!openInstructions);
};


  return (
    <Box> 

      {/**INSTRUCTION BLOCK */}
      <Grid container spacing={0} sx={{ mt:'1rem' }}>
        <Grid item xs={12} >
        <Paper sx={{ backgroundImage: "none", backgroundColor: theme.palette.background.alt, borderRadius: "0.55rem", mr:'0.55rem', p:'4px'}} >
          <Grid item xs={12} sx={{ p:'0.5rem' }}>
          {openInstructions ? (
            <IconButton color="primary"  aria-label="open or close instructions" onClick={handleOpenInstructions} >
              <ExpandCircleDownIcon sx={{ transform: 'rotate(180deg)'}} color='primary' fontSize="large" />
              <Typography variant='h3' sx={{ ml: '1.5rem' }}><u>Instructions</u>:</Typography>
            </IconButton>
              ) : (
              <IconButton color="primary"  aria-label="open or close detection and response" onClick={handleOpenInstructions} >
                <ExpandCircleDownIcon color='primary' fontSize="large" />
                <Typography variant='h3' sx={{ ml: '1.5rem' }}>Instructions</Typography>
              </IconButton>
              )}
            <Divider sx={{ border:0.5, mt:'6px' }}/>
          </Grid>
          <Collapse in={openInstructions} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ ml:'2rem' }}>
                <Typography>1. Answer each question for each cyber asset in the table below, then press the <Button sx={{ cursor:'text', mr: '0.5rem', ml: '0.5rem', mb: '0.1rem', fontSize: '10pt', p: '0.2rem' }} variant="contained" >Next Question</Button> button.</Typography>
                <Typography>2. After once you have answered all questions for each cyber asset, press the <Button sx={{ cursor:'text', mr: '0.5rem', ml: '0.5rem', mb: '0.1rem', fontSize: '10pt', p: '0.2rem' }} variant="contained" >Complete Inventory Designation</Button> button. </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
     </Grid>

      <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleClickOpen} >Start Over</Button>
            </Grid>
            <Grid item xs={7}>

            </Grid>
            <Grid item xs={3}>
              <Button sx={{ mb: '0.5rem', fontSize: '30', p: '1rem' }} variant="contained" onClick={handleCompleteCyberDesignation} >Complete Inventory Designation</Button>
            </Grid>
          </Grid>
          <Divider />
        
          <Box sx={{ margin: '1rem' }}>
            {/** Pop up for question information. */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">
                Reset FERC Designation Questions
              </DialogTitle>
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography sx={{ p: '1rem' }}>
                  Are you sure you want to start over?  This will erase your answers. 
                </Typography>
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button onClick={handleYes} autoFocus>
                  Yes
              </Button>
              <Button onClick={handleNo} autoFocus>
                  No
              </Button>
              </DialogActions>
            </Dialog>
            <Grid container spacing={2}> 
            {questionIndex<=1 ? (
              <RemoteAccessDetermination questionIndex={questionIndex} setQuestionIndex={setQuestionIndex} setActiveStep={setActiveStep} />
            ) : (
              questionIndex===2 ? (
                
                <ConsequenceDetermination questionIndex={questionIndex} setQuestionIndex={setQuestionIndex} />
              ) : (
                questionIndex<=7 ? (
                  <ConsequenceThreshold questionIndex={questionIndex} setQuestionIndex={setQuestionIndex} />
                ) : (
                  <CyberAssetDeterminationResults />
                )
              )
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CyberSCADAAssetDesignation