import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Button, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, IconButton,
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WidgetWrapper from '../../../components/WidgetWrapper';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useReactToPrint } from 'react-to-print';

const AssessmentCards = ({
  _id,
  assessmentID,
  sectionTitle,
  sectionContent,
  sectionList,
  refresh,
  setRefresh

}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [assessmentToDelete, setAssessmentToDelete] = useState();
  const [damNameToDelete, setDamNameToDelete] = useState();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [newThreatName, setNewThreatName] = useState(sectionTitle);
  const [newThreatDescription, setNewThreatDescription] = useState(sectionContent);
 
  const newSections = [];
//  console.log(sectionList);
  const createSections = () => {
    if (sectionList?.length) {
      sectionList?.forEach(section => {newSections.push(section); });
    }
  };

  createSections();
  
  const handleDeleteAssessment = async () => {
    let id = _id;
    try {
        const response = await axiosPrivate.delete(`/rapidresponse/${id}`, 
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
    } catch (err) {
        console.error(err);
    }
    setRefresh(!refresh);
    setAssessmentToDelete(null);
    setDamNameToDelete(null);
    };
  
  const handleClickOpen = (_id, sectionTitle) => {
      setAssessmentToDelete(_id);
      setDamNameToDelete(sectionTitle);
      setOpen(true);
    };
  
  const handleNo = () => {
      setOpen(false);
  };

  const handleYes = () => {
      handleDeleteAssessment();
      setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sectionTitle = newThreatName;
    let sectionContent = newThreatDescription;
    let sectionList = newSections;
    let id = _id;
    let allAssessmentRapidResponseData = JSON.stringify({sectionTitle, sectionContent, sectionList})
    try {
      const response = await axiosPrivate.patch(`/rapidresponse/rapidresponse/${id}`,
       JSON.stringify({ allAssessmentRapidResponseData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
  
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
    setRefresh(!refresh)
};

const handleAddSection = (e) =>{
  // newSections.push(
  //   {
  //     name: '',
  //     procedures: ['']
  //   }
  // )
  // handleSubmit(e);
};

const handleRemoveSection = (e, index) =>{
  newSections.splice(index, 1);
  handleSubmit(e);
};

const handleAddMeasure = (e, index) => {
  // console.log(index);
  // console.log(newSections[index]);
  newSections.push("newMeasure");
  handleSubmit(e);
};

const handleRemoveMeasure = (e, index, locale) => {
  newSections.splice(index, 1);
  handleSubmit(e);
};

  return (
    <Box>
    <Dialog
      open={open}
      onClose={handleNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      {"Verify Delete Command"}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the {damNameToDelete} assessment?
      </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleYes}>Yes</Button>
      <Button onClick={handleNo} autoFocus>
          No
      </Button>
      </DialogActions>
    </Dialog>
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <WidgetWrapper>
      {isExpanded ? (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
          <ExpandCircleDownIcon sx={{ transform: 'rotate(270deg)'}} color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
        </IconButton>
        ) : (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
        </IconButton>
        )}
        <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
        <Box sx={{ display:'flex', justifyContent:'end' }}>
          <Button sx={{ marginRight: '1rem' }} variant='contained' onClick={() => {handleClickOpen(assessmentID, sectionTitle)}}>Delete</Button>
        </Box>
        <Typography variant='h3' sx={{textAlign: 'center' }}>Introduction Section</Typography>
        <Typography>Section Title</Typography>
          <textarea
            value={newThreatName || []}
            style={{ width:'100%', maxWidth:'100%' }}
            onChange={(e) => setNewThreatName(e.target.value)}
            onBlur={(e)=> handleSubmit(e)}
            >
          </textarea>
          {/* <Button 
                  variant="primary"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
              >
                  See More
              </Button> */}
              
              <Divider />
              <Typography>Description</Typography>
              <textarea 
                value = {newThreatDescription || []}
                style={{ height: '150px', width:'100%', maxWidth:'100%' }} 
                onChange={(e) => setNewThreatDescription(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
                
              </textarea>
              <Divider />

            {/** Modify to use newSections list. */}
            {/* {newSections.map((section, index)=> (
              <Box>
                <Box sx={{ display:'flex', justifyContent:'space-between', mb:'1rem' }}>
                  <Button variant='contained' onClick={(e)=>handleAddSection(e)}> Add NEW SECTION </Button>
                  <Typography variant='h3' sx={{textAlign: 'center' }}>{section.name} Section</Typography>
                  <Button variant='contained' onClick={(e)=> handleRemoveSection(e, index)}>Remove This Section</Button>
                </Box>
                
                <Typography>Title</Typography>
               <textarea 
                style={{ width:'100%', maxWidth:'100%' }}
                onChange={(e) => newSections[index].name=(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
                  {section}
                </textarea> */}
                {/* <Typography>{section.name}</Typography> */}
                <Typography>Measures</Typography>
                {newSections.map((section, index) => (
                  <Box>
                  <Box sx={{ p:'10px', borderRadius:'10px', border:'solid 1px' }}>
                    <Button onClick={(e)=> handleRemoveMeasure(e, index )}>Remove</Button>
                    <textarea 
                      style={{ height: '150px', width:'100%', ml:'1rem' }} 
                      onChange={(e) => newSections[index]=(e.target.value)}
                      onBlur={(e)=> handleSubmit(e)}
                    >{section}</textarea>
                  </Box>
                {/* ))} */}

                <IconButton sx={{ mb:'2rem' }} color="primary"  aria-label="add a critical asset" onClick={(e)=>handleAddMeasure(e, index)} >
                  <AddCircleIcon fontSize="large" /> Add Measure
                </IconButton>

                <Divider sx={{ mb:'1rem' }} />
              </Box>
            ))}
            
        </Collapse>
    </WidgetWrapper>

      </Grid>
    </Grid>
   
</Box>
  )
};

const ScenarioCards = ({
  _id,
    assessmentID,
    scenario,
    primaryConcerns,
    materialsEquipmentNeeded,
    operatingProcedures,
    refresh,
    setRefresh

}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [assessmentToDelete, setAssessmentToDelete] = useState();
  const [damNameToDelete, setDamNameToDelete] = useState();
  const [open, setOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [newThreatName, setNewThreatName] = useState(scenario);
  const [newThreatDescription, setNewThreatDescription] = useState(primaryConcerns);
 
  const newMaterialsList = [];
//  console.log(sectionList);
  const createSections = () => {
    if (materialsEquipmentNeeded?.length) {
      materialsEquipmentNeeded.forEach(section => {newMaterialsList.push(section); });
    }
  };

  const newOperatingProceduresList = [];
  //  console.log(sectionList);
    const createOpProcedures = () => {
      if (operatingProcedures?.length) {
        operatingProcedures.forEach(section => {newOperatingProceduresList.push(section); });
      }
    };

  createSections();
  createOpProcedures();
  
  const handleDeleteAssessment = async () => {
    let id = _id;
    try {
        const response = await axiosPrivate.delete(`/emergencyscenarios/${id}`, 
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
    } catch (err) {
        console.error(err);
    }
    setRefresh(!refresh);
    setAssessmentToDelete(null);
    setDamNameToDelete(null);
    };
  
  const handleClickOpen = (_id, sectionTitle) => {
      setAssessmentToDelete(_id);
      setDamNameToDelete(sectionTitle);
      setOpen(true);
    };
  
  const handleNo = () => {
      setOpen(false);
  };

  const handleYes = () => {
      handleDeleteAssessment();
      setOpen(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let scenario = newThreatName;
    let primaryConcerns = newThreatDescription;
    let materialsEquipmentNeeded = newMaterialsList;
    let operatingProcedures = newOperatingProceduresList
    let id = _id;
    let allAssessmentEmergencyScenarioData = JSON.stringify({scenario, primaryConcerns, materialsEquipmentNeeded, operatingProcedures})
    try {
      const response = await axiosPrivate.patch(`/emergencyscenarios/emergencyscenarios/${id}`,
       JSON.stringify({ allAssessmentEmergencyScenarioData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
  
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
    setRefresh(!refresh)
};

const handleAddSection = (e) =>{
  // newSections.push(
  //   {
  //     name: '',
  //     procedures: ['']
  //   }
  // )
  // handleSubmit(e);
};

const handleRemoveSection = (e, index) =>{
  newMaterialsList.splice(index, 1);
  handleSubmit(e);
};

const handleAddMeasure = (e, index) => {
  newMaterialsList.push("New Material/Equipment ");
  handleSubmit(e);
};

const handleAddOperatingProcedure = (e) => {
  newOperatingProceduresList.push('New Procedure');
  handleSubmit(e);
}

const handleRemoveMeasure = (e, index, locale) => {
  newMaterialsList.splice(index, 1);
  handleSubmit(e);
};

const handleRemoveOperatingProcedure = (e, index, locale) => {
  newOperatingProceduresList.splice(index, 1);
  handleSubmit(e);
};

  return (
    <Box>
    <Dialog
      open={open}
      onClose={handleNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
      {"Verify Delete Command"}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the {damNameToDelete} assessment?
      </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleYes}>Yes</Button>
      <Button onClick={handleNo} autoFocus>
          No
      </Button>
      </DialogActions>
    </Dialog>
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <WidgetWrapper>
      {isExpanded ? (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
          <ExpandCircleDownIcon sx={{ transform: 'rotate(270deg)'}} color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
        </IconButton>
        ) : (
        <IconButton color="primary"  aria-label="open or close detection and response" onClick={() => setIsExpanded(!isExpanded)} >
          <ExpandCircleDownIcon color='primary' fontSize="large" />
          <Typography variant='h2' sx={{ ml: '2rem' }}>{newThreatName}</Typography>
        </IconButton>
        )}
        <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ color: theme.palette.secondary[300] }}>
        <Box sx={{ display:'flex', justifyContent:'end' }}>
          <Button sx={{ marginRight: '1rem' }} variant='contained' onClick={() => {handleClickOpen(assessmentID, scenario)}}>Delete</Button>
        </Box>
        <Typography variant='h3' sx={{textAlign: 'center' }}>Scenario</Typography>
        <Typography>Scenario Title</Typography>
          <textarea
            value={newThreatName || []}
            style={{ width:'100%', maxWidth:'100%' }}
            onChange={(e) => setNewThreatName(e.target.value)}
            onBlur={(e)=> handleSubmit(e)}
            >
          </textarea>
          {/* <Button 
                  variant="primary"
                  size="small"
                  onClick={() => setIsExpanded(!isExpanded)}
              >
                  See More
              </Button> */}
              
              <Divider />
              <Typography>Primary Concerns</Typography>
              <textarea 
                value = {newThreatDescription || []}
                style={{ height: '150px', width:'100%', maxWidth:'100%' }} 
                onChange={(e) => setNewThreatDescription(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
                
              </textarea>
              <Divider />

            {/** Modify to use newSections list. */}
            {/* {newSections.map((section, index)=> (
              <Box>
                <Box sx={{ display:'flex', justifyContent:'space-between', mb:'1rem' }}>
                  <Button variant='contained' onClick={(e)=>handleAddSection(e)}> Add NEW SECTION </Button>
                  <Typography variant='h3' sx={{textAlign: 'center' }}>{section.name} Section</Typography>
                  <Button variant='contained' onClick={(e)=> handleRemoveSection(e, index)}>Remove This Section</Button>
                </Box>
                
                <Typography>Title</Typography>
               <textarea 
                style={{ width:'100%', maxWidth:'100%' }}
                onChange={(e) => newSections[index].name=(e.target.value)}
                onBlur={(e)=> handleSubmit(e)}
                >
                  {section}
                </textarea> */}
                {/* <Typography>{section.name}</Typography> */}
                <Typography>Materials and Equipment</Typography>
                {newMaterialsList?.map((section, index) => (
                  <Box sx={{ p:'10px', borderRadius:'10px', border:'solid 1px' }}>
                    <Button onClick={(e)=> handleRemoveMeasure(e, index )}>Remove</Button>
                    <textarea 
                      style={{ height: '150px', width:'100%', ml:'1rem' }} 
                      onChange={(e) => newMaterialsList[index]=(e.target.value)}
                      onBlur={(e)=> handleSubmit(e)}
                    >{section}</textarea>
                  </Box>
                ))}

                <IconButton sx={{ mb:'2rem' }} color="primary"  aria-label="add a critical asset" onClick={(e)=>handleAddMeasure(e)} >
                  <AddCircleIcon fontSize="large" /> Add Measure
                </IconButton>
              

                <Divider sx={{ mb:'1rem' }} />

                <Typography>Operating Procedures</Typography>
                {newOperatingProceduresList?.map((procedure, locale) => (
                  <Box>
                    <Button onClick={(e)=> handleRemoveOperatingProcedure(e, procedure )}>Remove</Button>
                    <textarea 
                    style={{ height: '150px', width:'100%', ml:'1rem' }} 
                    onChange={(e) => newMaterialsList[locale]=(e.target.value)}
                    onBlur={(e)=> handleSubmit(e)}
                  >{procedure}</textarea>
                  </Box>
                ))}
                 <IconButton sx={{ mb:'2rem' }} color="primary"  aria-label="add a critical asset" onClick={(e)=>handleAddOperatingProcedure(e)} >
                  <AddCircleIcon fontSize="large" /> Add Measure
                </IconButton>
            
        </Collapse>
    </WidgetWrapper>

      </Grid>
    </Grid>
   
</Box>
  )
};


const RapidRecoveryProcedures = () => {

  // **valueComplete should be the value of the next tab in the Step X page or the fercAssessmentPage. 
  const valueComplete = '4';
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [assessmentData, setAssessmentData] = useState(null);
  const [currentDam, setCurrentDam] = useState({});
  const { assessmentID } = useParams();
  const [overviewScopeData, setOverviewScopeData] = useState(null);
  const [allAssessmentRapidResponseData, setAllAssessmentRapidResponseData] = useState();
  const [allAssessmentEmergencyScenarioData, setAllAssessmentEmergencyScenarioData] = useState();
  const [refresh, setRefresh] = useState(false);

  const componentRef = useRef();
  const marginTop = '2em';
  const marginBottom = '4em';
  const marginLeft = '1em';
  const marginRight = '1em';


  // **GET current assessment data. 
  const getAssessment = async () => {
    try {
        const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
            withCredentials: true
        });
        const data = await response.data;
        console.log(data);
        setAssessmentData(data);
    }
    catch (err) {
        // alert("Login Expired.");
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
    }
  };


  // **GET Rapid Response Data for this assessment. 
  const getRapidResponseData = async () => {
    try {
      const response = await axiosPrivate.get(`/rapidresponse/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      setAllAssessmentRapidResponseData(data); 
      // console.log(data);
    } catch (err) {
      // alert("Login Expired. access control");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  }

  // **GET Rapid Response Data for this assessment. 
  const getEmergencyScenarioData = async () => {
    try {
      const response = await axiosPrivate.get(`/emergencyscenarios/${assessmentID}`, {
        withCredentials: true
      });
      const data = await response.data;
      setAllAssessmentEmergencyScenarioData(data); 
      // console.log(data);
    } catch (err) {
      // alert("Login Expired. access control");
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  }



    // Execute these functions on load. Add functions as necessary. 
    useEffect(() => {
      getAssessment();
      getRapidResponseData();
      getEmergencyScenarioData();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps


    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'emp-data',
      // onAfterPrint: () => alert('Print Success!')
    });
  
    const createDoc = () => {
      let tempList = [];
      tempList.push(<Typography variant='h2' sx={{ mt: '2rem', textAlign:'center' }}><u><b>Rapid Recovery Procedures</b></u></Typography>)
      tempList.push(
        <TableContainer sx={{ mt:'1rem' }}>
          <Table aria-label='table for rapid recovery procedures'>
            <TableBody>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Dam:</b></TableCell>
                <TableCell colSpan={3} sx={{ border:'solid 1px'}}>{assessmentData.damName}</TableCell>
              </TableRow>
              <TableRow >
                <TableCell sx={{ border:'solid 1px'}}><b>Assessment Type:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}} >{assessmentData.assessmentType}</TableCell>
                <TableCell sx={{ border:'solid 1px'}}><b>Prepared for Fiscal Year:</b></TableCell>
                <TableCell sx={{ border:'solid 1px'}}>{assessmentData.assessmentYear}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )
      for (let section in allAssessmentRapidResponseData) {
        tempList.push( <Typography variant='h3' sx={{ mt: '2rem' }} ><u><b>{allAssessmentRapidResponseData[section].sectionTitle}</b></u></Typography>);
        tempList.push(<style>{getPageMargins()}</style>);
        tempList.push( <Typography sx={{ m:'2rem', whiteSpace:'pre-wrap' }}>{allAssessmentRapidResponseData[section].sectionContent}</Typography>);
        // let part = JSON.parse(allAssessmentRapidResponseData[section].sectionList);
        for (let index in allAssessmentRapidResponseData[section].sectionList) {
          tempList.push(<Typography variant='h3'sx={{ m:'1rem' }} >{allAssessmentRapidResponseData[section].sectionList[index]}</Typography>);
        }
      }
      tempList.push(<Typography variant='h2' sx={{ mt: '2rem' }}><u><b>Appendix: Emergency Scenarios</b></u></Typography>)
      for (let index in allAssessmentEmergencyScenarioData) {
        tempList.push(<Typography variant='h3' sx={{ mt: '2rem', whiteSpace:'pre-wrap' }}><u><b>{allAssessmentEmergencyScenarioData[index].scenario}</b></u></Typography>);
        tempList.push(<Typography sx={{ m:'2rem', whiteSpace:'pre-wrap' }}><b>Primary Concerns: </b>{allAssessmentEmergencyScenarioData[index].primaryConcerns}</Typography>);
        for (let item in allAssessmentEmergencyScenarioData[index].materialsEquipmentNeeded) {
          tempList.push(<Typography sx={{ m:'2rem' }}><b>Materials and Equipment Needed: </b>{allAssessmentEmergencyScenarioData[index].materialsEquipmentNeeded[item]}</Typography>);
        }
        for (let procedure in allAssessmentEmergencyScenarioData[index].operatingProcedures) {
          tempList.push(<Typography sx={{ m:'2rem', whiteSpace:'pre-wrap' }} ><b>Operating Procedures: </b>{allAssessmentEmergencyScenarioData[index].operatingProcedures[procedure]}</Typography>);
        }
      } 
      return (
        tempList
      )
    };

    const getPageMargins = () => {
      return `@page {margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    }

    {/** ADD NEW INTERNAL RESPOSE SECTION */}
  const handleAddSection = async () => {
    
    let sectionTitle = 'New Title';
    let sectionContent = 'New Section';
    let sectionList = ['New Measure'];
    let allAssessmentRapidResponseData = JSON.stringify({sectionTitle, sectionContent, sectionList})
    const response = await axiosPrivate.post('/rapidresponse/new',
      JSON.stringify({ assessmentID, allAssessmentRapidResponseData }),
        {
          headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
      );
      setRefresh(!refresh);
    };
    

  return (
    <Box>

{assessmentData===null ? (
        // LOADING
        <Dialog
          open={assessmentData===null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          >
          <DialogTitle id="alert-dialog-title"> </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Typography variant='h2'>Loading...</Typography>
          </DialogContentText>
          </DialogContent>
        </Dialog>
      ):(
        // Check for hasInternalEmergencyResponseRapidRecoveryProcedures
        (assessmentData?.hasInternalEmergencyResponseRapidRecoveryProcedures ? (
          <>
            <WidgetWrapper sx={{ display:'flex', justifyContent:'center', alignItems:'center', mt:'1rem' }}>
              <Typography>Add your existing Rapid Recover Plan to this site's Security Plan.</Typography>
              <Typography>{assessmentData?.hasInternalEmergencyResponseRapidRecoveryProcedures}</Typography>
            </WidgetWrapper>
          </>
        ):(
        (assessmentData?.hasInternalEmergencyResponseRapidRecoveryProcedures===true ? (
          <>
            <WidgetWrapper sx={{ display:'flex', justifyContent:'center', alignItems:'center', mt:'1rem' }}>
              <Typography>Add your existing Key Control procedures to this site's Security Plan.</Typography>
            </WidgetWrapper>
          </>
        ):(
          <Grid container spacing={2}>
          <Grid item xs={12}>
                 <Box sx={{ display:'flex', justifyContent:'space-between'}}>
                  <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handleAddSection}>ADD New Section</Button>
                  <Button sx={{ m: '0.5rem', fontSize: '30', p: '1rem', width: '10%' }} variant='contained' onClick={handlePrint}>Print Rapid Recovery Procedures</Button>
                 </Box>
              
               </Grid>
               <Grid item xs={6}>
               <Box m="1.5rem 2.5rem">
                <Box mt="20px" display="grid" gridTemplateColumns="repeat(1, minmax(0, 1fr))" 
                    justifyContent="space-between" 
                    rowGap="20px" 
                    columnGap="1.33%" 
                    // sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" } }}
                >
                    {allAssessmentRapidResponseData?.map(
                      ({  
                        _id,
                        assessmentID,
                        sectionTitle,
                        sectionContent,
                        sectionList,
                    }) => (
                        <AssessmentCards
                            key={_id}
                            _id={_id}
                            assessmentID={assessmentID}
                            sectionTitle={sectionTitle}
                            sectionContent={sectionContent}
                            sectionList={sectionList}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                        )
                    )}
<Divider />
<Typography variant='h2'>Appendix: Emergency Scenarios</Typography>
{allAssessmentEmergencyScenarioData?.map(
  ({
    _id,
    assessmentID,
    scenario,
    primaryConcerns,
    materialsEquipmentNeeded,
    operatingProcedures
  }) => (
    <ScenarioCards
      key={_id}
      _id={_id}
      assessmentID={assessmentID}
      scenario={scenario}
      primaryConcerns={primaryConcerns}
      materialsEquipmentNeeded={materialsEquipmentNeeded}
      operatingProcedures={operatingProcedures}
      refresh={refresh}
      setRefresh={setRefresh}
    />
  )
)}
                </Box>
            </Box>
               </Grid>
    
                 <Grid item xs={6}>
                  <Paper sx={{ pt:'2em', pr:'5em', pl:'5em' }} ref={componentRef}>
                  
                    {createDoc()}
                   </Paper>
                 </Grid>
    
               </Grid>
          ))
        ))
      )}

    </Box>
  )
}


export default RapidRecoveryProcedures