import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import QuestionnaireForm from '../../../../components/QuestionnaireForm';
import { Box, Button, Divider, FormControlLabel , Typography, useTheme, Grid, Paper, useMediaQuery } from "@mui/material";

const SystemRestorationRecoveryQuestions = ({questionFamily, setQuestionFamily}) => {


    // *************** Questionnaire specific Variables***************//
  
    const [hasRestorationRecoveryPlan, setHasRestorationRecoveryPlan] = useState("");
    const [doesTestRestorationRecoveryPlan, setDoesTestRestorationRecoveryPlan] = useState("");
    const [hasBackupSite, setHasBackupSite] = useState("");
    const [hasRestorationRecoveryPlanComments, setHasRestorationRecoveryPlanComments] = useState("");
    const [doesTestRestorationRecoveryPlanComments, setDoesTestRestorationRecoveryPlanComments] = useState("");
    const [hasBackupSiteComments, setHasBackupSiteComments] = useState("");




   // *************** Questionnaire specific Variables***************//

  const [assessmentData, setAssessmentData] = useState();
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");             // From mern_app project
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentID } = useParams();
  const theme = useTheme();     


  const getAssessmentData = async () => {
    try {
      const response = await axiosPrivate.get(`/assessment/${assessmentID}`, {
        withCredentials: true
    });
    const data = await response.data;
    setAssessmentData(data);
    setHasRestorationRecoveryPlan(data?.hasRestorationRecoveryPlan?.toString());
    setDoesTestRestorationRecoveryPlan(data?.doesTestRestorationRecoveryPlan?.toString());
    setHasBackupSite(data?.hasBackupSite?.toString());
    setHasRestorationRecoveryPlanComments(data?.hasRestorationRecoveryPlanComments?.toString());
    setDoesTestRestorationRecoveryPlanComments(data?.doesTestRestorationRecoveryPlanComments?.toString());
    setHasBackupSiteComments(data?.hasBackupSiteComments?.toString());

    } catch (err) {
      alert("Login Expired.");
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
  }

    if (assessmentData === null) {
        return;
    }
};

useEffect(() => {
    getAssessmentData();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  if (!assessmentData) {
    return null;
  }

 // *************** Update to use Questionnaire specific Variables***************//
  const handleSubmit = async (e) => {
    e.preventDefault();
    let systemRestorationRecoveryQuestions = true;
    const systemRestorationRecoveryData = JSON.stringify({ hasRestorationRecoveryPlan,
      doesTestRestorationRecoveryPlan,
      hasBackupSite,
      hasRestorationRecoveryPlanComments,
      doesTestRestorationRecoveryPlanComments,
      hasBackupSiteComments,
    });
    try {
        const response = await axiosPrivate.patch(`/assessment/${assessmentID}`,
       JSON.stringify({ systemRestorationRecoveryQuestions, systemRestorationRecoveryData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    );
    // questionFamily(7) activates the Asset Management questions. 
    setQuestionFamily(7)
    } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
    }
}

const buttonStyle = {
  'backgroundColor': '#00D5FA',
  'width': '35%',
  'marginTop': '15px',
  'padding': '10px'
}

  const GridItemQuestionXS = 7;
  const GridItemInputXS = 2;
  const GridItemInputSX = 'center';
  const inputWidth = '18px';
  const inputHeight = '18px';
  const GridItemCommentsXS = 3;
  const inputCommentWidth = '100%';



  //************Add category specific Questions.****************//
  const SystemRestorationRecoveryQuestionsList = [
    {
    // 1	Do you have restoration and recovery of plan for control systems?
    //	hasRestorationRecoveryPlan	setHasRestorationRecoveryPlan	hasRestorationRecoveryPlanComments	setHasRestorationRecoveryPlanComments
      uid: 'SRR-1',
      variable: hasRestorationRecoveryPlan,
      questionNumber: '1',
      questionText: 'Do you have restoration and recovery of plan for control systems?',
      name: "hasRestorationRecoveryPlan",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setHasRestorationRecoveryPlan,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "hasRestorationRecoveryPlanComments",
      valueComment: hasRestorationRecoveryPlanComments,
      onChangeComments: setHasRestorationRecoveryPlanComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
      // 2	Do you review, update, and test the plan at least annually?
      // 	doesTestRestorationRecoveryPlan	setDoesTestRestorationRecoveryPlan	doesTestRestorationRecoveryPlanComments	setDoesTestRestorationRecoveryPlanComments
      uid: 'SRR-2',
      variable: doesTestRestorationRecoveryPlan,
      questionNumber: '2',
      questionText: 'Do you review, update, and test the plan at least annually?',
      name: "doesTestRestorationRecoveryPlan",
      type: 'radio',
      answerType: 'YesNo', 
      //***Only used for question type 'checkbox'. */
      checkboxInputs: [
        {
          // answer options for checkbox
        },
        {
          // answer options for checkbox
        }
      ], 
      onChangeInput: setDoesTestRestorationRecoveryPlan,
      //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
      comments: 'true',
      idComment: "doesTestRestorationRecoveryPlanComments",
      valueComment: doesTestRestorationRecoveryPlanComments,
      onChangeComments: setDoesTestRestorationRecoveryPlanComments,
      //***Modify these settings if you want to change them from the defaults for this specific question. */
      GridItemQuestionXS: GridItemQuestionXS,
      GridItemInputXS: GridItemInputXS,
      GridItemCommentsXS: GridItemCommentsXS,
      GridItemInputSX: GridItemInputSX,
      inputStyleWidth: inputWidth,
      inputStyleHeight: inputHeight,
      inputCommentWidth: inputCommentWidth,
    }, 
    {
    // 3	Does a back-up site exist and are systems routinely backed-up for ICS and non-ICS networks?	hasBackupSite
    //	setHasBackupSite	hasBackupSiteComments	setHasBackupSiteComments
        uid: 'SRR-3',
        variable: hasBackupSite,
        questionNumber: '3',
        questionText: 'Does a back-up site exist and are systems routinely backed-up for ICS and non-ICS networks?',
        name: "hasBackupSite",
        type: 'radio',
        answerType: 'YesNo', 
        //***Only used for question type 'checkbox'. */
        checkboxInputs: [
        {
            // answer options for checkbox
        },
        {
            // answer options for checkbox
        }
        ], 
        onChangeInput: setHasBackupSite,
        //***If this question has a comments section, set 'comments: true' and complete 'idComment', 'valueComment', 'onChangeComments' */
        comments: 'true',
        idComment: "hasBackupSiteComments",
        valueComment: hasBackupSiteComments,
        onChangeComments: setHasBackupSiteComments,
        //***Modify these settings if you want to change them from the defaults for this specific question. */
        GridItemQuestionXS: GridItemQuestionXS,
        GridItemInputXS: GridItemInputXS,
        GridItemCommentsXS: GridItemCommentsXS,
        GridItemInputSX: GridItemInputSX,
        inputStyleWidth: inputWidth,
        inputStyleHeight: inputHeight,
        inputCommentWidth: inputCommentWidth,
    }, 
  ]


  return (
   <Box>
     <Grid container spacing={2} columns={12}>
      <Grid item xs={12}>
          <Box
          width="100%"
          p="1rem"
          // m="1rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
          >
    <section>
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
      </p>
      <h1>System Restoration and Recovery</h1>
      <form>
      <Box
          display="grid"
          alignItems="downward"
          gridTemplateColumns="repeat(4, minmax(1fr, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4"},
          }}
      >
        {SystemRestorationRecoveryQuestionsList.map(({
          uid,
          variable,
          GridItemQuestionXS,
          questionNumber,
          questionText,
          GridItemInputXS,
          GridItemInputSX,
          inputStyleWidth,
          inputStyleHeight,
          name,
          type,
          answerType,
          checkboxInputs,
          onChangeInput,
          comments,
          GridItemCommentsXS,
          inputCommentWidth,
          idComment,
          valueComment,
          onChangeComments
        }) => (
          <QuestionnaireForm 
            key={uid}
            variable={variable}
            GridItemQuestionXS={GridItemQuestionXS}
            questionNumber={questionNumber}
            questionText={questionText}
            GridItemInputXS={GridItemInputXS}
            GridItemInputSX={GridItemInputSX}
            inputStyleWidth={inputStyleWidth}
            inputStyleHeight={inputStyleHeight}
            name={name}
            type={type}
            answerType={answerType}
            checkboxInputs={checkboxInputs}
            onChangeInput={onChangeInput}
            comments={comments}
            GridItemCommentsXS={GridItemCommentsXS}
            inputCommentWidth={inputCommentWidth}
            idComment={idComment}
            valueComment={valueComment}
            onChangeComments={onChangeComments}
            />
          )
          )}
          {/* <button disabled={( knowsAssetCriticality==="" || doesRemoveConfigs==="" )} style={buttonStyle}>Submit</button> */}
          <Button disabled={( hasRestorationRecoveryPlan ==="" || doesTestRestorationRecoveryPlan ==="" || hasBackupSite ==="" )} sx={{ m: '1rem', width: '25%' }} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
      </form>
    </section>
    </Box>
    </Grid>
    </Grid>
   </Box> 
  )
}

export default SystemRestorationRecoveryQuestions