import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, Button, Typography, useTheme, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';
import DataGridCustomToolbar from '../components/DataGridCustomToolbar';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';



// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;
  


//   const handleClick = () => {
//     const id = randomId();
//     setRows((oldRows) => [...oldRows, { id, assetName: '', assetType: '', localID: '', manufacturer: '', model: '', description: '', macAddress: '', physicalLocation: '', yearPurchased: '',  isNew: true }]);
//     setRowModesModel((oldModel) => ({
//       ...oldModel,
//       [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
//     }));
//   };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         Add record
//       </Button>
//     </GridToolbarContainer>
//   );
// }

export default function FullFeaturedCrudGrid(refresh) {
  const [rows, setRows] = React.useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const theme = useTheme();
  const axiosPrivate = useAxiosPrivate();

  // const [dialogTitle, setDialogTitle] = useState();
  // const [dialogMessage, setDialogMessage] = useState();
  // const [dialogReference, setDialogReference] = useState();
  // const [dialogReferenceLink, setDialogReferenceLink] = useState();

  // const [description, setDescription] = useState("");
  // const [manufacturer, setManufacturer] = useState("");
  // const [macAddress, setMACAddress] = useState("");
  // const [yearPurchased, setYearPurchased] = useState("");
  // const [assetName, setAssetName] = useState(null);
  // const [assetType, setAssetType] = useState(null);
  // const [model, setModel] = useState("");
  // const [physicalLocation, setPhysicalLocation] = useState("");
  // const [localID, setLocalID] = useState("");

  const date = new Date();
  const currentYear = date.getFullYear();

  const [cyberAssetToDelete, setCyberAssetToDelete] = useState(null);
  const [cyberAssetName, setCyberAssetName] = useState(null);

  const [open, setOpen] = useState(false);

  const {assessmentID} = useParams();


  // Get cyberAssets Function.  This should pull cyberAssets for the current assessment. 
  const getCyberAssets = async () => {
    try {
      const response = await axiosPrivate.get(`/assets/cyberassets/${assessmentID}`, {
          withCredentials: true
      });
      const data = await response.data;
      for (let i=0; i<data.length; i++) {
        if (data[i].cyberAssetData) {
          let assetData = JSON.parse(data[i]?.cyberAssetData);
          for (let key in assetData) {
            data[i][key] = assetData[key];
          }
        }
    }
      setRows(data);
      if (rows === null) {
          return;
      }
    } catch (err) {
      alert("Login Expired. Overview");
      console.error(err);
    }
  };

  useEffect(() => {
      getCyberAssets();
    }, [refresh]);  // eslint-disable-line react-hooks/exhaustive-deps
    if (!rows) {
      return null;
    }

  
  // Update Database Function 
  const handleAddCyberAsset = () => {
    setRows(null);
  }

  const handleRowEditStop = (id, event) => {
    if (id.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (row) => () => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.Edit } });
  };

  // Update Database Function 
  const handleCyberAssetUpdate = async (updatedRow) => {
    let id = updatedRow._id;
    let assetName = updatedRow.assetName;
    let assetType = updatedRow.assetType;
    let manufacturer = updatedRow.manufacturer;
    let model = updatedRow.model;
    let macAddress = updatedRow.macAddress;
    let yearPurchased = updatedRow.yearPurchased;
    let physicalLocation = updatedRow.physicalLocation;
    let description = updatedRow.description;
    const cyberAssetData = JSON.stringify({ manufacturer, model, macAddress, yearPurchased, physicalLocation, description });
    try {
      const response = await axiosPrivate.patch(`/assets/cyberasset/${id}`,
        JSON.stringify({ assessmentID, assetName, assetType, cyberAssetData }),
        {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
        );
        const data = await response.data;
        // Axios provides responses in JSON by default. The response JSON is always named 'data'. 
        // The console log commands below allow you to see the responses from Axios for the response.  
        // navigate(from, { replace: true });
      } catch (err) {
        if (!err?.response) {
            console.log('No Server Response');
        } else {
            console.log('Action Failed')
        }
      }
    };

  const handleSaveClick = (row) => {
    setRowModesModel({ ...rowModesModel, [row._id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row._id === newRow._id ? updatedRow : row)));
    handleCyberAssetUpdate(updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleDeleteAsset = async () => {
    try {
        const response = await axiosPrivate.patch(`/assets/cyberasset/${cyberAssetToDelete}`, 
            ({ "active": false }),
          {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
          }
      );
        if (response.data.success) {
            alert(response.data.msg);
        } 
        // console.log(response);
    } catch (err) {
        console.error(err);
    }
     setCyberAssetToDelete(null);
     setCyberAssetName(null);
     getCyberAssets();
 }
 
 const handleClickOpen = (params) => {
     setCyberAssetToDelete(params.row._id);
     setCyberAssetName(params.row.assetName || "this device");
     setOpen(true);
   };

  const handleNo = () => {
    setOpen(false);
};

const handleYes = () => {
    handleDeleteAsset();
    setOpen(false);
};

const columns = [
  {
      field: "assetName",
      headerName: "Name",
      flex: 1,
      editable: true
  },
  {
      field: "assetType",
      headerName: "Asset Type/Function",
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Application Server', 'Control Server', 'Data Gateway', 'Data Historian', 'Field I/O', 'Human Machine Interface', 'Intelligent Electronic Device', 'Jump Host', 'Programmable Logic Controller', 'Remote Terminal Unit', 'Router', 'Safety Controller', 'VPN Server', 'Workstation'],
  },
  {
      field: "localID",
      headerName: "Property Tag or Local ID",
      flex: 0.3,
      editable: true
  },
  {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 0.75,
      editable: true
  },
  {
      field: "model",
      headerName: "Model",
      flex: 0.5,
      editable: true
  },
  {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true
  },
  {
      field: "macAddress",
      headerName: "MAC Address",
      flex: 0.5,
      editable: true
  },
  {
      field: "yearPurchased",
      headerName: "Year Purchased (approximate)",
      flex: 0.5,
      type: "year",
      editable: true,
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            max: currentYear,
            min: 1980,
          }}
        />
      ),
  },
  {
      field: "physicalLocation",
      headerName: "Physical Location",
      flex: 1,
      editable: true
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: (params) => {
      const isInEditMode = rowModesModel[params.row._id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={() => handleSaveClick(params.row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(params.row._id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params.row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleClickOpen(params)}
          color="inherit"
        />,
      ];
    },
  },
];

// console.log("ROW Data is: ");
// console.log(typeof(rows));
// console.log(rows);

  return (
    <Grid container spacing={2} sx={{mb: '1rem'}}>
      <Box sx={{ mt:'1rem', flexGrow: 1,  padding: "1.5rem 1.5rem 0.75rem 1.5rem", backgroundColor: theme.palette.background.alt, borderRadius: "0.75rem" }}>
        <h3>Current Inventory</h3>
        <Grid item xs={12} >
          <Dialog
                  open={open}
                  onClose={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">
                  {"Verify Delete Command"}
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete {cyberAssetName}?
                  </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Button onClick={handleYes}>Yes</Button>
                  <Button onClick={handleNo} autoFocus>
                      No
                  </Button>
                  </DialogActions>
          </Dialog>
          <Box
            sx={{
              height: 500,
              width: '100%',
              "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.secondary.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "solid 2px black",
                  borderTop: "solid 2px black"
              },
              "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary[100],
                  borderTop: "none"
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
              },
          }}
          >
            <DataGrid
              rows={rows || []} 
              getRowId={(row) => row._id}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                toolbar: DataGridCustomToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}